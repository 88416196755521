import React from 'react'
import styled from 'styled-components'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'

const EmptyBpList = ({ favourites, style }) => {
  const currentUserRole = useSelector(getCurrentUserRole)
  const isResponsiblePerson = currentUserRole === 'responsible_person'

  return (
    <PanelWrapper style={style}>
      {
        favourites && (
          <Text>
            <Header>
              No favourite Building Passports
            </Header>
            Add one from
            <Link to={PRIVATE_PATHS.bpList}>
              &nbsp;Your BPs&nbsp;
            </Link>
          </Text>
        )
      }
      {
        !favourites && (
          <>
            <Text>
              {
                isResponsiblePerson
                  ? 'You have no access to any Building Passport'
                  : 'Create your first building passport'
              }
            </Text>
            {
              !isResponsiblePerson && (
                <CreateBpButton
                  onClick={() => redirect(PRIVATE_PATHS.createBp)}
                >
                  + Create new Building Passport
                </CreateBpButton>
              )
            }
          </>
        )
      }
    </PanelWrapper>
  )
}

export default EmptyBpList

const PanelWrapper = styled.div`
  box-sizing: border-box;
  max-width: 600px;
  width: 100%;
  margin: 50px auto 0;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
`

const CreateBpButton = styled.button`
  outline: 0;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  border: 3px solid ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 0 20px rgba(41, 152, 163, 0.31);
  border-radius: 20px;
  min-width: 200px;
  height: 40px;
  transition: all .3s;
  cursor: pointer;
  font-size: 16px;
  padding: 2px 10px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 22px;
  text-align: center;
  
  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`

const Header = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
`
