import { createSelector } from 'reselect'
import { pathOr, propOr } from 'ramda'

const billingList = state => state.billingList

export const getTransactionsList = createSelector(
  billingList,
  pathOr([], ['entries', 'transactions'])
)

export const isInvoiceLoading = createSelector(
  billingList,
  propOr([], 'isLoading')
)
