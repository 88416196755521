import React from 'react'
import Button from 'components/atoms/Button'
import styled, { css } from 'styled-components'

export const PrimaryButton = props => {
  return (
    <StyledButton variant='contained' color='primary' {...props}>
      {props.children}
    </StyledButton>
  )
}

export default PrimaryButton

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary} !important;
  
  ${({ disabled }) => disabled && css`
    background-color: ${({ theme }) => theme.colors.grey[500]} !important;
    color: ${({ theme }) => theme.colors.grey[600]};
    border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  `}
`
