import { object, string } from 'yup'
import { validateField, validateValues } from 'utils/form'

const urlPattern = new RegExp('^$|^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

export const editUserSchema = object().shape({
  email: string()
    .required('Email is required')
    .email('Wrong email address'),
  firstName: string()
    .required('First name is required')
    .min(2, 'First name must contain at least 2 characters')
    .matches(/^\D*$/, {
      message: 'Incorrect value'
    }),
  lastName: string()
    .required('Last name is required')
    .min(2, 'Last name must contain at least 2 characters')
    .matches(/^\D*$/, {
      message: 'Incorrect value'
    }),
  company: string()
    .nullable(),
  postalCode: string()
    .required('Postal code is required'),
  city: string()
    .required('City is required'),
  street: string()
    .required('Address is required'),
  linkedinUrl: string()
    .matches(urlPattern, 'URL is not valid')
    .nullable()
    .notRequired()
})
export const validateEditUserField = values => validateField(editUserSchema, values)
export const validateEditUserValues = validateValues(editUserSchema)
