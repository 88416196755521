import React, { useCallback, useEffect, useState } from 'react'
import { head, pathOr, propOr } from 'ramda'
import Input from 'components/atoms/Input'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersByEmailRoutine } from 'features/bpDetails/ducks/actions'
import { isNotEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { validateEmailValue } from 'features/createBp/ducks/schema'
import PhoneField from 'components/atoms/PhoneInput'
import { getOwners } from 'features/bpDetails/ducks/selectors'

const PersonDetailsForm = ({
  type,
  values,
  onChange,
  validate,
  formValid,
  addPerson,
  setValues,
  key
}) => {
  const { t } = useTranslation()
  const [formVisible, setFormVisible] = useState(false)
  const [autoFilled, setAutoFilled] = useState(false)
  const [email, setEmail] = useState(propOr('', 'email', values))
  const [hasValidEmail, setHasValidEmail] = useState(false)
  const dispatch = useDispatch()
  const foundOwners = useSelector(getOwners)
  const [phoneValid, setPhoneValid] = useState(false)

  const searchUser = useCallback(() => {
    setFormVisible(true)
    dispatch(getUsersByEmailRoutine({ email, type }))
  }, [dispatch, email])

  useEffect(() => {
    validateEmailValue({ email }, setHasValidEmail)
  }, [email])

  const handleChangeEmail = (name, value) => {
    onChange(name, value)
    setEmail(value)
  }

  const handlePhoneNumberChange = (name, value) => {
    onChange(name, value)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(foundOwners) && type === 'owner') {
      setValues('owner', {
        ...head(foundOwners),
        email
      })
      setAutoFilled(true)
    } else if (!isNotNilOrEmpty(foundOwners) && type === 'owner') {
      setValues('owner', {
        email
      })
      setAutoFilled(false)
    }
  }, [foundOwners])

  useEffect(() => {
    isNotEmpty(email) && setFormVisible(true)
  }, [])

  return (
    <FormWrapper key={key}>
      <Input
        key={`email-input-${type}`}
        type='email'
        required
        label={t('signUp.labels.email')}
        value={pathOr('', ['email'], values)}
        name='email'
        onChange={handleChangeEmail}
        validate={validate}
      />
      <CheckButtonWrapper>
        <CheckEmailButtom disabled={!hasValidEmail} type='button' onClick={searchUser}>check email</CheckEmailButtom>
      </CheckButtonWrapper>
      {
        formVisible && (
          <>
            <SectionName>personal details</SectionName>
            <Input
              required
              label={t('signUp.labels.firstName')}
              value={pathOr('', ['firstName'], values)}
              name='firstName'
              onChange={onChange}
              validate={validate}
              disabled={autoFilled}
            />
            <Input
              required
              label={t('signUp.labels.lastName')}
              value={pathOr('', ['lastName'], values)}
              name='lastName'
              onChange={onChange}
              validate={validate}
              disabled={autoFilled}
            />
            <PhoneField
              name='phoneNumber'
              label='Phone number'
              value={pathOr('', ['phoneNumber'], values)}
              onChange={handlePhoneNumberChange}
              validate={valid => setPhoneValid(valid)}
              disabled={autoFilled}
              noValidation={autoFilled}
              optional
            />
            <ButtonWrapper>
              <AddButton
                disabled={!formValid || !phoneValid}
                onClick={addPerson}
              >
                Save
              </AddButton>
            </ButtonWrapper>
          </>
        )
      }
    </FormWrapper>
  )
}

export default PersonDetailsForm

const FormWrapper = styled.form`
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 25px;
`

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const SectionName = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: .1px;
  margin: 15px 0;
`

const AddButton = styled.button`
  width: 195px;
  height: 36px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 2px;
  transition: all .3s;
  outline: none;
  box-shadow: none;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.grey[500]};
    border-color: ${({ theme }) => theme.colors.grey[500]};

    &:hover {
      color: ${({ theme }) => theme.colors.grey[500]};
      background-color: ${({ theme }) => theme.colors.white};
    }
  `}
`

const CheckEmailButtom = styled.button`
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  transition: all .3s;
  border-radius: 4px;
  padding: 5px 15px;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.haiti};
    border-color: ${({ theme }) => theme.colors.haiti};
  }

  ${({ disabled, theme }) => disabled && css`
    background-color: ${theme.colors.grey[500]};
    color: ${theme.colors.darkGrey};
    border-color: ${theme.colors.grey[900]};
    cursor: default;

    &:hover {
      background-color: ${theme.colors.grey[500]};
      color: ${theme.colors.grey[900]};
      border-color: transparent;
    }
  `}
`

const CheckButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
