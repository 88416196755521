import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserFirstName } from 'features/auth/ducks/selectors'
import PageHeader from 'components/atoms/PageHeader'
import styled from 'styled-components'
import ProfileInfo from 'features/profileSettings/components/ProfileInfo'
import { isNotNilOrEmpty } from 'utils/ramda'
import BpBox from 'features/dashboard/components/BpBox'
import {
  getBuildingTypes,
  getUserBps
} from 'features/bpList/ducks/selectors'
import { fetchUserBpsRoutine } from 'features/bpList/ducks/actions'
import { isEmpty, propOr, values } from 'ramda'
import TemplateBox from 'features/dashboard/components/TemplateBox'
import DashboardEmptyState from 'features/dashboard/components/DashboardEmptyState'
import { getDashboardStats, getLastViewedBps } from 'features/dashboard/ducks/selectors'
import { fetchDashboardStatisticsRoutine, fetchLastViewedBpsRoutine } from 'features/dashboard/ducks/actions'

const SectionTitle = ({ children }) => {
  return (
    <SectionTitleWrapper>
      <SectionTitleText>{children}</SectionTitleText>
      <SectionUnderline />
    </SectionTitleWrapper>
  )
}

const Dashboard = () => {
  const currentUserName = useSelector(getUserFirstName)
  const dashboardStats = useSelector(getDashboardStats)
  const bps = useSelector(getUserBps)
  const lastViewed = useSelector(getLastViewedBps)
  const buildingTypes = useSelector(getBuildingTypes)
  const dispatch = useDispatch()

  const templatesStats = useMemo(() => {
    const templates = propOr({}, 'analyticsTemplates', dashboardStats)
    if (isNotNilOrEmpty(templates)) {
      return values(templates).map(template => {
        let ranges = { 1: [], 2: [], 3: [], 4: [], 5: []}
        propOr([], 'percentageValues', template).forEach(percentage => {
          switch (true) {
            case percentage < 20:
              ranges = { ...ranges, [1]: [ ...ranges[1], percentage ]}
              break
            case percentage > 19 && percentage < 50:
              ranges = { ...ranges, [2]: [ ...ranges[2], percentage ]}
              break
            case percentage > 49 && percentage < 75:
              ranges = { ...ranges, [3]: [ ...ranges[3], percentage ]}
              break
            case percentage > 74 && percentage < 100:
              ranges = { ...ranges, [4]: [ ...ranges[4], percentage ]}
              break
            default:
              ranges = { ...ranges, [5]: [ ...ranges[5], percentage ]}
              break
          }
        })
      return { ...template, percentageValues: ranges }
      })
    } else {
      return []
    }
  }, [dashboardStats])

  useEffect(() => {
    dispatch(fetchLastViewedBpsRoutine())
    dispatch(fetchDashboardStatisticsRoutine())
    dispatch(fetchUserBpsRoutine({
      page: 1,
      sort: '-created_at'
    }))
  }, [])

  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeaderWrapper>
          <PageHeader>Welcome back, {currentUserName}!</PageHeader>
        </PageHeaderWrapper>
        {
          !isEmpty(templatesStats) && (
            <>
              <SectionTitle>Analytics summary</SectionTitle>
              <BpsList>
                <Legend>
                  <LegendLabel>Legend</LegendLabel>
                  <ColorItem>
                    <ColorBox color='#33becc'/> 100%
                  </ColorItem>
                  <ColorItem>
                    <ColorBox color='#BAEAEF'/> 75 - 99%
                  </ColorItem>
                  <ColorItem>
                    <ColorBox color='#ffaf7e'/> 50 - 74%
                  </ColorItem>
                  <ColorItem>
                    <ColorBox color='#ff836b'/> 20 - 49%
                  </ColorItem>
                  <ColorItem>
                    <ColorBox color='#cc2200'/> 0 - 20%
                  </ColorItem>
                </Legend>
                {
                  templatesStats.map((template, index) => <TemplateBox key={`template-${index}`} template={template} />)
                }
              </BpsList>
            </>
          )
        }
        <SectionTitle>Recently added</SectionTitle>
        <BpsList>
          {
            isNotNilOrEmpty(bps)
              ? (
                bps.map(bp =>
                  <BpBox
                    key={bp.id}
                    bp={bp}
                    buildingTypes={buildingTypes}
                  />
                )
              )
              : <DashboardEmptyState style={{ margin: '0 auto' }} />
          }
        </BpsList>
        {
          isNotNilOrEmpty(lastViewed) && (
            <>
              <SectionTitle>Last viewed</SectionTitle>
              <BpsList>
                {
                  lastViewed.map(bp =>
                    <BpBox
                      key={bp.id}
                      bp={bp}
                      buildingTypes={buildingTypes}
                    />
                  )
                }
              </BpsList>
            </>
          )
        }
      </ContentWrapper>
      <DetailsWrapper>
        <ProfileInfo />
      </DetailsWrapper>
    </PageWrapper>
  )
}

export default Dashboard

const PageWrapper = styled.div`
  display: flex;
`

const ContentWrapper = styled.div`
  width: 100%;
  padding-right: 300px;
  overflow: hidden;
`

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  height: 210px;
  margin-right: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
`

const DetailsWrapper = styled.div`
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`

const SectionTitleWrapper = styled.div`
  margin: 40px 0 20px;
`

const SectionTitleText = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 10px;
`

const SectionUnderline = styled.div`
  width: 50px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const BpsList = styled.div`
  box-sizing: border-box;
  display: flex;
  align-content: flex-start;
  min-height: 170px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ColorItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[800]};
  width: 80px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  }
`

const ColorBox = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${({ color }) => color};
  border-radius: 100%;
  margin-right: 10px;
`

const LegendLabel = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.darkGrey};
`
