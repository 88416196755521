import React, { useEffect, useState } from 'react'
import { propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import NextButton from 'features/createBp/components/atoms/NextButton'
import {
  validateGeneralInfoField, validateGeneralInfoWithoutUprnField, validateGeneralInfoWithoutUprnValues
} from 'features/bpList/ducks/schema'
import HelpIcon from '@material-ui/icons/Help'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const EditGeneralInfo = ({ closeModal, isAnalyticsView, currentPage }) => {
  const bp = useSelector(getSelectedBp)
  const dispatch = useDispatch()
  const [valid, setValid] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [values, setValues] = useState({
    name: propOr('', 'name', bp),
    uprn: propOr('', 'uprn', bp),
    id: propOr('', 'id', bp)
  })

  const openTooltip = () => setTooltipOpen(true)
  const closeTooltip = () => setTooltipOpen(false)

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleUpdate = () => {
    dispatch(updateBpRoutine({
      ...values,
      uprn: values.uprn,
      isEdit: true,
      isAnalyticsView: isAnalyticsView,
      currentPage: currentPage
    }))
    closeModal()
  }

  useEffect(() => {
   validateGeneralInfoWithoutUprnValues(values, setValid)
  }, [values])

  return (
    <FormWrapper>
      <Form>
        <Input
          required
          label='Building Name / Number'
          value={values.name}
          name='name'
          onChange={handleOnChange}
          validate={validateGeneralInfoField(values)}
        />
        <Input
          required
          noMarginBottom
          label='UPRN'
          value={values.uprn}
          name='uprn'
          onChange={handleOnChange}
          validate={ validateGeneralInfoWithoutUprnField(values)}
        />
        <ClickAwayListener onClickAway={closeTooltip}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={closeTooltip}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title='UPRN stands for Unique Property Reference Number and was created by the Ordnance Survey (OS).
             It consists of numbers of up to 12 digits in length. Local governments in the UK have allocated
             a unique number for each land or property.'
          >
            <UprnHint>
              <QuestionIcon />
              <UprnHintText onClick={openTooltip}>What is UPRN?</UprnHintText>
            </UprnHint>
          </Tooltip>
        </ClickAwayListener>
        <UprnDescription>
          <InputDescription>
            If you don’t know your UPRN, you can find it&nbsp;
            <a
              href='https://www.findmyaddress.co.uk/search'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>
          </InputDescription>
        </UprnDescription>
        <NextButton
          onClick={handleUpdate}
          disabled={!valid}
        >
          Save changes
        </NextButton>
      </Form>
    </FormWrapper>
  )
}

export default EditGeneralInfo

const Form = styled.form`
  box-sizing: border-box;
  width: 100%;
`

const FormWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`

const QuestionIcon = styled(HelpIcon)`
  color: #a7b5e8;
  margin-right: 5px;
  font-size: 16px !important;
`

const UprnHint = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 15px;
`

const UprnHintText = styled.div`
  cursor: pointer;
  font-size: 12px;
`

const UprnDescription = styled.div`
  margin-bottom: 20px;

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`
