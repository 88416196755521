import { not, isNil, isEmpty, pathOr } from 'ramda'

export const isNotEmpty = value => not(isEmpty(value))
export const isNotNil = value => not(isNil(value))
export const isNotNilOrEmpty = value => isNotNil(value) && isNotEmpty(value)
export const isNilOrEmpty = value => isNil(value) || isEmpty(value)

export const renderPropIfExists = (path, object, render) => {
  const element = pathOr(null, path, object)
  return isNotNil(element)
    ? render(element)
    : ''
}
