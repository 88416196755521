import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getBpPrices } from 'features/bpList/ducks/selectors'
import {
  getBpsPricesRoutine
} from 'features/bpList/ducks/actions'
import { getCart } from 'utils/localStorage'
import ItemsTable from 'features/cart/components/ItemsTable'
import { cartEmitter } from 'utils/emitters'
import EmptyState from 'features/cart/components/EmptyState'
import CartSummary from 'features/cart/components/CartSummary'
import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { selectCartAvailableBps } from 'features/cart/ducks/selectors'
import { checkBasketAvailabilityRoutine, clearBasketAvailabilityRoutine } from 'features/cart/ducks/actions'

const Cart = () => {
  const dispatch = useDispatch()
  const prices = useSelector(getBpPrices)
  const [cartData, setCartData] = useState({
    prices: {},
    bps: []
  })
  const availableBps = useSelector(selectCartAvailableBps)

  useEffect(() => {
    if (prices) {
      setCartData({
        prices: prices.summaryPrices,
        bps: prices.buildingPassportsList
      })
    }
  }, [prices])

  const prepareValues = () => {
    let cartBps = getCart()
    if (isNotNilOrEmpty(cartBps)) {
      dispatch(checkBasketAvailabilityRoutine({
        buildingPassportIds: cartBps
      }))
    } else {
      dispatch(clearBasketAvailabilityRoutine())
      setCartData({
        prices: {},
        bps: []
      })
    }
  }

  useEffect(() => {
    isNotNilOrEmpty(availableBps)
      ? dispatch(getBpsPricesRoutine({
        buildingPassportsIds: availableBps
      }))
      : setCartData({
        prices: {},
        bps: []
      })
  }, [availableBps])

  useEffect(() => {
    setTimeout(prepareValues, 500)
    cartEmitter.on('cart-change', prepareValues)

    return () => {
      cartEmitter.off('cart-change', prepareValues)
    }
  }, [])

  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeader>Your cart</PageHeader>
        {
          pathOr([], ['bps'], cartData).length > 0
            ? <ItemsTable bps={cartData.bps} />
            : <EmptyState />
        }
      </ContentWrapper>
      <DetailsWrapper>
        <CartSummary prices={cartData.prices}/>
      </DetailsWrapper>
    </PageWrapper>
  )
}

export default Cart

const PageWrapper = styled.div`
  display: flex;
`
const ContentWrapper = styled.div`
  width: 100%;
  padding-right: 320px;
`
const DetailsWrapper = styled.div`
  width: 300px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`
