import React, { useState } from 'react'
import { USER_ROLES } from 'utils/userRoles'
import { formatDate } from 'utils/date'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import { revokeAccessToBpRoutine } from 'features/accessControl/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr, propOr } from 'ramda'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp, hideEmail } from 'utils/user'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import ManageFilesPermissions from 'features/bpDetails/Components/files/ManageFilesPermissions'

const AssignedUserBox = ({ user, index, openEditModal, isPpOnList }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const bp = useSelector(getSelectedBp)

  const canEdit = canEditBp(currentUser, bp)

  const openMenu = e => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleModalOpen = () => {
    handleClose()
    setModalOpen(true)
  }
  const handleModalClose = () => setModalOpen(false)

  const handleRevokeAccess = () => {
    const bpId = pathOr(null, ['pivot', 'buildingPassportId'], user) || pathOr(null, ['pivot', 0, 'buildingPassportId'], user)
    const relationId = pathOr(null, ['pivot', 'id'], user) || pathOr(null, ['pivot', 0, 'id'], user)
    dispatch(revokeAccessToBpRoutine({
      bpId,
      relationId
    }))
    handleModalClose()
  }

  const handleEditModalOpen = () => {
    handleClose()
    openEditModal(user)()
  }

  const expiresAt = pathOr(null, ['pivot', 'expiresAt'], user) || pathOr(null, ['pivot', 0, 'expiresAt'], user)

  return (
    <>
      {
        user.email
         ? (
              <tr key={`assigned-user-${index}`}>
                <td>{user.firstName} {user.lastName}</td>
                <td>{hideEmail(user.email)}</td>
                <td>{USER_ROLES[user.relationType]}</td>
                <td>{formatDate(expiresAt)}</td>
                {
                  isPpOnList && (
                    <td>{propOr('---', 'viewsCount', user)}</td>
                  )
                }
                <td>
                  {canEdit && <MoreIcon onClick={openMenu} />}
                </td>
              </tr>
            )
          : (
            <tr key={`assigned-user-${index}`}>
              <td colSpan={2}>{user.firstName}</td>
              <td colSpan='50%'>Prospective Purchaser</td>
            </tr>
          )
      }

      <Menu
        keepMounted
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        style={{
          marginTop: '10px'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditModalOpen}>Edit</MenuItem>
        <ManageFilesPermissions user={user} bp={bp} />
        <MenuItem onClick={handleModalOpen} disabled={user.id === currentUser.id}>Revoke access</MenuItem>
        {/*<a href={`mailto:${user.email}`} rel='noreferrer' target='_blank'><MenuItem>Contact user</MenuItem></a>*/}
      </Menu>
      <Modal
        open={modalOpen}
        title='Revoke access'
        onClose={handleModalClose}
        withCancel
        onCancel={handleModalClose}
        withSubmit
        onSubmit={handleRevokeAccess}
      >
        Do you really want to revoke user&apos;s assignment?
      </Modal>
    </>
  )
}

export default AssignedUserBox

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[700]};
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`
