import React from 'react'
import theme from 'theme/theme'
import styled from 'styled-components'
import { getFloorName } from 'utils/bpData'

const NoteLabel = ({ type, floor }) => {
  const getNoteDetails = () => {
    switch (type) {
      case 'notice': {
        return {
          style: {
            backgroundColor: theme.colors.haiti,
            color: theme.colors.white
          },
          label: 'Notice'
        }
      }
      case 'general': {
        return {
          style: {
            backgroundColor: 'rgba(12, 18, 41, .14)',
            color: 'rgba(12, 18, 41, .40)'
          },
          label: 'General'
        }
      }
      case 'floor': {
        return {
          style: {
            backgroundColor: 'rgba(51, 190, 204, .14)',
            color: theme.colors.secondary
          },
          label: getFloorName(floor)
        }
      }
      default: {
        return {
          style: {
            backgroundColor: theme.colors.lightGrey,
            color: theme.colors.haiti
          },
          label: 'New'
        }
      }
    }
  }

  return (
    <Note style={getNoteDetails().style}>
      {getNoteDetails().label}
    </Note>
  )
}

export default NoteLabel

const Note = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 7px;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
`
