import React, { useEffect, useMemo, useState } from 'react'
import Select from 'components/atoms/Select'
import NextButton from 'features/createBp/components/atoms/NextButton'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import { getFloorOptions } from 'utils/bpData'
import { omit, pathOr, propOr } from 'ramda'
import CountButton from 'components/atoms/CountButton'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import UnitFormPersonSection from 'features/createBp/components/atoms/UnitFormPersonSection'
import UnitPersonForm from 'features/createBp/components/atoms/UnitPersonForm'
import { useDispatch, useSelector } from 'react-redux'
import { selectUnitForEditForm, selectUnitInvitationsList } from 'ducks/units/selectors'
import { fetchUnitInvitationsListRoutine, fetchUnitRoutine } from 'ducks/units/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { validateUnitFormField, validateUnitFormValues } from 'ducks/units/schema'
import HelpIcon from '@material-ui/icons/Help'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Tooltip from '@material-ui/core/Tooltip'
import { clearUsersFoundByEmailRoutine } from 'features/bpDetails/ducks/actions'

const emptyValues = {
  index: 0,
  name: ``,
  uprn: '',
  owner: {},
  responsiblePerson: {},
  agent: {},
  floorNumbers: [],
  area: '',
  buildingType: '',
  occupied: false,
  expectedOccupantsAmount: 0,
  disabledOccupantsAmount: 0,
  elderlyOccupantsAmount: 0
}

const UnitForm = ({ closeEditModal, unit, onSubmit, bp, buildingType, hideAssignments }) => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const selectedUnit = useSelector(selectUnitForEditForm)
  const invitedUsers = useSelector(selectUnitInvitationsList)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const openTooltip = () => setTooltipOpen(true)
  const closeTooltip = () => setTooltipOpen(false)

  useEffect(() => {
    validateUnitFormValues(values, setValid)
  }, [values])

  const hasOwner = useMemo(() => {
    if (invitedUsers && selectedUnit) {
      const findInvitedUser = invitedUsers.find(user => user.relationType === 'owner')
      return isNotNilOrEmpty(propOr(null, 'owners', selectedUnit)) || isNotNilOrEmpty(findInvitedUser)
    }
  }, [selectedUnit, invitedUsers])

  const hasAgent = useMemo(() => {
    if (invitedUsers && selectedUnit) {
      const findInvitedUser = invitedUsers.find(user => user.relationType === 'agent')
      return isNotNilOrEmpty(propOr(null, 'agents', selectedUnit)) || isNotNilOrEmpty(findInvitedUser)
    }
  }, [selectedUnit, invitedUsers])

  const hasRp = useMemo(() => {
    if (invitedUsers && selectedUnit) {
      const findInvitedUser = invitedUsers.find(user => user.relationType === 'responsible_person')
      return isNotNilOrEmpty(propOr(null, 'responsiblePersons', selectedUnit)) || isNotNilOrEmpty(findInvitedUser)
    }
  }, [selectedUnit, invitedUsers])

  const handleOnChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const floorOptions = useMemo(() => {
    if (bp) {
      return getFloorOptions(bp)
    } else {
      return []
    }
  }, [bp])

  const handleSubmit = () => {
    let usersToOmit = []
    hasOwner && usersToOmit.push('owner')
    hasAgent && usersToOmit.push('agent')
    hasRp && usersToOmit.push('responsiblePerson')
    onSubmit({
      ...omit(usersToOmit, values),
      disabledOccupantsAmount: values.occupied ? values.disabledOccupantsAmount : 0,
      elderlyOccupantsAmount: values.occupied ? values.elderlyOccupantsAmount : 0,
      callback: () => {
        setValues(emptyValues)
        closeEditModal()
      }
    })
    dispatch(clearUsersFoundByEmailRoutine())
  }

  useEffect(() => {
    const owner = pathOr({}, ['owners', 0], selectedUnit)
    const invitedOwner = invitedUsers.find(user => user.relationType === 'owner')
    const agent = pathOr({}, ['agents', 0], selectedUnit)
    const invitedAgent = invitedUsers.find(user => user.relationType === 'agent')
    const rp = pathOr({}, ['responsiblePersons', 0], selectedUnit)
    const invitedRp = invitedUsers.find(user => user.relationType === 'responsible_person')

    selectedUnit && setValues({
      ...selectedUnit,
      floorNumbers: propOr([], 'floorNumbers', selectedUnit),
      owner: invitedOwner || owner,
      agent: invitedAgent || agent,
      responsiblePerson: invitedRp || rp,
      area: selectedUnit.area || ''
    })
  }, [selectedUnit, invitedUsers])

  useEffect(() => {
    if (unit) {
      dispatch(fetchUnitInvitationsListRoutine({
        bpId: bp.id,
        unitId: unit.id
      }))
      dispatch(fetchUnitRoutine({
        bpId: bp.id,
        unitId: unit.id,
        editForm: true
      }))
    }
  }, [unit])

  useEffect(() => {
    buildingType && handleOnChange('buildingType', buildingType)
  }, [buildingType])

  return (
    <div>
      <SelectWrapper>
        <Input
          required
          label='Unit name*'
          name='name'
          value={values.name}
          onChange={handleOnChange}
          validate={validateUnitFormField(values)}
        />
        <Input
          required
          label='UPRN'
          name='uprn'
          value={values.uprn}
          onChange={handleOnChange}
          noMarginBottom
        />
        <ClickAwayListener onClickAway={closeTooltip}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={closeTooltip}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title='UPRN stands for Unique Property Reference Number and was created by the Ordnance Survey (OS).
             It consists of numbers of up to 12 digits in length. Local governments in the UK have allocated
             a unique number for each land or property.'
          >
            <UprnHint>
              <QuestionIcon />
              <UprnHintText onClick={openTooltip}>What is UPRN?</UprnHintText>
            </UprnHint>
          </Tooltip>
        </ClickAwayListener>
        <UprnDescription>
          <InputDescription>
            If you don’t know your UPRN, you can find it&nbsp;
            <a
              href='https://www.findmyaddress.co.uk/search'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>
          </InputDescription>
        </UprnDescription>
        <Select
          label='Floors*'
          name='floorNumbers'
          onChange={handleOnChange}
          value={values.floorNumbers}
          multiple
          options={floorOptions}
          validate={validateUnitFormField(values)}
        />
        <Input
          required
          label='Area (sqm)'
          name='area'
          value={values.area}
          onChange={handleOnChange}
          validate={validateUnitFormField(values)}
        />
        <CountButton
          min={0}
          value={values.expectedOccupantsAmount}
          label='Expected number unit occupants'
          name='expectedOccupantsAmount'
          onChange={handleOnChange}
        />
        <Select
          placeholder='Select'
          value={values.occupied}
          label='Occupied'
          name='occupied'
          onChange={handleOnChange}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
            <OccupiedDetailsWrapper visible={values.occupied}>
              <AddDetails>Add details</AddDetails>
              <SubFormWrapper>
                <InputDescription>
                  Rough estimate
                </InputDescription>
                <MarginBox>
                  <CountButton
                    min={0}
                    value={values.disabledOccupantsAmount}
                    label='Disabilities'
                    name='disabledOccupantsAmount'
                    onChange={handleOnChange}
                  />
                </MarginBox>
                <CountButton
                  min={0}
                  label='Elderly'
                  value={values.elderlyOccupantsAmount}
                  name='elderlyOccupantsAmount'
                  onChange={handleOnChange}
                />
              </SubFormWrapper>
            </OccupiedDetailsWrapper>

        {
          !hideAssignments && (
            <>
              <UnitFormPersonSection title='Owner'>
                <UnitPersonForm
                  hasAssignedUser={hasOwner}
                  relationType='owner'
                  userToEdit={values.owner}
                  onChange={value => handleOnChange('owner', value )}
                />
              </UnitFormPersonSection>
              <UnitFormPersonSection title='Responsible person'>
                <UnitPersonForm
                  hasAssignedUser={hasRp}
                  relationType='responsiblePerson'
                  userToEdit={values.responsiblePerson}
                  onChange={value => handleOnChange('responsiblePerson', value )}
                />
              </UnitFormPersonSection>
              <UnitFormPersonSection title='Agent'>
                <UnitPersonForm
                  hasAssignedUser={hasAgent}
                  relationType='agent'
                  userToEdit={values.agent}
                  onChange={value => handleOnChange('agent', value )}
                />
              </UnitFormPersonSection>
            </>
          )
        }
      </SelectWrapper>
      <NextButton onClick={handleSubmit} disabled={!valid}>
        Save
      </NextButton>
    </div>
  )
}

export default UnitForm

const SelectWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
`

const SubFormWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(247, 248, 253, 0.51);
  border: 1px solid #e9eff4;
  padding: 20px 15px;
  margin-bottom: 30px;
`

const MarginBox = styled.div`
  margin: 20px 0 25px;
`

const AddDetails = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .11px;
  font-weight: bold;
  margin-bottom: 15px;
`

const OccupiedDetailsWrapper = styled.div`
  display: ${({ visible }) => visible ? 'static' : 'none'};
`

const UprnHint = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 15px;
`

const UprnHintText = styled.div`
  cursor: pointer;
  font-size: 12px;
`

const QuestionIcon = styled(HelpIcon)`
  color: #a7b5e8;
  margin-right: 5px;
  font-size: 16px !important;
`

const UprnDescription = styled.div`
  margin-bottom: 20px;

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`
