import { pathOr, propOr } from 'ramda'

export const getUserRelationType = (user, bp) => {
  const roles = [
    ...propOr([], 'agents', bp).map(item => ({ id: item.id, role: 'agent' })),
    ...propOr([], 'owners', bp).map(item => ({ id: item.id, role: 'owner' })),
    ...propOr([], 'responsiblePersons', bp).map(item => ({ id: item.id, role: 'responsiblePerson' })),
    ...propOr([], 'principalAccountablePersons', bp).map(item => ({ id: item.id, role: 'principalAccountablePerson' })),
    ...propOr([], 'principalDesigners', bp).map(item => ({ id: item.id, role: 'principalDesigner' })),
    ...propOr([], 'prospectivePurchasers', bp).map(item => ({ id: item.id, role: 'prospectivePurchaser' }))
  ]

  const relation = roles.find(role => role.id === user.id)
  return propOr('agent', 'role', relation)
}

export const getUserRemindersPeriod = (user, bp) => {
  const userReminders = propOr([], 'userReminders', bp)
  const periodicReminder = userReminders.find(reminder =>
    reminder.notificationType === 'App\\Notifications\\BuildingPassport\\PeriodicReminderNotification')
  return propOr(null, 'remindersPeriodInMonths', periodicReminder)
}

export const canEditBp = (user, bp, canFrsEdit = false) => {
  if (user && bp) {
    const relations = [
      ...propOr([], 'owners', bp),
      ...propOr([], 'agents', bp),
      ...propOr([], 'responsiblePersons', bp),
      ...propOr([], 'principalAccountablePersons', bp),
      ...propOr([], 'principalDesigners', bp)
    ].map(r => pathOr('', ['pivot', 'userId'], r))
    return canFrsEdit
      ? user.type === 'emergency_service' || (relations.includes(user.id) || bp.creatorId === user.id)
      : user.type !== 'emergency_service' && (relations.includes(user.id) || bp.creatorId === user.id)
  }
}

export const getUserRoleInUnit = (user, bp) => {
  const roles = [
    ...propOr([], 'agents', bp).map(item => ({ id: item.id, role: 'agent' })),
    ...propOr([], 'owners', bp).map(item => ({ id: item.id, role: 'owner' })),
    ...propOr([], 'responsiblePersons', bp).map(item => ({ id: item.id, role: 'responsiblePerson' })),
    ...propOr([], 'occupants', bp).map(item => ({ id: item.id, role: 'occupant' }))
  ]

  const relations = roles.filter(role => role.id === user.id).map(rel => rel.role)

  switch (true) {
    case relations.includes('owner'):
      return 'owner'
    case relations.includes('agent'):
      return 'agent'
    case relations.includes('responsiblePerson'):
      return 'responsiblePerson'
    case relations.includes('occupant'):
      return 'occupant'
    default:
      return null
  }
}

export const hideEmail = email => {
  if (null !== email && email.includes('@')) {
    const splitted = email.split('@')
    return `${splitted[0][0]}***@${splitted[1]}`
  } else {
    return email
  }
}

export const hidePhoneNumber = phoneNumber => {
  if (null === phoneNumber || phoneNumber.length < 3) {
    return phoneNumber
  } else {
    const lastThreeDigits = phoneNumber.slice(-3)
    const stars = '*'.repeat(phoneNumber.length - 3)

    return stars + lastThreeDigits
  }
}
