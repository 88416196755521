import React, { useEffect, useState } from 'react'
import { omit, propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import styled, { css } from 'styled-components'
import NextButton from 'features/createBp/components/atoms/NextButton'
import BuildingsImg from 'assets/images/buildings.svg'
import Checkbox from 'components/atoms/Checkbox'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import CountButton from 'components/atoms/CountButton'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const EditFloors = ({ closeModal }) => {
  const bp = useSelector(getSelectedBp)
  const dispatch = useDispatch()
  const [valid, setValid] = useState(false)
  const [values, setValues] = useState({
    hasBasement: propOr(0, 'undergroundFloorsNumber', bp) > 0,
    floorsNumber: propOr(0, 'floorsNumber', bp),
    groundFloor: propOr(true, 'groundFloor', bp),
    undergroundFloorsNumber: propOr(1, 'undergroundFloorsNumber', bp),
    lowerGroundFloor: propOr(false, 'lowerGroundFloor', bp),
    id: propOr(false, 'id', bp)
  })

  const handleOnChange = (name, value) => {
    setValues({ ...values, [name]: value })
  }

  const handleUpdate = () => {
    dispatch(updateBpRoutine({
      ...omit(['hasBasement'], values),
      undergroundFloorsNumber: values.hasBasement ? values.undergroundFloorsNumber : 0,
      isEdit: true
    }))
    closeModal()
  }

  useEffect(() => {
    setValid(
      values.floorsNumber > 0 ||
      values.groundFloor ||
      values.lowerGroundFloor ||
      values.hasBasement
    )
  }, [values])

  return (
    <FormWrapper>
      <Form>
        <SectionWithBuildings>
          <FieldWrapper>
            <CountButton
              value={values.floorsNumber}
              label='Number of floors (above ground floor)'
              name='floorsNumber'
              onChange={handleOnChange}
            />
            <InputDescription>
              If single-storey (e.g.&nbsp;bungalow), enter 0.
            </InputDescription>
            <FieldSpacer/>
            <CheckboxWrapper>
              <Checkbox
                value={values.groundFloor}
                label='Ground Floor'
                name='groundFloor'
                onChange={handleOnChange}
              />
            </CheckboxWrapper>
          </FieldWrapper>
        </SectionWithBuildings>
        <SectionWithoutBuildings>
          <FieldWrapper>
            <CheckboxWrapper>
              <Checkbox
                value={values.lowerGroundFloor}
                label='Lower Ground Floor'
                name='lowerGroundFloor'
                onChange={handleOnChange}
              />
            </CheckboxWrapper>
            <CheckboxWrapper style={{ margin: '5px 0' }}>
              <Checkbox
                value={values.hasBasement}
                label='Subterranean Storeys'
                name='hasBasement'
                onChange={handleOnChange}
              />
            </CheckboxWrapper>
                <SubFormWrapper open={values.hasBasement}>
                  <CountButton
                    min={1}
                    value={values.undergroundFloorsNumber}
                    label='Subterranean Storeys'
                    name='undergroundFloorsNumber'
                    onChange={handleOnChange}
                  />
                  <InputDescription>
                    Enter the number of below-ground storeys (excluding
                    Lower Ground).
                  </InputDescription>
                </SubFormWrapper>
          </FieldWrapper>
        </SectionWithoutBuildings>
        <NextButtonWrapper>
          <NextButton
            onClick={handleUpdate}
            disabled={!valid}
          >
            Save changes
          </NextButton>
        </NextButtonWrapper>
      </Form>
    </FormWrapper>
  )
}

export default EditFloors

const FormWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`

const Form = styled.form`
  box-sizing: border-box;
  width: 100%;
`

const CheckboxWrapper = styled.div`
  margin: 15px 0;
`

const SubFormWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  padding: 20px 5px 20px 0;
  overflow: hidden;
  height: 0;
  opacity: 0;

  ${({ open }) => open && css`
    padding-top: 20px;
    opacity: 1;
    height: auto;
  `}
`

const SectionWithBuildings = styled.div`
  padding-top: 20px;
  border-bottom: 4px solid #ebeef9;
  background-image: url(${BuildingsImg});
  background-position: right bottom;
  background-repeat: no-repeat;
`

const SectionWithoutBuildings = styled.div`
  background-color: #f9fafe;
  padding-top: 1px;
  padding-bottom: 15px;
`

const FieldWrapper = styled.div`
  width: 298px;
  margin: 0 auto;
`

const FieldSpacer = styled.div`
  width: 100%;
  height: 57px;
`

const NextButtonWrapper = styled.div`
  background-color: #fff;
  padding-top: 30px;
`
