import React, { useMemo, useState } from 'react'
import { propOr, values } from 'ramda'
import { useSelector } from 'react-redux'
import { getBuildingTypesForForms } from 'features/bpList/ducks/selectors'
import { validateBuildingDetailsField } from 'features/createBp/ducks/schema'
import AddBuildingTypeForm from 'features/bpDetails/Components/AddBuildingTypeForm'
import Select from 'components/atoms/Select'
import { isNotNilOrEmpty } from 'utils/ramda'
import AddUnitForm from 'features/createBp/components/atoms/AddUnitForm'
import styled from 'styled-components'

const emptyValues = {
  type: '',
  unit: ''
}

const AddUnitsToBpForm = ({ bp, closeModal, isWizard }) => {
  const [selectedType, setSelectedType] = useState(emptyValues)
  const buildingTypes = useSelector(getBuildingTypesForForms)

  const selectOptions = useMemo(() => {
    if (buildingTypes) {
      return values(buildingTypes).map(type => ({
        label: type.label,
        value: type.key,
        unit: type.unit,
        multipleBpUnits: type.multipleBpUnits,
      })).filter(e => !['residential.dwelling.sole_unit', 'public'].includes(e.value))
    }
  }, [buildingTypes, selectedType])

  const handleSelectChange = (_, type) => {
    const foundOption = selectOptions.find(option => option.value === type)
    setSelectedType({
      type,
      unit: foundOption.unit
    })
  }

  const isTypeAdded = useMemo(() => {
    const currentTypes = propOr([], 'buildingTypes', bp).map(type => type.type)
    return currentTypes.includes(selectedType.type)
  }, [selectedType])

  return (
    <FormWrapper>
      <Select
        name='type'
        options={selectOptions}
        placeholder='Select'
        value={selectedType.type}
        label='Select the primary use of the unit(s)'
        onChange={handleSelectChange}
        validate={validateBuildingDetailsField(selectedType)}
      />
      {
        isNotNilOrEmpty(selectedType.type) && (
          <>
            {
              isTypeAdded
                ? (
                  <AddUnitForm
                    closeModal={closeModal}
                    bp={bp}
                    buildingType={selectedType.type}
                    isWizard={isWizard}
                  />
                )
                : (
                  <AddBuildingTypeForm
                    bp={bp}
                    closeModal={closeModal}
                    isWizard={isWizard}
                    selectedType={selectedType}
                  />
                )
            }
          </>
        )
      }
    </FormWrapper>
  )
}

export default AddUnitsToBpForm

const FormWrapper = styled.div`
  width: 400px;
`
