import { createSelector } from 'reselect'
import { propOr, pipe, any, equals, values } from 'ramda'

export const getLoaderState = state => state.loader

export const selectGlobalLoader = createSelector(
  getLoaderState,
  pipe(values, any(equals(true)))
)

export const selectIsFetchingBps = createSelector(
  getLoaderState,
  propOr(false, 'isFetchingBps')
)

export const selectIsFetchingFavourites = createSelector(
  getLoaderState,
  propOr(false, 'isFetchingFavouriteBps')
)

export const selectIsFetchingSingleBp = createSelector(
  getLoaderState,
  propOr(false, 'isFetchingSingleBp')
)

export const selectIsFetchingFiles = createSelector(
  getLoaderState,
  propOr(false, 'isFetchingFiles')
)
