import React from 'react'
import Logo from 'features/navigation/components/Logo'
import { PATHS, PRIVATE_PATHS, redirect } from 'utils/paths'
import { useTranslation } from 'react-i18next'
import AvatarMenu from 'features/navigation/components/AvatarMenu'
import SearchInput from 'features/SearchInput/SearchInput'
import CartIcon from 'features/navigation/components/CartIcon'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'
import { useSelector } from 'react-redux'
import Notifications from 'features/notifications/Notifications'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const TopBar = ({ isLoggedIn }) => {
  const { t } = useTranslation()
  const userRole = useSelector(getCurrentUserRole)

  const handleCreateNewBp = () => {
    if (window.location.pathname.includes('create-bp')) {
      window.location.reload()
    } else {
      redirect(PRIVATE_PATHS.createBp)
    }
  }

  const isFrsUser = userRole === 'emergency_service'
  const isResponsiblePerson = userRole === 'responsible_person'

  return (
    <TopBarWrapper>
      <Logo isLoggedIn={isLoggedIn} />
      {isLoggedIn && <SearchInput />}
      <NavOptions>
        {
          !isLoggedIn &&
            <>
              <NavItem to={PATHS.login}>
                {t('navigation.login')}
              </NavItem>
              <NavItem to={PATHS.signup}>
                {t('navigation.signUp')}
              </NavItem>
            </>
        }
        {
          isLoggedIn &&
            <>
              {
                !isFrsUser && !isResponsiblePerson &&
                  <>
                    <CreateBpButton onClick={handleCreateNewBp}>
                      +&nbsp;{t('navigation.createPassport')}
                    </CreateBpButton>
                    <CartIcon />
                  </>
              }
              {!isFrsUser && <Notifications />}
              <AvatarMenu />
            </>
        }
      </NavOptions>
    </TopBarWrapper>
  )
}

TopBar.defaultProps = {
  isLoggedIn: true
}

export default TopBar

const TopBarWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.navigation};
  height: ${({ theme }) => theme.dimensions.topBarHeight};
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
`

const NavOptions = styled.div`
  display: flex;
  align-items: center;
  padding-right: 48px;

  svg {
    cursor: pointer;
    margin-right: 32px;

    &:hover {
      g {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

const NavItem = styled(Link)`
  padding: 10px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary}
  }
`

const CreateBpButton = styled.button`
  outline: 0;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  border: 3px solid ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 0 20px rgba(41, 152, 163, 0.31);
  border-radius: 20px;
  min-width: 200px;
  height: 40px;
  margin-right: 60px;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`
