import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import { useSelector } from 'react-redux'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { propOr, values } from 'ramda'
import { formatDate } from 'utils/date'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'

const SearchListItem = ({ bp, onClick, disabled }) => {
  const buildingTypes = useSelector(getBuildingTypes)
  const userRole = useSelector(getCurrentUserRole)
  const isFrsUser = userRole === 'emergency_service'

  const getBuildingTypesString = () => {
    if (buildingTypes) {
      const types = bp.buildingTypes.map(type => type.type)
      const dictionaryArray = values(buildingTypes)
      let result =''
      types.forEach(type => {
        const found = dictionaryArray.find(e => e.key === type)
        result += propOr('', 'label', found) + ';'
      })
      return result.substring(0, result.length - 1)
    } else {
      return ''
    }
  }

  return (
    <ItemWrapper onClick={disabled && !isFrsUser ? () => {} : onClick} disabled={disabled && !isFrsUser}>
      <UprnWrapper>
        <FileIcon />
        <UprnCode>UPRN: {bp.uprn}</UprnCode>
      </UprnWrapper>
      <BuildingName>{bp.name}</BuildingName>
      <BuildingTypes>
        {getBuildingTypesString()}
      </BuildingTypes>
      <UpdateDate>
        Updated: {formatDate(bp.updatedAt)}
      </UpdateDate>
    </ItemWrapper>
  )
}

export default SearchListItem

const ItemWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[500]};
  }

  ${({ disabled }) => disabled && css`
    opacity: .4;
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: default;

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey[400]};
    }
  `}
`

const UprnWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  min-width: 150px;
  display: flex;
  align-items: center;
`

const UprnCode = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
  margin-left: 15px;
`

const BuildingName = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
`

const BuildingTypes = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const UpdateDate = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  min-width: 120px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`
