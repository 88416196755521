import React, { useEffect, useState } from 'react'
import Panel from 'features/createBp/components/atoms/Panel'
import { useTranslation } from 'react-i18next'
import NextButton from 'features/createBp/components/atoms/NextButton'
import CountButton from 'components/atoms/CountButton'
import styled from 'styled-components'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import Select from 'components/atoms/Select'
import { isNotNil } from 'utils/ramda'
import { getCreatedBpId } from 'features/createBp/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import { omit, propOr } from 'ramda'

const Residents = ({
  completed,
  open,
  setActiveStep,
  setBp,
  bp,
  setCompleted,
  setStarted
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const bpId = useSelector(getCreatedBpId)
  const [sectionValues, setSectionValues] = useState({
    occupied: propOr(null, 'occupied', bp),
    expectedBuildingOccupantsAmount: propOr(0, 'expectedBuildingOccupantsAmount', bp),
    disabledOccupantsAmount: propOr(0, 'disabledOccupantsAmount', bp),
    elderlyOccupantsAmount: propOr(0, 'elderlyOccupantsAmount', bp),
    expectedOccupationDateInWeeks: propOr(2, 'expectedOccupationDateInWeeks', bp)
  })
  const [valid, setIsValid] = useState(false)

  const handleOnChange = (name, value) => {
    setSectionValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    setStarted('details')
  }, [])

  const handleSkip = () => {
    setActiveStep('issues')
    setCompleted('residents')
  }

  const setNextSection = () => {
    const values = sectionValues.occupied
      ? omit([
          'expectedOccupationDateInWeeks'
        ], sectionValues)
      : omit([
          'disabledOccupantsAmount',
          'elderlyOccupantsAmount'
        ], sectionValues)

    setBp(prev => ({
      ...prev,
      ...values
    }))
    dispatch(updateBpRoutine({
      ...values,
      id: bpId,
      isWizard: true
    }))
    handleSkip()
  }

  useEffect(() => {
    setIsValid(isNotNil(sectionValues.occupied))
  }, [sectionValues])

  useEffect(() => {
    setSectionValues({
      occupied: propOr(null, 'occupied', bp),
      expectedBuildingOccupantsAmount: propOr(0, 'expectedBuildingOccupantsAmount', bp),
      disabledOccupantsAmount: propOr(0, 'disabledOccupantsAmount', bp),
      elderlyOccupantsAmount: propOr(0, 'elderlyOccupantsAmount', bp),
      expectedOccupationDateInWeeks: propOr(2, 'expectedOccupationDateInWeeks', bp)
    })
  }, [bp])

  return (
    <Panel
      open={open}
      title='Occupants'
      completed={completed}
      step='residents'
      setActiveStep={setActiveStep}
    >
      <CountButton
        value={sectionValues.expectedBuildingOccupantsAmount}
        label='Expected number building occupants'
        name='expectedBuildingOccupantsAmount'
        onChange={handleOnChange}
      />
      <Select
        placeholder='Select'
        value={sectionValues.occupied}
        label='Occupied'
        name='occupied'
        onChange={handleOnChange}
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
      {
        isNotNil(sectionValues.occupied) && (
          <>
            {
              sectionValues.occupied ? (
                <>
                  <AddDetails>Add details</AddDetails>
                  <SubFormWrapper>
                    <InputDescription>
                      Rough estimate
                    </InputDescription>
                    <MarginBox>
                      <CountButton
                        min={0}
                        value={sectionValues.disabledOccupantsAmount}
                        label='Disabilities'
                        name='disabledOccupantsAmount'
                        onChange={handleOnChange}
                      />
                    </MarginBox>
                    <CountButton
                      min={0}
                      label='Elderly'
                      value={sectionValues.elderlyOccupantsAmount}
                      name='elderlyOccupantsAmount'
                      onChange={handleOnChange}
                    />
                  </SubFormWrapper>
                </>
              ) : (
                <>
                  <Text>
                    When do you expect this building to be occupied?
                  </Text>
                  <SubFormWrapper>
                    <Select
                      value={sectionValues.expectedOccupationDateInWeeks}
                      label='Enter the approximate date'
                      name='expectedOccupationDateInWeeks'
                      onChange={handleOnChange}
                      options={[
                        { label: '2 weeks', value: 2 },
                        { label: '4 weeks', value: 4 }
                      ]}
                    />
                    <InputDescription>
                      We will send you a notification after this date.
                    </InputDescription>
                  </SubFormWrapper>
                </>
              )
            }
          </>
        )
      }
      <NextButton
        onClick={setNextSection}
        disabled={!valid}
      >
        {completed ? 'Save' : t('createBp.labels.next')}
      </NextButton>
      <SkipButton onClick={handleSkip}>Skip residents</SkipButton>
    </Panel>
  )
}

export default Residents

const SubFormWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(247, 248, 253, 0.51);
  border: 1px solid #e9eff4;
  padding: 20px 15px;
  margin-bottom: 30px;
`

const MarginBox = styled.div`
  margin: 20px 0 25px;
`

const AddDetails = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .11px;
  font-weight: bold;
  margin-bottom: 15px;
`

const SkipButton = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  text-decoration: underline;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Text = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .11px;
  font-weight: bold;
  margin-bottom: 15px;
`
