import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { values } from 'ramda'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@material-ui/icons/Check'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const TestOption = ({ valid, label }) => {
  return (
    <OptionWrapper valid={valid}>
      <OptionMark>
        {valid ? <StyledCheckIcon /> : <StyledDotIcon />}
      </OptionMark>
      <OptionLabel>
        {label}
      </OptionLabel>
    </OptionWrapper>
  )
}

const PasswordHint = ({ password = '' }) => {
  const [valid, setValid] = useState(false)
  const [testValues, setTestValues] = useState({})
  const { t } = useTranslation()

  const regTest = reg => reg.test(password)

  useEffect(() => {
    setTestValues({
      length: regTest( /(?=.{12,})/),
      lowercase: regTest( /(?=.*[a-z])/),
      uppercase: regTest( /(?=.*[A-Z])/),
      number: regTest( /(?=.*[0-9])/),
      special: regTest(/(?=.*[!@#$%^&*])/)
    })
  }, [password])

  useEffect(() => {
    setValid(values(testValues).every(val => val === true))
  }, [testValues])

  return (
    <Wrapper valid={valid}>
      <TestOption
        valid={testValues.length}
        label={t('signUp.validation.passwordLength')}
      />
      <TestOption
        valid={testValues.number}
        label={t('signUp.validation.digit')}
      />
      <TestOption
        valid={testValues.lowercase}
        label={t('signUp.validation.lowercase')}
      />
      <TestOption
        valid={testValues.special}
        label={t('signUp.validation.specialCharacter')}
      />
      <TestOption
        valid={testValues.uppercase}
        label={t('signUp.validation.uppercase')}
      />
    </Wrapper>
  )
}

export default PasswordHint

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;

  & > div {
    width: 50%;
  }
`

const OptionWrapper = styled.div`
  display: flex;
  color: ${({ theme, valid }) => valid ? theme.colors.secondary : theme.colors.haiti};
  align-items: center;
  height: 18px;

  &:not(:last-of-type) {
    margin-bottom: 5px
  }
`
const OptionMark = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 20px;
`
const OptionLabel = styled.div`
  font-size: 13px;
`

const StyledCheckIcon = styled(CheckIcon)`
  font-size: 18px !important;
`

const StyledDotIcon = styled(FiberManualRecordIcon)`
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: 16px !important;
`
