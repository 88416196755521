import { object, string, array } from 'yup'
import { validateField, validateValues } from 'utils/form'

export const unitFormSchema = object().shape({
  name: string()
    .required('Unit name is required'),
  area: string()
    .nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, {
      message: 'Incorrect area'
    })
    .test(
      'positive-number',
      'Area must be a positive number',
      function (value) {
        return value ? Number(value) > 0 : true
      }
    ),
  floorNumbers: array()
    .required('Floor number is required')
    .nullable()
})
export const validateUnitFormField = values => validateField(unitFormSchema, values)
export const validateUnitFormValues = validateValues(unitFormSchema)
