import React, { useState } from 'react'
import styled from 'styled-components'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ExpandablePanel = ({ title, open = false, children }) => {
  const [isOpen, setOpen] = useState(open)

  const toggleOpen = () => setOpen(prev => !prev)

  return (
    <PanelWrapper>
      <PanelTitle onClick={toggleOpen}>
        <div>
          {title}
          <TitleLine />
        </div>
        <StyledExpandIcon open={isOpen} />
      </PanelTitle>
      <PanelContent in={isOpen}>
        {children}
      </PanelContent>
    </PanelWrapper>
  )
}

export default ExpandablePanel

const PanelWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  overflow: hidden;
`

const PanelTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.colors.haiti};
  font-size: 18px;
  letter-spacing: .15px;
  cursor: pointer;
`

const TitleLine = styled.div`
  height: 4px;
  width: 50px;
  border-radius: 2px;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const PanelContent = styled(Collapse)`
  box-sizing: border-box;
  margin: ${(props) => props.in ? '20px auto 0' : '0 auto'};
  width: 320px;
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grey[700]};
  transition: all .3s;
  transform: rotate(${({ open }) => open ? '180deg' : '0'});
`
