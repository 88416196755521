import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as FolderIcon } from 'assets/images/folder .svg'
import { isNotNil } from 'utils/ramda'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { propOr } from 'ramda'
import { useSelector } from 'react-redux'
import { getFileTypeName } from 'utils/bpData'
import File from 'features/bpDetails/Components/File'
import { sortFilesByExpirationStatus } from 'utils/files'
import { AddButton } from 'features/bpDetails/BpDetails'
import { getFileTypes } from 'ducks/files/selectors'
import { selectUserRelationToBp } from 'features/bpDetails/ducks/selectors'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'

const UnitFolders = ({ unit }) => {
  const [currentFolder, setCurrentFolder] = useState(null)
  const hasSelectedFolder = isNotNil(currentFolder)
  const fileTypes = useSelector(getFileTypes)
  const userRoles = useSelector(selectUserRelationToBp)
  const isProspectivePurchaser = userRoles.includes('prospective_purchaser') && userRoles.length === 1

  const allFolders = isProspectivePurchaser
    ? [{
        id: 'sharedFiles',
        name: 'Files shared with unit',
        files: propOr([], 'sharedFiles', unit)
      }]
    : [{
        id: 'privateFiles',
        name: 'Private files',
        files: propOr([], 'privateFiles', unit)
      },
      {
        id: 'sharedFiles',
        name: 'Files shared with unit',
        files: propOr([], 'sharedFiles', unit)
      }]

  useEffect(() => {
    if (unit && isNotNil(currentFolder)) {
      setCurrentFolder(prev => ({
        ...prev,
        files: propOr([], prev.id, unit)
      }))
    }
  }, [unit])

  const handleCategoryChange = folderName => {
    setCurrentFolder(folderName)
  }

  return (
    <SectionWrapper withMargin>
      <HeaderWrapper>
        <HeaderTitleWrapper>
          <SectionHeader
            open={isNotNil(currentFolder)}
            onClick={() => setCurrentFolder(null)}
          >
            Folders
          </SectionHeader>
          {
            isNotNil(currentFolder) && (
              <>
                <BreadcrumbsSign>&gt;</BreadcrumbsSign>
                <CurrentFolderName>{currentFolder.name}</CurrentFolderName>
              </>
            )
          }
        </HeaderTitleWrapper>
        {!isProspectivePurchaser && (
          <AddMultipleFilesModal isPrivate unit={unit}>
            <AddButton>+ Add private files</AddButton>
          </AddMultipleFilesModal>
        )}
      </HeaderWrapper>
      <CategoriesWrapper>
        {
          hasSelectedFolder && (
            <CurrentCategoryTitle>
              {currentFolder.name}
            </CurrentCategoryTitle>
          )
        }
        {
          !hasSelectedFolder &&
          allFolders.map((category) => {
            const filesCount = propOr([], 'files', category).length
            return (
              <FolderWrapper
                key={category.id}
                onClick={filesCount > 0 ? () => handleCategoryChange(category) : () => {}}
                disabled={filesCount === 0}
              >
                <FolderIcon />
                <FolderNameWrapper>
                  <FolderName>{category.name}</FolderName>
                  <FilesCounter>({filesCount})</FilesCounter>
                </FolderNameWrapper>
              </FolderWrapper>
            )
          })
        }
        {
          sortFilesByExpirationStatus(propOr([], 'files', currentFolder)).map(file =>
            <File
              key={`file-${Math.random() * 99}`}
              file={file}
              type={currentFolder.id === 'privateFiles' ? 'Private' : getFileTypeName(file.fileType.id, fileTypes)}
              isUnit
              unit={unit}
              isPrivate={currentFolder.id === 'privateFiles'}
              isShared={currentFolder.id === 'sharedFiles'}
            />
          )
        }
      </CategoriesWrapper>
    </SectionWrapper>
  )
}

export default UnitFolders

const SectionWrapper = styled.div`
  margin-top: ${({ withMargin }) => withMargin ? '30px' : 0};
`

const FolderWrapper = styled.div`
  box-sizing: border-box;
  max-width: 185px;
  width: 31%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #dfe4f6;
  margin: 5px;
  cursor: pointer;
  transition: all .3s;
  border-radius: 4px;
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ disabled }) => disabled && css`
    cursor: default;
    opacity: .3;

    &:hover {
      border-color: #dfe4f6;
    }
  `}
`

const FolderName = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-left: 15px;
`

const CategoriesWrapper = styled.div`
  margin: 20px -5px 0;
  display: flex;
  flex-wrap: wrap;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SectionHeader = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .3px;
  display: flex;

  ${({ open }) => open && css`
    cursor: pointer;
  `}
`

const BreadcrumbsSign = styled(ChevronRightIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  font-size: 18px !important;
  margin: 0 5px;
`

const CurrentFolderName = styled(SectionHeader)`
  color: ${({ theme }) => theme.colors.primary};
`

const FilesCounter = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.grey[700]};
`

const CurrentCategoryTitle = styled.div`
  margin: 10px 0 20px;
`

const FolderNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
