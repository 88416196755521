import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import PageHeader from 'components/atoms/PageHeader'
import CreateBpForm from 'features/createBp/components/CreateBpForm'
import Summary from 'features/createBp/components/Summary'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreatedBpRoutine } from 'features/createBp/ducks/actions'
import { selectCreatedBp } from 'features/createBp/ducks/selectors'
import { isEmpty } from 'ramda'
import { clearBpFilesRoutine } from 'ducks/files/actions'
import { clearBpPricesRoutine } from 'features/bpList/ducks/actions'

const CreateBp = () => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('basic')
  const [activeStep, setActiveStep] = useState('building-info')
  const createdBp = useSelector(selectCreatedBp)
  const [bp, setBp] = useState({})
  const [completed, setCompleted] = useState({
    buildingInfo: false,
    buildingDetails: false,
    relationship: false,
    floorNumber: false,
    units: false,
    residents: false,
    issues: false,
    reminders: false
  })
  const [startedSections, setStartedSections] = useState({
    basic: true,
    floors: false,
    units: false,
    details: false
  })

  useEffect(() => {
    if (createdBp && !isEmpty(bp)) {
      setBp(createdBp)
    }
  }, [createdBp])

  useEffect(() => {
    dispatch(clearBpFilesRoutine())
    dispatch(clearCreatedBpRoutine())
    dispatch(clearBpPricesRoutine())
  }, [])

  const markAsCompleted = type => {
    setCompleted(prev => ({
      ...prev,
      [type]: true
    }))
  }

  const setStarted = type => {
    setStartedSections(prev => ({
      ...prev,
      [type]: true
    }))
  }

  const handleChangeTab = (tabName, started) => () => {
    if (started) {
      switch (tabName) {
        case 'basic':
          setActiveTab('basic')
          setActiveStep('building-info')
          break
        case 'floors':
          setActiveTab('floors')
          setActiveStep('floor-number')
          break
        case 'units':
          setActiveTab('units')
          setActiveStep('units')
          break
        case 'details':
          setActiveTab('details')
          setActiveStep('residents')
          break
      }
    }
  }

  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeader>
          Create Building Passport
        </PageHeader>
        <WizardContent>
          <FormSteps>
            <FormStep
              started={startedSections.basic}
              onClick={handleChangeTab('basic', startedSections.basic)}
              active={activeTab === 'basic'}
            >
              1. Basic information
            </FormStep>
            <FormStep
              started={startedSections.floors}
              onClick={handleChangeTab('floors', startedSections.floors)}
              active={activeTab === 'floors'}
            >
              2. Floors
            </FormStep>
            <FormStep
              started={startedSections.units}
              onClick={handleChangeTab('units', startedSections.units)}
              active={activeTab === 'units'}
            >
              3. Units
            </FormStep>
            <FormStep
              started={startedSections.details}
              onClick={handleChangeTab('details', startedSections.details)}
              active={activeTab === 'details'}
            >
              4. Additional details
            </FormStep>
          </FormSteps>
          <CreateBpForm
            completed={completed}
            setCompleted={markAsCompleted}
            activeTab={activeTab}
            activeStep={activeStep}
            setActiveTab={setActiveTab}
            setStarted={setStarted}
            setActiveStep={setActiveStep}
            setBp={setBp}
            bp={bp}
          />
        </WizardContent>
      </ContentWrapper>
      <Sidebar>
        <Summary bp={bp} />
      </Sidebar>
    </PageWrapper>
  )
}

export default CreateBp

const PageWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 300px;
`

const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`

const WizardContent = styled.div`
  width: 645px;
  box-sizing: border-box;
  margin: 0 auto;
`

const Sidebar = styled.div`
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`

const FormSteps = styled.div`
  width: 100%;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0 20px;
`

const FormStep = styled.div`
  color: ${({ theme, active }) => active ? theme.colors.secondary : theme.colors.grey[600]};

  &:not(:last-of-type) {
    margin-right: 30px;
  }

  ${({ started, active }) => (started && !active) && css`
    cursor: pointer;

    &:hover {
      color: ${({ theme, active }) => active ? theme.colors.secondary : theme.colors.grey[700]};
    }
  `}
`
