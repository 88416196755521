import { createSelector } from 'reselect'
import { propOr } from 'ramda'

const getDashboard = state => state.dashboard

export const getDashboardStats = createSelector(
  getDashboard,
  propOr([], 'dashboard')
)

export const getLastSearchedBps = createSelector(
  getDashboard,
  propOr([], 'lastSearched')
)

export const getLastViewedBps = createSelector(
  getDashboard,
  propOr([], 'lastViewed')
)
