import React, { useState } from 'react'
import Panel from 'features/createBp/components/atoms/Panel'
import NextButton from 'features/createBp/components/atoms/NextButton'
import styled from 'styled-components'
import Select from 'components/atoms/Select'
import { getCreatedBpId } from 'features/createBp/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Radio from 'components/atoms/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { addToCart } from 'utils/localStorage'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { propOr } from 'ramda'

const Reminders = ({
  completed,
  open,
  setActiveStep,
  setBp,
  setCompleted,
  bp
}) => {
  const dispatch = useDispatch()
  const bpId = useSelector(getCreatedBpId)
  const [wantsReminders, setWantsReminders] = useState('false')
  const [sectionValues, setSectionValues] = useState({
    periodicReminder: propOr(6, 'periodicReminder', bp )
  })

  const handleOnChange = e => {
    setSectionValues({
      periodicReminder: Number(e.target.value)
    })
  }

  const handleRemindersChange = (_, value) => setWantsReminders(value)

  const handleSaveChanges = () => {
    setCompleted('reminders')
    setBp(prev => ({
      ...prev,
      periodicReminder: wantsReminders === 'true' ? sectionValues.periodicReminder : null
    }))
    dispatch(updateBpRoutine({
      periodicReminder: wantsReminders === 'true' ? sectionValues.periodicReminder : null,
      id: bpId,
      isWizard: true
    }))
  }

  const saveAsDraft = () => {
    handleSaveChanges()
    setTimeout(() => redirect(PRIVATE_PATHS.drafts), 100)
  }

  const addToBasket = () => {
    addToCart(bpId)
    handleSaveChanges()
    setTimeout(() => redirect(PRIVATE_PATHS.cart), 100)
  }

  const createAnother = () => {
    addToCart(bpId)
    handleSaveChanges()
    setTimeout(() => window.location.reload(), 100)
  }

  return (
    <Panel
      open={open}
      title='Reminders'
      completed={completed}
      step='reminders'
      setActiveStep={setActiveStep}
    >
      <Select
        value={wantsReminders}
        noMargin
        label='Reminders'
        name='periodicReminder'
        onChange={handleRemindersChange}
        options={[
          { label: 'No', value: 'false' },
          { label: 'Yes', value: 'true' }
        ]}
      />
      <Hint>
        <InputDescription>
          Turn on to receive reminders about your Building Passport:
        </InputDescription>
      </Hint>
      {
        wantsReminders === 'true' && (
          <OptionsWrapper>
            <OptionsTitle>select frequency</OptionsTitle>
            <FormControl>
              <RadioGroup
                row
                value={sectionValues.periodicReminder}
                onChange={handleOnChange}
              >
                <ToggleLabel
                  value={6}
                  control={<Radio color='secondary' />}
                  label='every 6 mo'
                />
                <ToggleLabel
                  value={12}
                  control={<Radio color='secondary' />}
                  label='every 12 mo'
                />
              </RadioGroup>
            </FormControl>
          </OptionsWrapper>
        )
      }
      <Button
        onClick={saveAsDraft}
      >
        Save as draft
      </Button>
      <PrimaryButton
        onClick={addToBasket}
      >
        Add to basket
      </PrimaryButton>
      <SecondaryButton
        onClick={createAnother}
      >
        Add to basket & create another
      </SecondaryButton>
    </Panel>
  )
}

export default Reminders

const ToggleLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.haiti};
    font-family: FuturaPT-book;
  }
`

const OptionsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 6px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[800]};
  margin-bottom: 25px;
  position: relative;
`

const OptionsTitle = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
  font-weight: bold;
  letter-spacing: .3px;
`

const SecondaryButton = styled(NextButton)`
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-top: 15px;
`

const PrimaryButton = styled(NextButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  margin-top: 15px;
`

const Button = styled(NextButton)`
  margin-bottom: 15px;
`

const Hint = styled.div`
  margin: 5px 0 20px;
`
