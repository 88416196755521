import { toCamelCase } from 'js-convert-case'
import { ROLE_DESCRIPTION } from 'utils/constants'

export const USER_ROLES = {
  user: 'User',
  emergencyService: 'Emergency Service',
  emergency_service: 'Emergency Service',
  principal_accountable_person: 'Principal Accountable Person',
  principalAccountablePerson: 'Principal Accountable Person',
  principal_designer: 'Principal Accountable Person',
  principalDesigner: 'Principal Designer',
  agent: 'Agent',
  landlord: 'Building Owner',
  responsiblePerson: 'Responsible Person',
  responsible_person: 'Responsible Person',
  prospectivePurchaser: 'Prospective Purchaser',
  prospective_purchaser: 'Prospective Purchaser',
  owner: 'Owner',
  occupant: 'Occupant'
}
export const userRole = user => USER_ROLES[toCamelCase(user.type)]

export const getRoleDescription = role => {
  switch (role) {
    case 'user':
      return ROLE_DESCRIPTION.user
    case 'agent':
      return ROLE_DESCRIPTION.agent
    case 'landlord':
      return ROLE_DESCRIPTION.landlord
    case 'responsible_person':
      return ROLE_DESCRIPTION.responsiblePerson
    case 'principal_accountable_person':
      return ROLE_DESCRIPTION.principalAccountablePerson
    case 'principal_designer':
      return ROLE_DESCRIPTION.principalDesigner
    case 'occupant':
      return ROLE_DESCRIPTION.occupant
    case 'emergency_service':
      return ROLE_DESCRIPTION.emergencyService
    default:
      return ''
  }
}
