import { object, string } from 'yup'
import i18n from 'i18n'
import { validateField, validateValues } from 'utils/form'

const addressSchema = object().shape({
  postalCode: string()
    .required(i18n.t('common.validation.required', { field: 'Post code' }))
    .max(8, 'Postal code should not be longer than 8 characters'),
  street: string()
    .required(i18n.t('common.validation.required', { field: 'Address' })),
  city: string()
    .required(i18n.t('common.validation.required', { field: 'City name' }))
})

export const validateAddressField = values => validateField(addressSchema, values)
export const validateAddressValues = validateValues(addressSchema)

const generalInfoSchema = object().shape({
  name: string()
    .required(i18n.t('common.validation.required', { field: 'Building Name / Number' })),
  uprn: string()
    .required(i18n.t('common.validation.required', { field: 'UPRN' }))
    .matches(/^\d*$/, {
      message: 'Incorrect UPRN number',
    })
    .max(12, 'UPRN should not be longer than 12 characters')
})

const generalInfoWithoutUprnSchema = object().shape({
  name: string()
    .required(i18n.t('common.validation.required', { field: 'Building Name / Number' })),
  uprn: string()
    .matches(/^\d*$/, {
      message: 'Incorrect UPRN number'
    })
    .max(12, 'UPRN should not be longer than 12 characters')
})

export const validateGeneralInfoField = values => validateField(generalInfoSchema, values)
export const validateGeneralInfoWithoutUprnField = values => validateField(generalInfoWithoutUprnSchema, values)
export const validateGeneralInfoValues = validateValues(generalInfoSchema)
export const validateGeneralInfoWithoutUprnValues = validateValues(generalInfoWithoutUprnSchema)
