import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { selectFileTagsForBp } from 'ducks/files/selectors'
import Button from 'components/atoms/Button'
import PrimaryButton from 'components/atoms/PrimaryButton'
import { isNotNilOrEmpty } from 'utils/ramda'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import InputDescription from 'features/createBp/components/atoms/InputDescription'

const AttachTagsToUploadingFile = ({ onSave, savedCount, initiallySelected }) => {
  const tags = useSelector(selectFileTagsForBp)
  const [open, setOpen] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const toggleTagSelection = id => () => {
    setSelectedTags(prev => {
      if (prev.includes(id)) {
        return prev.filter(el => el !== id)
      } else {
        return [...prev, id]
      }
    })
  }

  useEffect(() => {
    if (isNotNilOrEmpty(initiallySelected)) {
      setSelectedTags(initiallySelected)
    }
  }, [initiallySelected])

  const handleSubmit = () => {
    onSave(selectedTags)
    handleClose()
  }

  return (
    <>
      <TriggerWrapper onClick={handleOpen}>
        <Wrapper>
          <StyledIcon />
          {savedCount > 0 && <Badge>{savedCount}</Badge>}
        </Wrapper>
      </TriggerWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        title='Attach tag to a file'
      >
        <ModalContent>
          <ModalDesc>
            Select tags to be assigned to this file:
          </ModalDesc>
          <InputDescription margin='0 0 20px'>
            Note: You cannot currently add new tags to files being uploaded.
            Please create the desired tag in the Documents section first, and then upload the files.
            The tag will be visible in the list below
          </InputDescription>
          <TagsList>
            {
              isNotNilOrEmpty(tags) ? tags.map(tag => {
                return (
                  <Tag
                    key={tag.id}
                    active={selectedTags.includes(tag.id)}
                    onClick={toggleTagSelection(tag.id)}
                  >
                    {tag.name}
                  </Tag>
                )
              }) : (
                <div>No tags to display</div>
              )
            }
          </TagsList>
          <ButtonsWrapper>
            <Button
              variant='outlined'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <PrimaryButton
              onClick={handleSubmit}
            >
              Save
            </PrimaryButton>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AttachTagsToUploadingFile

const TriggerWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`

const ModalContent = styled.div`
  max-width: 500px;
`

const ModalDesc = styled.div`
  margin-bottom: 20px;
`

const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  margin-bottom: 30px;
`

const Tag = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.darkGrey};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[500]};
  }

  ${({ active }) => active && css`
    background-color: #e6f2ff;
    border-color: #e6f2ff;

    &:hover {
      background-color: #e6f2ff;
      border-color: #e6f2ff;
    }
  `};

  ${({ disabled, theme }) => disabled && css`
    background-color: ${theme.colors.grey[400]};
    border-color: ${theme.colors.grey[400]};
    color: ${theme.colors.grey[700]};
    cursor: default;

    &:hover {
      background-color: ${theme.colors.grey[400]};
      border-color: ${theme.colors.grey[400]};
      color: ${theme.colors.grey[700]};
    }
  `};
`

const ButtonsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 16px;

  & > button {
    flex: 1;
  }
`

const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
`

const StyledIcon = styled(LocalOfferOutlinedIcon)`

  cursor: pointer !important;
`

const Badge = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  top: -3px;
  right: -3px;
  border-radius: 50%;
`
