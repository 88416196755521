import React, { useMemo } from 'react'
import { Collapse } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import styled from 'styled-components'
import DetailsUnitTable from 'features/bpDetails/Components/units/DetailsUnitTable'
import { useDispatch, useSelector } from 'react-redux'
import { selectBpUnits } from 'ducks/units/selectors'
import { addBpUnitsRoutine } from 'ducks/units/actions'
import { hasMultipleUnits } from 'utils/units'
import { isEmpty } from 'ramda'

const DetailsUnitSection = ({ open, section, toggleOpen, bp, sectionsCount, redirectPath }) => {
  const bpUnits = useSelector(selectBpUnits)
  const dispatch = useDispatch()
  const isSoleResidential = section.buildingType === 'residential.dwelling.sole_unit'
  const units = useMemo(() => {
    return bpUnits
      ? bpUnits.filter(unit => unit.buildingType === section.buildingType)
      : []
  }, [bpUnits])

  const hasFullAccess = bp.hasAccessToBuildingPassport

  const handleAddUnit = () => {
    dispatch(addBpUnitsRoutine({
      buildingType: section.buildingType,
      amount: 1,
      bpId: bp.id
    }))
  }

  const isSoleUnit = useMemo(() => {
    if (section) {
      return !hasMultipleUnits(section.buildingType)
    } else {
      return false
    }
  }, [section])

  return !isEmpty(units) ? (
    <DetailsUnitSectionWrapper>
      <DetailsUnitSectionHeader
        withPointer={sectionsCount > 1}
        onClick={sectionsCount > 1 ? toggleOpen : () => {}}
      >
        <DetailsUnitSectionTitle>
          <div>
            {section.name}
            {
              section.multi && (
                <DetailsUnitSectionUnitsNumber>
                  ({units.length})
                </DetailsUnitSectionUnitsNumber>
              )
            }
          </div>
          {
            sectionsCount > 1 && (
              <>
                {
                  open
                    ? <KeyboardArrowUpIcon />
                    : <KeyboardArrowDownIcon />
                }
              </>
            )
          }
        </DetailsUnitSectionTitle>
      </DetailsUnitSectionHeader>
      <Collapse in={open}>
        <DetailsUnitSectionUnitsHeader>
          {
            section.createMulti ? (
              <div>
                {units.length} units
              </div>
            ) : (
              <div>1 unit</div>
            )
          }
        </DetailsUnitSectionUnitsHeader>
        {units && (
          <DetailsUnitTable
            section={section}
            units={units}
            isSoleUnit={isSoleUnit}
            isSoleResidential={isSoleResidential}
            redirectPath={redirectPath}
          />
        )}
        {
          !isSoleUnit && hasFullAccess && (
            <AddIconWrapper onClick={handleAddUnit}>
              <AddUnitIcon>
                +
              </AddUnitIcon>
              <span>Add one unit</span>
            </AddIconWrapper>
          )
        }
      </Collapse>
    </DetailsUnitSectionWrapper>
  ) : null
}

export default DetailsUnitSection

const DetailsUnitSectionWrapper = styled.div`
  margin-bottom: 30px;
`

const DetailsUnitSectionHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 10px;
  cursor: ${({ withPointer }) => withPointer ? 'pointer' : 'default'};
`

const DetailsUnitSectionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DetailsUnitSectionUnitsNumber = styled.span`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-size: 18px;
  display: inline-block;
  margin-left: 5px;
  font-weight: normal;
`

const DetailsUnitSectionUnitsHeader = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[800]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
`

const AddIconWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
  width: fit-content;

  span {
    transition: all .3s;
    display: block;
    margin-left: 5px;
    opacity: 0;
    text-transform: uppercase;
    font-size: 14px;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`

const AddUnitIcon = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 30px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
`
