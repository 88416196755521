import React, { useEffect, useMemo, useState } from 'react'
import PanelFloor from 'features/createBp/components/atoms/PanelFloor'
import NextButton from 'features/createBp/components/atoms/NextButton'
import styled from 'styled-components'
import { selectCreatedBp } from 'features/createBp/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { isEmpty, pathOr, propOr, values } from 'ramda'
import { hasMultipleUnits, shouldCreateMultipleUnits } from 'utils/units'
import UnitSection from 'features/createBp/components/atoms/UnitSection'
import Modal from 'components/atoms/Modal'
import { AddButton } from 'features/bpDetails/BpDetails'
import { fetchBpUnitsRoutine } from 'ducks/units/actions'
import AddUnitsToBpForm from 'features/bpDetails/Components/units/AddUnitsToBpForm'

const Units = ({
  completed,
  open,
  setActiveStep,
  setBp,
  setCompleted,
  setStarted,
  setActiveTab
}) => {
  const dispatch = useDispatch()
  const createdBp = useSelector(selectCreatedBp)
  const [sectionValues, setSectionValues] = useState({
    units: []
  })
  const [collapsedSections, setCollapsedSections] = useState([])
  const buildingTypes = useSelector(getBuildingTypes)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const isSoleResidential = useMemo(() => {
    const bpType = pathOr('', ['buildingTypes', 0, 'type'], createdBp)
    return bpType === 'residential.dwelling.sole_unit'
  }, [createdBp])

  useEffect(() => {
    dispatch(fetchBpUnitsRoutine({ bpId: createdBp.id }))
  }, [])

  const toggleSectionOpen = index => {
    setCollapsedSections(prev => {
      let previous = [ ...prev ]
      previous[index] = !previous[index]
      return previous
    })
  }

  const sections = useMemo(() => {
    if (!isEmpty(buildingTypes)) {
      const types = propOr([], 'buildingTypes', createdBp)
      types.forEach(() => setCollapsedSections(prev => [ ...prev, true]))
      return types.map(type => {
        const foundType = values(buildingTypes).find(bpType => bpType.key === type.type)
        return {
          name: foundType.label,
          unit: foundType.unit,
          multi: hasMultipleUnits(foundType.key),
          createMulti: shouldCreateMultipleUnits(foundType.key),
          units: type.units,
          area: type.area,
          buildingType: type.type
        }
      })
    }
  }, [createdBp, buildingTypes])

  useEffect(() => {
    setStarted('units')
  }, [])

  const handleSkip = () => {
    setActiveStep('residents')
    setActiveTab('details')
  }

  const setNextSection = () => {
    handleSkip()
    setBp(prev => ({
      ...prev,
      ...sectionValues
    }))
    setCompleted('units')
  }

  const handleSectionValuesChange = units => {
    setSectionValues(prev => ({
      units: [
        ...prev.units,
        ...units
      ]
    }))
  }

  const handleModalOpen = () => setAddModalOpen(true)
  const handleModalClose = () => setAddModalOpen(false)

  return (
    <PanelFloor
      open={open}
      title='Unit details'
      completed={completed}
      step='units'
      setActiveStep={setActiveStep}
    >
      {
        !isSoleResidential && (
          <AddTypeButtonWrapper>
            <AddButton onClick={handleModalOpen}>+ add units</AddButton>
          </AddTypeButtonWrapper>
        )
      }
      <UnitsWrapper>
        {sections && sections.map((section, index) => (
          <UnitSection
            changeSectionValues={handleSectionValuesChange}
            key={`section-${index}`}
            section={section}
            open={collapsedSections[index]}
            toggleOpen={() => toggleSectionOpen(index)}
          />
        ))}
      </UnitsWrapper>
      <NextButtonWrapper>
        <NextButton
          onClick={setNextSection}
        >
          {completed ? 'Save' : 'Next'}
        </NextButton>
      </NextButtonWrapper>
      <Modal
        onClose={handleModalClose}
        onCancel={handleModalClose}
        open={addModalOpen}
        title='Add units'
      >
        <AddUnitsToBpForm isWizard bp={createdBp} closeModal={handleModalClose} />
      </Modal>
    </PanelFloor>
  )
}

export default Units

const NextButtonWrapper = styled.div`
  background-color: #fff;
  padding-top: 30px;
`

const UnitsWrapper = styled.div`
  margin-top: 20px;
`

const AddTypeButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  & > div {
    font-size: 14px;
  }
`
