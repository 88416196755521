import React from 'react'
import styled from 'styled-components'

const Terms = () => {
    return (
        <Wrapper>
            <Frame src="https://www.iubenda.com/terms-and-conditions/11279900" />
        </Wrapper>
    )
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

const Frame = styled.iframe`
    height: 100%;
    width: 100%;
    border: none;
`

export default Terms
