import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getSharedUnitsList, selectBpUnits } from 'ducks/units/selectors'
import { fetchBpUnitsRoutine } from 'ducks/units/actions'
import { isEmpty } from 'ramda'
import ShareUnitSelect from 'components/ShareUnitSelect'
import { getSharedUnitsListForFileRoutine, shareFileRoutine } from 'ducks/files/actions'

const ShareFileModal = ({
  open,
  onClose,
  file
}) => {
  const [values, setValues] = useState([])
  const dispatch = useDispatch()
  const bp = useSelector(getSelectedBp)
  const bpUnits = useSelector(selectBpUnits)
  const sharedUnitsList = useSelector(getSharedUnitsList)

  const handleSubmit = () => {
    const unitIds = values.map(unit => unit.value)
    dispatch(shareFileRoutine({
      fileId: file.id,
      buildingUnitPassportsIds: unitIds,
      bpId: bp.id
    }))
    onClose()
    dispatch(fetchBpUnitsRoutine({ bpId: bp.id }))
  }

  useEffect(() => {
    if (sharedUnitsList) {
      const selectedUnits = sharedUnitsList.map(unit => ({
        label: unit.name,
        value: unit.id,
        uprn: unit.uprn,
        floorNumbers: unit.floorNumbers
      }))
      setValues(selectedUnits)
    }
  }, [sharedUnitsList])

  const unitsOptions = useMemo(() => {
    if (!isEmpty(bpUnits)) {
      return bpUnits.map(unit => ({
        label: unit.name,
        value: unit.id,
        uprn: unit.uprn,
        floorNumbers: unit.floorNumbers
      }))
    } else {
      return []
    }
  }, [bpUnits])

  useEffect(() => {
    open && dispatch(getSharedUnitsListForFileRoutine(file.id))
  }, [open])
  return (
    <Modal
      title='File sharing'
      open={open}
      onClose={onClose}
    >
      <FormWrapper>
        <ShareUnitSelect
          name='units'
          placeholder='Units'
          options={unitsOptions}
          multiple
          values={values}
          label='Units'
          onChange={setValues}
          noMargin
          onSubmit={handleSubmit}
        />
      </FormWrapper>
    </Modal>
  )
}

export default ShareFileModal

const FormWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto 30px;
`
