import { isNotNil } from 'utils/ramda'

export const getOwner = owner => {
  if (owner) {
    return owner.company
      ? owner.company
      : `${owner.firstName} ${owner.lastName}`
  }
}

export const handleBooleanValue = value => {
  if (isNotNil(value)) {
    return value ? 'Yes' : 'No'
  } else {
    return 'N/A'
  }
}
