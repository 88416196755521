import React, { useEffect } from 'react'
import styled from 'styled-components'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { getCart } from 'utils/localStorage'
import { useDispatch, useSelector } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { getCheckoutSessionId } from 'features/cart/ducks/selectors'
import { paymentInitRoutine } from 'features/cart/ducks/actions'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_URL)

const PaymentButton = ({ accepted, hasItems }) => {
  const dispatch = useDispatch()
  const checkoutSessionId = useSelector(getCheckoutSessionId)

  const handleClick = () => {
    const cartItems = getCart()
    const payload = cartItems.map(item => ({ id: item }))

    dispatch(paymentInitRoutine({
      buildingPassportsList: payload
    }))
  }

  const startStripe = async () => {
    const stripe = await stripePromise
    stripe.redirectToCheckout({ sessionId: checkoutSessionId })
  }

  useEffect(() => {
    checkoutSessionId && startStripe()
  }, [checkoutSessionId])

  return (
    <PayButton
      disabled={!accepted || !hasItems}
      onClick={handleClick}
    >
      Pay for subscription
    </PayButton>
  )
}

export default PaymentButton

const PayButton = styled(NextButton)`
  margin-top: 20px;
  font-weight: normal;
`
