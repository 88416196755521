/* eslint-disable */
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { usePagination, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import { isEmpty, pathOr, propOr } from 'ramda'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { toCamelCase } from 'js-convert-case/lib'
import { removeFromCart } from 'utils/localStorage'
import { toast } from 'react-toastify'
import Tooltip from '@material-ui/core/Tooltip'
import { isNotNilOrEmpty } from 'utils/ramda'
import ClearIcon from '@material-ui/icons/Clear'

const ItemsTable = ({ bps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buildingTypes = useSelector(getBuildingTypes)
  const data = useMemo(() => bps, [bps])

  const handleRemove = value => () => {
    removeFromCart(value)
    toast.success('Building Passport was removed from your cart. You can still find it on drafts list.')
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t('bpList.table.name'),
        id: 'name',
        accessor: 'name',
        Cell: d => {
          const uprn = pathOr('Not yet allocated', ['row', 'original', 'uprn'], d)
          return (
            <PropertyNameWrapper>
              <IconWrapper>
                <FileIcon />
              </IconWrapper>
              <PropertyName>
                {d.value}
                <div className='uprn'>
                  UPRN: {
                    isNotNilOrEmpty(uprn)
                      ? uprn
                      : 'Not yet allocated'
                  }
                </div>
              </PropertyName>
            </PropertyNameWrapper>
          )
        }
      },
      {
        Header: 'type',
        accessor: 'buildingTypes',
        Cell: d => {
          return d.value.map(e => {
            const type = propOr(
              { label: '', unit: '' },
              toCamelCase(e.type),
              buildingTypes)
            return <Bold>{type.label}</Bold>
          })
        }
      },
      {
        Header: 'Area / Units',
        id: 'lettableArea',
        accessor: 'owner',
        Cell: d => {
          return d.row.original.buildingTypes.map(e => {
            const type = propOr(
              { label: '', unit: '' },
              toCamelCase(e.type),
              buildingTypes)
            return (
              <div>
                {`${type.unit === 'sqm' ? e.area : e.units} ${type.unit}`}
              </div>
            )
          })
        }
      },
      {
        Header: 'price',
        accessor: 'price',
        Cell: d => {
          const isMinimalPriceReached = pathOr(false, ['row', 'original', 'isMinimalPriceReached'], d)
          return isMinimalPriceReached
            ? d.row.original.buildingTypes.map(e => {
              const price = propOr(0, 'netPrice', e)
              return <div>{price.formatted}</div>
            })
            : <div>Applicable for minimum price</div>
        }
      },
      {
        Header: 'total',
        accessor: 'netPrice',
        Cell: d => {
          return (
            <Bold>{d.value.formatted}</Bold>
          )
        }
      },
      {
        Header: '',
        accessor: 'id',
        Cell: d => {
          return (
            <Tooltip
              title='Clicking “remove” will remove this Building Passport
               from the basket. It will still be available in your Drafts.'
            >
              <DeleteIconWrapper onClick={handleRemove(d.value)}>
                <span>REMOVE</span>
                <CrossIcon />
              </DeleteIconWrapper>
            </Tooltip>
          )
        }
      }
    ],
    [bps, buildingTypes]
  )

  const tableInstance = useTable({
    columns,
    data
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance

  return (
    <TableWrapper>
      <table {...getTableProps()}>
        <thead>
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                    <span>
                  </span>
                  </th>
                ))
              }
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          rows.map(row => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
              >
                {
                  row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {
                          cell.render('Cell')
                        }
                      </td>
                    )
                  })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {
        isEmpty(data) &&
        <EmptyState>
          No BPs found.
        </EmptyState>
      }
    </TableWrapper>
  )
}

export default ItemsTable

const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: auto;

  table {
    width: 100%;
  }

  th {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 11px;
    opacity: .5;
    text-transform: uppercase;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    position: relative;
  }

  td {
    box-sizing: border-box;
    padding: 20px 10px;
    height: 56px;
    font-size: 14px;
  }

  tbody > tr {
    border-bottom: 2px solid ${({ theme }) => theme.colors.backgroundColor};
    background-color: ${({ theme }) => theme.colors.lighterGrey};
  }
`

const PropertyNameWrapper = styled.div`
  display: flex;
`

const PropertyName = styled.div`
  font-size: 19px;
  color: ${({ theme }) => theme.colors.haiti};

  .uprn {
    margin-top: 3px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey[700]};
  }
`

const EmptyState = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.div`
  min-width: 43px;
  min-height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbede9;
  margin-right: 15px;
  border-radius: 4px;
`

const Bold = styled.div`
  font-weight: bold;
`

const DeleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.error};

  span {
    transition: all .3s;
    opacity: 0;
    margin-right: 3px;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`

const CrossIcon = styled(ClearIcon)`
  font-size: 16px !important;
  color: ${({ disabled, theme }) =>  disabled ? theme.colors.grey[600] : theme.colors.primary};
`
