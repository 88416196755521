import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import moment from 'moment'
import { propOr, pick, pathOr, isNil, dissoc } from 'ramda'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Checkbox from 'components/atoms/Checkbox'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { updateUnitFileRoutine } from 'ducks/units/actions'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getBpFilesRoutine, updateFileRoutine } from 'ducks/files/actions'
import { MenuItem } from '@material-ui/core'

const emptyFileValues = {
  displayName: null,
  fileTypeId: null,
  floorNumbers: [],
  producedBy: null,
  creationDate: null,
  legalOwner: null,
  expirationDate: null,
  approvedBy: null,
  expirationReminderDate: null,
  segment: null,
  fileCategory: null,
  dateOfIssue: null
}

const EditFileVersionModal = ({
  file,
  version,
  unit
}) => {
  const dispatch = useDispatch()
  const [modalValues, setValues] = useState({})
  const [open, setOpen] = useState(false)
  const [additionalOpen, setAdditionalOpen] = useState(false)
  const [wantsReminder, setWantsReminder] = useState(false)
  const bp = useSelector(getSelectedBp)
  const isPrivate = file.isPrivate

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = () => setOpen(false)

  const handleValueChange = (name, value) => {
    if (name === 'expirationDate') {
      const subtractSixMonths = moment(value).subtract(6, 'month')
      const isBeforeToday = subtractSixMonths.isBefore(moment())
      const expirationReminderDate = isBeforeToday
        ? moment().add(1, 'day').format('YYYY-MM-DD')
        : subtractSixMonths.format('YYYY-MM-DD')

      setValues(prev => ({
        ...prev,
        [name]: value,
        expirationReminderDate
      }))
    } else {
      setValues(prev => ({ ...prev, [name]: value }))
    }
  }

  const toggleAdditionalInfo = () => setAdditionalOpen(prev => !prev)

  useEffect(() => {
    if (file && version) {
      setWantsReminder(!isNil(propOr(null, 'expirationReminderDate', version)))
      setValues({
        ...pick([
          'floorNumbers',
          'producedBy',
          'legalOwner',
          'approvedBy',
          'segment',
          'dateOfIssue'
        ], version),
        fileTypeId: pathOr('', ['fileType', 'id'], file),
        fileCategory: pathOr('', ['fileType', 'fileCategoryId'], file),
        expirationDate: propOr(null, 'expirationDate', version) ? moment(propOr('', 'expirationDate', version)).format('YYYY-MM-DD') : null,
        creationDate: propOr('', 'creationDate', version) ? moment(propOr(null, 'creationDate', version)).format('YYYY-MM-DD') : null,
        expirationReminderDate: propOr('', 'expirationReminderDate', version)
          ? moment(propOr('', 'expirationReminderDate', version)).format('YYYY-MM-DD')
          : null,
        dateOfIssue: propOr('', 'dateOfIssue', version) ? moment(propOr('', 'dateOfIssue', version)).format('YYYY-MM-DD') : null,
        displayName: propOr('', 'name', version)
      })
    }
  }, [file, version, open])

  const afterSubmitCallback = () => {
    dispatch(getBpFilesRoutine({ id: bp.id }))
    handleModalClose()
  }

  const handleUpload = e => {
    e.preventDefault()
    if (isNotNilOrEmpty(unit)) {
      dispatch(updateUnitFileRoutine({
        bpId: bp.id,
        unitId: unit.id,
        displayName: unit.name,
        fileId: file.id,
        fileVersionId: version.id,
        body: {
          ...dissoc('fileTypeId', modalValues),
          floorNumbers: isNotNil(modalValues.floorNumbers) ? modalValues.floorNumbers.map(num => num === 'ground' ? '0' : num) : [],
          expirationReminderDate: wantsReminder ? modalValues.expirationReminderDate : null,
          expirationDate: isNotNilOrEmpty(modalValues.expirationDate) ? modalValues.expirationDate : null,
          displayName: modalValues.displayName
        },
        clearValues: afterSubmitCallback
      }))
    } else {
      dispatch(updateFileRoutine({
        bpId: bp.id,
        id: file.id,
        fileVersionId: version.id,
        body: {
          ...modalValues,
          floorNumbers: isNotNil(modalValues.floorNumbers) ? modalValues.floorNumbers.map(num => num === 'ground' ? '0' : num) : [],
          expirationReminderDate: wantsReminder ? modalValues.expirationReminderDate : null,
          expirationDate: isNotNilOrEmpty(modalValues.expirationDate) ? modalValues.expirationDate : null,
          name: modalValues.displayName
        },
        clearValues: afterSubmitCallback
      }))
    }
  }

  useEffect(() => {
    if (!open) {
      setValues(emptyFileValues)
      setWantsReminder(false)
    }
  }, [open])

  const isValid = useMemo(() => {
    const { fileTypeId, fileCategory } = modalValues
    return isPrivate ? true : [fileTypeId, fileCategory].every(e => isNotNilOrEmpty(e))
  }, [modalValues])

  const handleExpirationDateClear = () => {
    handleValueChange('expirationDate', '')
    handleValueChange('expirationReminderDate', '')
    setWantsReminder(false)
  }

  const handleReminderDateClear = () => {
    handleValueChange('expirationReminderDate', '')
  }

  const handleCreationDateClear = () => {
    handleValueChange('creationDate', '')
  }

  const handleDateOfIssueClear = () => {
    handleValueChange('dateOfIssue', '')
  }

  return (
    <>
      <MenuItem onClick={handleModalOpen}>Edit</MenuItem>
      <Modal
        title='File information'
        open={open}
        onClose={handleModalClose}
        key={version.id}
        id={version.id}
      >
        <FormWrapper>
          <>
            <Input
              label='File name'
              value={modalValues.displayName}
              name='displayName'
              onChange={handleValueChange}
              placeholder='File display name'
            />
            <Input
              label='Expiry date'
              value={modalValues.expirationDate}
              name='expirationDate'
              type='date'
              optional
              onChange={handleValueChange}
              additionalLabel={
                isNotNilOrEmpty(modalValues.expirationDate)
                  ? (
                    <ClearTrigger onClick={handleExpirationDateClear}>
                      clear
                    </ClearTrigger>
                  )
                  : null
              }
            />
            <Checkbox
              label='File expiry reminder'
              name='wantsReminder'
              disabled={!isNotNilOrEmpty(modalValues.expirationDate)}
              value={wantsReminder}
              onChange={() => setWantsReminder(prev => !prev)}
            />
            <InputDescription margin='10px 0 20px'>
              Get reminded via email on a specific date that this file is due to expire.
              Every user with access to the Building Passport will receive this email.
              Any user has the ability to turn off this type of reminder in the settings section of the Building Passport.
            </InputDescription>
            <Input
              label='Expiration reminder date'
              disabled={!wantsReminder}
              value={modalValues.expirationReminderDate}
              name='expirationReminderDate'
              type='date'
              onChange={handleValueChange}
              inputProps={{ min: moment().add(1, 'day').format('YYYY-MM-DD') }}
              additionalLabel={
                isNotNilOrEmpty(modalValues.expirationReminderDate)
                  ? (
                    <ClearTrigger onClick={handleReminderDateClear}>
                      clear
                    </ClearTrigger>
                  )
                  : null
              }
            />
            <AdditionalInfoSection>
              <CollapseTitle
                onClick={toggleAdditionalInfo}
              >
                <div>
                  Additional file information <span>(optional)</span>
                </div>
                <StyledExpandIcon open={additionalOpen} />
              </CollapseTitle>
              <Collapse in={additionalOpen}>
                <AdditionalInfoDescription>
                  In order to achieve the highest quality of Building Passport,
                  the following additional file information can be added.
                </AdditionalInfoDescription>
                <Input
                  label='Legal Owner'
                  value={modalValues.legalOwner}
                  name='legalOwner'
                  onChange={handleValueChange}
                  placeholder='Name of the company'
                />
                <Input
                  label='Creator'
                  placeholder='Person who created the document'
                  value={modalValues.producedBy}
                  name='producedBy'
                  onChange={handleValueChange}
                />
                <Input
                  type='date'
                  label='Date of issue'
                  value={modalValues.dateOfIssue}
                  name='dateOfIssue'
                  onChange={handleValueChange}
                  additionalLabel={
                    isNotNilOrEmpty(modalValues.dateOfIssue)
                      ? (
                        <ClearTrigger onClick={handleDateOfIssueClear}>
                          clear
                        </ClearTrigger>
                      )
                      : null
                  }
                />
                <Input
                  type='date'
                  label='Creation date'
                  value={modalValues.creationDate}
                  name='creationDate'
                  onChange={handleValueChange}
                  inputProps={{ max: moment().format('YYYY-MM-DD') }}
                  additionalLabel={
                    isNotNilOrEmpty(modalValues.creationDate)
                      ? (
                        <ClearTrigger onClick={handleCreationDateClear}>
                          clear
                        </ClearTrigger>
                      )
                      : null
                  }
                />
                <Input
                  label='Approved by'
                  value={modalValues.approvedBy}
                  name='approvedBy'
                  onChange={handleValueChange}
                  placeholder='Person who approved the document'
                />
                <Input
                  label='Segment / sheet'
                  value={modalValues.segment}
                  name='segment'
                  onChange={handleValueChange}
                  placeholder='Enter if applicable'
                />
              </Collapse>
            </AdditionalInfoSection>
            <ButtonWrapper>
              <UploadButton
                onClick={handleUpload}
                disabled={!isValid}
              >
                Save
              </UploadButton>
            </ButtonWrapper>
          </>
        </FormWrapper>
      </Modal>
    </>
  )
}

export default EditFileVersionModal

const FormWrapper = styled.div`
  max-width: 340px;
  margin: 0 auto;

`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const UploadButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.secondary};
  height: 30px;
  width: 80%;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ disabled, theme }) => disabled && css`
    border-color: ${theme.colors.grey[700]};
    color: ${theme.colors.grey[700]};

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.grey[700]};
    }
  `}
`

const AdditionalInfoSection = styled.div`
  margin: 20px 0 10px;
`

const CollapseTitle = styled.div`
  padding: 15px 0;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.grey[700]};
  }
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grey[700]};
  transition: all .3s;
  transform: rotate(${({ open }) => open ? '180deg' : '0'});
`

const AdditionalInfoDescription = styled.div`
  margin-bottom: 25px;
`

const ClearTrigger = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
`
