import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as bpService from 'services/BpService'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'
import { dissoc, omit } from 'ramda'
import { cartEmitter } from 'utils/emitters'
import { getBpsWithAnalyticsRoutine } from 'features/analytics/ducks/actions'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'

export const createBpRoutine = createRoutine('CREATE_BP')
export const removeBpRoutine = createRoutine('REMOVE_BP')
export const updateBpRoutine = createRoutine('UPDATE_BP')
export const addNoteRoutine = createRoutine('ADD_NOTE')
export const editNoteRoutine = createRoutine('EDIT_NOTE')
export const removeNoteRoutine = createRoutine('REMOVE_NOTE')
export const clearCreatedBpRoutine = createRoutine('CLEAR_LAST_CREATED_BP')
export const updateCreatedBpRoutine = createRoutine('UPDATE_CREATED_BP')
export const removeCreatedBpUnitRoutine = createRoutine('REMOVE_CREATED_BP_UNIT')

function * createBp ({ payload }) {
  const { setNextSection } = payload
  yield put(createBpRoutine.request())
  try {
    const result = yield call(bpService.createBp, dissoc('setNextSection', payload))
    yield put(createBpRoutine.success(result.data.data))
    if (typeof setNextSection === 'function') {
      setNextSection()
    }
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(createBpRoutine.failure(error))
  }
}

function * clearCreatedBp () {
  yield put(clearCreatedBpRoutine.request())
  try {
    yield put(clearCreatedBpRoutine.success())
  } catch (error) {
    yield put(clearCreatedBpRoutine.failure(error))
  }
}

function * removeBp ({ payload }) {
  const { id } = payload
  yield put(removeBpRoutine.request())
  try {
    yield call(bpService.deleteBp, id)
    yield put(removeBpRoutine.success(id))
    setTimeout(() => cartEmitter.emit('draft-removed'), 200)
    toast.success('Draft has been successfully deleted.')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(removeBpRoutine.failure(error))
  }
}

function * updateBp ({ payload }) {
  const { setNextSection, isEdit, isWizard, isAnalyticsView, currentPage } = payload
  yield put(updateBpRoutine.request())
  try {
    const { data } = yield call(bpService.updateBp, omit(['setNextSection', 'isEdit'], payload))
    if (typeof setNextSection === 'function') {
      setNextSection()
    }
    if (isWizard) {
      yield put(updateCreatedBpRoutine.success(data.data))
    } else {
      yield put(fetchBpRoutine({ id: payload.id }))
    }
    yield put(updateBpRoutine.success())
    isEdit && toast.success('Your Building Passport has been updated')
    if (isAnalyticsView && currentPage) {
      yield put(getBpsWithAnalyticsRoutine(currentPage))
    }
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(updateBpRoutine.failure(error))
  }
}

function * addNote ({ payload }) {
  yield put(addNoteRoutine.request())
  const { onClose } = payload
  try {
    const result = yield call(bpService.addNote, dissoc('onClose', payload))
    yield put(addNoteRoutine.success(result.data.data))
    toast.success('Your note has been successfully added.')
    typeof onClose === 'function' && onClose()
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(addNoteRoutine.failure(error))
  }
}

function * editNote ({ payload }) {
  yield put(editNoteRoutine.request())
  try {
    const result = yield call(bpService.updateNote, payload)
    yield put(editNoteRoutine.success(result.data.data))
    toast.success('Your note has been successfully updated.')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(editNoteRoutine.failure(error))
  }
}

function * removeNote ({ payload }) {
  yield put(removeNoteRoutine.request())
  try {
    yield call(bpService.deleteNote, payload)
    yield put(removeNoteRoutine.success(payload.noteId))
    toast.success('Your note has been successfully deleted.')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(removeNoteRoutine.failure(error))
  }
}

function * updateCreatedBp ({ payload }) {
  yield put(updateCreatedBpRoutine.success(payload))
}

function * removeCreatedBpUnit ({ payload }) {
  yield put(removeCreatedBpUnitRoutine.success(payload))
}

// WATCHERS

export function * createBpWatcher () {
  yield takeLatest(createBpRoutine.TRIGGER, createBp)
}

export function * removeBpWatcher () {
  yield takeLatest(removeBpRoutine.TRIGGER, removeBp)
}

export function * updateBpWatcher () {
  yield takeLatest(updateBpRoutine.TRIGGER, updateBp)
}

export function * addNoteWatcher () {
  yield takeLatest(addNoteRoutine.TRIGGER, addNote)
}

export function * editNoteWatcher () {
  yield takeLatest(editNoteRoutine.TRIGGER, editNote)
}

export function * removeNoteWatcher () {
  yield takeLatest(removeNoteRoutine.TRIGGER, removeNote)
}

export function * clearCreatedBpWatcher () {
  yield takeLatest(clearCreatedBpRoutine.TRIGGER, clearCreatedBp)
}

export function * updateCreatedBpWatcher () {
  yield takeLatest(updateCreatedBpRoutine.TRIGGER, updateCreatedBp)
}

export function * removeCreatedBpUnitWatcher () {
  yield takeLatest(removeCreatedBpUnitRoutine.TRIGGER, removeCreatedBpUnit)
}

// SAGAS

export const bpCreationSagas = [
  fork(createBpWatcher),
  fork(removeBpWatcher),
  fork(updateBpWatcher),
  fork(addNoteWatcher),
  fork(editNoteWatcher),
  fork(clearCreatedBpWatcher),
  fork(removeNoteWatcher),
  fork(updateCreatedBpWatcher),
  fork(removeCreatedBpUnitWatcher)
]
