import React, { useEffect, useRef, useState } from 'react'
import { PDFDocument } from 'components/PdfViewer'
import styled, { css } from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import CloseIcon from '@material-ui/icons/Close'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { pathOr } from 'ramda'
import { markFileAsViewed } from 'services/fileService'

const PdfFullScreenViewer = ({ open, onClose, file, version }) => {
  const [rotation, setRotation] = useState(0)
  const [scale, setScale] = useState(1)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const timeoutRef = useRef(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const setNextPage = () => setPageNumber(prev => Math.min(prev + 1, numPages))
  const setFirstPage = () => setPageNumber(1)
  const setLastPage = () => setPageNumber(numPages)
  const setPrevPage = () => setPageNumber(prev => Math.max(prev - 1, 1))

  const handleZoomIn = () => setScale(prev => Math.min(5, Number(prev) + 0.1))
  const handleZoomOut = () => setScale(prev => Math.max(0.5, Number(prev) - 0.1))

  const handleRotateLeft = () => {
    setRotation(prev => {
      switch (prev) {
        case 0:
          return 270
        case 270:
          return 180
        case 180:
          return 90
        case 90:
          return 0
      }
    })
  }

  const handleRotateRight = () => {
    setRotation(prev => {
      switch (prev) {
        case 0:
          return 90
        case 90:
          return 180
        case 180:
          return 270
        case 270:
          return 0
      }
    })
  }

  useEffect(() => {
    if (open) {
      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          const fileId = file.id
          const fileVersionId = version ? version.id : pathOr('', ['versions', 0, 'id'], file)

          markFileAsViewed({
            fileId,
            fileVersionId,
            type: 'view'
          })
        }, 4000)
      }
    } else {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
        timeoutRef.current = null
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
        timeoutRef.current = null
      }
    }
  }, [open, file])

  return (
    <PreviewWrapper open={open}>
      <PreviewOptions>
        <FirstPage onClick={setFirstPage} />
        <PrevIcon onClick={setPrevPage} />
        <PageInput
          type='number'
          value={pageNumber}
          min={1}
          max={numPages}
          onChange={(e) => setPageNumber(Number(e.target.value))}
        />&nbsp;/ {numPages}
        <NextIcon onClick={setNextPage} />
        <LastPage onClick={setLastPage} />
        <ZoomWrapper>
          <ZoomOut onClick={handleZoomOut} />
          <input
            style={{ cursor: 'pointer' }}
            type='range'
            value={scale}
            min={0.5}
            max={5}
            step={0.1}
            onChange={e => setScale(Number(e.target.value))}
          />
          <ZoomIn onClick={handleZoomIn} />
        </ZoomWrapper>
        <RotateLeft onClick={handleRotateLeft} />
        <RotateRight onClick={handleRotateRight} />
        <PreviewClose onClick={onClose} />
      </PreviewOptions>
      <PreviewContentWrapper>
        <ScrollContainer className='scroll-container'>
          {
            open && (
              <PDFDocument
                onLoad={onDocumentLoadSuccess}
                url={file.url}
                pageScale={scale}
                pageNumber={pageNumber}
                rotation={rotation}
              />
            )
          }
        </ScrollContainer>
      </PreviewContentWrapper>
    </PreviewWrapper>
  )
}

export default PdfFullScreenViewer

const PreviewWrapper = styled.div`
  box-sizing: border-box;
  background-color: rgba(30, 30, 30, .9);
  display: none;
  transition: all .3s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${({ open }) => open && css`
    display: block;
    z-index: 9999;
  `}
`

const PreviewContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  overflow: auto;
  height: 95vh;
`

const PreviewClose = styled(CloseIcon)`
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 25px !important;
  color: ${({ theme }) => theme.colors.haiti};
`

const PreviewOptions = styled.div`
  height: 40px;
  width: 100%;
  background-color: rgba(255, 255, 255, .85);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1)
`

const FirstPage = styled(FirstPageIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  margin: 0 10px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const PrevIcon = styled(NavigateBeforeIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  margin: 0 10px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const NextIcon = styled(NavigateNextIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  margin: 0 10px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const LastPage = styled(LastPageIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  margin: 0 10px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const ZoomWrapper = styled.div`
  display: flex;
  margin: 0 50px;
`

const ZoomOut = styled(ZoomOutIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  margin-right: 5px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const ZoomIn = styled(ZoomInIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  margin-left: 5px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const RotateLeft = styled(RotateLeftIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  margin-left: 5px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const RotateRight = styled(RotateRightIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  margin-left: 5px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const PageInput = styled.input`
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
