import React from 'react'
import styled from 'styled-components'

const SignUpConfirmation = () => {
  return (
    <Wrapper>
      <Content>
        <Header>
          To finish setting up your account, we need to confirm your email address. Please click on the ‘Complete account
          creation’ button in the email we&apos;ve just sent to you.
        </Header>
        <Description>
          When you click the button in the email, you will be taken to the log in page where you can enter your log in
          credentials and view your Building Passport(s). You can now close this tab.
        </Description>
      </Content>
    </Wrapper>
  )
}

export default SignUpConfirmation

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  max-width: 600px;
  font-size: 30px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.dimensions.topBarHeight};
`

const Header = styled.h1`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;

  div {
    text-align: center;
  }
`

const Description = styled.p`
  font-size: 18px;
  margin-top: 20px;
`
