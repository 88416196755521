import LocalStorageService from 'services/LocalStorageService'
import { isNotEmpty } from 'utils/ramda'
import { cartEmitter } from 'utils/emitters'

export const KEYS = {
  token: 'api-token',
  cart: 'user-cart',
  userId: 'last-logged',
  version: 'current-version',
  redirectPath: 'redirect-path'
}

export const getCart = () => {
  const cartItems = LocalStorageService.get(KEYS.cart) || ''
  return cartItems.split(',').filter(e => isNotEmpty(e))
}

export const updateCart = items => {
  LocalStorageService.set(KEYS.cart, items)
  cartEmitter.emit('count-cart-items')
}

export const addToCart = itemId => {
  const cart = getCart()
  LocalStorageService.set(KEYS.cart, [ ...cart, itemId ])
  cartEmitter.emit('cart-change')
  cartEmitter.emit('item-added')
}

export const removeFromCart = itemId => {
  const cart = getCart()
  const newCart = cart.filter(e => e !== itemId)
  LocalStorageService.set(KEYS.cart, newCart)
  cartEmitter.emit('cart-change')
}

export const clearCart = () => {
  LocalStorageService.remove(KEYS.cart)
  cartEmitter.emit('cart-change')
}
