import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrentUserInitials } from 'features/auth/ducks/selectors'
import styled from 'styled-components'

const InitialsAvatar = () => {
  const userInitials = useSelector(getCurrentUserInitials)

  return <StyledAvatar>{userInitials}</StyledAvatar>
}

export default InitialsAvatar

const StyledAvatar = styled.div`
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`
