import { createRoutine } from 'redux-saga-routines'
import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'
import * as dictionariesService from 'services/dictionariesService'

export const getFileCategoriesRoutine = createRoutine('GET_FILE_CATEGORIES')

function * getFileCategories () {
  yield put(getFileCategoriesRoutine.request())
  try {
    const { data } = yield call(dictionariesService.getFileCategories)
    yield put(getFileCategoriesRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getFileCategoriesRoutine.failure(error))
  }
}

export function * getFileCategoriesWatcher () {
  yield takeLatest(getFileCategoriesRoutine.TRIGGER, getFileCategories)
}

export const dictionariesSagas = [
  fork(getFileCategoriesWatcher)
]
