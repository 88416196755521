import { createSelector } from 'reselect'
import { propOr } from 'ramda'

const getAnalyticsState = state => state.analytics

export const selectAnalyticsTemplates = createSelector(
  getAnalyticsState,
  propOr([], 'templates')
)

export const selectBpsWithAnalytics = createSelector(
  getAnalyticsState,
  propOr([], 'bps')
)

export const selectBpsWithAnalyticsTotalPages = createSelector(
  getAnalyticsState,
  propOr([], 'totalPages')
)

export const selectAnalyticsSharingInvitations = createSelector(
  getAnalyticsState,
  propOr([], 'invitations')
)

export const selectAddTemplateValues = createSelector(
  getAnalyticsState,
  propOr({}, 'newTemplate')
)

export const selectAddTemplateFiles = createSelector(
  getAnalyticsState,
  propOr([], 'newTemplateFiles')
)
