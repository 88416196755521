import React, { useEffect } from 'react'
import styled from 'styled-components'
import UnitBox from 'features/bpDetails/Components/units/UnitBox'
import UnitAssignedUsers from 'features/bpDetails/Components/units/UnitAssignedUsers'
import UnitFiles from 'features/bpDetails/Components/units/UnitFiles'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import UnitFloorplans from 'features/bpDetails/Components/units/UnitFloorplans'
import { getUserRelationToUnitRoutine } from 'ducks/units/actions'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { selectUserRelationToUnit } from 'ducks/units/selectors'
import { canEditUnit } from 'utils/units'
import { canEditBp } from 'utils/user'

const UnitDetails = ({ unit }) => {
  const currentUser = useSelector(getCurrentUser)
  const bp = useSelector(getSelectedBp)
  const dispatch = useDispatch()
  const userRelationToUnit = useSelector(selectUserRelationToUnit)
  const canEdit = canEditUnit(userRelationToUnit) || canEditBp(currentUser, bp)

  useEffect(() => {
    if (bp.id && unit.id && currentUser.id) {
      dispatch(getUserRelationToUnitRoutine({
        bpId: bp.id,
        unitId: unit.id,
        userId: currentUser.id
      }))
    }
  }, [bp])

  return (
    <UnitDetailsWrapper>
      <UnitBox unit={unit} canEdit={canEdit} />
      <UnitAssignedUsers unit={unit} canEdit={canEdit} />
      <UnitFloorplans unit={unit} />
      <UnitFiles
        canEdit={canEdit}
        unit={unit}
      />
    </UnitDetailsWrapper>
  )
}

export default UnitDetails

const UnitDetailsWrapper = styled.div`

`
