import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Input from 'components/atoms/Input'
import { requestResetPasswordRoutine } from 'features/auth/ducks/actions'
import {
  validateRequestChangePasswordField,
  validateRequestChangePasswordValues
} from 'features/auth/ducks/schema'
import { useTranslation } from 'react-i18next'
import FullWidthButton from 'features/auth/components/FullWidthButton'
import { PATHS } from 'utils/paths'
import BackButton from 'components/atoms/BackButton'

const emptyValues = { email: '' }

const RequestResetPassword = () => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setIsValid] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    await dispatch(requestResetPasswordRoutine({
      email: values.email.toLowerCase()
    }))
  }

  useEffect(() => {
    validateRequestChangePasswordValues(values, setIsValid)
  }, [values])

  return (
    <PageWrapper>
      <BackButtonWrapper>
        <BackButton to={PATHS.login}>Back to Log in</BackButton>
      </BackButtonWrapper>
      <LoginWrapper>
        <HeaderText>
          {t('passwordReset.requestHeader')}
          <span>{t('passwordReset.requestSubheader')}</span>
        </HeaderText>
        <FormDescription>
          {t('passwordReset.requestFormDescription')}
        </FormDescription>
        <LoginForm onSubmit={handleSubmit}>
          <Input
            required
            label={t('passwordReset.labels.email')}
            name='email'
            onChange={handleOnChange}
            validate={validateRequestChangePasswordField(values)}
          />
          <ButtonWrapper>
            <FullWidthButton
              type='submit'
              disabled={!valid}
            >
              {t('passwordReset.labels.requestSubmitButton')}
            </FullWidthButton>
          </ButtonWrapper>
        </LoginForm>
      </LoginWrapper>
    </PageWrapper>
  )
}

export default RequestResetPassword

const PageWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LoginWrapper = styled.div`
  box-sizing: border-box;
  width: 645px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 50px 160px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(93, 117, 208, 0.1);
`

const HeaderText = styled.h1`
  font-size: 38px;
  margin-bottom: 30px;
  
  span {
    display: block;
    font-weight: bold;
  }
`

const LoginForm = styled.form`
  box-sizing: border-box;
  width: 100%;
`

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  text-align: right;
`

const FormDescription = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .7;
  margin: 25px 0 30px;
`

const BackButtonWrapper = styled.div`
  padding: 0 0 15px;
  width: 645px;
  margin: 0 auto;
`
