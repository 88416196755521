import React, { useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import styled, { css } from 'styled-components'
import { getAssignedUsers } from 'utils/bpData'
import UnitAssignedUserBox from 'features/bpDetails/Components/units/UnitAssignedUserBox'
import UnitAddAssignmentModal from 'features/bpDetails/Components/units/UnitAddAssignmentModal'
import Modal from 'components/atoms/Modal'
import RequestsList from 'features/accessControl/components/RequestsList'
import InvitationsList from 'features/accessControl/components/InvitationsList'
import RevokedList from 'features/accessControl/components/RevokedList'
import { useSelector } from 'react-redux'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'
import { selectUserRelationToBp } from 'features/bpDetails/ducks/selectors'

const UnitAssignedUsers = ({ unit }) => {
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [userToEdit, setUserToEdit] = useState({})
  const [activeTab, setTab] = useState('requests')
  const userRoles = useSelector(selectUserRelationToBp)
  const userRole = useSelector(getCurrentUserRole)
  const isProspectivePurchaser = userRoles.includes('prospective_purchaser') && userRoles.length === 1
  const isFrs = userRole === 'emergency_service'

  const handleModalOpen = user => () => {
    user ? setUserToEdit(user) : setUserToEdit({})
    setAddModalOpen(true)
  }

  const assignedUsers = useMemo(() => {
    return getAssignedUsers(unit)
  }, [unit])

  const tabContent = useMemo(() => {
    switch (activeTab) {
      case 'requests':
        return <RequestsList unit={unit} />
      case 'invitations':
        return <InvitationsList unit={unit} />
      case 'revoked':
      default:
        return <RevokedList unit={unit} />
    }
  }, [activeTab, unit])

  return (
    <UsersWithAccessWrapper>
      {
        !isProspectivePurchaser && !isFrs && (
        <>
          <TabsWrapper>
            <Tab
              onClick={() => setTab('requests')}
              isActive={activeTab === 'requests'}
            >
              Access Requests
            </Tab>
            <Tab
              onClick={() => setTab('revoked')}
              isActive={activeTab === 'revoked'}
            >
              Access Revoked
            </Tab>
            <Tab
              onClick={() => setTab('invitations')}
              isActive={activeTab === 'invitations'}
            >
              Invitations
            </Tab>
          </TabsWrapper>

      <ContentWrapper>
        {tabContent}
      </ContentWrapper>
        </>
        )
      }
      <HeaderWrapper>
        <SectionHeader>
          Users with access
        </SectionHeader>
        {
          !isProspectivePurchaser && !isFrs && (
            <AddUserButton
              onClick={handleModalOpen(null)}
            >
              + assign another user
            </AddUserButton>
          )
        }
      </HeaderWrapper>
      <UsersTable>
        <thead>
        <th>Name</th>
        <th>Email</th>
        <th>Role</th>
        <th>Expires</th>
        <th />
        </thead>
        <tbody>
        {
          !isEmpty(assignedUsers)
            ? assignedUsers.map((user, index) => (
              <UnitAssignedUserBox
                key={`assignedUser-${user.id}-${index}`}
                user={user}
                index={index}
                unit={unit}
                openEditModal={handleModalOpen}
              />
            ))
            : <p>Assigned users list is empty</p>
        }
        </tbody>
      </UsersTable>
      <Modal
        open={addModalOpen}
        title='Assign user'
        onClose={() => setAddModalOpen(false)}
      >
        <UnitAddAssignmentModal
          userToEdit={userToEdit}
          unit={unit}
          closeModal={() => setAddModalOpen(false)}
        />
      </Modal>
    </UsersWithAccessWrapper>
  )
}

export default UnitAssignedUsers

const UsersWithAccessWrapper = styled.div`
  margin-top: 20px;
`

const SectionHeader = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const AddUserButton = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;

  ${({ disabled, theme }) => disabled && css`
    color: ${theme.colors.grey[600]};
    cursor: default;
  `}
`

const UsersTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};

  thead {
    background-color: ${({ theme }) => theme.colors.backgroundColor};

    th {
      padding: 8px 10px;
      text-align: left;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  tbody {
    tr {
      background-color: ${({ theme }) => theme.colors.backgroundColor};

      td {
        padding: 8px 10px;

        &:first-of-type {
          font-weight: bold;
        }
      }
    }
  }
`

const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;

  & > div {
    flex: 1;
  }
`

const Tab = styled.div`
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-color: ${({ theme, isActive }) =>
  isActive ? theme.colors.secondary : theme.colors.lightGrey};
  padding: 5px 0;
  text-align: center;
  cursor: pointer;
`

const ContentWrapper = styled.div`
  padding: 10px 0;
`
