import React, { useEffect, useMemo, useState } from 'react'
import { convertBytes } from 'utils/files'
import Select from 'components/atoms/Select'
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg'
import { useSelector } from 'react-redux'
import { getFileTypes } from 'ducks/files/selectors'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import { selectBpUnits } from 'ducks/units/selectors'
import styled, { css } from 'styled-components'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getFloorOptions } from 'utils/bpData'
import FileTypesSearch from 'features/bpDetails/Components/files/FileTypesSearch'
import { propOr } from 'ramda'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import Input from 'components/atoms/Input'
import AddUsersToFile from 'features/bpDetails/Components/files/AddUsersToFile'
import { Tooltip } from '@material-ui/core'
import AttachTagsToUploadingFile from 'features/bpDetails/Components/files/AttachTagsToUploadingFiles'

const defaultValues = {
  fileCategory: '',
  fileType: '',
  expirationDate: '',
  units: [],
  floors: [],
  userIds: [],
  tagIds: []
}

const informationExchangeCategoryName = 'FRS Information Exchange'

const FileModalTableRow = ({
  file,
  onRemove,
  onSelect,
  isSubmitted,
  onValidationStateChange,
  onChange,
  bulkChangeValues,
  isSelected,
  initialType,
  initialCategory,
  unit,
  isPrivate
}) => {
  const [values, setValues] = useState(defaultValues)
  const fileExtension = file.path?.split('.')?.pop()
  const [displayName, setDisplayName] = useState(
    file.name
      ?.replace(`.${fileExtension}`, '')
      ?.replace(`.${fileExtension.toUpperCase()}`, '')
  )
  const [editNameMode, setEditNameMode] = useState(false)
  const [tempDisplayName, setTempDisplayName] = useState(displayName)
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)
  const bpUnits = useSelector(selectBpUnits)
  const bp = useSelector(getSelectedBp)
  const isValidExtensionForFloorPlan = ['pdf', 'dwg', 'jpg', 'jpeg', 'png', 'svg'].includes(fileExtension)
  const isValidExtensionForErp = ['pdf'].includes(fileExtension)

  const isUnit = isNotNilOrEmpty(unit)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (isNotNilOrEmpty(fileTypes) && isNotNilOrEmpty(initialType)) {
      handleValueChange('fileType', initialType)
    }

    if (isNotNilOrEmpty(fileCategories) && isNotNilOrEmpty(initialCategory)) {
      handleValueChange('fileCategory', initialCategory)
    }
  }, [initialType, initialCategory, fileCategories, fileTypes])

  const handleCategoryChange = (_, value) => {
    setValues(prev => ({
      ...prev,
      fileType: '',
      fileCategory: value,
      floors: []
    }))
  }

  const handleTypeChange = (_, value) => {
    setValues(prev => ({
      ...prev,
      floors: [],
      fileType: value
    }))
  }

  useEffect(() => {
    if (isNotNilOrEmpty(bulkChangeValues) && isSelected) {
      const { fileCategory, fileType, units, floors, expirationDate, userIds, tagIds } = bulkChangeValues
      const selectedNewType = fileTypes.find(type => type.id === fileType)
      const selectedOldType = fileTypes.find(type => type.id === values.fileType)
      const isFloorPlan = (selectedOldType?.name === 'Building plan' && isNilOrEmpty(selectedNewType?.name)) ||
        selectedNewType?.name === 'Building plan'

      isNotNilOrEmpty(fileCategory) && handleCategoryChange('', fileCategory)
      isNotNilOrEmpty(fileType) && handleTypeChange('', fileType)
      isNotNilOrEmpty(units) && handleValueChange('units', units)
      isNotNilOrEmpty(floors) && isFloorPlan && handleValueChange('floors', floors)
      isNotNilOrEmpty(expirationDate) && handleValueChange('expirationDate', expirationDate)
      isNotNilOrEmpty(userIds) && handleValueChange('userIds', userIds)
      isNotNilOrEmpty(tagIds) && handleValueChange('tagIds', tagIds)
    }
  }, [bulkChangeValues])

  const categoriesOptions = fileCategories
    .filter(cat => cat.name !== informationExchangeCategoryName)
    .map(type => ({
      label: type.name,
      value: type.id
    }))

  const bpFloorOptions = getFloorOptions(bp)
  const unitFloorNumbers = propOr([], 'floorNumbers', unit)

  const floorOptions = bpFloorOptions.filter(option => unitFloorNumbers.includes(option.value))

  const isFloorPlan = useMemo(() => {
    if (isNotNilOrEmpty(values.fileType)) {
      const selectedType = fileTypes.find(type => type.id === values.fileType)
      return selectedType?.name === 'Building plan'
    } else {
      return false
    }
  }, [values.fileType])

  const isErpFile = useMemo(() => {
    if (isNotNilOrEmpty(values.fileCategory)) {
      const selectedCategory = fileCategories.find(category => category.id === values.fileCategory)
      return selectedCategory?.name === 'Emergency Response Pack (ERP)'
    } else {
      return false
    }
  }, [values.fileCategory])

  const unitsOptions = bpUnits
    .map(type => ({
      label: type.name,
      value: type.id
    }))

  useEffect(() => {
    const { fileType, fileCategory, floors } = values
    const floorPlanValid = isFloorPlan ? isNotNilOrEmpty(floors) && isValidExtensionForFloorPlan : true
    const erpValid = isErpFile ? isValidExtensionForErp : true

    const isValid = isNotNilOrEmpty(fileType) && isNotNilOrEmpty(fileCategory) && floorPlanValid && erpValid

    onValidationStateChange(isValid)
  }, [values, isFloorPlan, isErpFile])

  useEffect(() => {
    if (isPrivate) {
      onChange({ file, values: { displayName } })
    } else {
      onChange({ file, values: { ...values, displayName } })
    }
  }, [values])

  const handleTypeAndCategoryChange = (category, type) => {
    category !== values.fileCategory && handleCategoryChange('', category)
    type !== values.fileType && handleTypeChange('', type)
  }

  const toggleEditNameMode = () => {
    setEditNameMode(prev => !prev)
  }

  const handleSaveDisplayName = () => {
    setDisplayName(tempDisplayName)
    toggleEditNameMode()
  }

  return (
    <Wrapper>
      <TableRow key={file.id} isUnit={isUnit} isPrivate={isPrivate}>
        <RadioCell>
          <input type='checkbox' onChange={onSelect} checked={isSelected} />
        </RadioCell>
        <TableCell>
          <FileNameWrapper>
            <FileIconWrapper>
              <FileIcon />
            </FileIconWrapper>
            <div>
              <FileNameEditWrapper>
                {
                  editNameMode
                    ? (
                      <input
                        name='displayName'
                        value={tempDisplayName}
                        onChange={e => setTempDisplayName(e.target.value)}
                      />
                    )
                    : <FileName isPrivate={isPrivate}>{displayName}.{fileExtension}</FileName>
                }
                {
                  editNameMode
                    ? (
                      <StyledSaveIcon
                        onClick={isNotNilOrEmpty(tempDisplayName) ? handleSaveDisplayName : () => {}}
                        disabled={isNilOrEmpty(tempDisplayName)}
                      />
                    )
                    : <StyledEditIcon onClick={toggleEditNameMode} />
                }
              </FileNameEditWrapper>
              <FileSize>{convertBytes(file.size)}</FileSize>
            </div>
          </FileNameWrapper>
        </TableCell>
        {
          !isPrivate && (
            <>
              <TableCell>
                <Select
                  noMargin
                  value={values.fileCategory}
                  name='fileCategory'
                  options={categoriesOptions}
                  hasError={isNilOrEmpty(values.fileCategory) && isSubmitted}
                  onChange={handleCategoryChange}
                />
              </TableCell>
              <TableCell>
                <FileTypesSearch
                  placeholder='Type to search'
                  category={values.fileCategory}
                  onFileTypeSelect={handleTypeAndCategoryChange}
                  value={values.fileType}
                  hasError={isNilOrEmpty(values.fileType) && isSubmitted}
                />
              </TableCell>
              <TableCell>
                <Select
                  noMargin
                  multiple
                  onChange={handleValueChange}
                  name='floors'
                  value={values.floors}
                  selectAll
                  options={isUnit ? floorOptions : bpFloorOptions}
                  disabled={!isFloorPlan}
                  hasError={isNilOrEmpty(values.floors) && isFloorPlan && isSubmitted}
                />
              </TableCell>
              {
                !isUnit && (
                  <TableCell>
                    <Select
                      noMargin
                      multiple
                      onChange={handleValueChange}
                      value={values.units}
                      name='units'
                      selectAll
                      disabled={isNilOrEmpty(unitsOptions)}
                      options={unitsOptions}
                    />
                  </TableCell>
                )
              }
              {
                !isPrivate && (
                  <TableCell>
                    <Input
                      value={values.expirationDate}
                      name='expirationDate'
                      type='date'
                      onChange={handleValueChange}
                      noMarginBottom
                    />
                  </TableCell>
                )
              }
              {
                !isUnit && (
                  <>
                    <TableCell>
                      <Tooltip title='Manage permissions'>
                        <AddUsersToFile
                          isUpload
                          initiallySelected={values.userIds}
                          savedCount={values.userIds?.length || 0}
                          onSave={value => {
                            handleValueChange('userIds', value)
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Manage tags'>
                        <AttachTagsToUploadingFile
                          savedCount={values.tagIds?.length || 0}
                          initiallySelected={values.tagIds}
                          onSave={value => {
                            handleValueChange('tagIds', value)
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </>
                )
              }
            </>
          )
        }
        <TableCell>
          <DeleteIconWrapper onClick={onRemove}>
            <DeleteIcon />
          </DeleteIconWrapper>
        </TableCell>
      </TableRow>
      {
        isFloorPlan && !isValidExtensionForFloorPlan && (
          <TypeError>
            Wrong file extension for Building Plan.
          </TypeError>
        )
      }
      {
        isErpFile && !isValidExtensionForErp && (
          <TypeError>
            Only PDF files are allowed in the ERP category.
          </TypeError>
        )
      }
    </Wrapper>

  )
}

export default FileModalTableRow

const FileName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.haiti};
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ isPrivate }) => isPrivate && css`
    max-width: unset;
    width: 100%;
  `}
`

const FileSize = styled.div`
  font-size: 12px;
`

const RadioCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const FileIconWrapper= styled.div`
  margin-right: 10px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAE6E1;
`

const FileIcon = styled(InsertDriveFileOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px !important;
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr repeat(4, 160px) 180px 40px 40px 40px;
  height: 72px;
  margin-top: -1px;
  position: relative;
  cursor: pointer;

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  ${({ isUnit }) => isUnit && css`
    grid-template-columns: 40px 1fr repeat(4, 160px) 40px;
  `}

  ${({ isPrivate }) => isPrivate && css`
    grid-template-columns: 40px 1fr 40px;
  `}
`

const TableCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  padding: 0 5px;
`

const DeleteIconWrapper = styled.div`
  cursor: pointer;
  padding: 3px;

  svg {
    cursor: pointer !important;
  }
`

const TypeError = styled.div`
  color: ${({ theme }) => theme.colors.error};
  padding-left: 45px;
  margin-top: -10px;
`

const Wrapper = styled.div`
  border: 1px solid #EAECF0;
`

const FileNameEditWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const StyledEditIcon = styled(EditIcon)`
  font-size: 14px !important;
  cursor: pointer !important;
`

const StyledSaveIcon = styled(SaveIcon)`
  font-size: 14px !important;
  cursor: pointer !important;
  color: ${({ theme, disabled }) => disabled ? theme.colors.error : theme.colors.success};
`
