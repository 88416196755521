import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearNewTemplateValuesRoutine,
  detachAnalyticsTemplateRoutine,
  getAnalyticsSharingInvitationsRoutine,
  getAnalyticsTemplatesRoutine,
  getBpsWithAnalyticsRoutine
} from 'features/analytics/ducks/actions'
import {
  selectAnalyticsSharingInvitations,
  selectAnalyticsTemplates,
  selectBpsWithAnalytics, selectBpsWithAnalyticsTotalPages
} from 'features/analytics/ducks/selectors'
import styled from 'styled-components'
import PageHeader from 'components/atoms/PageHeader'
import TemplateItem from 'features/analytics/compontents/TemplateItem'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import BpDropContainer from 'features/analytics/compontents/BpDropContainer'
import { isNotNilOrEmpty } from 'utils/ramda'
import { isEmpty, propOr } from 'ramda'
import Modal from 'components/atoms/Modal'
import SharedTemplate from 'features/analytics/compontents/SharedTemplate'
import Pagination from 'components/atoms/Pagination'

const Analytics = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const templates = useSelector(selectAnalyticsTemplates)
  const sharingInvitations = useSelector(selectAnalyticsSharingInvitations)
  const bps = useSelector(selectBpsWithAnalytics)
  const [selectedBp, setSelectedBp] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [removeOpenModal, setRemoveModalOpen] = useState(false)
  const pagesCount = useSelector(selectBpsWithAnalyticsTotalPages)

  const handleOpenRemoveModal = (bp, template) => {
    setRemoveModalOpen(true)
    setSelectedBp(bp)
    setSelectedTemplate(template)
  }

  const handleCloseRemoveModal = () => {
    setRemoveModalOpen(false)
    setSelectedBp(null)
    setSelectedTemplate(null)
  }

  const bpsSortedByName = useMemo(() => {
    return isNotNilOrEmpty(bps)
      ? bps
        .filter(bp => bp.status !== 'draft')
        .sort((a, b) => a.name.localeCompare(b.name))
      : []
  }, [bps])

  const customTemplates = useMemo(() => {
    return isNotNilOrEmpty(templates)
      ? templates.filter(t => t.type !== 'default')
      : []
  }, [templates])

  useEffect(() => {
    dispatch(getAnalyticsTemplatesRoutine())
    dispatch(clearNewTemplateValuesRoutine())
    dispatch(getAnalyticsSharingInvitationsRoutine())
  }, [])

  useEffect(() => {
    dispatch(getBpsWithAnalyticsRoutine(currentPage))
  }, [currentPage])

  const handleRemoveTemplate = () => {
    dispatch(detachAnalyticsTemplateRoutine({
      page: currentPage,
      bpId: selectedBp.id,
      templateId: selectedTemplate.id
    }))
    handleCloseRemoveModal()
  }

  return (
    <PageWrapper>
      <PageHeader>
        Analytics
      </PageHeader>
      <PageContent>
        {
          bpsSortedByName.map((bp, index) => {
            return (
              <BpDropContainer
                currentPage={currentPage}
                handleOpenRemoveModal={handleOpenRemoveModal}
                bps={bpsSortedByName}
                index={index}
                key={`bp-drop-${bp.id}`}
                bp={bp}
              />
            )
          })
        }
        <Pagination
          totalPages={pagesCount}
          onChange={page => setCurrentPage(page)}
        />
      </PageContent>
      <SidePanel>
        <SidePanelTitle>
          templates
        </SidePanelTitle>
        <SidePanelDescription>
          Drag and drop to apply template to a Building Passport
        </SidePanelDescription>
        <SidePanelSectionTitle>
          <span>My templates</span>
          <EditIcon onClick={() => redirect(PRIVATE_PATHS.editAnalytics)} />
        </SidePanelSectionTitle>
        <SidePanelTemplatesList>
          {
            customTemplates.map(template => {
              return <TemplateItem key={template.id} template={template} />
            })
          }
          {
            isEmpty(customTemplates) && (
              <EmptyState>There are no custom templates</EmptyState>
            )
          }
        </SidePanelTemplatesList>
        <AddTemplateButton onClick={() => redirect(PRIVATE_PATHS.addAnalytics)}>
          + add template
        </AddTemplateButton>
        {
          !isEmpty(sharingInvitations) && (
            <PendingInvitations>
              <SidePanelSectionTitle>
                <span>Sharing invitations</span>
              </SidePanelSectionTitle>
              {
                sharingInvitations.map(invitation => {
                  return <SharedTemplate key={invitation.id} invitation={invitation} />
                })
              }
            </PendingInvitations>
          )
        }
      </SidePanel>
      <Modal
        open={removeOpenModal}
        title='Detach template'
        onClose={handleCloseRemoveModal}
        onCancel={handleCloseRemoveModal}
        onSubmit={handleRemoveTemplate}
        withCancel
        withSubmit
        submitButtonText='Remove'
      >
        Do you really want to remove this template from {propOr('', 'name', selectedBp)}?
      </Modal>
    </PageWrapper>
  )
}

export default Analytics

const PageWrapper = styled.div`
  padding-bottom: 30px;
`

const PageContent = styled.div`
  padding-right: 300px;
`

const SidePanel = styled.div`
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`

const SidePanelTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 5px;
`

const SidePanelDescription = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const SidePanelSectionTitle = styled.div`
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};

  span {
    opacity: .6;
  }
`

const SidePanelTemplatesList = styled.div`
  padding: 10px 0;
`

const EditIcon = styled(EditOutlinedIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  transition: all .3s;

  &:hover {
    opacity: .8;
  }
`

const AddTemplateButton = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }
`

const EmptyState = styled.div`
  color: ${({ theme }) => theme.colors.grey[700]};
  padding: 5px 0;
`

const PendingInvitations = styled.div`
  margin-bottom: 20px;
`
