const emptyUnitsState = {
  units: [],
  selectedUnit: null,
  editUnit: null,
  userUnits: [],
  sharedUnitsList: [],
  floorplans: [],
  accessRequests: []
}

export default emptyUnitsState
