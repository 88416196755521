import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from 'components/atoms/Radio'
import { isNil } from 'ramda'
import { isNotNil } from 'utils/ramda'

const ChoiceFilter = ({ name, label, handleChange, filters }) => {
  const [value, setValue] = useState()

  useEffect(() => {
    if (isNotNil(value)) {
      const val = value === 'yes'
      typeof handleChange === 'function' && handleChange(name, val)
    }
  }, [value])

  useEffect(() => {
    isNil(filters)
      ? setValue(null)
      : setValue(filters === true ? 'yes' : 'no')
  }, [filters])

  const changeValue = e => setValue(e.target.value)

  return (
    <Wrapper>
      <FilterName>
        {label}
      </FilterName>
      <Options>
        <FormControl>
          <RadioGroup
            row
            value={value || null}
            onChange={changeValue}
          >
            <ToggleLabel
              value='yes'
              control={<Radio color='secondary' />}
              label='Yes'
            />
            <ToggleLabel
              value='no'
              control={<Radio color='secondary' />}
              label='No'
            />
          </RadioGroup>
        </FormControl>
      </Options>
    </Wrapper>
  )
}

export default ChoiceFilter

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FilterName = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .7;
`

const Options = styled.div`
  
`

const ToggleLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.haiti};
    font-family: FuturaPT-book;
  }
`
