import React from 'react'
import styled from 'styled-components'

const PageHeader = ({ children }) =>
  <StyledHeader>{children}</StyledHeader>

export default PageHeader

const StyledHeader = styled.h1`
  font-size: 36px;
  margin-bottom: 15px;
  letter-spacing: -1.03px;
  font-weight: normal;
  font-family: 'FuturaPT-Light';
`
