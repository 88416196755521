import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getBuildingTypesForForms } from 'features/bpList/ducks/selectors'
import Select from 'components/atoms/Select'
import { not, propOr, values, equals, isEmpty } from 'ramda'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import { validateBuildingDetailsField } from 'features/createBp/ducks/schema'
import { isNotEmpty, isNotNilOrEmpty } from 'utils/ramda'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { getBuildingTypeValueType } from 'utils/bpData'

const BuildingTypeSelect = ({ disabled, onChange, index, value, withDelete, onDelete, blockDwelling }) => {
  const [selectedType, setSelectedType] = useState({
    type: propOr('', 'type', value),
    value: propOr('', 'value', value),
    unit: propOr('', 'unit', value),
    description: propOr('', 'description', value),
    initialUnitsNumber: propOr(1, 'initialUnitsNumber', value),
    index
  })
  const buildingTypes = useSelector(getBuildingTypesForForms)
  const isDwellingSoleUnit = selectedType.type === 'residential.dwelling.sole_unit'
  const isHotel = selectedType.type === 'hotel'

  useEffect(() => {
    const def = {
      type: propOr('', 'type', value),
      value: propOr('', 'value', value),
      unit: propOr('', 'unit', value),
      description: propOr('', 'description', value),
      initialUnitsNumber: propOr(1, 'initialUnitsNumber', value),
      index
    }

    if (not(equals(def, selectedType))) {
      setSelectedType(def)
    }
  }, [value])

  const selectOptions = useMemo(() => {
    if (buildingTypes) {
      const options = values(buildingTypes).map(type => ({
        label: type.label,
        value: type.key,
        unit: type.unit,
        multipleBpUnits: type.multipleBpUnits,
      })).filter(e => e.value !== 'public')
      return blockDwelling
        ? options.filter(option => option.value !== 'residential.dwelling.sole_unit')
        : options
    }
  }, [buildingTypes, selectedType, blockDwelling])

  const unit = useMemo(() => {
    if (isNotNilOrEmpty(selectOptions)) {
      const item = selectOptions.find(e => e.value === selectedType.type)
      return propOr('', 'unit', item)
    }
  }, [selectedType])

  const hasMultipleUnits = useMemo(() => {
    if (isNotNilOrEmpty(selectOptions)) {
      const item = selectOptions.find(e => e.value === selectedType.type)
      return propOr('', 'multipleBpUnits', item)
    }
  }, [selectedType])

  const handleSelectChange = (_, type) => {
    const foundOption = selectOptions.find(option => option.value === type)
    setSelectedType(prev => ({
      ...prev,
      type,
      unit: foundOption.unit,
      value: type === 'residential.dwelling.sole_unit' ? 1 : selectedType.value
    }))
  }

  const handleInputChange = (name, value) => {
    setSelectedType(prev => ({
      ...prev,
      [name]: value
    }))
  }

  useEffect(() => {
    onChange({
      ...selectedType,
      description: unit === 'unit' ? selectedType.description : '',
      value: String(selectedType.value).replace(/^0*/, ''),
      index
    })
  }, [selectedType])

  return (
    <Wrapper>
      {
        disabled && index === 0 && (
          <DisabledWarning>
            Building types can be edited by adding or removing units in the third step of this wizard.
          </DisabledWarning>
        )
      }
      <Select
        name='type'
        options={selectOptions}
        placeholder='Select'
        value={selectedType.type}
        label='Building type'
        onChange={handleSelectChange}
        validate={validateBuildingDetailsField(selectedType)}
        withDelete={withDelete && !disabled}
        onDelete={onDelete}
        disabled={disabled}
      />
      {
        isNotEmpty(selectedType.type) && (
          <ValueWrapper>
            {
              !isDwellingSoleUnit && (
                <Input
                  label={getBuildingTypeValueType(selectedType.type)}
                  value={isEmpty(selectedType.value) ? 0 : selectedType.value}
                  name='value'
                  onChange={handleInputChange}
                  validate={validateBuildingDetailsField(selectedType)}
                  disabled={disabled || isDwellingSoleUnit}
                />
              )
            }
            {
              hasMultipleUnits && unit !== 'unit' && (
                <Input
                  label='Initial units number'
                  type='number'
                  inputProps={{ min: 0 }}
                  value={Number(selectedType.initialUnitsNumber)}
                  name='initialUnitsNumber'
                  onChange={handleInputChange}
                  disabled={disabled}
                />
              )
            }
            {
              unit === 'unit' && (
                <Input
                  label='Description'
                  value={selectedType.description}
                  name='description'
                  optional
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                  validate={validateBuildingDetailsField(selectedType)}
                  disabled={disabled}
                  placeholder={
                    isHotel
                      ? 'Building offering paid lodging on a short basis (i.e. it is not the main residence of the inhabitants)'
                      : 'e.g. Detached two storey house. Brick construction with wooden cladding.'}
                />
              )
            }
            {
              isDwellingSoleUnit && (
                <DescriptionWrapper>
                  <InputDescription>
                    This type of building is one-time subscription. You cannot add more building types to this one.
                  </InputDescription>
                </DescriptionWrapper>
              )
            }
          </ValueWrapper>
        )
      }
    </Wrapper>
  )
}

export default BuildingTypeSelect

const Wrapper = styled.div`
  &:not(:first-of-type) {
    padding-top: 22px;
    border-top: 1px solid ${({ theme }) => theme.colors.grey[500]};
    margin-top: 30px;
  }
`

const ValueWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 15px 15px 0;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`

const DisabledWarning = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: rgba(51, 190, 204, .07);
  padding: 10px;
  text-align: center;
`
