import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { propOr, values } from 'ramda'
import { ReactComponent as KeyIcon } from 'assets/images/icon-key.svg'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import UnitForm from 'features/createBp/components/atoms/UnitForm'
import FloorBadges from 'features/bpDetails/Components/files/FloorBadges'
import Modal from 'components/atoms/Modal'
import {
  clearSelectedUnitRoutine,
  removeBpUnitRoutine,
  updateBpUnitRoutine
} from 'ducks/units/actions'
import { hasMultipleUnits } from 'utils/units'
import { selectBpUnits } from 'ducks/units/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const UnitBox = ({ unit, canEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dropdownOpen = Boolean(anchorEl)
  const buildingTypes = useSelector(getBuildingTypes)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const dispatch = useDispatch()
  const bp = useSelector(getSelectedBp)
  const isSoleResidential = unit.buildingType === 'residential.dwelling.sole_unit'
  const isSoleUnit = !hasMultipleUnits(unit.buildingType)
  const bpUnits = useSelector(selectBpUnits)

  const unitsCount = useMemo(() => {
    if (bpUnits) {
      const typeUnits = bpUnits.filter(bpUnit => bpUnit.buildingType === unit.buildingType)
      return typeUnits.length
    } else {
      return 0
    }
  }, [bpUnits])

  const handleEditModalOpen = () => {
    setEditModalOpen(true)
  }
  const handleEditModalClose = () => {
    setEditModalOpen(false)
  }

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true)
  }
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false)
  }

  const handleRemoveUnit = values => () => {
    dispatch(removeBpUnitRoutine(values))
    dispatch(clearSelectedUnitRoutine())
    handleDeleteModalClose()
  }

  const handleUpdateUnit = values => {
    dispatch(updateBpUnitRoutine({
      ...values,
      bpId: bp.id,
      updateSelected: true
    }))
  }

  const openMenu = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const buildingType = useMemo(() => {
    if (buildingTypes) {
      const type = values(buildingTypes).find(type => type.key === unit.buildingType)
      return propOr('', 'label', type)
    }
  }, [buildingTypes])

  return (
    <UnitBoxWrapper>
      <UnitBoxHeader>
        <UnitBasicDetails>
          <UnitBoxImage>
            <KeyIcon />
          </UnitBoxImage>
          <div>
            <UnitBoxUnitName>
              {unit.name}
            </UnitBoxUnitName>
            <UnitBoxUprn>
              UPRN: {unit.uprn || 'Not yet allocated'}
            </UnitBoxUprn>
          </div>
        </UnitBasicDetails>
        {
          canEdit && (
            <IconWrapper>
              <MoreIcon onClick={openMenu} />
              <Menu
                keepMounted
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                style={{
                  marginTop: '10px'
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={dropdownOpen}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleEditModalOpen}
                  disabled={
                    isSoleResidential ||
                    (unitsCount === 1 && isSoleUnit)
                  }
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={handleDeleteModalOpen}
                  disabled={
                    bpUnits.length <= 1 ||
                    isSoleResidential
                  }
                >
                  Remove Unit
                </MenuItem>
              </Menu>
            </IconWrapper>
          )
        }
      </UnitBoxHeader>
      <UnitMoreDetails>
        <Details>
          <Detail withMargin>Type: <span>{buildingType}</span></Detail>
          <Detail>
            Area:
            <span>
              {unit.area ? `${unit.area} sqm` : ' ---'}
            </span>
          </Detail>
        </Details>
        <Floors>
          Floors:&nbsp;
          {
            unit.floorNumbers
              ? <FloorBadges floorNumbers={unit.floorNumbers} />
              : '---'
          }
        </Floors>
      </UnitMoreDetails>
      <Modal
        open={editModalOpen}
        title='Edit unit'
        onClose={handleEditModalClose}
      >
        <UnitForm
          unit={unit}
          closeEditModal={handleEditModalClose}
          onSubmit={handleUpdateUnit}
          bp={bp}
          buildingType={unit.buildingType}
          hideAssignments
        />
      </Modal>
      <Modal
        open={deleteModalOpen}
        title='Delete unit'
        onClose={handleDeleteModalClose}
        withCancel
        onCancel={handleDeleteModalClose}
        withSubmit
        onSubmit={handleRemoveUnit({ bpId: bp.id, unitId: propOr('', 'id', unit) })}
      >
        Do you want to remove <ModalUnitName>{propOr('', 'name', unit)}</ModalUnitName>?
        Removing the unit will have an impact on total unit number defined for this Building Passport.
      </Modal>
    </UnitBoxWrapper>
  )
}

export default UnitBox

const UnitBoxWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 4px;
`

const UnitBoxHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`

const UnitBoxImage = styled.div`
  width: 56px;
  height: 56px;
  margin-right: 15px;
  background-color: #fbede9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const UnitBoxUnitName = styled.div`
  font-size: 19px;
`

const UnitBoxUprn = styled.div`
  font-size: 12px;
  opacity: .4;
`

const UnitBasicDetails = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  font-size: 30px !important;
`

const UnitMoreDetails = styled.div`
  padding: 7px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[800]};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: ${({ theme }) => theme.colors.haiti};
    font-weight: 600;
  }
`

const Details = styled.div`
  display: flex;
`

const Detail = styled.div`
  margin-right: ${({ withMargin }) => withMargin ? '20px' : 0};
`

const Floors = styled.div`
  display: flex;
  align-items: center;
`

const ModalUnitName = styled.span`
  font-weight: bold;
`
