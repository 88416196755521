import React, { useEffect, useMemo, useState } from 'react'
import Select from 'components/atoms/Select'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { useDispatch, useSelector } from 'react-redux'
import { requestAccessToBpRoutine } from 'features/AccessRequest/ducks/actions'
import { isEmpty } from 'ramda'
import Checkbox from 'components/atoms/Checkbox'
import styled from 'styled-components'
import { selectBpUnits } from 'ducks/units/selectors'
import {
  fetchBpUnitsForAccessRequestRoutine,
  requestAccessToUnitRoutine
} from 'ducks/units/actions'
import { isNotNilOrEmpty } from 'utils/ramda'

const emptyValues = {
  relationType: '',
  consent: false,
  isUnitRequest: false,
  unit: null
}

const NewRequestForm = ({ closeRequestModal, bpId }) => {
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const units = useSelector(selectBpUnits)

  const unitsOptions = useMemo(() => {
    if (isEmpty(units)) {
      return []
    } else {
      return units.map(unit => ({
        label: unit.name,
        value: unit.id
      }))
    }
  }, [units])

  useEffect(() => {
    if (bpId) {
      dispatch(fetchBpUnitsForAccessRequestRoutine({ bpId }))
    }
  }, [bpId])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const toggleCheckbox = name => () => {
    handleOnChange(name, !values[name])
  }

  const handleSubmit = () => {
    if (values.isUnitRequest) {
      dispatch(requestAccessToUnitRoutine({ bpId, unitId: values.unit, ...values }))
    } else {
      dispatch(requestAccessToBpRoutine({ bpId, ...values }))
    }
    closeRequestModal()
    setValues(emptyValues)
  }

  const occupantOption = [
    { label: 'Occupant', value: 'occupant' }
  ]

  return (
    <div>
      {
        isNotNilOrEmpty(units) && (
          <>
            <CheckboxWrapper>
              <InfoWrapper>
                You are about to request access to the entire Building Passport and its contents
              </InfoWrapper>
              <Checkbox
                value={values.isUnitRequest}
                onChange={(_, value) => {
                  if (value && values.relationType === 'occupant') {
                    handleOnChange('relationType', '')
                  }
                  toggleCheckbox('isUnitRequest')()
                }}
                label='I want to request access to the Building Passport for a specific unit'
              />
            </CheckboxWrapper>
            <SelectWrapper>
              <Select
                label='Unit'
                placeholder='Select'
                name='unit'
                onChange={handleOnChange}
                options={unitsOptions}
                disabled={!values.isUnitRequest}
              />
            </SelectWrapper>
          </>
        )
      }
      <CheckboxWrapper>
        <Checkbox
          value={values.consent}
          onChange={toggleCheckbox('consent')}
          label='I understand that the building owner (or representative of the owner)
           will see this request for access and will have visibility of my email address
            in the event that they wish to contact me directly.'
        />
      </CheckboxWrapper>
      <SelectWrapper>
        <Select
          label='Role'
          placeholder='Select'
          name='relationType'
          onChange={handleOnChange}
          options={[
            { label: 'Agent', value: 'agent' },
            { label: 'Owner', value: 'owner' },
            { label: 'Responsible Person', value: 'responsible_person' },
            { label: 'Prospective Purchaser', value: 'prospective_purchaser' },
            { label: 'Principal Accountable Person', value: 'principal_accountable_person' },
            { label: 'Principal Designer', value: 'principal_designer' },
            ...values.isUnitRequest ? occupantOption : []
          ]}
        />
      </SelectWrapper>
      <NextButton
        onClick={handleSubmit}
        disabled={isEmpty(values.relationType) || !values.consent || (values.isUnitRequest && !isNotNilOrEmpty(values.unit))}
      >
        Send request
      </NextButton>
    </div>
  )
}

export default NewRequestForm

const CheckboxWrapper = styled.div`
  max-width: 350px;
  margin: 20px auto;
`

const SelectWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
`

const InfoWrapper = styled.div`
  margin-bottom: 15px;
`
