import React, { useEffect, useState } from 'react'
import Panel from 'features/createBp/components/atoms/Panel'
import NextButton from 'features/createBp/components/atoms/NextButton'
import styled from 'styled-components'
import Select from 'components/atoms/Select'
import { isNotNil } from 'utils/ramda'
import { getCreatedBpId } from 'features/createBp/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import { propOr } from 'ramda'
import InputDescription from 'features/createBp/components/atoms/InputDescription'

const Issues = ({
  completed,
  open,
  setActiveStep,
  setBp,
  bp,
  setCompleted
}) => {
  const dispatch = useDispatch()
  const bpId = useSelector(getCreatedBpId)
  const [sectionValues, setSectionValues] = useState({
    hoardings: propOr(null, 'hoardings', bp),
    structuralDefects: propOr(null, 'structuralDefects', bp),
    asbestos: propOr(null, 'asbestos', bp),
    floodRisk: propOr(null, 'floodRisk', bp),
    claddingIssue: propOr(null, 'claddingIssue', bp),
    hazardousSubstances: propOr(null, 'hazardousSubstances', bp)
  })
  const [valid, setIsValid] = useState(false)

  const handleOnChange = (name, value) => {
    setSectionValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSkip = () => {
    setCompleted('issues')
    setActiveStep('reminders')
  }

  const setNextSection = () => {
    setBp(prev => ({
      ...prev,
      ...sectionValues
    }))
    dispatch(updateBpRoutine({
      ...sectionValues,
      id: bpId,
      isWizard: true
    }))
    handleSkip()
  }

  useEffect(() => {
    setIsValid([
      'hoardings',
      'structuralDefects',
      'asbestos',
      'floodRisk',
      'claddingIssue',
      'hazardousSubstances'
    ].every(issue => isNotNil(sectionValues[issue])))
  }, [sectionValues])

  useEffect(() => {
    setSectionValues({
      hoardings: propOr(null, 'hoardings', bp),
      structuralDefects: propOr(null, 'structuralDefects', bp),
      asbestos: propOr(null, 'asbestos', bp),
      floodRisk: propOr(null, 'floodRisk', bp),
      claddingIssue: propOr(null, 'claddingIssue', bp),
      hazardousSubstances: propOr(null, 'hazardousSubstances', bp)
    })
  }, [bp])

  return (
    <Panel
      open={open}
      title='Issues'
      completed={completed}
      step='issues'
      setActiveStep={setActiveStep}
    >
      <Select
        placeholder='Select'
        value={sectionValues.hoardings}
        label='Hoarding'
        name='hoardings'
        onChange={handleOnChange}
        noMargin
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
      <DescriptionWrapper>
        <InputDescription>
          Is there (or is there likely to be) unnecessary clutter that poses a fire hazard and/or disrupts the usability
          of any area of the building?
        </InputDescription>
      </DescriptionWrapper>
      <Select
        placeholder='Select'
        value={sectionValues.structuralDefects}
        label='Structural defects'
        name='structuralDefects'
        noMargin
        onChange={handleOnChange}
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
      <DescriptionWrapper>
        <InputDescription>
          Is there any structural issue that could pose a hazard to life?
        </InputDescription>
      </DescriptionWrapper>
      <Select
        placeholder='Select'
        value={sectionValues.asbestos}
        label='Asbestos'
        name='asbestos'
        noMargin
        onChange={handleOnChange}
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
      <DescriptionWrapper>
        <InputDescription>
          Is asbestos present in/on the building?
        </InputDescription>
      </DescriptionWrapper>
      <Select
        placeholder='Select'
        value={sectionValues.floodRisk}
        label='Flood risk'
        name='floodRisk'
        noMargin
        onChange={handleOnChange}
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
      <DescriptionWrapper>
        <InputDescription>
          Is the property in an area of flood risk?
        </InputDescription>
      </DescriptionWrapper>
      <Select
        placeholder='Select'
        value={sectionValues.claddingIssue}
        label='Cladding issue'
        name='claddingIssue'
        onChange={handleOnChange}
        noMargin
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
      <DescriptionWrapper>
        <InputDescription>
          Is there any issue with the exterior cladding of the building?
        </InputDescription>
      </DescriptionWrapper>
      <Select
        placeholder='Select'
        value={sectionValues.hazardousSubstances}
        label='Hazardous substances'
        name='hazardousSubstances'
        onChange={handleOnChange}
        noMargin
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
      <DescriptionWrapper>
        <InputDescription>
          Are there any hazardous substances (flammable, toxic, caustic, explosive etc.) currently on site or within the
          property?
        </InputDescription>
      </DescriptionWrapper>
      <NextButton
        onClick={setNextSection}
        disabled={!valid}
      >
        {completed ? 'Save' : 'Next'}
      </NextButton>
      <SkipButton onClick={handleSkip}>Skip issues</SkipButton>
    </Panel>
  )
}

export default Issues

const SkipButton = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  text-decoration: underline;
  transition: all .3s;
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const DescriptionWrapper = styled.div`
  margin: 3px 0 20px;
`
