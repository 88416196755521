import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'
import PageHeader from 'components/atoms/PageHeader'
import styled from 'styled-components'
import ProfileInfo from 'features/profileSettings/components/ProfileInfo'
import StatBox from 'features/dashboard/components/StatBox'
import { isEmpty, pathOr } from 'ramda'
import PeriodSwitch from 'components/atoms/PeriodSwitch'
import { getBillingListRoutine } from 'features/billingList/ducks/actions'
import { getTransactionsList } from 'features/billingList/ducks/selectors'
import BillingListTable from 'features/billingList/components/BillingListTable'
import EmptyState from 'features/billingList/components/EmptyState'
import { getDashboardStats } from 'features/dashboard/ducks/selectors'
import { fetchDashboardStatisticsRoutine } from 'features/dashboard/ducks/actions'

const SectionTitle = ({ children }) => {
  return (
    <SectionTitleWrapper>
      <SectionTitleText>{children}</SectionTitleText>
      <SectionUnderline />
    </SectionTitleWrapper>
  )
}

const BillingList = () => {
  const userRole = useSelector(getCurrentUserRole)
  const dashboardStats = useSelector(getDashboardStats)
  const transactions = useSelector(getTransactionsList)
  const dispatch = useDispatch()
  const [period, setPeriod] = useState('month')

  const handlePeriodChange = period => setPeriod(period)

  useEffect(() => {
    dispatch(fetchDashboardStatisticsRoutine())
    dispatch(getBillingListRoutine())
  }, [])

  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeaderWrapper>
          <PageHeader>List of transactions</PageHeader>
          <PeriodSwitch onChange={handlePeriodChange} />
        </PageHeaderWrapper>
        <Statistics>
          <StatBox
            title='Amount spent on Building Passport'
            value={pathOr(0, ['amountSpent', period, 'formatted'], dashboardStats)}
            period={`this ${period}`}
            primary
          />
          {
            userRole === 'agent' && (
              <StatBox
                title='Number of Clients'
                value={pathOr('', ['numberOfClients', period], dashboardStats)}
                period={`this ${period}`}
              />
            )
          }
          <StatBox
            title='Number of Building Passports'
            value={pathOr('', ['buildingPassports', period], dashboardStats)}
            period={`this ${period}`}
          />
        </Statistics>
        <SectionTitle>Billing list</SectionTitle>
        {
          isEmpty(transactions)
            ? <EmptyState />
            : <BillingListTable transactions={transactions} />
        }
      </ContentWrapper>
      <DetailsWrapper>
        <ProfileInfo />
      </DetailsWrapper>
    </PageWrapper>
  )
}

export default BillingList

const PageWrapper = styled.div`
  display: flex;
`
const ContentWrapper = styled.div`
  width: 100%;
  padding-right: 300px;
  overflow: hidden;
`
const DetailsWrapper = styled.div`
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`

const Statistics = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  & > div:not(:first-of-type) {
    margin-left: 20px;
  }
`

const SectionTitleWrapper = styled.div`
  margin: 40px 0 20px;
`

const SectionTitleText = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 10px;
`

const SectionUnderline = styled.div`
  width: 50px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
