import React from 'react'
import styled from 'styled-components'
import { Button as MaterialButton } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isNotNilOrEmpty } from 'utils/ramda'

const Button = ({ children, isLoading, loadingText, ...props }) => (
  <MaterialButton {...props}>
    <ButtonContent>
      {isLoading && (
        <CircularProgress
          size={25}
          color='inherit'
          style={{ marginRight: 15 }}
        />
      )}
      {isLoading && isNotNilOrEmpty(loadingText) ? loadingText : children}
    </ButtonContent>
  </MaterialButton>
)

const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Button
