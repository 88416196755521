import React, { useMemo } from 'react'
import { ReactComponent as FavoritesIcon } from 'assets/images/favorites.svg'
import Tooltip from '@material-ui/core/Tooltip'
import styled, { css } from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getFavourites } from 'features/bpList/ducks/selectors'
import { addToFavouritesRoutine, removeFromFavouritesRoutine } from 'features/bpDetails/ducks/actions'

const ShortlistButton = () => {
  const { id } = useParams()
  const favourites = useSelector(getFavourites)
  const dispatch = useDispatch()

  const isShortlisted = useMemo(() => {
    return isNotNilOrEmpty(favourites.find(e => e.id === id))
  }, [id, favourites])

  const addToFavourites = () => {
    dispatch(addToFavouritesRoutine({ bpId: id }))
  }

  const removeFromFavourites = () => {
    dispatch(removeFromFavouritesRoutine({ bpId: id }))
  }

  return (
    <Tooltip title={isShortlisted ? 'Remove from favourites' : 'Add to favourites'}>
      <IconWrapper active={isShortlisted}>
        <FavoritesIcon
          onClick={isShortlisted ? removeFromFavourites : addToFavourites}
        />
      </IconWrapper>
    </Tooltip>
  )
}

export default ShortlistButton

const IconWrapper = styled.div`
  width: fit-content;
  ${({ active, theme }) => active && css`
    svg {
      g {
        fill: ${theme.colors.primary}
      }
    }
  `}
`
