import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { CircularProgress, Collapse } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUploadProcess, selectCurrentUploadProcessFetchDetails } from 'ducks/files/selectors'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CloseIcon from '@material-ui/icons/Close'

import {
  clearUploadProcessFetchDetailsRoutine,
  clearUploadProcessRoutine, getBpFilesRoutine,
  getUploadProcessRoutine
} from 'ducks/files/actions'
import { fetchUnitRoutine } from 'ducks/units/actions'

const FileUploadProcess = () => {
  const [open, setOpen] = useState(true)
  const currentFileUploadProcess = useSelector(selectCurrentUploadProcess)
  const hasActiveUploadProcess = isNotNilOrEmpty(currentFileUploadProcess)
  const files = propOr([], 'files', currentFileUploadProcess)
  const currentFileUploadProcessFetchDetails = useSelector(selectCurrentUploadProcessFetchDetails)
  const dispatch = useDispatch()
  const [intervalId, setIntervalId] = useState(null)
  const filesFromLocalStorage = JSON.parse(localStorage.getItem('uploadFiles') || '[]').map(f => f.trim())

  const allCompleted = useMemo(() => {
    if (isNotNilOrEmpty(files)) {
      return files.every(file => ['completed', 'failed'].includes(file.status)) && filesFromLocalStorage.length === files.length
    } else {
      return false
    }
  }, [filesFromLocalStorage, files])

  useEffect(() => {
    if (currentFileUploadProcessFetchDetails) {
      const bpId = currentFileUploadProcessFetchDetails?.bpId
      const unitId = currentFileUploadProcessFetchDetails?.unitId

      if (!hasActiveUploadProcess) {
        if (isNotNilOrEmpty(unitId) && isNotNilOrEmpty(bpId)) {
          dispatch(fetchUnitRoutine({ bpId, unitId }))
        } else if (isNotNilOrEmpty(bpId)) {
          dispatch(getBpFilesRoutine({ id: bpId }))
        }
      }
    }
  }, [currentFileUploadProcessFetchDetails, hasActiveUploadProcess])

  useEffect(() => {
    // has active process and all files are uploaded
    if (isNotNilOrEmpty(intervalId) && allCompleted) {
      const bpId = currentFileUploadProcessFetchDetails?.bpId
      const unitId = currentFileUploadProcessFetchDetails?.unitId
      const allFailed = isNotNilOrEmpty(currentFileUploadProcess) && files.every(file => file.status === 'failed')

      if (isNotNilOrEmpty(unitId) && isNotNilOrEmpty(bpId) && !allFailed) {
        dispatch(fetchUnitRoutine({ bpId, unitId }))
      } else if (isNotNilOrEmpty(bpId) && !allFailed) {
        dispatch(getBpFilesRoutine({ id: bpId }))
      }
      clearInterval(intervalId)
      setIntervalId(null)
    // doesn't have active process
    } else if (isNotNilOrEmpty(currentFileUploadProcessFetchDetails) && isNilOrEmpty(intervalId)) {
      dispatch(getUploadProcessRoutine(currentFileUploadProcessFetchDetails))
      setIntervalId(setInterval(() => {
        dispatch(getUploadProcessRoutine(currentFileUploadProcessFetchDetails))
      }, 2000))
      // has active process and modal was closed
    } else if (isNilOrEmpty(currentFileUploadProcessFetchDetails) && isNotNilOrEmpty(intervalId)) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [currentFileUploadProcessFetchDetails, allCompleted])

  const filesBoxes = useMemo(() => {
    const filesNames = files.map(file => file.data?.displayName?.trim())

    return filesFromLocalStorage.map(lsFileName => {
      if (filesNames.includes(lsFileName)) {
        const file = files.find(f => f.data?.displayName === lsFileName)

        return (
          <FileBox key={file.id}>
            <Left>
              <IconWrapper>
                <CloudIcon />
              </IconWrapper>
              <FileName>
                {file.data?.displayName}
              </FileName>
            </Left>
            {
              file.status === 'completed'
                ? <CheckIcon />
                : file.status === 'failed'
                  ? <FailIcon />
                  : <CircularProgress size={16} />
            }
          </FileBox>
        )
      } else {
        return (
          <FileBox key={lsFileName}>
            <Left>
              <IconWrapper>
                <CloudIcon />
              </IconWrapper>
              <FileName>
                {lsFileName}
              </FileName>
            </Left>
            <CircularProgress size={16} />
          </FileBox>
        )
      }
    })
  }, [files, filesFromLocalStorage])

  const toggleOpen = () => {
    setOpen(prev => !prev)
  }

  const handleClose = () => {
    dispatch(clearUploadProcessFetchDetailsRoutine())
    dispatch(clearUploadProcessRoutine())
  }

  return hasActiveUploadProcess ? (
    <Wrapper>
      <Header>
        <HeaderTitle>
          File upload {!allCompleted && <CircularProgress size={16} color='white'/>}
        </HeaderTitle>
        <Actions>
          <ChevronIcon open={open} onClick={toggleOpen} />
          <StyledCloseIcon onClick={handleClose} />
        </Actions>
      </Header>
      <Collapse in={open}>
        <Content>
          {filesBoxes}
        </Content>
      </Collapse>
    </Wrapper>
  ) : null
}

export default FileUploadProcess

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  border-radius: 6px 6px 0 0;
  width: 360px;
`

const Header = styled.div`
  border-radius: 6px 6px 0 0;
  color: #fff;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.haiti};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
`

const Content = styled.div`
  padding: 8px;
  background-color: #fff;
  overflow: hidden;
  max-height: 220px;
  border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const FileBox = styled.div`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
`

const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`

const IconWrapper = styled.div`
  background: radial-gradient(#fae4de 50%, #fcf4f2 30%);
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloudIcon = styled(CloudUploadOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px !important;
`

const CheckIcon = styled(CheckCircleOutlineIcon)`
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.success};
`

const FailIcon = styled(CloseIcon)`
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.error};
`

const ChevronIcon = styled(KeyboardArrowDownIcon)`
  color: #fff;
  cursor: pointer;
  ${({ open }) => !open && css`
    transform: rotate(-180deg);
  `}
`

const StyledCloseIcon = styled(CloseIcon)`
  color: #fff;
  cursor: pointer;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
