import { isNil } from 'ramda'
import moment from 'moment'

export const fileToUploadPath = (universalPath, addExtension) => {
  return universalPath
      .replace(/.*com\/(.*)\//, '')
      .replace(/%2F/gi, '/')
    + `${addExtension ? '.pdf' : ''}`
}

export const thumbnailToUploadPath = (universalPath, addExtension) => {
  return universalPath
      .replace(/.*com\/(.*)\//, '')
      .replace(/%2F/gi, '/')
    + `${addExtension ? '.png' : ''}`
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const convertBytes = (x) => {
  let l = 0, n = parseInt(x, 10) || 0

  while (n >= 1024 && ++l) {
    n = n/1024;
  }

  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const getFloorplans = (files, typeId) => {
  const filtered = files.filter((file) => {
    return file.fileTypeId === typeId
  })

  return filtered && filtered.map((floorplan, index) => ({ ...floorplan, index }))
}

export const sortFilesByExpirationStatus = files => {
  if (files) {
    const sorted = files.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
    const active = sorted
      .filter(file => isNil(file.expirationDate) || (file.expirationDate && moment(file.expirationDate).isAfter(moment())))
    const expired = sorted
      .filter(file => file.expirationDate && moment(file.expirationDate).isSameOrBefore(moment()))

    return [
      ...active,
      ...expired
    ]
  }
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
})
