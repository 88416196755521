import { emptyDictionariesState } from 'ducks/dictionaries/state'
import { getFileCategoriesRoutine } from 'ducks/dictionaries/actions'

export default (state = emptyDictionariesState, action) => {
  switch (action.type) {
    case getFileCategoriesRoutine.SUCCESS:
      return {
        ...state,
        fileCategories: action.payload
      }
    default:
      return state
  }
}
