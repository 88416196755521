import { createSelector } from 'reselect'
import { pathOr, propOr } from 'ramda'

const createdBp = state => state.bpCreation

export const getCreatedBpId = createSelector(
  createdBp,
  pathOr(null, ['bp', 'id'])
)

export const selectCreatedBp = createSelector(
  createdBp,
  propOr({}, 'bp')
)
