import React from 'react'
import { Dialog as MaterialModal } from '@material-ui/core'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import PrimaryButton from 'components/atoms/PrimaryButton'
import Button from 'components/atoms/Button'

const Modal = props => {
  const {
    title,
    children,
    withSubmit,
    withCancel,
    submitButtonText,
    cancelButtonText,
    onCancel,
    onSubmit,
    disabled,
    onClose
  } = props

  return (
    <MaterialModal {...props}>
      <StyledModal>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <StyledCloseIcon onClick={onClose} />
        </ModalHeader>
        {children}
        {
          (withSubmit || withCancel) && (
            <ButtonsWrapper>
              {
                withCancel && (
                  <Button variant='outlined' onClick={onCancel}>
                    {cancelButtonText}
                  </Button>
                )
              }
              {
                withSubmit && (
                  <SubmitButton onClick={onSubmit} disabled={disabled}>
                    {submitButtonText}
                  </SubmitButton>
                )
              }
            </ButtonsWrapper>
          )
        }
      </StyledModal>
    </MaterialModal>
  )
}

Modal.defaultProps = {
  submitButtonText: 'Submit',
  cancelButtonText: 'Cancel',
  onCancel: () => {},
  onSubmit: () => {}
}

export default Modal

const StyledModal = styled.div`
  box-sizing: border-box;
  padding: 20px;
  min-width: 435px;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.white};
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
`

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  ${({ theme }) => theme.colors.haiti};
  opacity: .5;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.haiti};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[600]};
  display: flex;
  justify-content: flex-end;
`

const SubmitButton = styled(PrimaryButton)`
  margin-left: 15px !important;
`
