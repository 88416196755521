import { createRoutine } from 'redux-saga-routines'
import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import * as bpService from 'services/BpService'

export const fetchDashboardStatisticsRoutine = createRoutine('FETCH_DASHBOARD_STATISTICS')
export const fetchLastViewedBpsRoutine = createRoutine('FETCH_LAST_VIEWED')
export const fetchLastSearchedBpsRoutine = createRoutine('FETCH_LAST_SEARCHED')

function * fetchDashboardStatistics ({ payload }) {
  yield put(fetchDashboardStatisticsRoutine.request())
  try {
    const result = yield call(bpService.fetchDashboardStats, payload)
    yield put(fetchDashboardStatisticsRoutine.success(result.data.data))
  } catch (error) {
    yield put(fetchDashboardStatisticsRoutine.failure(error))
  }
}

function * fetchLastSearchedBps () {
  yield put(fetchLastSearchedBpsRoutine.request())
  try {
    const { data } = yield call(bpService.fetchLastSearchedBps)
    yield put(fetchLastSearchedBpsRoutine.success(data.data))
  } catch (error) {
    yield put(fetchLastSearchedBpsRoutine.failure(error))
  }
}

function * fetchLastViewedBps () {
  yield put(fetchLastViewedBpsRoutine.request())
  try {
    const result = yield call(bpService.fetchLastViewedBps)
    yield put(fetchLastViewedBpsRoutine.success(result.data.data))
  } catch (error) {
    yield put(fetchLastViewedBpsRoutine.failure(error))
  }
}

export function * fetchLastSearchedBpsWatcher () {
  yield takeLatest(fetchLastSearchedBpsRoutine.TRIGGER, fetchLastSearchedBps)
}

export function * fetchLastViewedBpsWatcher () {
  yield takeLatest(fetchLastViewedBpsRoutine.TRIGGER, fetchLastViewedBps)
}

export function * fetchDashboardStatisticsWatcher () {
  yield takeLatest(fetchDashboardStatisticsRoutine.TRIGGER, fetchDashboardStatistics)
}

export const dashboardSagas = [
  fork(fetchLastSearchedBpsWatcher),
  fork(fetchLastViewedBpsWatcher),
  fork(fetchDashboardStatisticsWatcher),
  fork(fetchDashboardStatisticsWatcher)
]
