import React from 'react'
import { useDrag } from 'react-dnd'
import styled from 'styled-components'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { isNil } from 'ramda'

const TemplateDataItem = ({ item, type, key }) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type,
      item,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }), [])

  return (
    <TemplateDataItemWrapper
      onClick={e => e.stopPropagation()}
      key={key}
      ref={dragRef}
      style={{ opacity }}
    >
      <div>
        <TemplateName>{!isNil(item.label) ? item.label : item.name}</TemplateName>
      </div>
      <DragIconWrapper>
        <DragIndicatorIcon />
      </DragIconWrapper>
    </TemplateDataItemWrapper>
  )
}

export default TemplateDataItem

const TemplateDataItemWrapper = styled.div`
  padding: 7px 50px 7px 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  cursor: grab;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
`

const TemplateName = styled.div`
  font-size: 14px;
`

const DragIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey[400]};
  color: ${({ theme }) => theme.colors.grey[600]};
  border-left: 1px solid ${({ theme }) => theme.colors.grey[500]};
`
