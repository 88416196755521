import React, { useState } from 'react'
import { isEmpty, propOr } from 'ramda'
import TemplateDataItem from 'features/analytics/compontents/TemplateDataItem'
import styled from 'styled-components'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const CategoryPanel = ({ category }) => {
  const [open, setOpen] = useState(false)
  const types = propOr([], 'types', category)

  return (
    <CategoryPanelWrapper
      key={category.id}
      onClick={e => {
        e.stopPropagation()
        setOpen(() => !open)
      }}
    >
      <CategoryPanelTitle>
        {category.label}
        <ArrowDown open={open} />
      </CategoryPanelTitle>
      <CategoryPanelContent open={open}>
        <Collapse in={open} key={`collapse-${category.id}`}>
          {
            !isEmpty(types)
              ? types.map(type => (
                <TemplateDataItem key={type.name} item={type} type='template-file' />
              ))
              : <EmptyCategory>All types are selected</EmptyCategory>
          }
        </Collapse>
      </CategoryPanelContent>
    </CategoryPanelWrapper>
  )
}

export default CategoryPanel

const CategoryPanelWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`

const CategoryPanelTitle = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CategoryPanelContent = styled.div`
  transition: all .3s;
  margin-top: ${({ open }) => open ? '20px' : 0};
`

const ArrowDown = styled(KeyboardArrowDownIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  transform: ${({ open }) => `rotate(${open ? '-180deg' : '0'})`};
`

const EmptyCategory = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[700]};
`
