import React, { useState } from 'react'
import styled from 'styled-components'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ExpandableAccreditations = ({ title, open = false, children, optional }) => {
  const [isOpen, setOpen] = useState(open)

  const toggleOpen = () => setOpen(prev => !prev)

  return (
    <PanelWrapper>
      <PanelTitle onClick={toggleOpen}>
        <Title>
          {title} {optional && <span>(optional)</span>}
        </Title>
        <StyledExpandIcon open={isOpen} />
      </PanelTitle>
      <Collapse in={isOpen}>
        {children}
      </Collapse>
    </PanelWrapper>
  )
}

export default ExpandableAccreditations

const PanelWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;
`

const PanelTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.colors.haiti};
  font-size: 18px;
  letter-spacing: .15px;
  cursor: pointer;
`

const Title = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: .1px;
  margin: 15px 0;

  span {
    color: ${({ theme }) => theme.colors.grey[700]};
    text-transform: lowercase;
  }
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grey[700]};
  transition: all .3s;
  transform: rotate(${({ open }) => open ? '180deg' : '0'});
`
