import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import PageHeader from 'components/atoms/PageHeader'
import BackButton from 'components/atoms/BackButton'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import TemplateDataItem from 'features/analytics/compontents/TemplateDataItem'
import { TEMPLATE_ITEMS } from 'utils/constants'
import { getFileTypes } from 'ducks/files/selectors'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import CategoryPanel from 'features/analytics/compontents/CategoryPanel'
import Input from 'components/atoms/Input'
import { isEmpty, pathOr } from 'ramda'
import SearchIcon from '@material-ui/icons/Search'
import {
  attachAnalyticsTemplateToAllBpsRoutine,
  getAnalyticsTemplatesRoutine,
  removeAnalyticsTemplateRoutine
} from 'features/analytics/ducks/actions'
import { selectAnalyticsTemplates } from 'features/analytics/ducks/selectors'
import EditTemplateForm from 'features/analytics/compontents/EditTemplateForm'
import Modal from 'components/atoms/Modal'
import { isNotNilOrEmpty } from 'utils/ramda'
import ShareTemplateModal from 'features/analytics/compontents/ShareTemplateModal'

const EditTemplates = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [attachToAllModalOpen, setAttachToAllModalOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)
  const templates = useSelector(selectAnalyticsTemplates)
  const hash = pathOr('', ['location', 'hash'], window).replace('#', '')

  const handleRemoveModalOpen = template => {
    setRemoveModalOpen(true)
    setSelectedTemplate(template)
  }

  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false)
    setSelectedTemplate(null)
  }

  const handleShareModalOpen = template => () => {
    setShareModalOpen(true)
    setSelectedTemplate(template)
  }

  const handleShareModalClose = () => {
    setShareModalOpen(false)
    setSelectedTemplate(null)
  }

  const handleAttachToAllModalOpen = template => {
    setAttachToAllModalOpen(true)
    setSelectedTemplate(template)
  }

  const handleAttachToAllModalClose = () => {
    setAttachToAllModalOpen(false)
    setSelectedTemplate(null)
  }

  useEffect(() => {
    dispatch(getAnalyticsTemplatesRoutine())
  }, [])

  const filePanels = useMemo(() => {
    if (fileCategories && fileTypes) {
      const isSearching = !isEmpty(searchQuery)
      const types = isSearching
        ? fileTypes.filter(type => type.name.toLowerCase().includes(searchQuery.toLowerCase()))
        : fileTypes

      const categories = fileCategories.map(category => {
        return {
          id: category.id,
          label: category.name,
          types: types.filter(type => type.fileCategoryId === category.id) || []
        }
      })

      return isSearching
        ? types.map(type => {
          return <TemplateDataItem key={type.name} item={type} type='template-file' />
        })
        : categories.map(category => {
          return <CategoryPanel key={category.id} category={category} />
        })
    }
  }, [fileTypes, fileCategories, searchQuery])

  const sortedTemplates = useMemo(() => {
    if (templates) {
      return [
        ...templates.filter(v => v.type === 'default'),
        ...templates.filter(v => v.type === 'custom').sort((a, b) => a.name.localeCompare(b.name))
      ]
    } else {
      return []
    }
  }, [templates])

  const handleRemoveTemple = () => {
    setIsLoading(true)
    dispatch(removeAnalyticsTemplateRoutine({
      id: selectedTemplate.id,
      callback: () => setIsLoading(false)
    }))
    handleRemoveModalClose()
  }

  const handleAttachToAllBps = () => {
    setIsLoading(true)
    dispatch(attachAnalyticsTemplateToAllBpsRoutine({
      templateId: selectedTemplate.id,
        callback: () => setIsLoading(false)
    }))
    handleAttachToAllModalClose()
  }

  useEffect(() => {
    if (isNotNilOrEmpty(hash)) {
      const el = document.getElementById(hash)
      el && el.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [hash])

  return (
    <PageWrapper>
      <BackButton to={PRIVATE_PATHS.analytics}>Back to Analytics</BackButton>
      <PageHeader>
        Edit templates
      </PageHeader>
      {
        sortedTemplates.map(template => {
          return (
            <EditTemplateForm
              isLoading={isLoading}
              id={template.id}
              initialOpen={hash === template.id}
              openRemoveModal={handleRemoveModalOpen}
              openShareModal={handleShareModalOpen}
              openAttachToAllModal={handleAttachToAllModalOpen}
              key={template.id}
              template={template}
            />
          )
        })
      }
      <SidePanel>
        <SidePanelTitle>
          templates
        </SidePanelTitle>
        <SidePanelDescription>
          Drag and drop to add an item to a custom template
        </SidePanelDescription>
        <AddTemplateButton onClick={() => redirect(PRIVATE_PATHS.addAnalytics)}>
          + add template
        </AddTemplateButton>
        <SidePanelSectionTitle>
          <span>Data</span>
        </SidePanelSectionTitle>
        <SidePanelTemplatesList>
          {
            TEMPLATE_ITEMS.map(item => {
              return <TemplateDataItem key={item.label} item={item} type='template-data' />
            })
          }
        </SidePanelTemplatesList>
        <SidePanelSectionTitle>
          <span>Files</span>
        </SidePanelSectionTitle>
        <Input
          startAdornment={<SearchIcon />}
          name='searchQuery'
          value={searchQuery}
          onChange={(_, value) => setSearchQuery(value)}
          noMarginBottom
        />
        <SidePanelTemplatesList>
          {filePanels}
        </SidePanelTemplatesList>
      </SidePanel>
      <Modal
        onClose={handleRemoveModalClose}
        open={removeModalOpen}
        title='Remove template'
        withCancel
        withSubmit
        onSubmit={handleRemoveTemple}
        onCancel={handleRemoveModalClose}
        submitButtonText='Remove'
      >
        Do you really want to remove this template?
      </Modal>
      <Modal
        onClose={handleAttachToAllModalClose}
        open={attachToAllModalOpen}
        title='Attach to all Building Passports'
        withCancel
        withSubmit
        onSubmit={handleAttachToAllBps}
        onCancel={handleAttachToAllModalClose}
      >
        Do you really want to attach this template to all Building Passports?
      </Modal>
      <Modal
        onClose={handleShareModalClose}
        open={shareModalOpen}
        title='Share template'
      >
        <ShareTemplateModal closeModal={handleShareModalClose} template={selectedTemplate} />
      </Modal>
    </PageWrapper>
  )
}

export default EditTemplates

const PageWrapper = styled.div`
  padding-bottom: 50px;
  padding-right: 290px;
  width: 100%;
  box-sizing: border-box;
`

const SidePanel = styled.div`
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`

const SidePanelTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 5px;
`

const SidePanelDescription = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const SidePanelSectionTitle = styled.div`
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};

  span {
    opacity: .6;
  }
`

const SidePanelTemplatesList = styled.div`
  padding: 10px 0;
`

const AddTemplateButton = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    opacity: .8;
  }
`
