import React, { useEffect, useMemo, useState } from 'react'
import Panel from 'features/createBp/components/atoms/Panel'
import { useTranslation } from 'react-i18next'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { useDispatch, useSelector } from 'react-redux'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { dissoc, propOr, values } from 'ramda'
import BuildingTypeSelect from 'features/createBp/components/atoms/BuildingTypeSelect'
import { iterable } from 'utils/array'
import styled, { css } from 'styled-components'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { getCreatedBpId } from 'features/createBp/ducks/selectors'
import { createBpRoutine, updateBpRoutine } from 'features/createBp/ducks/actions'

const BuildingInformation = ({
  completed,
  open,
  setActiveStep,
  setBp,
  setCompleted,
  bp
}) => {
  const { t } = useTranslation()
  const [sectionValues, setSectionValues] = useState(
    isNotNilOrEmpty(bp.buildingTypes)
      ? { ...bp.buildingTypes  }
      : { 0: { type: '', value: '' } }
  )
  const [valid, setIsValid] = useState(false)
  const buildingTypes = useSelector(getBuildingTypes)
  const dispatch = useDispatch()
  const bpId = useSelector(getCreatedBpId)

  const handleOnChange = selectedType => {
    if (isNotNil(selectedType.index)) {
      setSectionValues(prev => ({
        ...prev,
        [selectedType.index]: {
          type: selectedType.type,
          value: selectedType.value,
          description: selectedType.description,
          initialUnitsNumber: selectedType.initialUnitsNumber,
          unit: selectedType.unit
        }
      }))
    }
  }

  const handleDelete = index => () => {
    setSectionValues(prev => ({ ...values(dissoc(index, prev)) }))
  }

  const addAnotherInput = () => setSectionValues(prev => ({
    ...prev,
    [values(prev).length]: {
      type: '',
      value: ''
    }
  }))

  const setNextSection = () => {
    setActiveStep('relationship')
    setCompleted('buildingDetails')
  }

  const handleSectionSubmit = () => {
    if (completed) {
      setNextSection()
    } else {
      setBp(prev => {
        if (isNotNilOrEmpty(bpId)) {
          dispatch(updateBpRoutine({
            buildingTypes: values(sectionValues),
            id: bpId,
            isWizard: true
          }))
          setNextSection()
        } else {
          const typeValues = values(sectionValues).map(type => ({
            ...type,
            units: type.unit === 'sqm' ? Number(propOr(1, 'initialUnitsNumber', type)) : Number(type.value),
            area: type.unit === 'sqm' ? Number(type.value) : null
          }))
          dispatch(createBpRoutine({
            ...bp,
            buildingTypes: typeValues,
            groundFloor: true,
            setNextSection
          }))
        }
        return {
          ...prev,
          buildingTypes: values(sectionValues)
        }
      })
    }

  }

  useEffect(() => {
    const isValid = sectionValues && values(sectionValues).every(type => {
      return isNotNilOrEmpty(type.type) && isNotNilOrEmpty(type.value) && Number(type.value) > 0
    })
    setIsValid(isValid)
  }, [sectionValues])

  useEffect(() => {
    if (isNotNilOrEmpty(bp.buildingTypes)) {
      const parsedTypes = bp.buildingTypes.map(type => {
        const foundType = values(buildingTypes).find(bt => bt.key === type.type)
        const typeUnit = propOr('', 'unit', foundType)
        return {
          ...type,
          value: typeUnit === 'sqm' ? type.area : type.units,
          initialUnitsNumber: typeUnit === 'sqm' ? type.units : 1
        }
      })
      setSectionValues({ ...parsedTypes  })
    } else {
      setSectionValues({ 0: { type: '', value: '' } })
    }

  }, [bp])

  const shouldBlockDwellingSole = useMemo(() => {
    const dwellingSoleUnit = values(sectionValues).filter(type => type.type === 'residential.dwelling.sole_unit')
    return isNotNilOrEmpty(dwellingSoleUnit)
  }, [sectionValues])

  return (
    <Panel
      open={open}
      title='Building details'
      completed={completed}
      step='building-details'
      setActiveStep={setActiveStep}
      key={`panel-bp-details-${values(sectionValues).length}`}
    >
      {
        iterable(values(sectionValues).length).map((input, index) => {
          return <BuildingTypeSelect
            value={propOr(null, index, sectionValues)}
            index={index}
            onChange={handleOnChange}
            key={`building-type-input-${index + 1}`}
            id={`building-type-input-${index + 1}`}
            withDelete={values(sectionValues).length > 1}
            onDelete={handleDelete(index)}
            blockDwelling={values(sectionValues).length > 1}
            disabled={completed}
          />
        })
      }
      <AddButton
        disabled={shouldBlockDwellingSole || completed}
        onClick={shouldBlockDwellingSole || completed
          ? () => {}
          : addAnotherInput}
      >
        + add another type
      </AddButton>
      <NextButton
        onClick={handleSectionSubmit}
        disabled={!valid}
      >
        {completed ? 'Save' : t('createBp.labels.next')}
      </NextButton>
    </Panel>
  )
}

export default BuildingInformation

const AddButton = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
  margin: 10px 0 20px auto;

  ${({ disabled, theme }) => disabled && css`
    color: ${theme.colors.grey[600]};
    cursor: default;
  `}
`
