import React, { useEffect, useMemo, useState } from 'react'
import { dissoc, propOr, values } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import styled from 'styled-components'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { iterable } from 'utils/array'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import BuildingTypeSelect from 'features/createBp/components/atoms/BuildingTypeSelect'
import { AddButton } from 'features/bpDetails/BpDetails'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const EditBuildingTypes = ({ closeModal, isAnalyticsView, currentPage }) => {
  const bp = useSelector(getSelectedBp)
  const dispatch = useDispatch()
  const [valid, setValid] = useState(false)
  const [formValues, setFormValues] = useState(
    isNotNilOrEmpty(bp.buildingTypes)
      ? { ...bp.buildingTypes  }
      : { 0: { type: '', value: '' } }
  )

  const handleOnChange = selectedType => {
    if (isNotNil(selectedType.index)) {
      setFormValues(prev => ({
        ...prev,
        [selectedType.index]: {
          type: selectedType.type,
          value: selectedType.value,
          description: selectedType.description
        }
      }))
    }
  }

  const handleDelete = index => () => {
    setFormValues(prev => ({ ...values(dissoc(index, prev)) }))
  }

  const addAnotherInput = () => setFormValues(prev => ({
    ...prev,
    [values(prev).length]: {
      type: '',
      value: ''
    }
  }))

  useEffect(() => {
    const isValid = formValues && values(formValues).every(type => {
      return isNotNilOrEmpty(type.type) && isNotNilOrEmpty(type.value)
    })
    setValid(isValid)
  }, [formValues])

  useEffect(() => {
    setFormValues(
      isNotNilOrEmpty(bp.buildingTypes)
        ? { ...bp.buildingTypes  }
        : { 0: { type: '', value: '' } }
    )
  }, [bp])

  const shouldBlockDwellingSole = useMemo(() => {
    const dwellingSoleUnit = values(formValues).filter(type => type.type === 'residential.dwelling.sole_unit')
    return isNotNilOrEmpty(dwellingSoleUnit)
  }, [formValues])

  const handleUpdate = () => {
    dispatch(updateBpRoutine({ ...formValues,
      buildingTypes: values(formValues),
      id: bp.id,
      isEdit: true,
      isAnalyticsView: isAnalyticsView,
      currentPage: currentPage
    }))
    closeModal()
  }

  useEffect(() => {
  }, [formValues])

  return (
    <FormWrapper>
      {
        iterable(values(formValues).length).map((input, index) => {
          return <BuildingTypeSelect
            value={propOr(null, index, formValues)}
            index={index}
            onChange={handleOnChange}
            key={`building-type-input-${index + 1}`}
            id={`building-type-input-${index + 1}`}
            withDelete={values(formValues).length > 1}
            onDelete={handleDelete(index)}
            blockDwelling={values(formValues).length > 1}
          />
        })
      }
      <AddButtonWrapper>
        <AddButton
          disabled={shouldBlockDwellingSole}
          onClick={shouldBlockDwellingSole
            ? () => {}
            : addAnotherInput}
        >
          + add another type
        </AddButton>
      </AddButtonWrapper>
      <NextButton
        onClick={handleUpdate}
        disabled={!valid}
      >
        Save changes
      </NextButton>
    </FormWrapper>
  )
}

export default EditBuildingTypes

const FormWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`

const AddButtonWrapper = styled.div`
  margin: 10px 0 20px;
  display: flex;
  justify-content: flex-end;
`
