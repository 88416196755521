import React, { useMemo, useState } from 'react'
import { MainContentPanel } from 'features/bpDetails/BpDetails'
import styled, { css } from 'styled-components'
import { getAssignedUsers } from 'utils/bpData'
import AddAssignmentModal from 'features/accessControl/components/AddAssignmentModal'
import InvitationsList from 'features/accessControl/components/InvitationsList'
import RequestsList from 'features/accessControl/components/RequestsList'
import RevokedList from 'features/accessControl/components/RevokedList'
import AssignedUserBox from 'features/accessControl/components/AssignedUserBox'
import { isEmpty } from 'ramda'
import Modal from 'components/atoms/Modal'
import { canEditBp } from 'utils/user'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const AccessControl = () => {
  const bp = useSelector(getSelectedBp)
  const [activeTab, setTab] = useState('requests')
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [userToEdit, setUserToEdit] = useState({})
  const user = useSelector(getCurrentUser)
  const isDraft = bp.status === 'draft'

  const assignedUsers = useMemo(() => {
    return getAssignedUsers(bp)
  }, [bp])

  const canEdit = canEditBp(user, bp)

  const tabContent = useMemo(() => {
    switch (activeTab) {
      case 'requests':
        return <RequestsList />
      case 'invitations':
        return <InvitationsList />
      case 'revoked':
      default:
        return <RevokedList />
    }
  }, [activeTab])

  const handleModalOpen = user => () => {
    user ? setUserToEdit(user) : setUserToEdit({})
    setAddModalOpen(true)
  }

  const isPpOnList = assignedUsers.some(user => user.relationType === 'prospectivePurchaser')

  return (
    <>
      <MainContentPanel>
        {
          isDraft
            ? <EmptyState>Access Control Panel is available when BP is active</EmptyState>
            : (
              <>
                {
                  canEdit && (
                    <>
                      <TabsWrapper>
                        <Tab
                          onClick={() => setTab('requests')}
                          isActive={activeTab === 'requests'}
                        >
                          Access Requests
                        </Tab>
                        <Tab
                          onClick={() => setTab('revoked')}
                          isActive={activeTab === 'revoked'}
                        >
                          Access Revoked
                        </Tab>
                        <Tab
                          onClick={() => setTab('invitations')}
                          isActive={activeTab === 'invitations'}
                        >
                          Invitations
                        </Tab>
                      </TabsWrapper>
                      <ContentWrapper>
                        {tabContent}
                      </ContentWrapper>
                    </>
                  )
                }
                <UsersWithAccessWrapper>
                  <HeaderWrapper>
                    <SectionHeader>
                      Users with access
                    </SectionHeader>
                    {
                      canEdit && (
                        <AddUserButton
                          onClick={handleModalOpen(null)}
                        >
                          + assign another user
                        </AddUserButton>
                      )
                    }
                  </HeaderWrapper>
                  <UsersTable>
                    <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Expires</th>
                    {isPpOnList && <th>Visits</th>}
                    <th />
                    </thead>
                    <tbody>
                    {
                      !isEmpty(assignedUsers)
                        ? assignedUsers.map((user, index) => (
                          <AssignedUserBox
                            key={`assignedUser-${user.id}-${index}`}
                            user={user}
                            index={index}
                            openEditModal={handleModalOpen}
                            isPpOnList={isPpOnList}
                          />
                        ))
                        : <p>Assigned users list is empty</p>
                    }
                    </tbody>
                  </UsersTable>
                </UsersWithAccessWrapper>
              </>
            )
        }
      </MainContentPanel>
      <Modal
        open={addModalOpen}
        title='Assign user'
        onClose={() => setAddModalOpen(false)}
      >
        <AddAssignmentModal
          userToEdit={userToEdit}
          bp={bp}
          closeModal={() => setAddModalOpen(false)}
        />
      </Modal>
    </>
  )
}

export default AccessControl

const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;

  & > div {
    flex: 1;
  }
`

const Tab = styled.div`
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-color: ${({ theme, isActive }) =>
  isActive ? theme.colors.secondary : theme.colors.lightGrey};
  padding: 5px 0;
  text-align: center;
  cursor: pointer;
`

const ContentWrapper = styled.div`
  padding: 10px 0;
`

const UsersWithAccessWrapper = styled.div`
  margin-top: 20px;
`

const SectionHeader = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const AddUserButton = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;

  ${({ disabled, theme }) => disabled && css`
    color: ${theme.colors.grey[600]};
    cursor: default;
  `}
`

const UsersTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};

  thead {
    background-color: ${({ theme }) => theme.colors.backgroundColor};

    th {
      padding: 8px 10px;
      text-align: left;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  tbody {
    tr {
      background-color: ${({ theme }) => theme.colors.backgroundColor};

      td {
        padding: 8px 10px;

        &:first-of-type {
          font-weight: bold;
        }
      }
    }
  }
`

const EmptyState = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`
