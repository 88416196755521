import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getFileTagsForBpRoutine } from 'ducks/files/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectFileTagsForBp } from 'ducks/files/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import AddNewFileTag from 'features/bpDetails/Components/files/AddNewFileTag'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useLocation } from 'react-router'
import GlobalTagItem from 'features/bpDetails/Components/files/GlobalTagItem'

const FileTagsPanel = ({ onTagClick, onSortingChange }) => {
  const [selectedTags, setSelectedTags] = useState([])
  const [searchType, setSearchType] = useState('any')
  const bp = useSelector(getSelectedBp)
  const tags = useSelector(selectFileTagsForBp)
  const dispatch = useDispatch()
  const { state } = useLocation()

  useEffect(() => {
    const hasSelectedTag = isNotNilOrEmpty(state?.selectedTag)

    if (hasSelectedTag) {
      setSelectedTags([state.selectedTag])
    }
  }, [state])

  useEffect(() => {
    typeof onSortingChange === 'function' && onSortingChange(selectedTags, searchType)
  }, [selectedTags, searchType])

  useEffect(() => {
    bp.id && dispatch(getFileTagsForBpRoutine({ bpId: bp.id }))
  }, [bp])

  const handleTagClick = id => () => {
    if (typeof onTagClick === 'function') {
      onTagClick(id)
    } else {
      setSelectedTags(prev => {
        if (prev.includes(id)) {
          return prev.filter(el => el !== id)
        } else {
          return [...prev, id]
        }
      })
    }
  }

  const handleSearchTypeChange = type => () => {
    setSearchType(type)
  }

  const handleDeselectTag = tagId => {
    setSelectedTags(prev => prev.filter(el => el !== tagId))
  }

  return (
    <Wrapper>
      <Header>
        <div>
          File tags
        </div>
        <AddNewFileTag />
      </Header>
      <TagsList>
        {
          isNotNilOrEmpty(tags)
            ? tags.map(tag => {
              return (
                <GlobalTagItem
                  key={tag.id}
                  tag={tag}
                  selectedTags={selectedTags}
                  handleTagClick={handleTagClick}
                  handleDeselectTag={handleDeselectTag}
                />
              )
            })
            : <div>No tags were added</div>
        }
      </TagsList>
      <SearchTypeToggle>
        Search files with
        <SearchTypeButton active={searchType === 'all'} onClick={handleSearchTypeChange('all')}>all</SearchTypeButton>
        <SearchTypeButton active={searchType === 'any'} onClick={handleSearchTypeChange('any')}>one of</SearchTypeButton>
        the selected tags
      </SearchTypeToggle>
    </Wrapper>
  )
}

export default FileTagsPanel

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

const TagsList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 10px 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SearchTypeToggle = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
`

const SearchTypeButton = styled.div`
  width: fit-content;
  padding: 3px 7px;
  background: ${({ active, theme }) => active ? theme.colors.primary : theme.colors.backgroundColor};
  color: ${({ active, theme }) => active ? '#fff' : theme.colors.darkgrey};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 4px;
`
