import React, { useMemo } from 'react'
import styled from 'styled-components'
import { formatDateWithTime } from 'utils/date'
import { isEmpty, isNil, pathOr, propOr } from 'ramda'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import LogoShort from 'assets/images/bp-logo-orange.png'
import { isNotNilOrEmpty } from 'utils/ramda'
import { PRIVATE_PATHS } from 'utils/paths'

const NotificationItem = ({ notification, handleClick }) => {
  const { readAt, createdAt, id, data, actor } = notification
  const bp = propOr({}, 'buildingPassport', data)
  const avatarUrl = pathOr('', ['avatar'], actor)

  const isUnread = isNil(readAt)

  const bodyContent = useMemo(() => {
    switch (true) {
      case data.code.includes('note'):
        return (
          <NoteContentWrapper>
            {`"${propOr('', 'body', data)}"`}
          </NoteContentWrapper>
        )
      case data.code.includes('files'):
        return (
          <FileContentWrapper>
            <FileIcon />
            <p>
              {
                !isEmpty(pathOr('', ['body', 0, 'displayName'], data))
                  ? pathOr('', ['body', 0, 'displayName'], data)
                  : pathOr('', ['body', 'displayName'], data)
              }
            </p>
          </FileContentWrapper>
        )
      default:
        return null
    }
  }, [data])

  const redirectPath = data.code === 'invitation.registered'
    ? `/bp-list/${bp.id}/accessControl/${data.inviteeId}`
    : data.code.includes('invitation')
      ? PRIVATE_PATHS.analytics
      : null

  return (
    <ItemWrapper
      onClick={
        handleClick(id, bp.id, redirectPath)
      }
    >
      {isUnread && <UnreadMark />}
      <ContentWrapper>
        <AvatarWrapper>
          <img src={isNotNilOrEmpty(avatarUrl) ? avatarUrl : LogoShort} alt='user-avatar' />
        </AvatarWrapper>
        <DetailsWrapper>
          <NotificationItemTitle>
            {propOr('', 'subject', data)}
          </NotificationItemTitle>
          {
            bodyContent && (
              <NotificationBody>
                {bodyContent}
              </NotificationBody>
            )
          }
          {
            !isEmpty(bp) && (
              <BpDetails>
                <BpName>
                  {propOr('', 'name', bp)},&nbsp;
                </BpName>
                <BpAddress>
                  {propOr('', 'postalCode', bp)},&nbsp;
                  {propOr('', 'city', bp)},&nbsp;
                  {propOr('', 'street', bp)}
                </BpAddress>
              </BpDetails>
            )
          }
          <CreationDate>
            {formatDateWithTime(createdAt)}
          </CreationDate>
        </DetailsWrapper>
      </ContentWrapper>

    </ItemWrapper>
  )
}

export default NotificationItem

const ItemWrapper = styled.div`
  box-sizing: border-box;
  padding: 15px 0;
  position: relative;
  max-width: 100%;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  }
`

const DetailsWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
`

const NotificationItemTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-right: 15px;
  box-sizing: border-box;
  width: 100%;
  //text-overflow: ellipsis;
  //overflow: hidden;
  //white-space: nowrap;

`

const CreationDate = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .4;
`

const UnreadMark = styled.div`
  width: 8px;
  height: 8px;
  right: 0;
  top: 20px;
  box-shadow: 0 2px 10px 0 rgba(209, 73, 37, 0.37);
  background-color: ${({ theme }) => theme.colors.error};
  border-radius: 50px;
  position: absolute;
`

const BpDetails = styled.div`
  box-sizing: border-box;
  width: 97%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const BpName = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.haiti};
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const BpAddress = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: 65%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const NotificationBody = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0;
`

const FileContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  p {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    min-width: 19px;
    min-height: 17px;
    margin-right: 10px;
  }
`

const NoteContentWrapper = styled.div`
  opacity: 0.87;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.haiti};
`

const ContentWrapper = styled.div`
  display: flex;
`

const AvatarWrapper = styled.div`
  margin-right: 15px;

  img {
    min-height: 32px;
    min-width: 32px;
    max-height: 32px;
    max-width: 32px;
    border-radius: 50%;
  }
`
