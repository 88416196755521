// you can provide condition like "hasUprn" or file type id
import { isNotNilOrEmpty } from 'utils/ramda'
import { isNil } from 'ramda'

export const checkAnalyticsCondition = (condition, bp, files = []) => {
  switch (condition) {
    case 'hasUprn':
      return isNotNilOrEmpty(bp.uprn)
    case 'hasAddress':
      return isNotNilOrEmpty(bp.street) && isNotNilOrEmpty(bp.city) && isNotNilOrEmpty(bp.postalCode)
    case 'hasOwner':
      return isNotNilOrEmpty(bp.owners)
    case 'hasAgent':
      return isNotNilOrEmpty(bp.agents)
    case 'hasBuildingTypes':
      return isNotNilOrEmpty(bp.buildingTypes)
    default:
      return isNotNilOrEmpty(
        files
          .filter(file => isNil(file.deletedAt))
          .find(file => file.fileTypeId === condition)
      )
  }
}
