import {
  getFileTypesRoutine,
  uploadFileRoutine,
  removeFileRoutine,
  addFileVersionRoutine,
  getUploadProcessRoutine,
  saveUploadProcessFetchDetailsRoutine,
  clearUploadProcessFetchDetailsRoutine,
  clearUploadProcessRoutine,
  getBpFilesRoutine,
  getFileTagsForBpRoutine,
  createFileTagRoutine,
  attachFileTagToFileRoutine,
  detachFileTagFromFileRoutine,
  syncFileTagsInFileRoutine,
  syncFileTagsInFileVersionRoutine,
  clearBpFilesRoutine
} from 'ducks/files/actions'
import { pathOr } from 'ramda'
import emptyFilesState from 'ducks/files/state'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'

export default (state = emptyFilesState, action) => {
  switch (action.type) {
    case uploadFileRoutine.REQUEST:
    case addFileVersionRoutine.REQUEST:
      return {
        ...state,
        isUploading: true
      }
    case uploadFileRoutine.FAILURE:
    case addFileVersionRoutine.FAILURE:
      return {
        ...state,
        isUploading: false
      }
    case addFileVersionRoutine.SUCCESS:
      return {
        ...state,
        isUploading: false
      }
    case uploadFileRoutine.SUCCESS:
      if (action.payload === 'unit') {
        return {
          ...state,
          isUploading: false
        }
      } else {
        return {
          ...state,
          currentBpFiles: pathOr([], ['payload', 'files'], action),
          isUploading: false
        }
      }
    case removeFileRoutine.SUCCESS:
      return {
        ...state,
        currentBpFiles: state.currentBpFiles.filter(e => e.id !== action.payload)
      }
    case getFileTypesRoutine.SUCCESS:
      return {
        ...state,
        fileTypes: action.payload
      }
    case fetchBpRoutine.SUCCESS:
      return {
        ...state,
        currentBpFiles: pathOr([], ['payload', 'files'], action)
      }
    case getUploadProcessRoutine.SUCCESS:
      return {
        ...state,
        uploadProcess: action.payload
      }
    case clearUploadProcessRoutine.SUCCESS:
      return {
        ...state,
        uploadProcess: null
      }
    case saveUploadProcessFetchDetailsRoutine.SUCCESS:
      return {
        ...state,
        uploadProcessFetchDetails: action.payload
      }
    case clearUploadProcessFetchDetailsRoutine.SUCCESS:
      return {
        ...state,
        uploadProcessFetchDetails: null
      }
    case getBpFilesRoutine.SUCCESS:
      return {
        ...state,
        bpFilesList: action.payload
      }
    case clearBpFilesRoutine.SUCCESS:
      return {
        ...state,
        bpFilesList: []
      }
    case getBpFilesRoutine.FAILURE:
      return {
        ...state,
        bpFilesList: []
      }
    case getFileTagsForBpRoutine.SUCCESS:
      return {
        ...state,
        fileTags: action.payload
      }
    case createFileTagRoutine.SUCCESS:
      return {
        ...state,
        fileTags: [
          ...state.fileTags,
          action.payload
        ]
      }
    case attachFileTagToFileRoutine.SUCCESS:
    case detachFileTagFromFileRoutine.SUCCESS:
    case syncFileTagsInFileRoutine.SUCCESS:
      return {
        ...state,
        bpFilesList: state.bpFilesList.map(file => {
          if (file.id === action.payload.id) {
            return action.payload
          } else {
            return file
          }
        })
      }
    case syncFileTagsInFileVersionRoutine.SUCCESS:
      return {
        ...state,
        bpFilesList: state.bpFilesList.map(file => {
          if (file.id === action.payload.fileId) {
            const version = file?.versions || []
            return {
              ...file,
              versions: version.map(v => {
                if (v.id === action.payload.id) {
                  return action.payload
                } else {
                  return v
                }
              })
            }
          } else {
            return file
          }
        })
      }
    default:
      return state
  }
}
