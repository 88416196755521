import React from 'react'
import styled, { css } from 'styled-components'

const StatBox = ({ title, value, period, primary }) => {
  return (
    <StatBoxWrapper>
      <Title>{title}</Title>
      <ValueWrapper primary={primary}>
        {value} <span>{period}</span>
      </ValueWrapper>
    </StatBoxWrapper>
  )
}

export default StatBox

const StatBoxWrapper = styled.div`
  box-sizing: border-box;
  max-width: 300px;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  height: 115px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
`

const Title = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 10px;
`

const ValueWrapper = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
  
  ${({ primary }) => primary && css`
    color: ${({ theme }) => theme.colors.primary};
  `}
  
  span {
    font-size: 17px;
    display: inline-block;
    margin-left: 3px;
    color: ${({ theme }) => theme.colors.grey[700]};
    font-weight: normal;
  }
`
