import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInvitationsList } from 'features/bpList/ducks/selectors'
import { getInvitationsListForBpRoutine } from 'features/accessControl/ducks/actions'
import InvitationBox from 'features/accessControl/components/InvitationBox'
import { isEmpty } from 'ramda'
import styled from 'styled-components'
import { fetchUnitInvitationsListRoutine } from 'ducks/units/actions'
import { selectUnitInvitationsList } from 'ducks/units/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const InvitationsList = ({ unit }) => {
  const bp = useSelector(getSelectedBp)
  const bpInvitations = useSelector(selectInvitationsList)
  const unitInvitations = useSelector(selectUnitInvitationsList)
  const dispatch = useDispatch()

  const invitations = useMemo(() => {
    return isNotNilOrEmpty(unit) ? unitInvitations : bpInvitations
  }, [bpInvitations, unitInvitations, unit])

  useEffect(() => {
    if (unit) {
      dispatch(fetchUnitInvitationsListRoutine({
        bpId: bp.id,
        unitId: unit.id
      }))
    } else if (bp) {
      dispatch(getInvitationsListForBpRoutine(bp.id))
    }
  }, [unit, bp])

  return (
    <>
      {
        !isEmpty(invitations)
          ? invitations.map(invitation => (
              <InvitationBox key={invitation.id} invitation={invitation} unit={unit} />
            ))
          : <EmptyState>Invitations list is empty</EmptyState>

      }
    </>
  )
}

export default InvitationsList

const EmptyState = styled.div`
  box-sizing: border-box;
  display: flex;
  min-height: 83px;
  align-items: center;
`
