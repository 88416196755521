import { createSelector } from 'reselect'
import { pathOr, propOr } from 'ramda'

const getCart = state => state.cart

export const getCheckoutSessionId = createSelector(
  getCart,
  propOr('', 'checkoutSessionId')
)

export const selectCartAvailableBps = createSelector(
  getCart,
  pathOr([], ['cartAvailableBps', 'buildingPassportIds'])
)
