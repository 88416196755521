import React, { useEffect, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import {
  changeNewTemplateFilesValueRoutine,
  removeNewTemplateFilesValueRoutine
} from 'features/analytics/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectAddTemplateFiles } from 'features/analytics/ducks/selectors'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import ClearIcon from '@material-ui/icons/Clear'

const FilesDropContainer = ({ isEdit, fileTypesValues, setFileTypesValues, isDefaultTemplate }) => {
  const dispatch = useDispatch()
  const selectedValues = useSelector(selectAddTemplateFiles)
  const fileCategories = useSelector(selectFileCategories)

  const [{ getItem, didDrop, getItemType, canDrop }, drop] = useDrop(() => ({
    accept: 'template-file',
    collect: monitor => ({
      getItem: monitor.getItem(),
      didDrop: monitor.didDrop(),
      getDropResult: monitor.getDropResult(),
      getItemType: monitor.getItemType(),
      canDrop: monitor.canDrop()
    })
  }))

  const addValue = value => {
    if (isEdit) {
      setFileTypesValues(prev => [ ...prev, value ])
    } else {
      dispatch(changeNewTemplateFilesValueRoutine(value))
    }
  }

  const removeValue = value => {
    if (isEdit) {
      setFileTypesValues(fileTypesValues.filter(type => type.id !== value))
    } else {
      dispatch(removeNewTemplateFilesValueRoutine(value))
    }
  }

  useEffect(() => {
    if (didDrop && getItemType === 'template-file') {
      return isEdit
        ? isNotNilOrEmpty(fileTypesValues.find(v => v.id === getItem.id))
          ? () => {}
          : addValue(getItem)
        : addValue(getItem)
    }
  }, [didDrop])

  const valuesToDisplay = useMemo(() => {
    if (fileCategories && (selectedValues || fileTypesValues)) {
      const values = isEdit ? fileTypesValues : selectedValues

      const categories = fileCategories.map(category => {
        return {
          id: category.id,
          label: category.name,
          values: values.filter(type => type.fileCategoryId === category.id) || []
        }
      })

      return categories.map(category => {
        if (isNotNilOrEmpty(category.values)) {
          return (
            <>
              <CategoryName>{category.label}</CategoryName>
              {
                category.values.map(value => (
                  <SingleItem key={category.label}>
                    <div>
                      <span>•</span>{value.name}
                    </div>
                    <CrossIcon
                      disabled={isDefaultTemplate}
                      onClick={
                        isDefaultTemplate
                          ? () => {}
                          : () => removeValue(value.id)
                      }
                    />
                  </SingleItem>
                ))
              }
            </>
          )
        } else {
          return null
        }
      })
    }
  }, [selectedValues, fileTypesValues, fileCategories])

  return (
    <Content>
      <SectionTitle>files</SectionTitle>
      {valuesToDisplay}
      {!isDefaultTemplate && <DropContainer ref={drop} isHighlighted={canDrop && getItemType === 'template-file'} />}
    </Content>
  )
}

export default FilesDropContainer

const DropContainer = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.colors.grey[600]};
  margin-top: 20px;
  transition: .3s all;
  background-color: ${({ isHighlighted }) => isHighlighted ? '#eaf9fa' : 'transparent'};
`

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  padding: 20px;
`

const SingleItem = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.secondary};
    margin-right: 10px;
  }
`

const SectionTitle = styled.div`
  text-transform: uppercase;
  margin-bottom: 10px;
`

const CategoryName = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  margin: 15px 0 10px;
`

const CrossIcon = styled(ClearIcon)`
  font-size: 14px !important;
  cursor: pointer;
  color: ${({ disabled, theme }) =>  disabled ? theme.colors.grey[600] : theme.colors.primary}; 
`
