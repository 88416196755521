import { object, string } from 'yup'
import { validateField, validateValues } from 'utils/form'

export const shareTemplateSchema = object().shape({
  email: string()
    .required('Email address is required')
    .email('Email is not correct')
})
export const validateShareTemplateField = values => validateField(shareTemplateSchema, values)
export const validateShareTemplateValues = validateValues(shareTemplateSchema)
