import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const PeriodSwitch = ({ onChange }) => {
  const [period, setPeriod] = useState('month')

  const handleChange = period => () => setPeriod(period)

  useEffect(() => {
    onChange(period)
  }, [period])

  return (
    <SwitchWrapper>
      <SwitchOption
        onClick={handleChange('month')}
        active={period === 'month'}
      >
        Month
      </SwitchOption>
      <SwitchOption
        onClick={handleChange('year')}
        active={period === 'year'}
      >
        Year
      </SwitchOption>
    </SwitchWrapper>
  )
}

export default PeriodSwitch

const SwitchWrapper = styled.div`
  box-sizing: border-box;
  width: 170px;
  padding: 5px;
  height: 44px;
  display: flex;
  border-radius: 4px;
  background-color: #eff2fa;
`

const SwitchOption = styled.div`
  box-sizing: border-box;
  background-color: ${({ active, theme }) => active ? theme.colors.white : 'transparent'};
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  color: ${({ theme, active }) => active ? theme.colors.haiti : theme.colors.grey[700]};
  width: 50%;
  transition: all .3s;
  cursor: ${({ active }) => active ? 'default' : 'pointer'};
`
