import React, { useEffect, useState } from 'react'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/atoms/Input'
import { validateEditUserField, validateEditUserValues } from 'features/profileSettings/ducks/schema'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { updateUserRoutine } from 'features/auth/ducks/actions'
import { isEmpty, pick } from 'ramda'
import PhoneField from 'components/atoms/PhoneInput'
import styled from 'styled-components'

const UserBox = () => {
  const currentUser = useSelector(getCurrentUser)
  const isFrs = currentUser.type === 'emergency_service'
  const [values, setValues] = useState(currentUser)
  const [valid, setValid] = useState(false)
  const [phoneValid, setPhoneValid] = useState(true)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateEditUserValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(updateUserRoutine(
      pick([
        'firstName',
        'lastName',
        'phoneNumber',
        'company',
        'postalCode',
        'city',
        'street',
        'linkedinUrl'
      ]
      , values)
    ))
  }

  return (
    <div>
      <Input
        required
        value={values.email}
        label='Email'
        name='email'
        disabled
      />
      <Input
        required
        value={values.firstName}
        label='First name'
        name='firstName'
        onChange={handleValueChange}
        validate={validateEditUserField(values)}
      />
      <Input
        required
        value={values.lastName}
        label='Last name'
        name='lastName'
        onChange={handleValueChange}
        validate={validateEditUserField(values)}
      />
      <PhoneField
        name='phoneNumber'
        label='Phone number'
        value={values.phoneNumber}
        onChange={handleValueChange}
        validate={valid => setPhoneValid(valid)}
      />
      <Input
        required={isFrs}
        optional={!isFrs}
        label={isFrs ? 'FRS Station' : 'Company'}
        name='company'
        value={values.company}
        onChange={handleValueChange}
        validate={validateEditUserField(values)}
      />
      <Input
        required
        value={values.postalCode}
        label='Postal code'
        name='postalCode'
        onChange={handleValueChange}
        validate={validateEditUserField(values)}
      />
      <Input
        required
        label='Address'
        value={values.street}
        name='street'
        onChange={handleValueChange}
        validate={validateEditUserField(values)}
      />
      <Input
        required
        label='City'
        name='city'
        value={values.city}
        onChange={handleValueChange}
        validate={validateEditUserField(values)}
      />
      <SocialMediaWrapper>
        <p>LinkedIn profile</p>
        <Input
          required
          label='LinkedIn url'
          name='linkedinUrl'
          value={values.linkedinUrl}
          onChange={handleValueChange}
          validate={!isEmpty(values.linkedinUrl) ? validateEditUserField(values) : () => {}}
        />
      </SocialMediaWrapper>
      <NextButton
        disabled={!phoneValid || !valid}
        onClick={handleSubmit}
      >
        Save
      </NextButton>
    </div>
  )
}

export default UserBox

const SocialMediaWrapper = styled.div`
  margin-top: 50px;
  
  p {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};

  }
`
