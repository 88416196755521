import { object, string } from 'yup'
import i18n from 'i18n'
import { validateField, validateValues } from 'utils/form'

const buildingInformationSchema = object().shape({
  name: string()
    .required(i18n.t('common.validation.required', { field: 'Building Name / Number' })),
  uprn: string()
    .required(i18n.t('common.validation.required', { field: 'UPRN' }))
    .matches(/^\d*$/, { message: 'Incorrect UPRN number' })
    .max(12, 'UPRN should not be longer than 12 characters'),
  postalCode: string()
    .required(i18n.t('common.validation.required', { field: 'Post code' }))
    .max(8, 'Postal code should not be longer than 8 characters'),
  street: string()
    .required(i18n.t('common.validation.required', { field: 'Address' })),
  city: string()
    .required(i18n.t('common.validation.required', { field: 'City name' }))
})

export const validateBuildingInformationField = values => validateField(buildingInformationSchema, values)
export const validateBuildingInformationValues = validateValues(buildingInformationSchema)

const buildingInformationWithoutUprnSchema = object().shape({
  name: string()
    .required(i18n.t('common.validation.required', { field: 'Building Name / Number' })),
  uprn: string()
    .nullable()
  .matches(/^\d*$/, {
    message: 'Incorrect UPRN number',
  }),
  postalCode: string()
    .required(i18n.t('common.validation.required', { field: 'Post code' }))
    .max(8, 'Postal code should not be longer than 8 characters'),
  street: string()
    .required(i18n.t('common.validation.required', { field: 'Address' })),
  city: string()
    .required(i18n.t('common.validation.required', { field: 'City name' }))
})

export const validateBuildingInformationWithoutUprnField = values => validateField(buildingInformationWithoutUprnSchema, values)
export const validateBuildingInformationWithoutUprnValues = validateValues(buildingInformationWithoutUprnSchema)

const buildingDetailsSchema = object().shape({
  type: string()
    .required('You must add at least 1 building type'),
  description: string(),
  value: string()
    .matches(/^[0-9]*(\.[0-9]+)?$/, {
      message: 'Incorrect value'
    })
    .required('This field is required')
})

export const validateBuildingDetailsField = values => validateField(buildingDetailsSchema, values)

const ownerSchema = object().shape({
  email: string()
    .required(i18n.t('common.validation.required', { field: 'Email' }))
    .email(i18n.t('common.validation.incorrectEmail')),
  firstName: string()
    .required(i18n.t('common.validation.required', { field: 'First name' }))
    .min(2, i18n.t('common.validation.length', { field: 'First name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    }),
  lastName: string()
    .required(i18n.t('common.validation.required', { field: 'Last name' }))
    .min(2, i18n.t('common.validation.length', { field: 'Last name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    })
})

export const validateOwnerField = values => validateField(ownerSchema, values)
export const validateOwnerValues = validateValues(ownerSchema)

const responsiblePersonSchema = object().shape({
  email: string()
    .required(i18n.t('common.validation.required', { field: 'Email' }))
    .email(i18n.t('common.validation.incorrectEmail')),
  phoneNumber: string()
    .required(i18n.t('common.validation.required', { field: 'Phone number' }))
    .matches(/^\d{10}$/, {
      message: i18n.t('common.validation.incorrectPhone')
    }),
  firstName: string()
    .required(i18n.t('common.validation.required', { field: 'First name' }))
    .min(2, i18n.t('common.validation.length', { field: 'First name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    }),
  lastName: string()
    .required(i18n.t('common.validation.required', { field: 'Last name' }))
    .min(2, i18n.t('common.validation.length', { field: 'Last name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    })
})

export const validateResponsiblePersonField = values => validateField(responsiblePersonSchema, values)
export const validateResponsiblePersonValues = validateValues(responsiblePersonSchema)

export const validateEmailFieldSchema = object().shape({
  email: string()
    .required(i18n.t('common.validation.required', { field: 'Email' }))
    .email(i18n.t('common.validation.incorrectEmail')),
})

export const validateEmailField = values => validateField(validateEmailFieldSchema, values)
export const validateEmailValue = validateValues(validateEmailFieldSchema)

export const addAssignmentSchema = object().shape({
  email: string()
    .required(i18n.t('common.validation.required', { field: 'Email' }))
    .email(i18n.t('common.validation.incorrectEmail')),
  firstName: string()
    .required(i18n.t('common.validation.required', { field: 'First name' }))
    .min(2, i18n.t('common.validation.length', { field: 'First name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    }),
  lastName: string()
    .required(i18n.t('common.validation.required', { field: 'Last name' }))
    .min(2, i18n.t('common.validation.length', { field: 'Last name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    }),
  relationType: string().oneOf(
    [
      'agent',
      'owner',
      'responsible_person',
      'responsiblePerson',
      'responsible_persons',
      'responsiblePersons',
      'prospective_purchaser',
      'prospectivePurchaser',
      'occupant',
      'principal_accountable_person',
      'principalAccountablePerson',
      'principal_designer',
      'principalDesigner'
    ],
    i18n.t('signUp.validation.wrongRole')
  ).required('Role is required'),
})

export const validateAssignmentField = values => validateField(addAssignmentSchema, values)
export const validateAssignmentValues = validateValues(addAssignmentSchema)
