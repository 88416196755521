import React from 'react'
import styled, { css } from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const NextButton = props => {
  return (
    <StyledButton {...props} type={props.type || 'button'}>
      <ContentWrapper>
        {props.isLoading && <CircularProgress size={20} style={{ color: '#444' }} />}
        <div>{props.children}</div>
      </ContentWrapper>
    </StyledButton>
  )
}

export default NextButton

const StyledButton = styled.button`
  box-sizing: border-box;
  width: 245px;
  height: 45px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  transition: all .3s;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  border: solid 1px rgba(77, 161, 255, 0.7);

  &:hover {
    opacity: .7;
  }

  ${({ disabled }) => disabled && css`
    background-color: ${({ theme }) => theme.colors.grey[400]};
    color: ${({ theme }) => theme.colors.grey[600]};
    border-color: ${({ theme }) => theme.colors.grey[500]};

    &:hover {
      opacity: 1;
    }
  `}
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
`
