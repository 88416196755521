import { createSelector } from 'reselect'
import { propOr } from 'ramda'

const notifications = state => state.notifications

export const getAllNotifications = createSelector(
  notifications,
  propOr([], 'all')
)

export const getUnreadNotificationsCounter = createSelector(
  notifications,
  propOr(0, 'unreadCounter')
)

export const getUnseenNotificationsCounter = createSelector(
  notifications,
  propOr(0, 'unseenCounter')
)
