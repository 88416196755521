import React, { useState } from 'react'
import { USER_ROLES } from 'utils/userRoles'
import { formatDate } from 'utils/date'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { revokeAccessToUnitRoutine } from 'ducks/units/actions'
import { getUserRoleInUnit, hideEmail } from 'utils/user'
import { isNotNil } from 'utils/ramda'

const UnitAssignedUserBox = ({ user, unit, index, openEditModal }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const currentBp = useSelector(getSelectedBp)

  const openMenu = e => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const userRole = getUserRoleInUnit(currentUser, unit)
  const blockOwnerEdit = userRole === 'occupant' && user.relationType === 'owner'

  const handleModalOpen = () => {
    handleClose()
    setModalOpen(true)
  }
  const handleModalClose = () => setModalOpen(false)

  const handleRevokeAccess = () => {
    dispatch(revokeAccessToUnitRoutine({
      bpId: currentBp.id,
      unitId: unit.id,
      relationId: pathOr(null, ['pivot', 'id'], user)
    }))
    handleModalClose()
  }

  const handleEditModalOpen = () => {
    handleClose()
    openEditModal(user)()
  }

  const expiresAt = pathOr(null, ['pivot', 'expiresAt'], user) || pathOr(null, ['pivot', 0, 'expiresAt'], user)

  return (
    <>
      <tr key={`assigned-user-${index}`}>
        <td>{user.firstName} {user.lastName}</td>
        <td>{hideEmail(user.email)}</td>
        <td>{USER_ROLES[user.relationType]}</td>
        <td>{formatDate(expiresAt)}</td>
        <td>
          {isNotNil(user.id) && <MoreIcon onClick={openMenu} />}
        </td>
      </tr>
      <Menu
        keepMounted
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        style={{
          marginTop: '10px'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleEditModalOpen}
          disabled={blockOwnerEdit}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={handleModalOpen}
          disabled={user.id === currentUser.id || blockOwnerEdit}
        >
          Revoke access
        </MenuItem>
        {/*<a href={`mailto:${user.email}`} rel='noreferrer' target='_blank'>*/}
        {/*  <MenuItem>Contact user</MenuItem>*/}
        {/*</a>*/}
      </Menu>
      <Modal
        open={modalOpen}
        title='Revoke access'
        onClose={handleModalClose}
        withCancel
        onCancel={handleModalClose}
        withSubmit
        onSubmit={handleRevokeAccess}
      >
        Do you really want to revoke user&apos;s assignment?
      </Modal>
    </>
  )
}

export default UnitAssignedUserBox

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[700]};
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`
