import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBuildingTypesForForms } from 'features/bpList/ducks/selectors'
import { propOr, values, isEmpty } from 'ramda'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import { validateBuildingDetailsField } from 'features/createBp/ducks/schema'
import { isNotEmpty, isNotNilOrEmpty } from 'utils/ramda'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { getBuildingTypeValueType } from 'utils/bpData'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { addBuildingTypeToBpRoutine } from 'features/bpDetails/ducks/actions'

const AddBuildingTypeForm = ({ bp, closeModal, isWizard, selectedType }) => {
  const [valid, setIsValid] = useState()
  const [formValues, setFormValues] = useState({
    value: '',
    description: '',
    initialUnitsNumber: 1
  })
  const buildingTypes = useSelector(getBuildingTypesForForms)
  const isDwellingSoleUnit = selectedType.type === 'residential.dwelling.sole_unit'
  const isHotel = selectedType.type === 'hotel'
  const dispatch = useDispatch()

  const unit = useMemo(() => {
    if (buildingTypes) {
      const item = values(buildingTypes).find(e => e.key === selectedType.type)
      return propOr('', 'unit', item)
    }
  }, [selectedType])

  const handleSubmit = () => {
    dispatch(addBuildingTypeToBpRoutine({
      bpId: bp.id,
      buildingType: selectedType.type,
      units: unit === 'sqm' ? Number(formValues.initialUnitsNumber) : Number(formValues.value),
      area: unit === 'sqm' ? Number(formValues.value) : null,
      description: formValues.description,
      isWizard
    }))
    closeModal()
  }

  const hasMultipleUnits = useMemo(() => {
    if (buildingTypes) {
      const item = values(buildingTypes).find(e => e.key === selectedType.type)
      return propOr('', 'multipleBpUnits', item)
    }
  }, [selectedType])

  useEffect(() => {
    setIsValid(isNotNilOrEmpty(formValues.value) && /^[0-9]*(\.[0-9]+)?$/.test(formValues.value))
  }, [formValues])

  const handleInputChange = (name, value) => {
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <Wrapper>
      {
        isNotEmpty(selectedType.type) && (
          <ValueWrapper>
            {
              !isDwellingSoleUnit && (
                <Input
                  label={getBuildingTypeValueType(selectedType.type)}
                  value={isEmpty(formValues.value) ? 0 : formValues.value}
                  name='value'
                  onChange={handleInputChange}
                  validate={validateBuildingDetailsField(formValues)}
                />
              )
            }
            {
              hasMultipleUnits && unit !== 'unit' && (
                <Input
                  label='Initial units number'
                  type='number'
                  inputProps={{ min: 1 }}
                  value={Number(formValues.initialUnitsNumber)}
                  name='initialUnitsNumber'
                  onChange={handleInputChange}
                />
              )
            }
            {
              unit === 'unit' && (
                <Input
                  label='Description'
                  value={formValues.description}
                  name='description'
                  optional
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                  validate={validateBuildingDetailsField(formValues)}
                  placeholder={
                    isHotel
                      ? 'Building offering paid lodging on a short basis (i.e. it is not the main residence of the inhabitants)'
                      : 'e.g. Detached two storey house. Brick construction with wooden cladding.'}
                />
              )
            }
            {
              isDwellingSoleUnit && (
                <DescriptionWrapper>
                  <InputDescription>
                    This type of building is one-time subscription. You cannot add more building types to this one.
                  </InputDescription>
                </DescriptionWrapper>
              )
            }
          </ValueWrapper>
        )
      }
      <ButtonWrapper>
        <NextButton disabled={!valid} onClick={handleSubmit}>Add new unit(s)</NextButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default AddBuildingTypeForm

const Wrapper = styled.div``

const ValueWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 15px 15px 0;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
`
