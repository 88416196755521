import { isNotNilOrEmpty } from 'utils/ramda'

export const getFiltersQuery = filters => {
  let query = ''

  for (const key in filters) {
    if (isNotNilOrEmpty(filters[key])) {
      if (typeof filters[key] === 'object') {
        let group = ''
        filters[key].forEach((e, i) => {
          group += `${e.value}${i === filters[key].length - 1 ? '' : ','}`
        })
        query += `&filter[${key}]=${group}`
      } else {
        query += `&filter[${key}]=${filters[key]}`
      }
    }
  }
  return query
}
