import React, { useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import { pathOr } from 'ramda'
import { formatDate } from 'utils/date'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import { detachFileTagFromFileRoutine } from 'ducks/files/actions'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const FileItemTag = ({ tag, disabled }) => {
  const bp = useSelector(getSelectedBp)
  const tagName = pathOr('', ['tag', 'name'], tag)
  const taggedByFirstName = pathOr('', ['taggedBy', 'firstName'], tag)
  const taggedByLastName = pathOr('', ['taggedBy', 'lastName'], tag)
  const date = pathOr('', ['createdAt'], tag)
  const [removeVisible, setRemoveVisible] = useState(false)
  const dispatch = useDispatch()

  const handleShowRemove = () => {
    setRemoveVisible(true)
  }

  const handleHideRemove = () => {
    setRemoveVisible(false)
  }

  const handleDetach = () => {
    dispatch(detachFileTagFromFileRoutine({ bpId: bp.id, id: tag.id }))
  }

  return (
    <Tooltip title={(
      <div>
        <div>Added by: {`${taggedByFirstName} ${taggedByLastName}`}</div>
        <div>Date: {formatDate(date)}</div>
      </div>
    )}>
      <Tag
        onMouseEnter={disabled ? () => {} : handleShowRemove}
        onMouseLeave={disabled ? () => {} : handleHideRemove}
      >
        <div>{tagName}</div>
        {removeVisible && (
          <RemoveIcon onClick={handleDetach}>
            <StyledCloseIcon />
          </RemoveIcon>
        )}

      </Tag>
    </Tooltip>
  )
}

export default FileItemTag

const Tag = styled.div`
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #e6f2ff;
  color: ${({ theme }) => theme.colors.darkGrey};
  position: relative;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 10px !important;
`

const RemoveIcon = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
`
