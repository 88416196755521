import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { selectPendingRequests, selectSearchedAccessRequestBps } from 'features/AccessRequest/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import AllBpsSearchInput from 'features/AccessRequest/components/AllBpsSearchInput'
import SearchedBpsTable from 'features/AccessRequest/components/SearchedBpsTable'
import Modal from 'components/atoms/Modal'
import NewRequestForm from 'features/AccessRequest/components/NewRequestForm'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import BpBox from 'features/dashboard/components/BpBox'
import { pathOr } from 'ramda'
import { toCamelCase } from 'js-convert-case'
import { fetchAuthUserAccessRequestsRoutine } from 'features/AccessRequest/ducks/actions'

const SectionTitle = ({ children }) => {
  return (
    <SectionTitleWrapper>
      <SectionTitleText>{children}</SectionTitleText>
      <SectionUnderline />
    </SectionTitleWrapper>
  )
}

const AccessRequest = () => {
  const searchResults = useSelector(selectSearchedAccessRequestBps)
  const pendingRequests = useSelector(selectPendingRequests)
  const buildingTypes = useSelector(getBuildingTypes)
  const [requestModalOpen, setRequestModalOpen] = useState(false)
  const [selectedBp, setSelectedBp] = useState({})
  const dispatch = useDispatch()
  const [isSearching, setIsSearching] = useState(false)

  const handleRequestModalOpen = bp => () => {
    bp && setSelectedBp(bp)
    setRequestModalOpen(true)
  }

  const requests = pendingRequests.map(request => {
    if (request.buildingUnitPassportId && request.buildingUnitPassport) {
      return {
        ...request,
        buildingPassport: {
          ...request.buildingUnitPassport.buildingPassport,
          isUnit: true,
          name: pathOr('', ['buildingUnitPassport', 'name'], request),
          uprn: pathOr('', ['buildingUnitPassport', 'uprn'], request)
        }
      }
    } else {
      return request
    }
  })

  useEffect(() => {
    dispatch(fetchAuthUserAccessRequestsRoutine())
  }, [])

  const handleRequestModalClose = () => {
    setRequestModalOpen(false)
  }

  const buildingTypesString = useMemo(() => {
    let result = ''
    if (isNotNilOrEmpty(selectedBp.buildingTypes)) {
      selectedBp.buildingTypes.forEach(e => {
        const type = toCamelCase(e.type)
        result += `${pathOr('', [type, 'label'], buildingTypes)};`
      })
    }
    return result.substring(0, result.length - 1)
  }, [selectedBp])

  return (
    <PageWrapper>
      <PageTitle>Request access to Building Passport</PageTitle>
      <PageSubtitle>Search below for a Building Passport you wish to access</PageSubtitle>
      <SearchInputWrapper>
        <AllBpsSearchInput setIsSearching={setIsSearching} />
      </SearchInputWrapper>
      {
        searchResults.length > 0
          ? <CountResults>{searchResults.length + ' Building Passports found'}</CountResults>
          : <CountResults>&nbsp;</CountResults>
      }
      <SearchedBpsTable
        bps={searchResults}
        openRequestModal={handleRequestModalOpen}
        isSearching={isSearching}
      />
      {
        isNotNilOrEmpty(requests) && (
          <PendingRequestsWrapper>
            <SectionTitle>Pending requests</SectionTitle>
            <BpsList>
              {
                requests.map(request =>
                  <BpBox
                    key={pathOr('', ['buildingPassport', 'id'], request)}
                    bp={request.buildingPassport}
                    buildingTypes={buildingTypes}
                    noAction
                  />
                )
              }
            </BpsList>
          </PendingRequestsWrapper>
        )
      }
      <Modal
        open={requestModalOpen}
        onClose={handleRequestModalClose}
        title='Request access'
      >
        <BpBoxWrapper>
          <DetailsWrapper>
            <IconWrapper>
              <FileIcon />
            </IconWrapper>
            <div>
              <BpName>
                {selectedBp.name}
              </BpName>
              <Uprn>
                UPRN: {
                isNotNilOrEmpty(selectedBp.uprn)
                  ? selectedBp.uprn
                  : 'Not yet allocated'
              }
              </Uprn>
            </div>
          </DetailsWrapper>
          <div>
            <DetailsItem>
              <DetailsLabel>Type</DetailsLabel>
              <DetailsValue>{buildingTypesString}</DetailsValue>
            </DetailsItem>
            <DetailsItem>
              <DetailsLabel>Address</DetailsLabel>
              <DetailsValue>{`${selectedBp.street}, ${selectedBp.city}, ${selectedBp.postalCode}`}</DetailsValue>
            </DetailsItem>
          </div>
        </BpBoxWrapper>
        <NewRequestForm bpId={selectedBp.id} closeRequestModal={handleRequestModalClose} />
      </Modal>
    </PageWrapper>
  )
}

export default AccessRequest

const PageWrapper = styled.div`

`

const PageTitle = styled.div`
  width: 100%;
  font-size: 38px;
  color: ${({ theme }) => theme.colors.haiti};
  text-align: center;
  font-weight: 100;
  font-family: 'FuturaPT-Light';
`

const PageSubtitle = styled.div`
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .8;
`

const SearchInputWrapper = styled.div`
  width: 600px;
  margin: 25px auto;
`

const BpBoxWrapper = styled.div`
  box-sizing: border-box;
  min-width: 350px;
  max-width: 350px;
  padding: 25px;
  height: 150px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 0 auto 30px;
  transition: all .3s;
`

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const IconWrapper = styled.div`
  background-color: #fbede9;
  min-width: 55px;
  min-height: 55px;
  max-width: 55px;
  max-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`

const BpName = styled.div`
  font-size: 19px;
  line-height: 20px;
  ${({ theme }) => theme.colors.haiti};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Uprn = styled.div`
  font-size: 13px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const DetailsItem = styled.div`
  display: flex;
`

const DetailsLabel = styled.div`
  box-sizing: border-box;
  padding-left: 10px;
  min-width: 55px;
  margin-right: 15px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const DetailsValue = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.haiti};
`

const CountResults = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  margin-bottom: 5px;
`

const PendingRequestsWrapper = styled.div`
  margin-top: 20px;
`

const SectionTitleWrapper = styled.div`
  margin: 40px 0 20px;
`

const SectionTitleText = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 10px;
`

const SectionUnderline = styled.div`
  width: 50px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const BpsList = styled.div`
  box-sizing: border-box;
  display: flex;
  align-content: flex-start;
  height: 170px;
  width: 100%;
  overflow-x: auto;
`
