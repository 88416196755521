import React, { useMemo } from 'react'
import { AddButton, MainContentPanel, SectionHeader, SectionHeaderWrapper } from 'features/bpDetails/BpDetails'
import Note from 'features/bpDetails/Components/Note'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { isEmpty, propOr } from 'ramda'
import styled from 'styled-components'
import { canEditBp } from 'utils/user'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import EmptyNotesState from 'features/bpList/Components/EmptyNotesState'
import AddNoteModal from 'features/bpDetails/Components/AddNoteModal'

const NotesPanel = () => {
  const bp = useSelector(getSelectedBp)
  const user = useSelector(getCurrentUser)

  const floorNotes = useMemo(() => {
    return propOr([], 'notes', bp)
  }, [bp])

  const canEdit = canEditBp(user, bp, true)

  return (
    <>
      <MainContentPanel>
        <SectionHeaderWrapper noMargin withBorder>
          <SectionHeader>Notes</SectionHeader>
          {canEdit && (
            <AddNoteModal>
              <AddButton>+ Add note</AddButton>
            </AddNoteModal>
          )}
        </SectionHeaderWrapper>
        {
          isEmpty(floorNotes)
            ? <EmptyNotesState
              text={
                canEdit
                  ? 'Add your first note to this Building Passport'
                  : 'No notes were added'
              }
              buttonText='+ Add note'
              padding='50px 0'
              hideButton={!canEdit}
            />
            : floorNotes.map(note => {
              return (
                <NoteWrapper key={note.id}>
                  <Note note={note} user={user} />
                </NoteWrapper>
              )
            })
        }
      </MainContentPanel>
    </>
  )
}

export default NotesPanel

const NoteWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;

  &:not(:last-of-type) {
    padding-bottom: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`
