import { createSelector } from 'reselect'
import { pathOr, propOr } from 'ramda'

const getBps = state => state.bpDetails

export const selectUserRelationToBp = createSelector(
  getBps,
  propOr([], 'userRelation')
)

export const getSelectedBp = createSelector(
  getBps,
  propOr([], 'selectedBp')
)

export const selectHasAccessToBp = createSelector(
  getBps,
  pathOr(null, ['selectedBp', 'hasAccessToBuildingPassport'])
)

export const getBpConnectedUsers = createSelector(
  getBps,
  propOr({}, 'connectedUsers')
)

export const getOwners = createSelector(
  getBps,
  pathOr({}, ['relationship', 'owner'])
)

export const getFoundUsersByRelationType = createSelector(
  getBps,
  propOr({
    owner: [],
    responsiblePersons: [],
    agent: [],
    occupant: []
  }, 'relationship')
)
