import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import PasswordHint from 'features/auth/components/PasswordHint'
import Collapse from '@material-ui/core/Collapse'
import { isNotNilOrEmpty } from 'utils/ramda'

export const Input = props => {
  const {
    name,
    type,
    onChange,
    validate,
    label,
    value: initialValue,
    required,
    variant,
    disabled,
    multiline,
    rows,
    clearValueTrigger,
    startAdornment,
    withPasswordHint,
    additionalLabel,
    noMarginBottom,
    optional,
    inputProps,
    placeholder,
    onBlur,
    onFocus,
    hasError
  } = props
  const [passwordVisible, _setPasswordVisibility] = useState(false)
  const [passwordHintVisible, setPasswordHintVisible] = useState(false)
  const [touched, _setTouched] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [{ valid, error }, _validate] = useState({
    valid: !validate,
    error: ''
  })

  useEffect(() => {
    validate && validate(name, v => {
      _validate({ valid: v.valid, error: v.errors })
    })
  }, [value])

  useEffect(() => {
    type === 'date' ? _setValue(null) : _setValue('')
  }, [clearValueTrigger])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  const inputType = !type || passwordVisible ? 'text' : type

  const handleVisibilityChange = () =>
    _setPasswordVisibility(prevVisibility => !prevVisibility)

  const getEndAdornment = type === 'password' && (
    <InputAdornment position='end'>
      <IconButton onClick={handleVisibilityChange}>
        {passwordVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
      </IconButton>
    </InputAdornment>
  )

  const handleBlur = () => {
    _setTouched(true)
    type === 'password' && withPasswordHint && setPasswordHintVisible(false)
    typeof onBlur === 'function' && onBlur()
  }
  const handleFocus = () => {
    typeof onFocus === 'function' && onFocus()
    type === 'password' && withPasswordHint && setPasswordHintVisible(true)
  }
  const handleChange = e => {
    _setValue(e.target.value)
    onChange(name, e.target.value)
  }

  return (
    <InputWrapper noMarginBottom={noMarginBottom}>
      {
        isNotNilOrEmpty(label) && (
          <Label>
            {
              valid || (!valid && !touched) || disabled
                ? optional
                  ? (
                    <LabelWrapper>
                      {label}
                      <span>(optional)</span>
                    </LabelWrapper>
                  )
                  : <div>{label}</div>
                : <Error>{error}</Error>
            }
            {additionalLabel && additionalLabel}
          </Label>
        )
      }

      <TextField
        disabled={disabled}
        placeholder={placeholder}
        variant={variant}
        required={required}
        name={name}
        multiline={multiline}
        rows={rows}
        value={value}
        type={inputType || 'text'}
        error={((!valid && touched) || hasError) && !disabled}
        fullWidth
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        InputProps={{
          startAdornment: <Adornment>{startAdornment}</Adornment>,
          endAdornment: getEndAdornment,
          style: {
            backgroundColor: disabled ? '#fcfcfc' : '#fff',
            height: multiline ? 'auto' : '45px'
          }
        }}
        inputProps={inputProps}
        style={{
          width: '100%'
        }}
      />
        <Collapse in={passwordHintVisible}>
          <PasswordHint password={value} />
        </Collapse>
    </InputWrapper>
  )
}

Input.defaultProps = {
  name: '',
  type: 'text',
  onChange: () => {},
  validate: null,
  label: '',
  value: '',
  required: false,
  variant: 'outlined',
  disabled: false,
  multiline: false,
  rows: 1,
  clearValueTrigger: '',
  startAdornment: null,
  withPasswordHint: false,
  additionalLabel: null,
  noMarginBottom: false,
  optional: false,
  inputProps: {},
  placeholder: '',
  onBlur: () => {},
  onFocus: () => {}
}

export default Input

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? 0 : '25px' };

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: .11px;
  min-height: 17px;
`

const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
`

const LabelWrapper = styled.div`
  span {
    display: inline-block;
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.grey[700]};
    font-size: 12px;
  }
`

const Adornment = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey[700]};
`
