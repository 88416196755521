import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import { removeFileTagRoutine } from 'ducks/files/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const GlobalTagItem = ({ tag, selectedTags, handleTagClick, handleDeselectTag }) => {
  const bp = useSelector(getSelectedBp)
  const [removeVisible, setRemoveVisible] = useState(false)
  const dispatch = useDispatch()

  const handleShowRemove = () => {
    setRemoveVisible(true)
  }

  const handleHideRemove = () => {
    setRemoveVisible(false)
  }

  const handleTagRemove = tagId => e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(removeFileTagRoutine({ bpId: bp.id, id: tagId }))
    handleDeselectTag(tagId)
  }

  return (
    <ClickableTag
      key={tag.id}
      active={selectedTags.includes(tag.id)}
      onClick={handleTagClick(tag.id)}
      onMouseEnter={handleShowRemove}
      onMouseLeave={handleHideRemove}
    >
      {tag.name}
      {removeVisible && (
        <RemoveIcon onClick={handleTagRemove(tag.id)}>
          <StyledCloseIcon />
        </RemoveIcon>
      )}
    </ClickableTag>
  )
}

export default GlobalTagItem

const ClickableTag = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #e6f2ff;
  color: ${({ theme }) => theme.colors.darkGrey};
  cursor: pointer;
  transition: all .3s;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }

  ${({ active, theme }) => active && css`
    background-color: ${theme.colors.primary};
    color: #fff;
  `}
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 10px !important;
`

const RemoveIcon = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
`
