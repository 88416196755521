import {
  clearSearchedBpsListRoutine, fetchAuthUserAccessRequestsRoutine,
  getAccessRequestsRoutine,
  searchInAllBpsRoutine
} from 'features/AccessRequest/ducks/actions'
import { emptyRequestAccessState } from 'features/AccessRequest/ducks/state'

export default (state = emptyRequestAccessState, action) => {
  switch (action.type) {
    case searchInAllBpsRoutine.SUCCESS:
      return {
        ...state,
        bps: action.payload
      }
    case clearSearchedBpsListRoutine.SUCCESS:
      return {
        ...state,
        bps: []
      }
    case getAccessRequestsRoutine.SUCCESS:
      return {
        ...state,
        requests: action.payload
      }
    case fetchAuthUserAccessRequestsRoutine.SUCCESS:
      return {
        ...state,
        pending: action.payload
      }
    default:
      return state
  }
}
