import { createSelector } from 'reselect'
import { pathOr, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

const getBps = state => state.bp

export const getUserBps = createSelector(
  getBps,
  propOr([], 'entries')
)

export const selectInvitationsList = createSelector(
  getBps,
  propOr([], 'invitations')
)

export const getFavourites = createSelector(
  getBps,
  pathOr([], ['favourites', 'entries'])
)

export const getFavouritesTotalPages = createSelector(
  getBps,
  pathOr(1, ['favourites', 'totalPages'])
)

export const getFavouritesCount = createSelector(
  getBps,
  pathOr(1, ['favourites', 'totalFound'])
)

export const getBpPrices = createSelector(
  getBps,
  propOr([], 'prices')
)

export const getSearchResults = createSelector(
  getBps,
  propOr([], 'searchResults')
)

export const getPagesCount = createSelector(
  getBps,
  propOr(0, 'totalPages')
)

export const getBpsCount = createSelector(
  getBps,
  propOr(0, 'totalFound')
)

export const getSelectedBp = createSelector(
  getBps,
  propOr([], 'selectedBp')
)

export const selectHasAccessToBp = createSelector(
  getBps,
  pathOr(false, ['selectedBp', 'hasAccessToBuildingPassport'])
)

export const selectCartAvailableBps = createSelector(
  getBps,
  pathOr([], ['cartAvailableBps', 'buildingPassportIds'])
)

export const getBuildingTypesForForms = createSelector(
  getBps,
  propOr({}, 'buildingTypes')
)

export const getBuildingTypes = createSelector(
  getBps,
  state => {
    const types = propOr({}, 'buildingTypes', state)
    return isNotNilOrEmpty(types)
      ? {
        ...types,
        // these types are currently not available but still exist in some units
        residentialOther: {
          key: 'residential.other',
          label: 'Residential',
          unit: 'unit',
          multipleBpUnits: true
        },
        residentialInstitutional: {
          key: 'residential.institutional',
          label: 'Residential',
          unit: 'unit',
          multipleBpUnits: true
        },
        residentialSoleUnit: {
          key: 'residential.dwelling.sole_unit',
          label: 'Residential',
          unit: 'unit',
          multipleBpUnits: false
        }
      }
      : {}
  }
)

export const getFileTypes = createSelector(
  getBps,
  propOr({}, 'fileTypes')
)

export const getOwners = createSelector(
  getBps,
  pathOr({}, ['relationship', 'owner'])
)

export const selectBpsCount = createSelector(
  getBps,
  propOr(0, 'bpsCount')
)

export const selectUnitsCount = createSelector(
  getBps,
  propOr(0, 'unitsCount')
)
