import APIService from 'services/APIService'
import qs from 'query-string'
import { omit } from 'ramda'

const api = APIService()

export const fetchUserUnits = ({ sort, page, filters }) =>
  api.get(`/building-passport/units?${qs.stringify({ sort })}&page=${page}${filters || ''}`)
export const fetchBpUnits = payload => api.get(`/building-passport/${payload.bpId}/units`)
export const fetchNoticeBoard = payload => api.get(`/building-passport/${payload.bpId}/notice-board`)
export const fetchUnit = payload => api.get(`/building-passport/${payload.bpId}/units/${payload.unitId}`)
export const addBpUnits = payload => api.post(`/building-passport/${payload.bpId}/units/generate`, payload)
export const editBpUnit = payload => api.patch(`/building-passport/${payload.bpId}/units/${payload.id}`, { ...payload })
export const removeBpUnit = payload =>
  api.delete(`/building-passport/${payload.bpId}/units/${payload.unitId}`)
export const editUserRelationToUnit = payload =>
  api.patch(`/building-passport/${payload.bpId}/units/${payload.unitId}/assigned-users/${payload.userId}`, { ...payload.body })
export const assignUserToUnit = payload =>
  api.post(`/building-passport/${payload.bpId}/units/${payload.unitId}/assigned-users/`, { ...payload.body })
export const inviteUserToUnit = payload =>
  api.post(`/building-passport/${payload.bpId}/units/${payload.unitId}/invitations`, { ...payload.body })
export const getUnitInvitation = payload =>
  api.get(`/building-passport/unit-invitations/${payload.id}?email=${payload.email}`)
export const fetchUnitInvitationsList = payload =>
  api.get(`/building-passport/${payload.bpId}/units/${payload.unitId}/invitations`)
export const revokeAccessToUnit = payload =>
  api.patch(`/building-passport/${payload.bpId}/units/${payload.unitId}/assigned-users/${payload.relationId}/revoke`)
export const cancelUnitInvitation = payload =>
  api.delete(`/building-passport/${payload.bpId}/units/${payload.unitId}/invitations/${payload.invitationId}`)
export const reactivateUnitUser = payload =>
  api.patch(`/building-passport/${payload.bpId}/units/${payload.unitId}/assigned-users/${payload.relationId}/reactivate`)
export const detachUnitUser = payload =>
  api.delete(`/building-passport/${payload.bpId}/units/${payload.unitId}/assigned-users/${payload.relationId}`)
export const getFloorPlansForUnit = payload =>
  api.get(`/building-passport/${payload.bpId}/units/${payload.unitId}/files/floor-plans`)

export const addFileToUnit = payload =>
  api.post(`/building-passport/${payload.bpId}/units/${payload.unitId}/files`, omit(['bpId, unitId'], payload))
export const updateUnitFile = payload =>
  api.patch(`/building-passport/${payload.bpId}/units/${payload.unitId}/files/${payload.fileId}/${payload.fileVersionId}`, payload.body)

export const requestAccessToUnit = payload =>
  api.post(`/building-passport/${payload.bpId}/units/${payload.unitId}/access-requests`, payload)
export const getUnitAccessRequests = payload =>
  api.get(`/building-passport/${payload.bpId}/units/${payload.unitId}/access-requests`)
export const acceptUnitAccessRequest = payload =>
  api.patch(`/building-passport/${payload.bpId}/units/${payload.unitId}/access-requests/${payload.requestId}/accept`)
export const denyUnitAccessRequest = payload =>
  api.delete(`/building-passport/${payload.bpId}/units/${payload.unitId}/access-requests/${payload.requestId}`, payload)
export const getUserRelationToUnit = payload =>
  api.get(`/building-passport/${payload.bpId}/units/${payload.unitId}/assigned-users/roles?user_id=${payload.userId}`)

export const removeUnitFile = payload =>
  api.delete(`/building-passport/${payload.bpId}/units/${payload.unitId}/files/${payload.fileId}`)

export const fetchBpUnitsForAccessRequest = payload =>
  api.get(`/building-passport/${payload.bpId}/access-requests/units`)

export const addUnitFileVersion = payload => {
  const { bpId, unitId, fileId } = payload
  return api.post(`building-passport/${bpId}/units/${unitId}/files/${fileId}/version`, omit(['bpId', 'unitId', 'fileId'], payload))
}
export const removeUnitFileVersion = payload => {
  const { bpId, unitId, fileId, fileVersionId } = payload
  return api.delete(`/building-passport/${bpId}/units/${unitId}/files/${fileId}/${fileVersionId}`)
}

export const reorderUnitFileVersions = payload => {
  const { bpId, unitId, fileId, versionsOrder } = payload
  return api.patch(`/building-passport/${bpId}/units/${unitId}/files/${fileId}/versions-order`, { versionsOrder })
}
