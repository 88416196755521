import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import styled, { css } from 'styled-components'
import ExpandablePanel from 'components/atoms/ExpandablePanel'
import Input from 'components/atoms/Input'
import { getCurrentUser, selectAccreditationTypes, selectPublicApiToken } from 'features/auth/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import NextButton from 'features/createBp/components/atoms/NextButton'
import {
  changePasswordRoutine, clearPublicApiTokenRoutine, deleteAccountRoutine,
  getAccreditationTypesRoutine,
  getPublicApiTokenRoutine,
  updateUserRoutine
} from 'features/auth/ducks/actions'
import { head, isEmpty, pathOr, propOr, remove, update, values } from 'ramda'
import ProfileInfo from 'features/profileSettings/components/ProfileInfo'
import { AccreditationForm } from 'features/auth/SignUp'
import { isNotNilOrEmpty } from 'utils/ramda'
import UserBox from 'features/profileSettings/components/UserBox'

const emptyPasswordValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: ''
}

const ProfileSettings = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const [passwordValues, setPasswordValues] = useState(emptyPasswordValues)
  const [accreditationsValid, setAccreditationsValid] = useState(false)
  const [accreditationsValues, setAccreditationsValues] = useState(
    isNotNilOrEmpty(currentUser.accreditations)
      ? currentUser.accreditations.map(acc => ({
        id: pathOr('', ['pivot', 'accreditationId'], acc),
        number: pathOr('', ['pivot', 'accreditationNumber'], acc),
      }))
      : [{
        id: '',
        number: ''
      }]
  )
  const [userPassword, setUserPassword] = useState('')
  const isFrs = currentUser.type === 'emergency_service'
  const accreditationTypes = useSelector(selectAccreditationTypes)
  const apiToken = useSelector(selectPublicApiToken)

  useEffect(() => {
    dispatch(getAccreditationTypesRoutine())

    return () => {
      dispatch(clearPublicApiTokenRoutine())
    }
  }, [])

  const accreditationOptions = useMemo(() => {
    return accreditationTypes
      ? [
        {
          label: '',
          value: ''
        },
        ...accreditationTypes.map(type => ({
          label: type.name,
          value: type.id
        }))
      ]
      : []
  }, [accreditationTypes])

  const handlePasswordValueChange = (name, value) =>
    setPasswordValues({ ...passwordValues, [name]: value })

  const clearValues = () => setPasswordValues(emptyPasswordValues)

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(changePasswordRoutine({ ...passwordValues, clearValues }))
  }

  const handleAccountDelete = useCallback(() => {
    dispatch(deleteAccountRoutine({ password: userPassword }))
  }, [userPassword])

  const hasEmptyValue = useMemo(() => {
    return values(passwordValues).some(e => isEmpty(e))
  }, [passwordValues])

  const handlePasswordChange = (_, value) => setUserPassword(value)

  const handleAccreditationChange = index => (name, value) => {
    const accreditation = accreditationsValues[index]
    const newValue = {
      ...accreditation,
      [name]: value
    }
    setAccreditationsValues(prev => update(index, newValue, prev))
  }

  const handleAccreditationRemove = index => () => {
    if (accreditationsValues.length > 1) {
      setAccreditationsValues(prev => remove(index, 1, prev))
    } else {
      setAccreditationsValues([{
          id: '',
          number: ''
        }]
      )
    }
  }

  const handleAccreditationAdd = () => {
    setAccreditationsValues(prev => ([
      ...prev,
      {
        id: '',
        number: ''
      }
    ]))
  }

  useEffect(() => {
    const length = accreditationsValues.length
    switch (true) {
      case length === 1:
        setAccreditationsValid(true)
        break
      case length > 1:
        setAccreditationsValid(
          accreditationsValues.every(item =>
            !isEmpty(item.id)
          )
        )
        break
      default:
        return () => {}
    }
  }, [accreditationsValues])

  const handleAccreditationsUpdate = e => {
    e.preventDefault()
    dispatch(updateUserRoutine({
      accreditations: isNotNilOrEmpty(head(accreditationsValues).id)
        ? accreditationsValues
        : []
    }))
  }

  const handleGenerateToken = () => {
    dispatch(getPublicApiTokenRoutine())
  }

  return (
    <ProfileContainer>
      <ContentWrapper>
        <PageHeader>My account</PageHeader>
        <Panels>
          <ExpandablePanel open title='Basic user data'>
            <UserBox />
          </ExpandablePanel>
          {!isFrs && <ExpandablePanel title='Accreditations / Qualifications'>
            <>
              <Text>
                Please add details of any professional accreditations or
                qualifications you have, along with corresponding membership
                numbers if appropriate. The quality and accuracy of the
                information you add to a Building Passport should be
                appropriate to the professional qualifications you hold.
              </Text>
              {
                accreditationsValues && (
                  <>
                    <InputDescriptionWrapper>
                      <InputDescription>
                        If you don&apos;t see your accreditation type on the list,
                        please <a href='mailto:info@buildingpassport.com'>contact the administrator.</a>
                      </InputDescription>
                    </InputDescriptionWrapper>
                    <AccreditationsFormsWrapper>
                      {
                        accreditationsValues.map((item, index) => {
                          return <AccreditationForm
                            index={index}
                            key={`accreditation-form-${index}`}
                            values={accreditationsValues[index]}
                            onChange={handleAccreditationChange}
                            options={accreditationOptions}
                            onRemove={handleAccreditationRemove}
                            withDelete={!isEmpty(head(accreditationsValues).id) || accreditationsValues.length > 1}
                          />
                        })
                      }
                    </AccreditationsFormsWrapper>
                    <AddAnotherTypeButton onClick={handleAccreditationAdd}>
                      + Add another accreditation
                    </AddAnotherTypeButton>
                    <NextButton
                      disabled={!accreditationsValid}
                      onClick={handleAccreditationsUpdate}
                    >
                      Save
                    </NextButton>
                  </>
                )
              }
            </>
          </ExpandablePanel>
          }
          <ExpandablePanel title='Change password'>
            <ChangePasswordDesc>
              Create a new password for your account using the form below.
            </ChangePasswordDesc>
            <Input
              label='Current password'
              value={passwordValues.currentPassword}
              name='currentPassword'
              onChange={handlePasswordValueChange}
              type='password'
            />
            <Input
              label='New password'
              value={passwordValues.newPassword}
              name='newPassword'
              onChange={handlePasswordValueChange}
              type='password'
              withPasswordHint
            />
            <Input
              label='Confirm new password'
              value={passwordValues.newPasswordConfirmation}
              name='newPasswordConfirmation'
              onChange={handlePasswordValueChange}
              type='password'
            />
            <NextButton
              onClick={handleSubmit}
              disabled={hasEmptyValue}
            >
              Change password
            </NextButton>
          </ExpandablePanel>
          {
            propOr(false, 'canIntegrateWithPublicApi', currentUser) && (
              <ExpandablePanel title='Public API key'>
                <Text>
                  You may be able to integrate your systems with Building Passport using our API. For documentation and
                  to discuss further please email us at <a href='mailto:app@buildingpassport.com'>app@buildingpassport.com</a>.
                  If you or your company is prepared for integration you can generate your API token using this button.
                </Text>
                {
                  isNotNilOrEmpty(apiToken) && (
                    <>
                      <TokenBox breakWord>
                        {apiToken}
                      </TokenBox>
                      <InputDescription margin='20px 0 0'>
                        {`Make note of your token as you won't be able to see it later.`}
                      </InputDescription>
                    </>
                  )
                }
                {
                  propOr(false, 'apiTokenExists', currentUser) && !isNotNilOrEmpty(apiToken) && (
                    <TokenBox>
                      Your API token has already been generated. You cannot see it, but you can replace it by generating a new token.
                    </TokenBox>
                  )
                }
                <ApiButtonWrapper>
                  <NextButton
                    onClick={handleGenerateToken}
                    disabled={isNotNilOrEmpty(apiToken)}
                  >
                    Generate API token
                  </NextButton>
                </ApiButtonWrapper>
              </ExpandablePanel>
            )
          }
          <ExpandablePanel title='Delete account'>
            <Text>
              When you confirm this operation, your account will be closed and you will no longer have access to the
              Building Passport system. In order to keep an accurate history and changelog of a building your name will
              still be visible against additions you have made to a Building Passport but your sensitive personal
              information (email, contact details, login details, profile history, IP addresses) will be anonymised.
            </Text>
            <Warning>
              WARNING! This operation cannot be undone.
            </Warning>
            <Input
              label='To confirm, enter your password'
              onChange={handlePasswordChange}
              value={userPassword}
              type='password'
            />
            <NextButton
              onClick={handleAccountDelete}
              disabled={isEmpty(userPassword)}
            >
              Delete account
            </NextButton>
          </ExpandablePanel>
        </Panels>
      </ContentWrapper>
      <DetailsWrapper>
        <ProfileInfo />
      </DetailsWrapper>
    </ProfileContainer>
  )
}

export default ProfileSettings

const ProfileContainer = styled.div`
  display: flex;
`

const DetailsWrapper = styled.div`
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`

const ContentWrapper = styled.div`
  width: 100%;
  padding-right: 300px;
`

const Panels = styled.div`
  max-width: 645px;
  margin: 20px auto 0;
`

const ChangePasswordDesc = styled.div`
  font-size: 15px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .7;
`

const Text = styled.div`
  font-size: 14px;
`

const Warning = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};
  margin: 20px 0;
  font-weight: bold;
`

const AccreditationsFormsWrapper = styled.div`
  margin-top: 10px;
`

const AddAnotherTypeButton = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
  margin: 10px 0 20px auto;

  ${({ disabled, theme }) => disabled && css`
    color: ${theme.colors.grey[600]};
    cursor: default;
  `}
`

const InputDescriptionWrapper = styled.div`
  margin-top: 15px;
`

const TokenBox = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  background-color: ${({ theme }) => theme.colors.grey[400]};
  border-radius: 4px;
  margin-top: 20px;

  ${({ breakWord }) => breakWord && css`
    word-break: break-all;
  `}
`

const ApiButtonWrapper = styled.div`
  margin-top: 20px;
`
