import { boolean, object, string } from 'yup'
import { validateField, validateValues } from 'utils/form'
import i18n from 'i18n'
import { isNotNilOrEmpty } from 'utils/ramda'

// eslint-disable-next-line
const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})')

// REGISTRATION
export const registrationSchema = object().shape({
  type: string().oneOf(
    ['user', 'emergency_service'],
    i18n.t('signUp.validation.wrongRole')
  ),
  email: string()
    .required(i18n.t('common.validation.required', { field: 'Email' }))
    .email(i18n.t('common.validation.incorrectEmail')),
  password: string()
    .required(i18n.t('common.validation.required', { field: 'Password' }))
    .matches(passwordRegex, i18n.t('common.validation.incorrectPassword')),
  firstName: string()
    .required(i18n.t('common.validation.required', { field: 'First name' }))
    .min(2, i18n.t('common.validation.length', { field: 'First name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    }),
  lastName: string()
    .required(i18n.t('common.validation.required', { field: 'Last name' }))
    .min(2, i18n.t('common.validation.length', { field: 'Last name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    }),
  company: string().test(
    'isFrs',
    'FRS station is required',
    function (value) {
      const { type } = this.parent
      if (type === 'emergency_service') {
        return isNotNilOrEmpty(value)
      } else {
        return true
      }
    }
  ),
  postalCode: string()
    .required(i18n.t('common.validation.required', { field: 'Postal code' })),
  city: string()
    .required(i18n.t('common.validation.required', { field: 'City name' })),
  street: string()
    .required(i18n.t('common.validation.required', { field: 'Address' })),
  disclaimer: boolean().oneOf(
    [true],
    i18n.t('common.validation.mandatory')
  ),
  updates: boolean(),
  reminders: boolean()
})
export const validateRegistrationField = values => validateField(registrationSchema, values)
export const validateRegistrationValues = validateValues(registrationSchema)

// UPDATE PROFILE INFO EXCHANGE USER
export const updateInfoExchangeUserSchema = object().shape({
  firstName: string()
    .required(i18n.t('common.validation.required', { field: 'First name' }))
    .min(2, i18n.t('common.validation.length', { field: 'First name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    }),
  lastName: string()
    .required(i18n.t('common.validation.required', { field: 'Last name' }))
    .min(2, i18n.t('common.validation.length', { field: 'Last name', length: '2' }))
    .matches(/^\D*$/, {
      message: i18n.t('common.validation.incorrectValue')
    }),
  postalCode: string()
    .required(i18n.t('common.validation.required', { field: 'Postal code' })),
  city: string()
    .required(i18n.t('common.validation.required', { field: 'City name' })),
  street: string()
    .required(i18n.t('common.validation.required', { field: 'Address' })),
})
export const validateUpdateField = values => validateField(updateInfoExchangeUserSchema, values)
export const validateUpdateValues = validateValues(updateInfoExchangeUserSchema)

// LOGIN
export const loginSchema = object().shape({
  email: string()
    .required(i18n.t('common.validation.required', { field: 'Email' }))
    .email(i18n.t('common.validation.incorrectEmail')),
  password: string().required('Password is required')
})
export const validateLoginField = values => validateField(loginSchema, values)
export const validateLoginValues = validateValues(loginSchema)

// REQUEST RESET PASSWORD
export const requestResetPasswordSchema = object().shape({
  email: string()
    .required(i18n.t('common.validation.required', { field: 'Email' }))
    .email(i18n.t('common.validation.incorrectEmail')),
})
export const validateRequestChangePasswordField = values => validateField(requestResetPasswordSchema, values)
export const validateRequestChangePasswordValues = validateValues(requestResetPasswordSchema)

// RESET PASSWORD
const resetPasswordSchema = object().shape({
  password: string()
    .required(i18n.t('common.validation.required', { field: 'Password' }))
    .matches(passwordRegex, i18n.t('common.validation.incorrectPassword')),
  passwordConfirmation: string().test(
    'password-match',
    i18n.t('passwordReset.validation.notEqual'),
    function (value) {
      const { password } = this.parent
      return password === value
    }
  )
})
export const validateChangePasswordField = values => validateField(resetPasswordSchema, values)
export const validateChangePasswordValues = validateValues(resetPasswordSchema)

const floorPlanUploadSchema = object().shape({
  producedBy: string()
    .required('Author is required'),
  creationDate: string()
    .required('Creation date is required')
})

export const validateFloorPlanUploadField = values => validateField(floorPlanUploadSchema, values)
export const validateFloorPlanUploadValues = validateValues(floorPlanUploadSchema)

const accreditationsSchema = object().shape({
  id: string(),
  number: string().nullable()
})

export const validateAccreditationField = values => validateField(accreditationsSchema, values)
