import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as FolderIcon } from 'assets/images/folder .svg'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { isNil, pathOr, propOr } from 'ramda'
import { AddButton } from 'features/bpDetails/BpDetails'
import { useSelector } from 'react-redux'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import { getFileTypeName } from 'utils/bpData'
import { sortFilesByExpirationStatus } from 'utils/files'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp } from 'utils/user'
import { selectSelectedUnitFiles } from 'ducks/units/selectors'
import { getFileTypes, selectBpFilesList } from 'ducks/files/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import File from 'features/bpDetails/Components/File'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'

const FileCategories = ({ setRecentFilesVisible, unit, canEditUnit }) => {
  const [currentFolder, setCurrentFolder] = useState(null)
  const [allFolders, setAllFolders] = useState([])
  const hasSelectedFolder = isNotNil(currentFolder)
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)
  const bpFiles = useSelector(selectBpFilesList)
  const unitFiles = useSelector(selectSelectedUnitFiles)
  const bp = useSelector(getSelectedBp)
  const currentUser = useSelector(getCurrentUser)
  const isUnit = isNotNilOrEmpty(unit)

  const files = useMemo(() => {
    return isUnit ? unitFiles : bpFiles
  }, [bpFiles, unitFiles, isUnit])

  const canEdit = isUnit ? canEditUnit : canEditBp(currentUser, bp)

  useEffect(() => {
    isNotNil(files) && isNotNil(fileCategories) &&
      setAllFolders([
        ...fileCategories.map(category => {
          return {
            ...category,
            files: files.filter(file => pathOr('', ['fileType', 'fileCategoryId'], file) === category.id)
          }
        })
      ])
  }, [files, fileCategories])

  const handleCategoryChange = category => {
    const folder = allFolders.find(folder => folder.id === category.id)
    setCurrentFolder(folder)
  }

  useEffect(() => {
    if (allFolders && currentFolder) {
      handleCategoryChange(currentFolder)
    }
  }, [allFolders])

  useEffect(() => {
    !isUnit && setRecentFilesVisible(isNil(currentFolder))
  }, [currentFolder])

  return (
    <SectionWrapper withMargin={isUnit || isNil(currentFolder)}>
      <HeaderWrapper>
        <HeaderTitleWrapper>
          <SectionHeader
            open={isNotNil(currentFolder)}
            onClick={() => setCurrentFolder(null)}
          >
            File category
          </SectionHeader>
          {
            isNotNil(currentFolder) && (
              <>
                <BreadcrumbsSign>&gt;</BreadcrumbsSign>
                <CurrentFolderName>{currentFolder.name}</CurrentFolderName>
              </>
            )
          }
        </HeaderTitleWrapper>
        {canEdit && (
          <AddMultipleFilesModal
            unit={unit}
            initialCategory={currentFolder?.id || ''}
          >
            <AddButton>+ Add new files</AddButton>
          </AddMultipleFilesModal>
        )}
      </HeaderWrapper>
      <CategoriesWrapper>
        {
          hasSelectedFolder && (
            <CurrentCategoryTitle>
              {currentFolder.name}
            </CurrentCategoryTitle>
          )
        }
        <FoldersList>
          {
            !hasSelectedFolder && allFolders &&
            allFolders.map((category) => {
              const filesCount = propOr([], 'files', category).length
                return (
                  <FolderWrapper
                    key={category.id}
                    onClick={filesCount > 0 ? () => handleCategoryChange(category) : () => {}}
                    disabled={filesCount === 0}
                  >
                    <FolderIcon />
                    <FolderNameWrapper>
                      <FolderName>{category.name}</FolderName>
                      <FilesCounter>({filesCount})</FilesCounter>
                    </FolderNameWrapper>
                  </FolderWrapper>
                )
              })
          }
        </FoldersList>
        {
          sortFilesByExpirationStatus(propOr([], 'files', currentFolder)).map(file =>
            <File
              isUnit={isUnit}
              key={`file-${Math.random() * 99}`}
              canEditUnit={canEditUnit}
              file={file}
              type={getFileTypeName(file.fileType.id, fileTypes)}
              isSharedWithUnits={file.isSharedWithUnits}
            />
          )
        }
      </CategoriesWrapper>
      </SectionWrapper>
  )
}

export default FileCategories

const SectionWrapper = styled.div`
  margin-top: ${({ withMargin }) => withMargin ? '30px' : 0};
`

const FoldersList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`

const FolderWrapper = styled.div`
  box-sizing: border-box;
  height: 60px;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #dfe4f6;
  margin: 5px;
  cursor: pointer;
  transition: all .3s;
  border-radius: 4px;
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ disabled }) => disabled && css`
    cursor: default;
    opacity: .3;

    &:hover {
      border-color: #dfe4f6;
    }
  `}
`

const FolderName = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-left: 15px;
`

const CategoriesWrapper = styled.div`
  margin: 20px -5px 0;
  display: flex;
  flex-wrap: wrap;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SectionHeader = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .3px;

  ${({ open }) => open && css`
    cursor: pointer;
  `}
`

const BreadcrumbsSign = styled(ChevronRightIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  font-size: 18px !important;
  margin: 0 5px;
`

const CurrentFolderName = styled(SectionHeader)`
  color: ${({ theme }) => theme.colors.primary};
`

const FilesCounter = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.grey[700]};
`

const CurrentCategoryTitle = styled.div`
  margin: 10px 0 20px;
`

const FolderNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
