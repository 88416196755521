export const hasMultipleUnits = key => {
  return key !== 'residential.dwelling.sole_unit'
}

export const shouldCreateMultipleUnits = key => {
  return key !== 'residential.dwelling.sole_unit'
}

export const isResidentialUnit = type => {
  const residentialUnitTypes = [
    'residential.dwelling.sole_unit',
    'residential.dwelling',
    'residential.institutional',
    'residential.other',
    'residential'
  ]
  return residentialUnitTypes.includes(type)
}

export const canEditUnit = roles => {
  return roles
    ? roles.some(role => ['agent', 'owner', 'responsible_person'].includes(role))
    : false
}
