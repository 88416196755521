import { createSelector } from 'reselect'
import { propOr } from 'ramda'

const getAccessRequestState = state => state.accessRequest

export const selectSearchedAccessRequestBps = createSelector(
  getAccessRequestState,
  propOr([], 'bps')
)

export const selectAccessRequests = createSelector(
  getAccessRequestState,
  propOr([], 'requests')
)

export const selectPendingRequests = createSelector(
  getAccessRequestState,
  propOr([], 'pending')
)
