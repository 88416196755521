import React from 'react'
import styled from 'styled-components'

const EmptyUnitsListState = () => {
  return (
    <PanelWrapper>
      <Text>
        You have no access to any Unit.
      </Text>
    </PanelWrapper>
  )
}

export default EmptyUnitsListState

const PanelWrapper = styled.div`
  box-sizing: border-box;
  max-width: 600px;
  width: 100%;
  margin: 50px auto 0;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 22px;
  text-align: center;
  
  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`
