import emptyAuthState from 'features/auth/ducks/state'
import {
  clearAuthUserRoutine,
  fetchAuthUserRoutine,
  getAccreditationTypesRoutine,
  getInvitationRoutine,
  loginUserRoutine,
  logoutUserRoutine,
  registerUserRoutine,
  updateUserRoutine,
  clearLatestInvitationRoutine, getPublicApiTokenRoutine, clearPublicApiTokenRoutine
} from 'features/auth/ducks/actions'
import { getUnitInvitationRoutine } from 'ducks/units/actions'

export default (state = emptyAuthState, action) => {
  switch (action.type) {
    case loginUserRoutine.REQUEST:
    case registerUserRoutine.REQUEST:
    case fetchAuthUserRoutine.REQUEST:
    case logoutUserRoutine.REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case registerUserRoutine.SUCCESS:
    case loginUserRoutine.SUCCESS:
    case fetchAuthUserRoutine.SUCCESS:
    case updateUserRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false
      }
    case logoutUserRoutine.SUCCESS:
    case clearAuthUserRoutine.SUCCESS:
      return {
        ...state,
        user: {},
        isLoading: false
      }
    case registerUserRoutine.FAILURE:
    case loginUserRoutine.FAILURE:
    case fetchAuthUserRoutine.FAILURE:
    case logoutUserRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case getAccreditationTypesRoutine.SUCCESS:
      return {
        ...state,
        accreditations: action.payload
      }
    case getInvitationRoutine.SUCCESS:
    case getUnitInvitationRoutine.SUCCESS:
      return {
        ...state,
        currentInvitation: action.payload
      }
    case clearLatestInvitationRoutine.SUCCESS: {
      return {
        ...state,
        currentInvitation: {}
      }
    }
    case getPublicApiTokenRoutine.SUCCESS: {
      return {
        ...state,
        publicApiToken: action.payload
      }
    }
    case clearPublicApiTokenRoutine.SUCCESS: {
      return {
        ...state,
        publicApiToken: null
      }
    }
    default:
      return state
  }
}
