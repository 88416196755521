/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNilOrEmpty } from 'utils/ramda'
import FileInput from 'components/atoms/FileInput'
import moment from 'moment'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Checkbox from 'components/atoms/Checkbox'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { isUploading } from 'ducks/files/selectors'
import { addFileVersionRoutine } from 'ducks/files/actions'
import Select from 'components/atoms/Select'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { pathOr, propOr, reverse } from 'ramda'

const emptyFileValues = {
  previousVersion: null,
  floorNumbers: [],
  producedBy: null,
  creationDate: null,
  fileType: null,
  fileCategory: 'all',
  legalOwner: null,
  fileTitle: '',
  expirationDate: null,
  expirationReminderDate: null,
  approvedBy: null,
  segment: null,
  dateOfIssue: null,
  uploadedImage: null
}

const AddNewFileVersionModal = ({ file, open, handleClose, unit }) => {
  const [modalValues, setValues] = useState({})
  const [additionalOpen, setAdditionalOpen] = useState(false)
  const [wantsReminder, setWantsReminder] = useState(false)
  const [isHistorical, setIsHistorical] = useState(false)
  const isLoading = useSelector(isUploading)
  const dispatch = useDispatch()
  const bp = useSelector(getSelectedBp)
  const isUnit = isNotNilOrEmpty(unit)

  const isFloorPlan = useMemo(() => {
    const type = pathOr('', ['fileType', 'name'], file)
    return type === 'FRS floor plan' || type === 'Building plan'
  }, [file])

  const handleValueChange = (name, value) => {
    if (name === 'expirationDate') {
      const subtractSixMonths = moment(value).subtract(6, 'month')
      const isBeforeToday = subtractSixMonths.isBefore(moment())
      const expirationReminderDate = isBeforeToday
        ? moment().add(1, 'day').format('YYYY-MM-DD')
        : subtractSixMonths.format('YYYY-MM-DD')

      setValues(prev => ({
        ...prev,
        [name]: value,
        expirationReminderDate
      }))
    } else {
      setValues(prev => ({ ...prev, [name]: value }))
    }
  }

  const toggleAdditionalInfo = () => setAdditionalOpen(prev => !prev)

  useEffect(() => {
    if (!open) {
      setValues(emptyFileValues)
      setWantsReminder(false)
    }
  }, [open])

  const isValid = useMemo(() => {
    return isNotNilOrEmpty(modalValues.uploadedImage)
  }, [modalValues])

  const handleExpirationDateClear = () => {
    handleValueChange('expirationDate', '')
    handleValueChange('expirationReminderDate', '')
    setWantsReminder(false)
  }

  const handleReminderDateClear = () => {
    handleValueChange('expirationReminderDate', '')
  }

  const handleCreationDateClear = () => {
    handleValueChange('creationDate', '')
  }

  const handleDateOfIssueClear = () => {
    handleValueChange('dateOfIssue', '')
  }

  const handleVersionTriggerChange = (_, value) => setIsHistorical(value)

  const versionsOptions = useMemo(() => {
    const versionsCount = propOr([], 'versions', file).length
    const arr = Array.from(Array(versionsCount).keys())
    const newArray = arr.map(num => num + 1)
    return versionsCount === 0 ? null : reverse(newArray).slice(1).map(num => ({
      value: num,
      label: String(num)
    }))
  }, [file, open])

  useEffect(() => {
    isNotNilOrEmpty(versionsOptions) && handleValueChange('previousVersion', pathOr('', [0, 'value'], versionsOptions))
  }, [versionsOptions])

  const latestVersion = useMemo(() => {
    return propOr([], 'versions', file).length
  }, [file])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(addFileVersionRoutine({
      ...modalValues,
      previousVersion: isHistorical
        ? latestVersion === 1
          ? 0
          : modalValues.previousVersion
        : latestVersion,
      bpId: bp.id,
      unitId: propOr('', 'id', unit),
      fileId: file.id,
      objectID: isUnit ? propOr('', 'id', unit) : bp.id,
      type: isUnit ? 'building_unit_passport_file' : 'building_passport_file',
      isFloorPlan,
      callback: handleClose
    }))
  }

  return (
    <Modal
      id='new-file-version-modal'
      title='File information'
      open={open}
      onClose={handleClose}
    >
      <FormWrapper onSubmit={handleSubmit}>
        <>
          <Select
            key='version-previousVersion-trigger'
            label='Version'
            value={isHistorical}
            name='versionTrigger'
            onChange={handleVersionTriggerChange}
            options={[
              { label: 'Latest', value: false },
              { label: 'Historic', value: true }
            ]}
          />
          {
            isHistorical && latestVersion !== 1 && isNotNilOrEmpty(versionsOptions) && (
              <Select
                key='version-previousVersion'
                label='Select previous version number'
                value={modalValues.previousVersion}
                name='previousVersion'
                onChange={handleValueChange}
                options={versionsOptions}
              />
            )
          }
          <Input
            key='version-expirationDate'
            label='Expiry date'
            value={modalValues.expirationDate}
            name='expirationDate'
            type='date'
            optional
            onChange={handleValueChange}
            additionalLabel={
              isNotNilOrEmpty(modalValues.expirationDate)
                ? (
                  <ClearTrigger onClick={handleExpirationDateClear}>
                    clear
                  </ClearTrigger>
                )
                : null
            }
          />
          <Checkbox
            key='version-reminder'
            label='File expiry reminder'
            name='wantsReminder'
            disabled={!isNotNilOrEmpty(modalValues.expirationDate)}
            value={wantsReminder}
            onChange={() => setWantsReminder(prev => !prev)}
          />
          <InputDescription margin='10px 0 20px'>
            Get reminded via email on a specific date that this file is due to expire.
            Every user with access to the Building Passport will receive this email.
            Any user has the ability to turn off this type of reminder in the settings section of the Building
            Passport.
          </InputDescription>
          {
            wantsReminder && (
              <Input
                key='version-reminderDate'
                label='Expiration reminder date'
                value={modalValues.expirationReminderDate}
                name='expirationReminderDate'
                type='date'
                onChange={handleValueChange}
                inputProps={{ min: moment().add(1, 'day').format('YYYY-MM-DD') }}
                additionalLabel={
                  isNotNilOrEmpty(modalValues.expirationReminderDate)
                    ? (
                      <ClearTrigger onClick={handleReminderDateClear}>
                        clear
                      </ClearTrigger>
                    )
                    : null
                }
              />
            )
          }
          <AdditionalInfoSection>
            <CollapseTitle
              onClick={toggleAdditionalInfo}
            >
              <div>
                Additional file information <span>(optional)</span>
              </div>
              <StyledExpandIcon open={additionalOpen} />
            </CollapseTitle>
            <Collapse in={additionalOpen}>
              <AdditionalInfoDescription>
                In order to achieve the highest quality of Building Passport,
                the following additional file information can be added.
              </AdditionalInfoDescription>
              <Input
                label='Legal Owner'
                key='version-legalOwner'
                value={modalValues.legalOwner}
                name='legalOwner'
                onChange={handleValueChange}
                placeholder='Name of the company'
              />
              <Input
                label='Creator'
                key='version-creator'
                placeholder='Person who created the document'
                value={modalValues.producedBy}
                name='producedBy'
                onChange={handleValueChange}
              />
              <Input
                type='date'
                key='version-dateOfIssue'
                label='Date of issue'
                value={modalValues.dateOfIssue}
                name='dateOfIssue'
                onChange={handleValueChange}
                additionalLabel={
                  isNotNilOrEmpty(modalValues.dateOfIssue)
                    ? (
                      <ClearTrigger onClick={handleDateOfIssueClear}>
                        clear
                      </ClearTrigger>
                    )
                    : null
                }
              />
              <Input
                type='date'
                label='Creation date'
                key='version-creationDate'
                value={modalValues.creationDate}
                name='creationDate'
                onChange={handleValueChange}
                inputProps={{ max: moment().format('YYYY-MM-DD') }}
                additionalLabel={
                  isNotNilOrEmpty(modalValues.creationDate)
                    ? (
                      <ClearTrigger onClick={handleCreationDateClear}>
                        clear
                      </ClearTrigger>
                    )
                    : null
                }
              />
              <Input
                label='Approved by'
                key='version-approvedBy'
                value={modalValues.approvedBy}
                name='approvedBy'
                onChange={handleValueChange}
                placeholder='Person who approved the document'
              />
              <Input
                label='Segment / sheet'
                key='version-sheet'
                value={modalValues.segment}
                name='segment'
                onChange={handleValueChange}
                placeholder='Enter if applicable'
              />
            </Collapse>
          </AdditionalInfoSection>
          {
            isFloorPlan ? (
              <FileInput
                onChange={file => handleValueChange('uploadedImage', file)}
                isUploaded={isNotNilOrEmpty(modalValues.uploadedImage)}
                isLoading={isLoading}
                accept='.pdf,.dwg'
              />
            ) : (
              <FileInput
                onChange={file => handleValueChange('uploadedImage', file)}
                isUploaded={isNotNilOrEmpty(modalValues.uploadedImage)}
                isLoading={isLoading}
                accept='.pdf,.dwg,.doc,.docx,.jpg,.jpeg,.txt,.png,.gif,.svg,.xlsx,.xls,.xlsm'
              />
            )
          }
          <ButtonWrapper>
            <UploadButton
              key='version-upload-button'
              type='submit'
              disabled={isLoading || !isValid}
            >
              Upload file
            </UploadButton>
          </ButtonWrapper>
        </>
      </FormWrapper>
    </Modal>
  )
}

export default AddNewFileVersionModal

const FormWrapper = styled.form`
  max-width: 320px;
  margin: 0 auto;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const UploadButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.secondary};
  height: 30px;
  width: 80%;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ disabled, theme }) => disabled && css`
    border-color: ${theme.colors.grey[700]};
    color: ${theme.colors.grey[700]};

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.grey[700]};
    }
  `}
`

const AdditionalInfoSection = styled.div`
  margin: 20px 0 10px;
`

const CollapseTitle = styled.div`
  padding: 15px 0;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.grey[700]};
  }
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grey[700]};
  transition: all .3s;
  transform: rotate(${({ open }) => open ? '180deg' : '0'});
`

const AdditionalInfoDescription = styled.div`
  margin-bottom: 25px;
`

const ClearTrigger = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
`
