import React, { useMemo, useState } from 'react'
import { AddButton, MainContentPanel, SectionHeader, SectionHeaderWrapper } from 'features/bpDetails/BpDetails'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { isEmpty, pathOr } from 'ramda'
import { getFileTypeName } from 'utils/bpData'
import FileCategories from 'features/bpDetails/Components/files/FileCategories'
import FilesSearchInput from 'features/bpDetails/Components/FilesSearchInput'
import { sortFilesByExpirationStatus } from 'utils/files'
import { canEditBp } from 'utils/user'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getFileTypes, selectBpFilesList } from 'ducks/files/selectors'
import File from 'features/bpDetails/Components/File'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'
import EmptyFileState from 'features/bpList/Components/EmptyFileState'
import { redirect } from 'utils/paths'
import FileTagsPanel from 'features/bpDetails/Components/files/FileTagsPanel'
import { selectIsFetchingFiles } from 'ducks/loaderSelectors'
import { CircularProgress } from '@material-ui/core'

const OtherFiles = () => {
  const files = useSelector(selectBpFilesList)
  const fileTypes = useSelector(getFileTypes)
  const [recentFilesVisible, setRecentFilesVisible] = useState(true)
  const [foundFiles, setFoundFiles] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const bp = useSelector(getSelectedBp)
  const currentUser = useSelector(getCurrentUser)
  const isFetchingFiles = useSelector(selectIsFetchingFiles)

  const canEdit = canEditBp(currentUser, bp)

  const recentFiles = useMemo(() => {
    if (files) {
      const sortedFiles = files
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      return sortedFiles.slice(0, 3)
    }
  }, [files])

  const handleSearchFile = files => {
    setFoundFiles(files)
  }

  return (
    <OtherFilesWrapper>
      <MainContentPanel>
        <PanelHeaderWrapper>
          <TitleWrapper>
          <div>Documents</div>
            {recentFilesVisible && <AddButton onClick={() => redirect(`/bp-list/${bp.id}/files/`)}>View all</AddButton>}
          </TitleWrapper>
          <SearchInputWrapper>
            <FilesSearchInput onChange={handleSearchFile} setIsSearching={setIsSearching} />
          </SearchInputWrapper>
        </PanelHeaderWrapper>
        {recentFilesVisible && <FileTagsPanel onTagClick={tagId => {
          redirect(`/bp-list/${bp.id}/files/`, { selectedTag: tagId })
        }} />}
        {
          isSearching && (
            <>
              <SectionHeaderWrapper>
                <SectionHeader>Search result</SectionHeader>
              </SectionHeaderWrapper>
              <FilesWrapper>
                {
                  isEmpty(foundFiles)
                    ? <div>No files found</div>
                    : sortFilesByExpirationStatus(foundFiles).map(file =>
                      <File
                        key={`file-${Math.random() * 99}`}
                        file={file}
                        type={getFileTypeName(pathOr('', ['fileType', 'id'], file), fileTypes)}
                        isSharedWithUnits={file.isSharedWithUnits}
                      />)
                }
              </FilesWrapper>
            </>
          )
        }
        {
          !isSearching && (
            <>
              {
                recentFilesVisible && (
                  <>
                    <SectionHeaderWrapper>
                      <SectionHeader>Recent files</SectionHeader>
                      {canEdit && (
                        <AddMultipleFilesModal>
                          <AddButton>+ Add new files</AddButton>
                        </AddMultipleFilesModal>
                      )}
                    </SectionHeaderWrapper>
                    <FilesWrapper>
                      {
                        isFetchingFiles
                          ? (
                            <FilesFetchingBox>
                              <CircularProgress />
                              Fetching files...
                            </FilesFetchingBox>
                          )
                          : isEmpty(recentFiles)
                            ? <EmptyFileState
                              text='Add your first file to this Building Passport'
                              buttonText='+ Add files'
                              hideButton={!canEdit}
                            />
                            : recentFiles.map(file =>
                              <File
                                key={`file-${Math.random() * 99}`}
                                file={file}
                                type={getFileTypeName(pathOr('', ['fileType', 'id'], file), fileTypes)}
                                isSharedWithUnits={file.isSharedWithUnits}
                              />)
                      }
                    </FilesWrapper>
                  </>
                )
              }
              <FileCategories setRecentFilesVisible={setRecentFilesVisible} />
            </>
          )
        }
      </MainContentPanel>
    </OtherFilesWrapper>
  )
}

export default OtherFiles

const OtherFilesWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

const FilesWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100%;
`

const PanelHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 14px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const SearchInputWrapper = styled.div`
  width: 231px;
`

const FilesFetchingBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`
