import React, { useEffect, useState } from 'react'
import { propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import styled from 'styled-components'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { isNotNil } from 'utils/ramda'
import Select from 'components/atoms/Select'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const EditIssues = ({ closeModal }) => {
  const bp = useSelector(getSelectedBp)
  const dispatch = useDispatch()
  const [valid, setValid] = useState(false)
  const [values, setValues] = useState({
    hoardings: propOr(null, 'hoardings', bp),
    structuralDefects: propOr(null, 'structuralDefects', bp),
    asbestos: propOr(null, 'asbestos', bp),
    floodRisk: propOr(null, 'floodRisk', bp),
    claddingIssue: propOr(null, 'claddingIssue', bp),
    hazardousSubstances: propOr(null, 'hazardousSubstances', bp),
    id: propOr(null, 'id', bp)
  })

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleUpdate = () => {
    dispatch(updateBpRoutine({ ...values, isEdit: true }))
    closeModal()
  }

  useEffect(() => {
    setValid([
      'hoardings',
      'structuralDefects',
      'asbestos',
      'floodRisk',
      'claddingIssue',
      'hazardousSubstances'
    ].every(issue => isNotNil(values[issue])))
  }, [values])

  return (
    <FormWrapper>
      <Form>
        <Select
          placeholder='Select'
          value={values.hoardings}
          label='Hoarding'
          name='hoardings'
          onChange={handleOnChange}
          noMargin
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <DescriptionWrapper>
          <InputDescription>
            Is there (or is there likely to be) unnecessary clutter that poses a fire hazard and/or disrupts the usability
            of any area of the building?
          </InputDescription>
        </DescriptionWrapper>
        <Select
          placeholder='Select'
          value={values.structuralDefects}
          label='Structural defects'
          name='structuralDefects'
          noMargin
          onChange={handleOnChange}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <DescriptionWrapper>
          <InputDescription>
            Is there any structural issue that could pose a hazard to life?
          </InputDescription>
        </DescriptionWrapper>
        <Select
          placeholder='Select'
          value={values.asbestos}
          label='Asbestos'
          name='asbestos'
          noMargin
          onChange={handleOnChange}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <DescriptionWrapper>
          <InputDescription>
            Is asbestos present in/on the building?
          </InputDescription>
        </DescriptionWrapper>
        <Select
          placeholder='Select'
          value={values.floodRisk}
          label='Flood risk'
          name='floodRisk'
          noMargin
          onChange={handleOnChange}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <DescriptionWrapper>
          <InputDescription>
            Is the property in an area of flood risk?
          </InputDescription>
        </DescriptionWrapper>
        <Select
          placeholder='Select'
          value={values.claddingIssue}
          label='Cladding issue'
          name='claddingIssue'
          onChange={handleOnChange}
          noMargin
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <DescriptionWrapper>
          <InputDescription>
            Is there any issue with the exterior cladding of the building?
          </InputDescription>
        </DescriptionWrapper>
        <Select
          placeholder='Select'
          value={values.hazardousSubstances}
          label='Hazardous substances'
          name='hazardousSubstances'
          onChange={handleOnChange}
          noMargin
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <DescriptionWrapper>
          <InputDescription>
            Are there any hazardous substances (flammable, toxic, caustic, explosive etc.) currently on site or within the
            property?
          </InputDescription>
        </DescriptionWrapper>
        <NextButton
          onClick={handleUpdate}
          disabled={!valid}
        >
          Save changes
        </NextButton>
      </Form>
    </FormWrapper>
  )
}

export default EditIssues

const FormWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`

const Form = styled.form`
  box-sizing: border-box;
  width: 100%;
`

const DescriptionWrapper = styled.div`
  margin: 3px 0 20px;
`
