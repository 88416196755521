import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Route, Router, Switch } from 'react-router-dom'
import { PATHS, PRIVATE_PATHS } from 'utils/paths'
import Login from 'features/auth/Login'
import SignUp from 'features/auth/SignUp'
import Dashboard from 'features/dashboard/Dashboard'
import TopBar from 'features/navigation/TopBar'
import Navigation from 'features/navigation/Navigation'
import EmailConfirmation from 'features/auth/EmailConfirmation'
import HomePage from 'features/homePage/HomePage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  fetchAuthUserRoutine,
  logoutUserRoutine
} from 'features/auth/ducks/actions'
import {
  getCurrentUserRole,
  isUserLoggedIn,
  getCurrentUser
} from 'features/auth/ducks/selectors'
import PrivateRoute from 'components/PrivateRoute'
import history from 'utils/history.js'
import RequestResetPassword from 'features/auth/RequestResetPassword'
import PasswordReset from 'features/auth/PasswordReset'
import ShortlistedBps from 'features/shortlistedBps/ShortlistedBps'
import BpDetails from 'features/bpDetails/BpDetails'
import ProfileSettings from 'features/profileSettings/ProfileSettings'
import Cart from 'features/cart/Cart'
import NotFound, { RedirectToNotFound } from 'components/NotFound'
import CreateBp from 'features/createBp/CreateBp'
import Drafts from 'features/drafts/Drafts'
import PaymentSuccess from 'features/cart/components/PaymentSuccess'
import BillingList from 'features/billingList/BillingList'
import Terms from 'features/terms/terms'
import AccessRequest from 'features/AccessRequest/AccessRequest'
import storageService from 'services/LocalStorageService'
import { KEYS } from 'utils/localStorage'
import packageJson from '../package.json'
import GlobalLoader from 'components/GlobalLoader'
import { selectGlobalLoader } from 'ducks/loaderSelectors'
import Analytics from 'features/analytics/Analytics'
import AddTemplate from 'features/analytics/AddTemplate'
import EditTemplates from 'features/analytics/EditTemplates'
import SignUpConfirmation from 'features/auth/components/SignUpConfirmation'
import MainList from 'features/bpList/MainList'
import { pdfjs } from 'react-pdf'
import { pathOr, propOr } from 'ramda'
import UpdateInfoExchangeAccount from 'features/auth/UpdateInfoExchangeAccount'
import { isNotNilOrEmpty } from 'utils/ramda'
import FileUploadProcess from 'components/FileUploadProcess'
import { getFileTypesRoutine } from 'ducks/files/actions'
import { getFileCategoriesRoutine } from 'ducks/dictionaries/actions'
import { getBuildingTypesRoutine } from 'features/bpList/ducks/actions'

function App ({ userRole, isLoggedIn }) {
  const dispatch = useDispatch()
  const [versionChecked, setVersionChecked] = useState(false)
  const isLoading = useSelector(selectGlobalLoader)
  const [dictionariesFetched, setDictionariesFetched] = useState(false)

  useEffect(() => {
    dispatch(fetchAuthUserRoutine())
    const appVersion = storageService.get(KEYS.version) || ''
    const packageJsonVersion = packageJson.version
    if (appVersion !== packageJsonVersion) {
      storageService.set(KEYS.version, packageJsonVersion)
      dispatch(
        logoutUserRoutine({
          callback: () => window.location.reload(true)
        })
      )
    } else {
      setVersionChecked(true)
    }
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  useEffect(() => {
    if (isLoggedIn && !dictionariesFetched) {
      dispatch(getFileTypesRoutine())
      dispatch(getFileCategoriesRoutine())
      dispatch(getBuildingTypesRoutine())
      setDictionariesFetched(true)
    } else if (!isLoggedIn) {
      setDictionariesFetched(false)
    }
  }, [isLoggedIn])

  const currentUser = useSelector(getCurrentUser)
  const currentUserSource = pathOr('', ['source', 'value'], currentUser)
  const isFromInfoExchange = currentUserSource === 'info_exchange'
  const hasCurrentUserFirstName = isNotNilOrEmpty(
    propOr('', 'firstName', currentUser)
  )

  return (
    <AppContainer>
      {isLoading && <GlobalLoader />}
      {!versionChecked && <LoadingScreen />}
      <Router history={history}>
        <TopBar isLoggedIn={isLoggedIn} />
        {isLoggedIn && <Navigation />}
        <Content isLoggedIn={isLoggedIn}>
          {isFromInfoExchange && !hasCurrentUserFirstName && (
            <FakeModal>
              <UpdateInfoExchangeAccount userInfo={currentUser} />
            </FakeModal>
          )}
          <Switch>
            <Route exact path={PATHS.home} component={HomePage} />
            <Route path={PATHS.login} component={Login} />
            <Route exact path={PATHS.signup} component={SignUp} />
            <Route
              exact
              path={PATHS.signupConfirm}
              component={SignUpConfirmation}
            />
            <Route
              path={PATHS.emailVerification}
              component={EmailConfirmation}
            />
            <Route
              path={PATHS.requestPasswordReset}
              component={RequestResetPassword}
            />
            <Route path={PATHS.passwordReset} component={PasswordReset} />
            <PrivateRoute
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.dashboard}
              component={Dashboard}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user', 'emergency_service']}
              path={PRIVATE_PATHS.bpList}
              component={MainList}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.drafts}
              component={Drafts}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user', 'emergency_service']}
              path={PRIVATE_PATHS.savedPassports}
              component={ShortlistedBps}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.accessRequests}
              component={AccessRequest}
            />
            <PrivateRoute
              userRole={userRole}
              allowedRoles={['user', 'emergency_service']}
              path={PRIVATE_PATHS.savedDetails}
              component={BpDetails}
            />
            <PrivateRoute
              userRole={userRole}
              allowedRoles={['user', 'emergency_service']}
              path={PRIVATE_PATHS.bpDetails}
              component={BpDetails}
            />
            <PrivateRoute
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.draftDetails}
              component={BpDetails}
            />

            <PrivateRoute
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.createBp}
              component={CreateBp}
            />
            <PrivateRoute
              userRole={userRole}
              allowedRoles={['user', 'emergency_service']}
              path={PRIVATE_PATHS.profileSettings}
              component={ProfileSettings}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.cart}
              component={Cart}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.terms}
              component={Terms}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.paymentSuccess}
              component={PaymentSuccess}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.billingList}
              component={BillingList}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.paymentError}
              component={Cart}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.analytics}
              component={Analytics}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.addAnalytics}
              component={AddTemplate}
            />
            <PrivateRoute
              exact
              userRole={userRole}
              allowedRoles={['user']}
              path={PRIVATE_PATHS.editAnalytics}
              component={EditTemplates}
            />
            <Route path={PATHS.notFound} component={NotFound} />
            <Route path='*' component={RedirectToNotFound} />
          </Switch>
        </Content>
        <FileUploadProcess />
      </Router>
      <ToastContainer />
    </AppContainer>
  )
}

const mapStateToProps = state => ({
  isLoggedIn: isUserLoggedIn(state),
  userRole: getCurrentUserRole(state)
})

export default connect(mapStateToProps)(App)

const AppContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
`

const Content = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: ${({ isLoggedIn }) =>
    isLoggedIn ? '40px 50px 40px 136px' : '40px 50px'};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  height: calc(100vh - 88px);
  overflow-y: auto;
`

const LoadingScreen = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const FakeModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`
