import moment from 'moment'

export const formatDate = date => date ? moment(date).format('DD.MM.YYYY') : '---'
export const formatDateWithTime = date => moment(date).format('DD.MM.YYYY HH:mm:ss')
export const calendarDate = date => moment(date).format('YYYY-MM-DD')

export const formatDateWithMonthName = date => moment(date).format('Do MMMM YYYY')

export const dateFilterOptions = [
  {
    label: 'One week',
    value: `${calendarDate(moment().subtract('1', 'week'))}|${calendarDate(moment())}`
  },
  {
    label: 'One month',
    value: `${calendarDate(moment().subtract('1', 'month'))}|${calendarDate(moment())}`
  },
  {
    label: '3 months',
    value: `${calendarDate(moment().subtract('3', 'month'))}|${calendarDate(moment())}`
  },
  {
    label: 'One year',
    value: `${calendarDate(moment().subtract('1', 'year'))}|${calendarDate(moment())}`
  }
]
