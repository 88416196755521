import React, { useState } from 'react'
import styled from 'styled-components'
import DetailsUnitTableItem from 'features/bpDetails/Components/units/DetailsUnitTableItem'
import Modal from 'components/atoms/Modal'
import { propOr } from 'ramda'
import UnitForm from 'features/createBp/components/atoms/UnitForm'
import { useDispatch, useSelector } from 'react-redux'
import { removeBpUnitRoutine, updateBpUnitRoutine } from 'ducks/units/actions'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const DetailsUnitTable = ({ units, section, isSoleUnit, isSoleResidential, redirectPath }) => {
  const bp = useSelector(getSelectedBp)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [closeDropdownTrigger, setCloseDropdownTrigger] = useState(0)
  const dispatch = useDispatch()
  const hasFullAccess = bp.hasAccessToBuildingPassport

  const handleEditModalOpen = unit => e => {
    e.stopPropagation()
    e.preventDefault()
    setSelectedUnit(unit)
    setEditModalOpen(true)
  }
  const handleEditModalClose = () => {
    setSelectedUnit(null)
    setEditModalOpen(false)
    setCloseDropdownTrigger(Math.random())
  }

  const handleDeleteModalOpen = unit => e => {
    e.stopPropagation()
    e.preventDefault()
    setSelectedUnit(unit)
    setDeleteModalOpen(true)
  }
  const handleDeleteModalClose = () => {
    setSelectedUnit(null)
    setDeleteModalOpen(false)
    setCloseDropdownTrigger(Math.random())
  }

  const handleRemoveUnit = values => () => {
    dispatch(removeBpUnitRoutine(values))
    handleDeleteModalClose()
  }

  const handleUpdateUnit = values => {
    dispatch(updateBpUnitRoutine({
      ...values,
      bpId: bp.id
    }))
  }

  return (
    <>
      <TableWrapper>
        <thead>
          <th>Unit name</th>
          <th>UPRN</th>
          <th>Floor(s)</th>
          <th>Area (sqm)</th>
          <th>Owner</th>
          <th />
        </thead>
        <tbody>
          {units && units.map(unit => (
            <DetailsUnitTableItem
              redirectPath={redirectPath}
              key={`unit-${unit.id}`}
              unit={unit}
              openEditModal={handleEditModalOpen}
              openDeleteModal={handleDeleteModalOpen}
              closeDropdownTrigger={closeDropdownTrigger}
              isSoleUnit={isSoleUnit}
              isSoleResidential={isSoleResidential}
              unitsCount={units.length}
              hasFullAccess={hasFullAccess}
            />
          ))}
        </tbody>
      </TableWrapper>
      <Modal
        open={editModalOpen}
        title='Edit unit'
        onClose={handleEditModalClose}
      >
        <UnitForm
          unit={selectedUnit}
          closeEditModal={handleEditModalClose}
          onSubmit={handleUpdateUnit}
          bp={bp}
          buildingType={section.buildingType}
          hideAssignments
        />
      </Modal>
      <Modal
        open={deleteModalOpen}
        title='Delete unit'
        onClose={handleDeleteModalClose}
        withCancel
        onCancel={handleDeleteModalClose}
        withSubmit
        onSubmit={handleRemoveUnit({ bpId: bp.id, unitId: propOr('', 'id', selectedUnit) })}
      >
        Removing <ModalUnitName>{propOr('', 'name', selectedUnit)}</ModalUnitName> will delete this unit
        and remove any links that were made to files within the Building Passport.
        Any files specific to this unit will also be deleted. Are you sure you wish to continue?
      </Modal>
    </>
  )
}

export default DetailsUnitTable

const TableWrapper = styled.table`
  width: 100%;

  th {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 11px;
    opacity: .5;
    text-transform: uppercase;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    position: relative;
  }

  td {
    box-sizing: border-box;
    padding: 0 10px;
    height: 56px;
    font-size: 14px;
  }

  tbody > tr {
    color: ${({ theme }) => theme.colors.grey[800]};
    cursor: pointer;
    transition: all .3s;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey[400]};
    }

    & > td:first-of-type {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.haiti};
    }
  }
`

const ModalUnitName = styled.span`
  font-weight: bold;
`
