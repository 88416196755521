import React, { useEffect, useMemo } from 'react'
import DetailsSection from 'features/bpDetails/Components/BpInfo/DetailsSection'
import styled from 'styled-components'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { useTranslation } from 'react-i18next'
import { getBpPrices, getBuildingTypes } from 'features/bpList/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { toCamelCase } from 'js-convert-case'
import { head, isNil, pathOr, propOr } from 'ramda'
import { getOwner, handleBooleanValue } from 'utils/hooks/values'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { getBpsPricesRoutine } from 'features/bpList/ducks/actions'
import { getCreatedBpId } from 'features/createBp/ducks/selectors'
import { getBuildingTypeValueType } from 'utils/bpData'
import { hidePhoneNumber } from 'utils/user'

const Summary = ({ bp }) => {
  const { t } = useTranslation()
  const buildingTypes = useSelector(getBuildingTypes)
  const prices = useSelector(getBpPrices)
  const dispatch = useDispatch()
  const bpId = useSelector(getCreatedBpId)

  useEffect(() => {
    if (bpId && bp) {
      setTimeout(() => {
        dispatch(getBpsPricesRoutine({
          buildingPassportsIds: [bpId]
        }))
      }, 300)
    }
  }, [bpId, bp])

  const currentPrice = useMemo(() => {
    return pathOr(null, ['summaryPrices', 'discountedNetPrice', 'formatted'], prices)
  }, [prices])

  const isDwellingSoleUnit = pathOr('', ['buildingTypes', 0, 'type'], bp) === 'residential.dwelling.sole_unit'

  return (
    <div>
      <Title>
        Building passport
      </Title>
      <Price>
        {isNotNil(currentPrice) && (
          <div>
            {currentPrice.replace(' ', '')}
            <span>{isDwellingSoleUnit ? '/one-time' : '/year'}</span>
          </div>
        )}
      </Price>
      <BasicInfoWrapper>
        <IconBox>
          <FileIcon />
        </IconBox>
        <BpName>
          {propOr('Building Name', 'name', bp)}
        </BpName>
        <UprnCode>
          UPRN: {propOr('Not yet allocated', 'uprn', bp)}
        </UprnCode>
      </BasicInfoWrapper>
      <DetailsSection
        label={t('bpDetails.labels.address')}
        disabled={isNil(bp.city) || isNil(bp.street) || isNil(bp.postalCode)}
      >
        <AddressWrapper>
          {propOr('', 'street', bp)},&nbsp;
          {propOr('', 'city', bp)},&nbsp;
          {propOr('', 'postalCode', bp)}
        </AddressWrapper>
      </DetailsSection>
      <DetailsSection
        label={t('bpDetails.labels.basicInfo')}
        disabled={!isNotNilOrEmpty(bp.buildingTypes)}
      >
        {
          bp.buildingTypes && bp.buildingTypes.map(el => {
            const type = propOr(
              { label: '', unit: '' },
              toCamelCase(el.type),
              buildingTypes)
            const value = type.unit === 'unit' ? el.units : el.area
            return (
              <div key={el.type}>
                <BuildingType>{type.label}</BuildingType>
                <ListItem>
                  <span>{getBuildingTypeValueType(el.type).replace(' (sqm)', '')}</span>
                  <div>{value}&nbsp;{type.unit}</div>
                </ListItem>
              </div>
            )
          })
        }
      </DetailsSection>
      <DetailsSection
        label={t('bpDetails.labels.owner')}
        disabled={isNil(pathOr(null, ['owners', 0], bp))}
        expandable
      >
        {
          isNotNil(pathOr(null, ['owners', 0], bp)) && (
            <OwnerWrapper>
              <Bolded>{getOwner(bp.owner)}</Bolded>
              <Lighter>+44 {hidePhoneNumber(pathOr('', ['owners', 0, 'phoneNumber'], bp))}</Lighter>
              <Lighter>{pathOr('', ['owners', 0, 'email'], bp)}</Lighter>
            </OwnerWrapper>
          )
        }
      </DetailsSection>
      <DetailsSection
        label={t('bpDetails.labels.responsiblePerson')}
        disabled={isNil(pathOr(null, ['responsiblePersons', 0], bp))}
        expandable
      >
        {
          isNotNil(pathOr(null, ['responsiblePersons', 0], bp)) && (
            <OwnerWrapper>
              <Bolded>{getOwner(head(bp.responsiblePersons))}</Bolded>
              <Lighter>+44 {hidePhoneNumber(pathOr('', ['responsiblePersons', 0, 'phoneNumber'], bp))}</Lighter>
              <Lighter>{pathOr('', ['responsiblePersons', 0, 'email'], bp)}</Lighter>
            </OwnerWrapper>
          )
        }
      </DetailsSection>
      <DetailsSection
        label={t('bpDetails.labels.floors')}
        disabled={isNil(bp.floorsNumber) && isNil(bp.undergroundFloorsNumber)}
        expandable
      >
        {
          (isNotNil(bp.floorsNumber) && bp.floorsNumber > 0) &&
          <ListItem>
            <span>{t('bpDetails.labels.floorsNumber')}</span>
            {t('bpDetails.floors', { count: bp.floorsNumber })}
          </ListItem>
        }
        {
          (isNotNil(bp.undergroundFloorsNumber) && bp.undergroundFloorsNumber > 0) &&
          <ListItem>
            <span>{t('bpDetails.labels.undergroundFloorsNumber')}</span>
            {t('bpDetails.floors', { count: bp.undergroundFloorsNumber })}
          </ListItem>
        }
        {
          isNotNil(bp.groundFloor) &&
          <ListItem>
            <span>Ground Floor</span>
            {bp.groundFloor ? 'Yes' : 'No'}
          </ListItem>
        }
        {
          isNotNil(bp.lowerGroundFloor) &&
          <ListItem>
            <span>Lower Ground Floor</span>
            {bp.lowerGroundFloor ? 'Yes' : 'No'}
          </ListItem>
        }
      </DetailsSection>
      <DetailsSection
        label='residents'
        disabled={isNil(bp.occupied)}
        expandable
      >
        <ListItem>
          <span>Occupied</span>
          {bp.occupied ? 'Yes' : 'No'}
        </ListItem>
        {
          isNotNilOrEmpty(bp.expectedBuildingOccupantsAmount) &&
            <ListItem>
              <span>Expected occupants</span>
              {bp.expectedBuildingOccupantsAmount}
            </ListItem>
        }
        {
          bp.occupied && (
            <>
              <ListItem>
                <span>Disabled occupants</span>
                {bp.disabledOccupantsAmount > 0 ? bp.disabledOccupantsAmount : 'No'}
              </ListItem>
              <ListItem>
                <span>Elderly occupants</span>
                {bp.elderlyOccupantsAmount > 0 ? bp.elderlyOccupantsAmount : 'No'}
              </ListItem>
            </>
          )
        }
      </DetailsSection>
      <DetailsSection
        label={t('bpDetails.labels.issues')}
        disabled={
          isNil(bp.hoardings) &&
          isNil(bp.structuralDefects) &&
          isNil(bp.hazardousSubstances) &&
          isNil(bp.asbestos) &&
          isNil(bp.floodRisk) &&
          isNil(bp.claddingIssue)
        }
        expandable
      >
        {
          isNotNil(bp.hoardings) && (
            <ListItem>
              <span>{t('bpDetails.labels.hoarding')}</span>
              {handleBooleanValue(bp.hoardings)}
            </ListItem>
          )
        }
        {
          isNotNil(bp.structuralDefects) && (
            <ListItem>
              <span>{t('bpDetails.labels.structuralDefects')}</span>
              {handleBooleanValue(bp.structuralDefects)}
            </ListItem>
          )
        }
        {
          isNotNil(bp.hazardousSubstances) && (
            <ListItem>
              <span>{t('bpDetails.labels.hazardousSubstances')}</span>
              {handleBooleanValue(bp.hazardousSubstances)}
            </ListItem>
          )
        }
        {
          isNotNil(bp.asbestos) && (
            <ListItem>
              <span>{t('bpDetails.labels.asbestos')}</span>
              {handleBooleanValue(bp.asbestos)}
            </ListItem>
          )
        }
        {
          isNotNil(bp.floodRisk) && (
            <ListItem>
              <span>{t('bpDetails.labels.floodRisk')}</span>
              {handleBooleanValue(bp.floodRisk)}
            </ListItem>
          )
        }
        {
          isNotNil(bp.claddingIssue) && (
            <ListItem>
              <span>{t('bpDetails.labels.claddingIssue')}</span>
              {handleBooleanValue(bp.claddingIssue)}
            </ListItem>
          )
        }
      </DetailsSection>
    </div>
  )
}

export default Summary

const Title = styled.div`
  font-size: 14px;
  text-transform: uppercase;
`

const BasicInfoWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 30px;
`

const BpName = styled.div`
  font-size: 19px;
  color: ${({ theme }) => theme.colors.haiti};
`

const UprnCode = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[700]};
`

const IconBox = styled.div`
  box-sizing: border-box;
  width: 82px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbede9;
  border-radius: 4px;
`

const FileIcon = styled(DescriptionOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 35px !important;
`

const Bolded = styled.div`
  font-weight: bold;
`

const Lighter = styled.div`
  opacity: .7;
`

const OwnerWrapper = styled.div`
  div:not(:last-of-type) {
    margin-bottom: 4px;
  }
`

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 13px;
  margin-bottom: 7px;

  span {
    opacity: .7;
  }
`

const BuildingType = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 3px;
`

const AddressWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.haiti};
  justify-content: space-between;
  opacity: .7;
`

const Price = styled.div`
  font-weight: bold;
  font-size: 22px;
  text-align: right;
  width: 100%;
  height: 25px;

  span {
    font-size: 16px;
    font-weight: normal;
  }
`
