import { createRoutine } from 'redux-saga-routines'
import { put, call, fork, takeLatest } from '@redux-saga/core/effects'
import * as notificationService from 'services/notificationService'

export const fetchAllNotificationsRoutine = createRoutine('GET_ALL_NOTIFICATIONS')
export const fetchUnreadNotificationsCounterRoutine = createRoutine('FETCH_UNREAD_NOTIFICATIONS_COUNTER')
export const fetchUnseenNotificationsCounterRoutine = createRoutine('FETCH_UNSEEN_NOTIFICATIONS_COUNTER')
export const markAllNotificationsAsSeenRoutine = createRoutine('MARK_ALL_NOTIFICATIONS_AS_SEEN')
export const markAllNotificationsAsReadRoutine = createRoutine('MARK_ALL_NOTIFICATIONS_AS_READ')
export const markNotificationAsReadRoutine = createRoutine('MARK_NOTIFICATION_AS_READ')

function * getAllNotifications () {
  yield put(fetchAllNotificationsRoutine.request())
  try {
    const result = yield call(notificationService.fetchAllNotifications)
    yield put(fetchAllNotificationsRoutine.success(result.data.data))
  } catch (err) {
    console.error(err)
    yield put(fetchAllNotificationsRoutine.failure())
  }
}

function * fetchUnreadNotificationsCounter () {
  yield put(fetchUnreadNotificationsCounterRoutine.request())
  try {
    const result = yield call(notificationService.fetchUnreadNotiicationsCounter)
    yield put(fetchUnreadNotificationsCounterRoutine.success(result.data.data.all))
  } catch (err) {
    console.error(err)
    yield put(fetchUnreadNotificationsCounterRoutine.failure())
  }
}

function * fetchUnseenNotificationsCounter () {
  yield put(fetchUnseenNotificationsCounterRoutine.request())
  try {
    const result = yield call(notificationService.fetchUnseenNotiicationsCounter)
    yield put(fetchUnseenNotificationsCounterRoutine.success(result.data.data.all))
  } catch (err) {
    console.error(err)
    yield put(fetchUnseenNotificationsCounterRoutine.failure())
  }
}

function * markAllNotificationsAsSeen () {
  yield put(markAllNotificationsAsSeenRoutine.request())
  try {
    yield call(notificationService.markAllNotificationsAsSeen)
    yield put(markAllNotificationsAsSeenRoutine.success())
  } catch (err) {
    console.error(err)
    yield put(markAllNotificationsAsSeenRoutine.failure())
  }
}

function * markAllNotificationsAsRead () {
  yield put(markAllNotificationsAsReadRoutine.request())
  try {
    yield call(notificationService.markAllNotificationsAsRead)
    yield put(markAllNotificationsAsReadRoutine.success())
  } catch (err) {
    console.error(err)
    yield put(markAllNotificationsAsReadRoutine.failure())
  }
}

function * markNotificationAsRead ({ payload }) {
  yield put(markNotificationAsReadRoutine.request())
  try {
    yield call(notificationService.markSingleNotificationsAsRead, payload)
    yield put(markNotificationAsReadRoutine.success())
  } catch (err) {
    console.error(err)
    yield put(markNotificationAsReadRoutine.failure())
  }
}

export function * getAllNotificationsWatcher () {
  yield takeLatest(fetchAllNotificationsRoutine.TRIGGER, getAllNotifications)
}

export function * fetchUnreadNotificationsCounterWatcher () {
  yield takeLatest(fetchUnreadNotificationsCounterRoutine.TRIGGER, fetchUnreadNotificationsCounter)
}

export function * fetchUnseenNotificationsCounterWatcher () {
  yield takeLatest(fetchUnseenNotificationsCounterRoutine.TRIGGER, fetchUnseenNotificationsCounter)
}

export function * markAllNotificationsAsSeenWatcher () {
  yield takeLatest(markAllNotificationsAsSeenRoutine.TRIGGER, markAllNotificationsAsSeen)
}

export function * markAllNotificationsAsReadWatcher () {
  yield takeLatest(markAllNotificationsAsReadRoutine.TRIGGER, markAllNotificationsAsRead)
}

export function * markNotificationAsReadWatcher () {
  yield takeLatest(markNotificationAsReadRoutine.TRIGGER, markNotificationAsRead)
}

export const notificationSagas = [
  fork(getAllNotificationsWatcher),
  fork(fetchUnreadNotificationsCounterWatcher),
  fork(fetchUnseenNotificationsCounterWatcher),
  fork(markAllNotificationsAsSeenWatcher),
  fork(markAllNotificationsAsReadWatcher),
  fork(markNotificationAsReadWatcher)
]
