import { flatten, join, pathOr, pipe, values } from 'ramda'

export const extractMessageFromErrorResponse = pathOr('Something went wrong', [
  'response',
  'data',
  'message'
])

export const getApiErrors = error => {
  const errors = pipe(
    pathOr({}, ['response', 'data', 'errors']),
    values,
    flatten,
    join('\n')
  )(error)

  // if there is only one error, it must be handled with previous helper
  return errors || extractMessageFromErrorResponse(error)
}
