import { createSelector } from 'reselect'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

const getFiles = state => state.files

export const getCurrentBpFiles = createSelector(
  getFiles,
  state => propOr([], 'currentBpFiles', state)
    .filter(file => !isNotNilOrEmpty(file.deletedAt))
)

export const selectBpFilesList = createSelector(
  getFiles,
  state => propOr([], 'bpFilesList', state)
    .filter(file => !isNotNilOrEmpty(file.deletedAt))
)

export const isUploading = createSelector(
  getFiles,
  propOr(false, 'isUploading')
)

export const getFileTypes = createSelector(
  getFiles,
  propOr({}, 'fileTypes')
)

export const selectCurrentUploadProcess = createSelector(
  getFiles,
  propOr(null, 'uploadProcess')
)

export const selectCurrentUploadProcessFetchDetails = createSelector(
  getFiles,
  propOr(null, 'uploadProcessFetchDetails')
)

export const selectFileTagsForBp = createSelector(
  getFiles,
  propOr([], 'fileTags')
)
