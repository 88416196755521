import { createSelector } from 'reselect'
import { pathOr, propOr } from 'ramda'

const getUnitsState = state => state.units

export const selectSelectedUnit = createSelector(
  getUnitsState,
  propOr(null, 'selectedUnit')
)

export const selectSelectedUnitFiles = createSelector(
  getUnitsState,
  pathOr([], ['selectedUnit', 'files'])
)

export const getSharedUnitsList = createSelector(
  getUnitsState,
  propOr([], 'sharedUnitsList')
)

export const selectUnitForEditForm = createSelector(
  getUnitsState,
  propOr(null, 'editUnit')
)

export const selectUserUnits = createSelector(
  getUnitsState,
  propOr([], 'userUnits')
)

export const getUnitsPagesCount = createSelector(
  getUnitsState,
  propOr([], 'totalPages')
)

export const getUnitsCount = createSelector(
  getUnitsState,
  propOr([], 'totalFound')
)

export const selectBpUnits = createSelector(
  getUnitsState,
  propOr([], 'units')
)

export const selectUnitFloorPlans = createSelector(
  getUnitsState,
  propOr([], 'floorplans')
)

export const selectNoticeBoard = createSelector(
  getUnitsState,
  propOr([], 'notices')
)

export const selectUnitInvitationsList = createSelector(
  getUnitsState,
  propOr([], 'invitationsList')
)

export const selectUnitRequestsList = createSelector(
  getUnitsState,
  propOr([], 'accessRequests')
)

export const selectUserRelationToUnit = createSelector(
  getUnitsState,
  pathOr([], ['userRelation', 'roles'])
)
