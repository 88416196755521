import React, { useEffect, useState } from 'react'
import PanelFloor from 'features/createBp/components/atoms/PanelFloor'
import { useTranslation } from 'react-i18next'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { omit, propOr } from 'ramda'
import CountButton from 'components/atoms/CountButton'
import styled, { css } from 'styled-components'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import Checkbox from 'components/atoms/Checkbox'
import { getCreatedBpId } from 'features/createBp/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import BuildingsImg from 'assets/images/buildings.svg'

const FloorNumber = ({
  completed,
  open,
  setActiveStep,
  setBp,
  bp,
  setCompleted,
  setStarted,
  setActiveTab
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const bpId = useSelector(getCreatedBpId)
  const [sectionValues, setSectionValues] = useState({
    hasBasement: propOr(0, 'undergroundFloorsNumber', bp) > 0,
    floorsNumber: propOr(0, 'floorsNumber', bp),
    groundFloor: propOr(true, 'groundFloor', bp),
    undergroundFloorsNumber: propOr(1, 'undergroundFloorsNumber', bp),
    lowerGroundFloor: propOr(false, 'loweGroundFloor', bp)
  })
  const [valid, setIsValid] = useState(false)

  const handleOnChange = (name, value) => {
    setSectionValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    setStarted('floors')
  }, [])

  const handleSkip = () => {
    setActiveStep('units')
    setActiveTab('units')
  }

  const setNextSection = () => {
    handleSkip()
    setBp(prev => ({
      ...prev,
      ...sectionValues,
      undergroundFloorsNumber: sectionValues.hasBasement ? sectionValues.undergroundFloorsNumber : 0,
    }))
    setCompleted('floorNumber')
    dispatch(updateBpRoutine({
      ...omit(['hasBasement'], sectionValues),
      id: bpId,
      undergroundFloorsNumber: sectionValues.hasBasement ? sectionValues.undergroundFloorsNumber : 0,
      isWizard: true
    }))
  }

  useEffect(() => {
    setIsValid(
      sectionValues.floorsNumber > 0 ||
      sectionValues.groundFloor ||
      sectionValues.lowerGroundFloor ||
      sectionValues.hasBasement
    )
  }, [sectionValues])

  useEffect(() => {
    setSectionValues({
      hasBasement: propOr(0, 'undergroundFloorsNumber', bp) > 0,
      floorsNumber: propOr(0, 'floorsNumber', bp),
      groundFloor: propOr(true, 'groundFloor', bp),
      undergroundFloorsNumber: propOr(0, 'undergroundFloorsNumber', bp),
      lowerGroundFloor: propOr(false, 'lowerGroundFloor', bp)
    })
  }, [bp])

  return (
    <PanelFloor
      open={open}
      title='Floor number'
      completed={completed}
      step='floor-number'
      setActiveStep={setActiveStep}
    >
      <SectionWithBuildings>
      <FieldWrapper>
        <CountButton
          value={sectionValues.floorsNumber}
          label='Number of floors (above ground floor)'
          name='floorsNumber'
          inputKey='floorsNumber'
          onChange={handleOnChange}
        />
        <InputDescription>
          If single-storey (e.g.&nbsp;bungalow), enter 0.
        </InputDescription>
        <FieldSpacer/>
        <CheckboxWrapper>
          <Checkbox
            value={sectionValues.groundFloor}
            label='Ground Floor'
            name='groundFloor'
            onChange={handleOnChange}
          />
        </CheckboxWrapper>
      </FieldWrapper>
        </SectionWithBuildings>
      <SectionWithoutBuildings>
        <FieldWrapper>
          <CheckboxWrapper>
            <Checkbox
              value={sectionValues.lowerGroundFloor}
              label='Lower Ground Floor'
              name='lowerGroundFloor'
              onChange={handleOnChange}
            />
          </CheckboxWrapper>
          <CheckboxWrapper style={{ margin: '5px 0' }}>
            <Checkbox
              value={sectionValues.hasBasement}
              label='Subterranean Storeys'
              name='hasBasement'
              onChange={handleOnChange}
            />
          </CheckboxWrapper>
            <SubFormWrapper open={sectionValues.hasBasement}>
              <CountButton
                min={1}
                value={sectionValues.undergroundFloorsNumber}
                label='Subterranean Storeys'
                name='undergroundFloorsNumber'
                inputKey='undergroundFloorsNumber'
                onChange={handleOnChange}
              />
              <InputDescription>
                Enter the number of below-ground storeys (excluding
                Lower Ground).
              </InputDescription>
            </SubFormWrapper>
        </FieldWrapper>
      </SectionWithoutBuildings>
      <NextButtonWrapper>
        <NextButton
          onClick={setNextSection}
          disabled={!valid}
        >
          {completed ? 'Save' : t('createBp.labels.next')}
        </NextButton>
      </NextButtonWrapper>
      <SkipButton onClick={handleSkip}>Skip floors</SkipButton>
    </PanelFloor>
  )
}

export default FloorNumber

const CheckboxWrapper = styled.div`
  margin: 15px 0;
`

const SubFormWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  padding: 20px 5px 20px 0;
  overflow: hidden;
  height: 0;
  opacity: 0;

  ${({ open }) => open && css`
    padding-top: 20px;
    opacity: 1;
    height: auto;
  `}
`

const SectionWithBuildings = styled.div`
  padding-top: 20px;
  border-bottom: 4px solid #ebeef9;
  background-image: url(${BuildingsImg});
  background-position: right bottom;
  background-repeat: no-repeat;
`

const SectionWithoutBuildings = styled.div`
  background-color: #f9fafe;
  padding-top: 1px;
  padding-bottom: 15px;
`

const FieldWrapper = styled.div`
  width: 298px;
  margin-left: 50px;
`

const FieldSpacer = styled.div`
  width: 100%;
  height: 57px;
`

const NextButtonWrapper = styled.div`
  background-color: #fff;
  padding-top: 30px;
`

const SkipButton = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  text-decoration: underline;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
