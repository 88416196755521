import React from 'react'
import styled, { css } from 'styled-components'

const EmptyState = ({ padding, buttonText, text, onClick, hideButton }) => {
  return (
    <EmptyStateWrapper padding={padding}>
      {text}
      {!hideButton && <EmptyStateButton onClick={onClick}>
        {buttonText}
      </EmptyStateButton>}
    </EmptyStateWrapper>
  )
}

export default EmptyState

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 19px;
  padding: 10px 0 50px;
  
  ${({ padding }) => padding && css`
    padding: ${padding}
  `}
`

const EmptyStateButton = styled.button`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.haiti};
  border: 1px solid transparent;
  transition: all .3s;
  padding: 10px 25px;
  margin-top: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.haiti};
    border-color: ${({ theme }) => theme.colors.haiti};
  }
`
