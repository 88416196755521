import React from 'react'
import styled from 'styled-components'
import BuildingInformation from 'features/createBp/components/steps/BuildingInformation'
import BuildingDetails from 'features/createBp/components/steps/BuildingDetails'
import Relationship from 'features/createBp/components/steps/Relationship'
import FloorNumber from 'features/createBp/components/steps/FloorNumber'
import Residents from 'features/createBp/components/steps/Residents'
import Issues from 'features/createBp/components/steps/Issues'
import Reminders from 'features/createBp/components/steps/Reminders'
import Units from 'features/createBp/components/steps/Units'

const CreateBpForm = ({
  completed,
  activeTab,
  activeStep,
  setActiveStep,
  setActiveTab,
  setCompleted,
  setBp,
  bp,
  setStarted,
  isDraft
}) => {
  return (
    <CreteBpForm>
      {
        activeTab === 'basic' && (
          <>
            <BuildingInformation
              open={activeStep === 'building-info'}
              setActiveStep={setActiveStep}
              setBp={setBp}
              bp={bp}
              setCompleted={setCompleted}
              completed={completed.buildingInfo}
            />
            <BuildingDetails
              open={activeStep === 'building-details'}
              setActiveStep={setActiveStep}
              setActiveTab={setActiveTab}
              setBp={setBp}
              bp={bp}
              setCompleted={setCompleted}
              completed={completed.buildingDetails}
              isDraft={isDraft}

            />
            <Relationship
              open={activeStep === 'relationship'}
              setActiveStep={setActiveStep}
              setBp={setBp}
              bp={bp}
              setCompleted={setCompleted}
              completed={completed.relationship}
              setActiveTab={setActiveTab}
            />
          </>
        )
      }
      {
        activeTab === 'floors' && (
          <FloorNumber
            open={activeStep === 'floor-number'}
            setActiveStep={setActiveStep}
            setActiveTab={setActiveTab}
            setBp={setBp}
            bp={bp}
            setCompleted={setCompleted}
            completed={completed.floorNumber}
            setStarted={setStarted}
            isDraft={isDraft}
          />
        )
      }
      {
        activeTab === 'units' && (
          <Units
            open={activeStep === 'units'}
            setActiveStep={setActiveStep}
            setActiveTab={setActiveTab}
            setBp={setBp}
            bp={bp}
            setCompleted={setCompleted}
            completed={completed.units}
            setStarted={setStarted}
            isDraft={isDraft}
          />
        )
      }
      {
        activeTab === 'details' && (
         <>
           <Residents
             open={activeStep === 'residents'}
             setActiveStep={setActiveStep}
             setBp={setBp}
             bp={bp}
             setCompleted={setCompleted}
             completed={completed.residents}
             setStarted={setStarted}
           />
           <Issues
             open={activeStep === 'issues'}
             setBp={setBp}
             bp={bp}
             setActiveStep={setActiveStep}
             setCompleted={setCompleted}
             completed={completed.issues}
           />
           <Reminders
             open={activeStep === 'reminders'}
             setBp={setBp}
             bp={bp}
             setActiveStep={setActiveStep}
             setCompleted={setCompleted}
             completed={completed.reminders}
             isDraft={isDraft}
           />
         </>
        )
      }
    </CreteBpForm>
  )
}

export default CreateBpForm

const CreteBpForm = styled.form`
  width: 100%;
`
