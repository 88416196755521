import React, { useMemo } from 'react'
import RevokedBox from 'features/accessControl/components/RevokedBox'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { useSelector } from 'react-redux'
import { propOr } from "ramda"

const RevokedList = ({ unit }) => {
  const bp = useSelector(getSelectedBp)
  const revokedBpUsers = useMemo(() => propOr([], 'revokedUsers', bp), [bp])
  const revokedUnitUsers = useMemo(() => propOr([], 'revokedUsers', unit), [unit])

  const users = useMemo(() => {
    return isNotNilOrEmpty(unit) ? revokedUnitUsers : revokedBpUsers
  }, [bp, unit])

  return (
    <>
      {
        isNotNilOrEmpty(users)
          ? users.map(user => (
              <RevokedBox key={`revoked-user-${user.id}`} user={user} unit={unit} />
            ))
          : <EmptyState>Revoked accesses list is empty</EmptyState>
      }
    </>
  )
}

export default RevokedList

const EmptyState = styled.div`
  box-sizing: border-box;
  display: flex;
  min-height: 83px;
  align-items: center;
`
