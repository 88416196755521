import React from 'react'
import ReactDOM from 'react-dom'
import GlobalStyles from 'theme/globalStyles'
import theme from 'theme/theme'
import { ThemeProvider } from 'styled-components'
import * as serviceWorker from './serviceWorker'
import ReduxProvider from 'providers/ReduxProvider'
import App from './App'
import './i18n'
import 'assets/fonts/fonts.css'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ReduxProvider>
        <App />
      </ReduxProvider>
    </ThemeProvider>
  </DndProvider>,
document.getElementById('root')
)

serviceWorker.unregister()
