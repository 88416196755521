import React from 'react'
import styled from 'styled-components'
import { PRIVATE_PATHS, redirect } from 'utils/paths'

const DashboardEmptyState = ({ style }) => {
  return (
    <PanelWrapper style={style}>
      <Text>
        Create your first building passport
      </Text>
      <CreateBpButton
        onClick={() => redirect(PRIVATE_PATHS.createBp)}
      >
        + Create new Building Passport
      </CreateBpButton>
      <RequestWrapper>
        or <RequestButton onClick={() => redirect(PRIVATE_PATHS.accessRequests)}>Search for an existing Building Passport </RequestButton>
        you wish to access
      </RequestWrapper>
    </PanelWrapper>
  )
}

export default DashboardEmptyState

const PanelWrapper = styled.div`
  box-sizing: border-box;
  max-width: 600px;
  width: 100%;
  margin: 50px auto 0;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
`

const CreateBpButton = styled.button`
  outline: 0;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  border: 3px solid ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 0 20px rgba(41, 152, 163, 0.31);
  border-radius: 20px;
  min-width: 200px;
  height: 40px;
  transition: all .3s;
  cursor: pointer;
  font-size: 16px;
  padding: 2px 10px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 22px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`

const RequestWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`

const RequestButton = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
  cursor: pointer;
`
