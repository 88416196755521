import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import { head } from 'ramda'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import { convertBytes } from 'utils/files'
import CircularProgress from '@material-ui/core/CircularProgress'

const FileInput = ({ onChange, isUploaded, isLoading, accept }) => {
  const [file, setFile] = useState({})

  const onDrop = useCallback(acceptedFiles => {
    onChange(head(acceptedFiles))
    setFile(head(acceptedFiles))
  }, [])

  const options = {
    onDrop
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    accept
      ? {
          ...options,
          accept
        }
      : options
  )

  return (
    <FileInputWrapper {...getRootProps()}>
      {
        isLoading
          ? <CircularProgress />
          : (
            <>
              <input {...getInputProps()} />
              {
                isUploaded
                  ? (
                    <UploadedFileWrapper>
                      <FileIcon />
                      <DetailsWrapper>
                        <FileName>
                          {file.name}
                        </FileName>
                        <FileSize>
                          {convertBytes(file.size)}
                        </FileSize>
                      </DetailsWrapper>
                    </UploadedFileWrapper>
                  )
                  : (
                    <EmptyStateWrapper>
                      <CloudUploadOutlinedIcon />
                      {
                        isDragActive
                          ? <p>Drop file here...</p>
                          : <div>
                              <p>Drop file here, or click to select file</p>
                              <p>({accept.replace(/,/g, ', ')})</p>
                            </div>
                      }
                    </EmptyStateWrapper>
                  )
              }
            </>
          )
      }
    </FileInputWrapper>
  )
}

export default FileInput

const FileInputWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  border: 1px dashed #4da1ff;
  border-radius: 4px;
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4da1ff;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  cursor: pointer;
  
  &:focus {
    outline: none;
  }
`

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  p {
    margin-left: 10px;
    font-size: 12px;
    text-align: center;
  }
`

const UploadedFileWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  
  svg {
    min-width: 30px;
    min-height: 30px;
  }
`

const FileName = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  margin-left: 15px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FileSize = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[700]};
  margin-left: 15px;
`

const DetailsWrapper = styled.div`
  box-sizing: border-box;
  max-width: 85%;
`
