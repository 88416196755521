import React, { useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import styled, { css } from 'styled-components'
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg'
import { isEmpty } from 'ramda'

const AutocompleteInput = ({
  options,
  onChange,
  name,
  noMarginBottom,
  validate,
  label,
  value: initialValue,
  disabled,
  clearValueTrigger,
  additionalLabel,
  optional,
  withDelete,
  onDelete
}) => {
  const [touched, _setTouched] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [{ valid, error }, _validate] = useState({
    valid: !validate,
    error: ''
  })
  useEffect(() => {
    validate && validate(name, v => {
      _validate({ valid: v.valid, error: v.errors })
    })
  }, [value])

  useEffect(() => {
    _setValue('')
  }, [clearValueTrigger])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  const handleBlur = () => {
    _setTouched(true)
  }

  const handleChange = (_, option) => {
    if (option) {
      _setValue(option.value)
      onChange(name, option.value)
    }
  }

  return (
    <InputWrapper noMarginBottom={noMarginBottom}>
      <LabelWrapper>
        <Label>
          {
            valid || (!valid && !touched) || disabled
              ? optional
              ? (
                <LabelWrapper>
                  {label}
                  <span>(optional)</span>
                </LabelWrapper>
              )
              : <div>{label}</div>
              : <Error>{error}</Error>
          }
          {additionalLabel && additionalLabel}
        </Label>
        {
          withDelete && (
            <DelIconWrapper onClick={onDelete}>
              <span>DELETE</span> <DeleteIcon />
            </DelIconWrapper>

          )
        }
      </LabelWrapper>
      <Autocomplete
        options={options}
        getOptionLabel={option => option.label}
        onChange={handleChange}
        getOptionDisabled={option => isEmpty(option.value)}
        value={value}
        renderInput={params => {
          return (
            <TextField
              id={params.id}
              inputProps={params.inputProps}
              error={(!valid && touched) && !disabled}
              validate={validate}
              InputProps={{
                ...params.InputProps,
                style: {
                  height: '45px',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: disabled ? '#fcfcfc' : '#fff',
                }
              }}
              handleBlur={handleBlur}
              variant='outlined'
              style={{
                width: '100%'
              }}
            />
          )
        }}
      />
    </InputWrapper>
  )
}

AutocompleteInput.defaultProps = {
  options: [],
  onChange: () => {},
  name: '',
  noMarginBottom: false,
  validate: () => {},
  label: '',
  value: '',
  disabled: false,
  clearValueTrigger: '',
  additionalLabel: null,
  optional: false,
  withDelete: false,
  onDelete: () => {}
}

export default AutocompleteInput

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? 0 : '25px' };
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    padding: 0;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: .11px;
  min-height: 17px;
`

const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  align-items: center;
  
  ${({ withDelete }) => withDelete && css`
    color: ${({ theme }) => theme.colors.primary};  
  `}
`

const DelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  cursor: pointer;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  
  span {
    transition: all .3s;
    opacity: 0;
  }
  
  &:hover {
    span {
      opacity: 1;
    }
  }
`
