import emptyBpListState from 'features/bpList/ducks/state'
import {
  paymentInitRoutine,
  checkBasketAvailabilityRoutine,
  clearBasketAvailabilityRoutine
} from 'features/cart/ducks/actions'
import { propOr } from 'ramda'

export default (state = emptyBpListState, action) => {
  switch (action.type) {
    case paymentInitRoutine.SUCCESS:
      return {
        ...state,
        checkoutSessionId: propOr([], 'payload', action)
      }
    case checkBasketAvailabilityRoutine.SUCCESS:
      return {
        ...state,
        cartAvailableBps: action.payload
      }
    case clearBasketAvailabilityRoutine.SUCCESS:
      return {
        ...state,
        cartAvailableBps: []
      }
    default:
      return state
  }
}
