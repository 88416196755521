import React, { useMemo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { isNotNilOrEmpty } from 'utils/ramda'

const PrivateRoute = ({ component: Component, allowedRoles, userRole = null, ...rest }) => {
  const hasValidRole = useMemo(() => {
   return userRole && allowedRoles
      ? allowedRoles.includes(userRole)
      : false
  }, [userRole])

  if (isNotNilOrEmpty(userRole)) {
    return hasValidRole
      ? <Route {...rest} render={props => <Component {...props} />} />
      : <Redirect to={PATHS.login} />
  } else {
    return <div />
  }

}

export default PrivateRoute
