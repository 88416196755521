import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as bpService from 'services/BpService'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'

export const clearSelectedBpRoutine = createRoutine('CLEAR_SELECTED_BP')
export const getUserRelationToBpRoutine = createRoutine('GET_USER_RELATION_TO_BP')
export const fetchUserBpsRoutine = createRoutine('FETCH_USER_BPS')
export const searchBpsRoutine = createRoutine('SEARCH_BPS')
export const getBuildingTypesRoutine = createRoutine('GET_BUILDING_TYPES')
export const getBpsPricesRoutine = createRoutine('GET_BPS_PRICES')
export const fetchFavouritesRoutine = createRoutine('FETCH_FAVOURITES')
export const getBpsCountRoutine = createRoutine('GET_BPS_COUNT')
export const getUnitsCountRoutine = createRoutine('GET_UNITS_COUNT')
export const clearBpPricesRoutine = createRoutine('CLEAR_BP_PRICES')

function * fetchUserBps ({ payload }) {
  yield put(fetchUserBpsRoutine.request())
  try {
    const result = yield call(bpService.fetchUserBps, payload)
    yield put(fetchUserBpsRoutine.success(result.data))
  } catch (error) {
    yield put(fetchUserBpsRoutine.failure(error))
  }
}

function * fetchFavourites ({ payload }) {
  yield put(fetchFavouritesRoutine.request())
  try {
    const result = yield call(bpService.fetchFavouriteBps, payload)
    yield put(fetchFavouritesRoutine.success(result.data))
  } catch (error) {
    yield put(fetchFavouritesRoutine.failure(error))
  }
}

function * searchBps ({ payload }) {
  yield put(searchBpsRoutine.request())
  try {
    const result = yield call(bpService.searchBps, payload)
    yield put(searchBpsRoutine.success(result.data))
  } catch (error) {
    yield put(searchBpsRoutine.failure(error))
  }
}

function * getBuildingTypes () {
  yield put(getBuildingTypesRoutine.request())
  try {
    const result = yield call(bpService.getBuildingTypes)
    yield put(getBuildingTypesRoutine.success(result.data.data))
  } catch (error) {
    yield put(getBuildingTypesRoutine.failure(error))
  }
}

function * getBpsPrices ({ payload }) {
  yield put(getBpsPricesRoutine.request())
  try {
    const result = yield call(bpService.getBpsPrices, payload)
    yield put(getBpsPricesRoutine.success(result.data.data))
  } catch (error) {
    yield put(getBpsPricesRoutine.failure(error))
  }
}

function * clearBpPrices () {
  yield put(clearBpPricesRoutine.success())
}

function * getBpsCount () {
  yield put(getBpsCountRoutine.request())
  try {
    const { data } = yield call(bpService.getBpsCount)
    yield put(getBpsCountRoutine.success(data.data.count))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getBpsCountRoutine.failure(error))
  }
}

function * getUnitsCount () {
  yield put(getUnitsCountRoutine.request())
  try {
    const { data } = yield call(bpService.getUnitsCount)
    yield put(getUnitsCountRoutine.success(data.data.count))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getUnitsCountRoutine.failure(error))
  }
}

// WATCHERS
export function * fetchUserBpsWatcher () {
  yield takeLatest(fetchUserBpsRoutine.TRIGGER, fetchUserBps)
}

export function * searchBpsWatcher () {
  yield takeLatest(searchBpsRoutine.TRIGGER, searchBps)
}

export function * getBuildingTypesWatcher () {
  yield takeLatest(getBuildingTypesRoutine.TRIGGER, getBuildingTypes)
}

export function * getBpsPricesWatcher () {
  yield takeLatest(getBpsPricesRoutine.TRIGGER, getBpsPrices)
}

export function * clearBpPricesWatcher () {
  yield takeLatest(clearBpPricesRoutine.TRIGGER, clearBpPrices)
}

export function * fetchFavouritesWatcher () {
  yield takeLatest(fetchFavouritesRoutine.TRIGGER, fetchFavourites)
}

export function * getBpsCountWatcher () {
  yield takeLatest(getBpsCountRoutine.TRIGGER, getBpsCount)
}

export function * getUnitsCountWatcher () {
  yield takeLatest(getUnitsCountRoutine.TRIGGER, getUnitsCount)
}

// SAGAS
export const bpSagas = [
  fork(fetchUserBpsWatcher),
  fork(fetchUserBpsWatcher),
  fork(searchBpsWatcher),
  fork(getBuildingTypesWatcher),
  fork(getBpsPricesWatcher),
  fork(fetchFavouritesWatcher),
  fork(getBpsCountWatcher),
  fork(getUnitsCountWatcher),
  fork(clearBpPricesWatcher)
]
