import React, { useEffect, useMemo, useState } from 'react'
import { AddButton, MainContentPanel, SectionHeader, SectionHeaderWrapper } from 'features/bpDetails/BpDetails'
import { useDispatch, useSelector } from 'react-redux'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { isEmpty, isNil, propOr, values } from 'ramda'
import { hasMultipleUnits, shouldCreateMultipleUnits } from 'utils/units'
import DetailsUnitSection from 'features/bpDetails/Components/units/DetailsUnitSection'
import styled from 'styled-components'
import UnitDetails from 'features/bpDetails/Components/units/UnitDetails'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { clearSelectedUnitRoutine, fetchUnitRoutine } from 'ducks/units/actions'
import { selectSelectedUnit } from 'ducks/units/selectors'
import Modal from 'components/atoms/Modal'
import AddUnitsToBpForm from 'features/bpDetails/Components/units/AddUnitsToBpForm'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { useParams } from 'react-router'
import { redirect } from 'utils/paths'
import { isNotNilOrEmpty } from 'utils/ramda'

const UnitsPanel = ({ redirectPath }) => {
  const { id, unitId } = useParams()
  const bp = useSelector(getSelectedBp)
  const dispatch = useDispatch()
  const [collapsedSections, setCollapsedSections] = useState([])
  const buildingTypes = useSelector(getBuildingTypes)
  const selectedUnit = useSelector(selectSelectedUnit)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const hasFullAccess = bp.hasAccessToBuildingPassport

  const isSoleUnitBuilding = useMemo(() => {
    return bp.buildingTypes && bp.buildingTypes.some(type => type.type === 'residential.dwelling.sole_unit')
  }, [bp])

  useEffect(() => {
    if (isNotNilOrEmpty(unitId)) {
      dispatch(fetchUnitRoutine({ bpId: id, unitId }))
    } else {
      dispatch(clearSelectedUnitRoutine())
    }
  }, [unitId])

  useEffect(() => {
    !isNil(selectedUnit) && dispatch(clearSelectedUnitRoutine())
  }, [])

  const toggleSectionOpen = index => {
    setCollapsedSections(prev => {
      let previous = [ ...prev ]
      previous[index] = !previous[index]
      return previous
    })
  }

  const handleModalOpen = () => setAddModalOpen(true)
  const handleModalClose = () => setAddModalOpen(false)

  const sections = useMemo(() => {
    if (!isEmpty(buildingTypes)) {
      const types = propOr([], 'buildingTypes', bp)
      types.forEach(() => setCollapsedSections(prev => [ ...prev, true]))
      return types.map(type => {
        const foundType = values(buildingTypes).find(bpType => bpType.key === type.type.replace('.dwelling', '').replace('.sole_unit', ''))
        return {
          name: propOr('', 'label', foundType),
          multi: foundType.key ? hasMultipleUnits(foundType.key) : false,
          createMulti: (foundType.key) ? shouldCreateMultipleUnits(foundType.key) : false,
          value: type.value,
          buildingType: type.type
        }
      })
    }
  }, [bp, buildingTypes])

  const goBack = () => {
    redirect(`${redirectPath}/${id}/units`)
  }

  const isUnitSelected = useMemo(() => !isNil(selectedUnit), [selectedUnit])

  return (
    <MainContentPanel>
      <SectionHeaderWrapper
        noMargin
        withBorder={!isUnitSelected}
      >
        <SectionHeader
          withPointer={isUnitSelected}
          onClick={
            isUnitSelected
              ? goBack
              : () => {}
          }
        >
          {
            isUnitSelected
              ? (
                <BackButton>
                  <ArrowIcon />
                  Back to Units
                </BackButton>
              ) : 'Unit details'
          }
        </SectionHeader>
        {
          !isUnitSelected &&
          hasFullAccess &&
          !isSoleUnitBuilding &&
          <AddButton onClick={handleModalOpen}>+ add new units</AddButton>
        }
      </SectionHeaderWrapper>
      <ContentWrapper>
        {
          isUnitSelected
            ? <UnitDetails unit={selectedUnit}>unit</UnitDetails>
            : sections && sections.map((section, index) => (
              <DetailsUnitSection
                key={`section-${index}`}
                sectionsCount={sections.length}
                section={section}
                bp={bp}
                open={collapsedSections[index]}
                toggleOpen={() => toggleSectionOpen(index)}
                redirectPath={`${redirectPath}/${id}/units`}
              />
          ))
        }
      </ContentWrapper>
      <Modal
        onClose={handleModalClose}
        onCancel={handleModalClose}
        open={addModalOpen}
        title='Add new units'
      >
        <AddUnitsToBpForm bp={bp} closeModal={handleModalClose} />
      </Modal>
    </MainContentPanel>
  )
}

export default UnitsPanel

const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`

const BackButton = styled.div`
  display: flex;
  align-items: center;
`

const ArrowIcon = styled(ArrowBackIcon)`
  font-size: 20px !important;
  margin-right: 7px;
`
