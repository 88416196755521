import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import { isNil } from 'ramda'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Radio from 'components/atoms/Radio'
import { isNotNilOrEmpty } from 'utils/ramda'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'

const initialDates = {
  dateFrom: moment().format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD')
}

const Option = ({ label, value, children }) => {
  return (
    <ToggleLabel
      value={value}
      control={<Radio color='secondary' />}
      label={label || children}
    >
      {children}
    </ToggleLabel>
  )
}

const RadioDropdown = ({ name, options, handleChange, filters, withDateInputs }) => {
  const [expanded, setExpanded] = useState(false)
  const [value, setValue] = useState(filters)
  const [dates, setDates] = useState(initialDates)

  const expandOptions = () => setExpanded(prev => !prev)

  useEffect(() => {
    value !== filters && typeof handleChange === 'function' && handleChange(name, value)
  }, [value])

  const changeDate = type => e => {
    setDates(prev => ({
      ...prev,
      [type]: e.target.value
    }))
  }

  useEffect(() => {
    setValue(`${dates.dateFrom}|${dates.dateTo}`)
  }, [dates])

  useEffect(() => {
    isNil(filters) && setValue(null)
  }, [filters])

  const changeValue = e => setValue(e.target.value)

  const selectedOptionLabel = useMemo(() => {
    const item = options.find(e => e.value === value)

    if (!isNil(item) && isNotNilOrEmpty(item.label)) {
      return item.label
    } else if (value && value.includes('|')) {
      const arr = value.split('|')
      return `${arr[0]} - ${arr[1]}`
    } else {
      return ''
    }
  }, [value])

  return (
    <>
      <RadioDropdownWrapper onClick={expandOptions}>
        <SelectContent>
          {
            isNotNilOrEmpty(value)
              ? <Placeholder>{selectedOptionLabel}</Placeholder>
              : <Placeholder>Select</Placeholder>
          }
        </SelectContent>
        <ExpandMore>
          <StyledExpandIcon expanded={expanded} />
        </ExpandMore>
      </RadioDropdownWrapper>
      <StyledCollapse in={expanded}>
        <CollapseContent>
          <FormControl>
            <RadioGroup
              value={value || null}
              onChange={changeValue}
            >
              {
                (options && options.length > 0) &&
                  options.map(option => {
                    return (
                      <Option
                        key={`option-${Math.random() * 999}`}
                        value={option.value}
                        label={option.label}
                      />
                    )
                  })
              }
              {
                withDateInputs && (
                  <Option
                    value={`${dates.dateFrom}|${dates.dateTo}`}
                  >
                    <DateInputsWrapper>
                      <StyledInput
                        id='date_from'
                        type='date'
                        defaultValue={dates.dateFrom}
                        onChange={changeDate('dateFrom')}
                        inputProps={{ max: dates.dateTo }}
                      />
                      <StyledInput
                        id='date_to'
                        type='date'
                        onChange={changeDate('dateTo')}
                        defaultValue={dates.dateTo}
                        inputProps={{ min: dates.dateFrom }}
                      />
                    </DateInputsWrapper>
                  </Option>
                )
              }
            </RadioGroup>
          </FormControl>
        </CollapseContent>
      </StyledCollapse>
    </>
  )
}

export default RadioDropdown

const RadioDropdownWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 38px;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`

const SelectContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 6px;
`

const ExpandMore = styled.div`
  box-sizing: border-box;
  min-width: 38px;
  max-width: 38px;
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  position: relative;
  top: 0;
  bottom: 0;
  padding: 10px 0;
  cursor: pointer;
`

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  display: flex;
  flex: 1;
  align-items: center;
  padding: 5px 5px 0;
`

const StyledCollapse = styled(Collapse)`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  background-color: ${({ theme }) => theme.colors.white};
  border-top: none;
`

const CollapseContent = styled.div`
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
`

const ToggleLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.haiti};
    font-family: FuturaPT-book;
  }
`

const StyledInput = styled(TextField)`
  box-sizing: border-box;
  padding-right: 10px;

  input {
    font-size: 12px !important;
    color: ${({ theme }) => theme.colors.haiti};
    opacity: .7;
    font-family: FuturaPT-book;
  }
`

const DateInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  transform: ${({ expanded }) => expanded ? 'rotate(-180deg)' : 'rotate(0)'};
`
