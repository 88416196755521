import React from 'react'
import styled, { css } from 'styled-components'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'

const EmptyFileState = ({ padding, buttonText, text, hideButton, initialCategory, initialType, unit }) => {
  return (
    <EmptyFileStateWrapper padding={padding}>
      {text}
      {!hideButton && (
        <AddMultipleFilesModal
          unit={unit}
          initialType={initialType || ''}
          initialCategory={initialCategory || ''}
        >
          <EmptyFileStateButton>
            {buttonText}
          </EmptyFileStateButton>
        </AddMultipleFilesModal>
      )}
    </EmptyFileStateWrapper>
  )
}

export default EmptyFileState

const EmptyFileStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 19px;
  padding: 10px 0 50px;

  ${({ padding }) => padding && css`
    padding: ${padding}
  `}
`

const EmptyFileStateButton = styled.button`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.haiti};
  border: 1px solid transparent;
  transition: all .3s;
  padding: 10px 25px;
  margin-top: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.haiti};
    border-color: ${({ theme }) => theme.colors.haiti};
  }
`
