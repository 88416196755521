import emptyAnalyticsState from './state'
import {
  getAnalyticsTemplatesRoutine,
  changeNewTemplateValueRoutine,
  clearNewTemplateValuesRoutine,
  changeNewTemplateFilesValueRoutine,
  removeNewTemplateFilesValueRoutine,
  getBpsWithAnalyticsRoutine,
  getAnalyticsSharingInvitationsRoutine
} from 'features/analytics/ducks/actions'
import { pathOr } from 'ramda'

export default (state = emptyAnalyticsState, action) => {
  switch (action.type) {
    case getAnalyticsTemplatesRoutine.SUCCESS:
      return {
        ...state,
        templates: action.payload
      }
    case changeNewTemplateValueRoutine.SUCCESS:
      return {
        ...state,
        newTemplate: {
          ...state.newTemplate,
          [action.payload.name]: action.payload.value
        }
      }
    case changeNewTemplateFilesValueRoutine.SUCCESS:
      return {
        ...state,
        newTemplateFiles: [
          ...state.newTemplateFiles,
          action.payload
        ]
      }
    case removeNewTemplateFilesValueRoutine.SUCCESS:
      return {
        ...state,
        newTemplateFiles: state.newTemplateFiles.filter(v => v.id !== action.payload)
      }
    case clearNewTemplateValuesRoutine.SUCCESS:
      return {
        ...state,
        newTemplate: emptyAnalyticsState.newTemplate,
        newTemplateFiles: emptyAnalyticsState.newTemplateFiles
      }
    case getBpsWithAnalyticsRoutine.SUCCESS:
      return {
        ...state,
        bps: pathOr([], ['payload', 'data'], action),
        totalPages: pathOr(1, ['payload', 'meta', 'lastPage'], action)
      }
    case getAnalyticsSharingInvitationsRoutine.SUCCESS:
      return {
        ...state,
        invitations: action.payload
      }
    default:
      return state
  }
}
