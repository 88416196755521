import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import moment from 'moment'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { pathOr, pick, propOr } from 'ramda'
import { updateUnitFileRoutine } from 'ducks/units/actions'

const emptyFileValues = {
  floorNumbers: [],
  producedBy: null,
  creationDate: null,
  legalOwner: null,
  fileTitle: null,
  expirationDate: null,
  expirationReminderDate: null,
  approvedBy: null,
  segment: null,
  dateOfIssue: null,
  isPrivate: true
}

const AddPrivateFileModal = ({
  open,
  onClose,
  bpId,
  unit,
  file
}) => {
  const { id: paramsId } = useParams()
  const dispatch = useDispatch()
  const [modalValues, setValues] = useState({})
  const id = isNotNilOrEmpty(bpId) ? bpId : paramsId.replace(/\?tab=.*/, '')
  const fileVersionId = pathOr('', ['versions', 0, 'id'], file)

  const handleValueChange = (name, value) => {
    if (name === 'expirationDate') {
      const subtractSixMonths = moment(value).subtract(6, 'month')
      const isBeforeToday = subtractSixMonths.isBefore(moment())
      const expirationReminderDate = isBeforeToday
        ? moment().add(1, 'day').format('YYYY-MM-DD')
        : subtractSixMonths.format('YYYY-MM-DD')

      setValues(prev => ({
        ...prev,
        [name]: value,
        expirationReminderDate
      }))
    } else {
      setValues(prev => ({ ...prev, [name]: value }))
    }
  }

  const handleUpload = e => {
    e.preventDefault()
    dispatch(updateUnitFileRoutine({
      bpId: id,
      unitId: unit.id,
      fileId: file.id,
      fileVersionId,
      body: {
        ...modalValues,
        name: modalValues.displayName,
        floorNumbers: isNotNil(modalValues.floorNumbers) ? modalValues.floorNumbers.map(num => num === 'ground' ? '0' : num) : [],
        displayName: modalValues.displayName
      }
    }))
  }

  useEffect(() => {
    if (file) {
      setValues({
        ...pick([
          'name',
          'displayName',
          'producedBy',
          'legalOwner',
          'approvedBy',
          'segment',
          'dateOfIssue'
        ], file),
        creationDate: propOr('', 'creationDate', file) ? moment(propOr(null, 'creationDate', file)).format('YYYY-MM-DD') : null,
        dateOfIssue: propOr('', 'dateOfIssue', file) ? moment(propOr('', 'dateOfIssue', file)).format('YYYY-MM-DD') : null,
      })
    }
  }, [file, open])

  useEffect(() => {
    if (!open) {
      setValues(emptyFileValues)
    }
  }, [open])

  return (
    <Modal
      title='File information'
      open={open}
      onClose={onClose}
    >
      <FormWrapper>
        <InputDescription margin='20px 0'>
          You upload the selected file as Private. Only you will be able to see it.
        </InputDescription>
        <Input
          label='Title of file'
          value={modalValues.displayName}
          name='displayName'
          optional
          onChange={handleValueChange}
          placeholder='e.g. Fire Risk Assessment'
        />
        <ButtonWrapper>
          <UploadButton
            onClick={handleUpload}
          >
            Save
          </UploadButton>
        </ButtonWrapper>
      </FormWrapper>
    </Modal>
  )
}

export default AddPrivateFileModal

const FormWrapper = styled.div`
  max-width: 320px;
  margin: 0 auto;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const UploadButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.secondary};
  height: 30px;
  width: 80%;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ disabled, theme }) => disabled && css`
    border-color: ${theme.colors.grey[700]};
    color: ${theme.colors.grey[700]};

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.grey[700]};
    }
  `}
`
