import React, { useState } from 'react'
import styled from 'styled-components'
import { USER_ROLES } from 'utils/userRoles'
import { formatDate } from 'utils/date'
import BoxButton from 'features/accessControl/components/BoxButton'
import Modal from 'components/atoms/Modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  removeAccessToBpRoutine,
  restoreAccessToBpRoutine
} from 'features/accessControl/ducks/actions'
import { pathOr } from 'ramda'
import { detachUnitUserRoutine, reactivateUnitUserRoutine } from 'ducks/units/actions'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { hideEmail, hidePhoneNumber } from 'utils/user'

const RevokedBox = ({ user, unit }) => {
  const [restoreModalOpen, setRestoreModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const currentBp = useSelector(getSelectedBp)
  const dispatch = useDispatch()

  const handleRestoreModalOpen = () => setRestoreModalOpen(true)
  const handleRestoreModalClose = () => setRestoreModalOpen(false)

  const handleCancelModalOpen = () => setCancelModalOpen(true)
  const handleCancelModalClose = () => setCancelModalOpen(false)

  const restoreAccess = () => {
    if (unit) {
      dispatch(reactivateUnitUserRoutine({
        bpId: currentBp.id,
        unitId: unit.id,
        relationId: pathOr(null, ['pivot', 'id'], user)
      }))
    } else {
      dispatch(restoreAccessToBpRoutine({
        bpId: currentBp.id,
        relationId: pathOr(null, ['pivot', 'id'], user) || pathOr(null, ['pivot', 0, 'id'], user),
      }))
    }
    handleRestoreModalClose()
  }

  const removeUser = () => {
    if (unit) {
      dispatch(detachUnitUserRoutine({
        bpId: currentBp.id,
        unitId: unit.id,
        relationId: pathOr(null, ['pivot', 'id'], user)
      }))
    } else {
      dispatch(removeAccessToBpRoutine({
        bpId: currentBp.id,
        relationId: pathOr(null, ['pivot', 'id'], user) || pathOr(null, ['pivot', 0, 'id'], user)
      }))
    }
    handleCancelModalClose()
  }

  const relationId = pathOr(null, ['pivot', 'relationType'], user) || pathOr(null, ['pivot', 0, 'relationType'], user)
  const revokedAt = pathOr(null, ['pivot', 'revokedAt'], user) || pathOr(null, ['pivot', 0, 'revokedAt'], user)

  return (
    <BoxWrapper>
      {
        user.email
          ? (
             <>
               <PersonDetailsWrapper>
                 <div><span>{user.firstName} {user.lastName}</span></div>
                 <div>Email: {hideEmail(user.email)}</div>
                 <div>Phone: {hidePhoneNumber(user.phoneNumber)}</div>
               </PersonDetailsWrapper>
               <Role>{USER_ROLES[relationId]}</Role>
               <DateAndButtonsWrapper>
                 <DateWrapper>
                   <div>Revoked:</div>
                   {formatDate(revokedAt)}
                 </DateWrapper>
                 <ButtonsWrapper>
                   <BoxButton onClick={handleRestoreModalOpen}>Reactivate</BoxButton>
                   <BoxButton red onClick={handleCancelModalOpen}>Delete</BoxButton>
                 </ButtonsWrapper>
               </DateAndButtonsWrapper>
             </>
          )
          : (
            <>
              <PersonDetailsWrapper>
                <div><span>{user.firstName}</span></div>
              </PersonDetailsWrapper>
              <Role>Prospective Purchaser</Role>
              <DateAndButtonsWrapper>
                <DateWrapper>
                  <div>Revoked:</div>
                  {formatDate(pathOr('', ['pivot', 'revokedAt'], user))}
                </DateWrapper>
              </DateAndButtonsWrapper>
            </>
          )
      }
      <Modal
        title='Reactivate access'
        open={restoreModalOpen}
        onClose={handleRestoreModalClose}
        onCancel={handleRestoreModalClose}
        onSubmit={restoreAccess}
        withCancel
        withSubmit
      >
        Do you really want to reactivate user&apos;s assignment?
      </Modal>
      <Modal
        title='Remove user'
        open={cancelModalOpen}
        onClose={handleCancelModalClose}
        onCancel={handleCancelModalClose}
        onSubmit={removeUser}
        withCancel
        withSubmit
      >
        Do you really want to remove user from the list?
      </Modal>
    </BoxWrapper>
  )
}

export default RevokedBox

const BoxWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 4px;
`

const PersonDetailsWrapper = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.haiti};
  padding: 0 5px 0 0;
  width: 60%;

  span {
    font-size: 14px;
  }
`

const Role = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  padding: 0 5px;
  width: 40%;
`

const DateWrapper = styled.div`
  font-size: 11px;
  padding: 0 5px;
  margin-right: 10px;
`

const ButtonsWrapper = styled.div`
  min-width: 126px;
`

const DateAndButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
`
