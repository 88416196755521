import React, { useEffect, useState } from 'react'
import Panel from 'features/createBp/components/atoms/Panel'
import { useTranslation } from 'react-i18next'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { pathOr, pick, propOr } from 'ramda'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import Select from 'components/atoms/Select'
import {
  validateOwnerField,
  validateOwnerValues,
} from 'features/createBp/ducks/schema'
import PersonDetailsBox from 'features/createBp/components/atoms/PersonDetailsBox'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { getCreatedBpId } from 'features/createBp/ducks/selectors'
import { getUserRelationType } from 'utils/user'
import PersonDetailsForm from 'features/createBp/components/atoms/PersonDetailsForm'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { assignUserToBpRoutine, inviteUserToBpRoutine } from 'features/accessControl/ducks/actions'
import { ROLE_DESCRIPTION } from 'utils/constants'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const Relationship = ({
  completed,
  open,
  setActiveStep,
  setBp,
  bp,
  setCompleted,
  setActiveTab
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const bpId = useSelector(getCreatedBpId)
  const currentUser = useSelector(getCurrentUser)
  const editedBp = useSelector(getSelectedBp)
  const [sectionValues, setSectionValues] = useState({
    owner: typeof bp.owner === 'string' ? currentUser.owner : pathOr({}, ['owners', 0], completed ? editedBp : bp),
    userRelationType: propOr('', 'userRelationType', completed ? editedBp : bp)
  })
  const [ownerValid, setOwnerValid] = useState(false)
  const [valid, setIsValid] = useState(false)
  const [added, setAdded] = useState({
    owner: isNotNilOrEmpty(bp.owner)
  })
  const [role, setRole] = useState(propOr('', 'userRelationType', bp))

  // useEffect(() => {
  //   if (completed && isNotNilOrEmpty(bpId)) {
  //     dispatch(fetchBpRoutine({ id: bpId }))
  //   }
  // }, [])

  const handleOnChange = type => (name, value) => {
    setSectionValues({
      ...sectionValues,
      [type]: {
        ...sectionValues[type],
        [name]: value
      }
    })
  }

  useEffect(() => {
    setSectionValues(prev => ({
      ...prev,
      owner: pathOr({}, ['owners', 0], bp)
    }))
  }, [editedBp, bp, open])

  const fillUserByEmail = (type, value) => {
    setSectionValues(prev => ({
      ...prev,
      [type]: value
    }))
  }

  const handleAdd = (type, value) => () => {
    setAdded(prev => ({ ...prev, [type]: value }))
    !completed && setBp(prev => ({
      ...prev,
      owner: role === 'owner' ? currentUser : sectionValues.owner,
      userRelationType: role
    }))
  }

  const handleRemovePerson = (type, value) => () => {
    handleAdd(type, value)()
    setSectionValues(prev => ({
      ...prev,
      [type]: {}
    }))
    !completed && setBp(prev => ({
      ...prev,
      [type]: {}
    }))
  }

  const setUserRole = (_, role) => {
    !completed && setBp(prev => ({
      ...prev,
      userRelationType: role
    }))
    setRole(role)
  }

  const setNextSection = () => {
    setActiveStep('floor-number')
    setActiveTab('floors')
    setCompleted('relationship')
  }

  const handleSectionSubmit = () => {
    setBp(prev => ({
      ...prev,
      owners: [
        role === 'owner' ? currentUser : sectionValues.owner,
      ],
      userRelationType: role
    }))

    if (isNotNilOrEmpty(bpId)) {
      if (role === 'owner') {
        dispatch(assignUserToBpRoutine({
          bpId,
          body: {
            userId: currentUser.id,
            relationType: 'owner'
          }
        }))
      } else {
        pathOr(false, ['owner', 'id'], sectionValues)
          ? dispatch(assignUserToBpRoutine({
            bpId,
            body: {
              userId: sectionValues.owner.id,
              relationType: 'owner'
            }
          }))
          : dispatch(inviteUserToBpRoutine({
            bpId,
            body: {
              email: sectionValues.owner.email,
              relationType: 'owner',
              ...pick(['firstName', 'lastName', 'phoneNumber'], completed ? editedBp.owner : bp.owner)
            }
          }))

        dispatch(assignUserToBpRoutine({
          bpId,
          body: {
            userId: currentUser.id,
            relationType: 'agent'
          }
        }))
      }
      setNextSection()
    }
  }

  useEffect(() => {
    if (role === 'agent') {
      validateOwnerValues(sectionValues.owner, setOwnerValid)
    }
  }, [sectionValues])

  useEffect(() => {
    role === 'owner' && handleAdd('owner', true)()
  }, [role])

  useEffect(() => {
    setIsValid(added.owner)
  }, [added, role])

  useEffect(() => {
    setAdded({
      owner: isNotNilOrEmpty(pathOr('', ['owner', 'email'], bp))
    })
    setSectionValues({
      owner: typeof bp.owner === 'string' ? currentUser.owner : propOr({}, 'owner', completed ? editedBp : bp),
      userRelationType: getUserRelationType(currentUser, completed ? editedBp : bp)
    })
    setRole(() => {
      const relation = propOr(null, 'userRelationType', completed ? editedBp : bp)
      return isNotNilOrEmpty(relation)
        ? relation
        : getUserRelationType(currentUser, completed ? editedBp : bp)
    })
  }, [bp, editedBp])

  return (
    <Panel
      open={open}
      title='Your relationship'
      completed={completed}
      step='relationship'
      setActiveStep={setActiveStep}
    >
      <Select
        label='Your role'
        onChange={setUserRole}
        disabled={completed}
        placeholder='Select'
        value={propOr('', 'userRelationType', sectionValues)}
        name='userRelationType'
        options={[
          { label: 'Agent', value: 'agent' },
          { label: 'Owner', value: 'owner' }
        ]}
      />
      {
        role && (
          <DescriptionWrapper>
            <InputDescription>
              {ROLE_DESCRIPTION[role]}
            </InputDescription>
          </DescriptionWrapper>
        )
      }
      {
        !completed && (
          <DescriptionWrapper>
            <InputDescription>
              If you know the details of the landlord/owner of the building please input them below.
              You can also add/amend them once the Building Passport is live.
            </InputDescription>
          </DescriptionWrapper>
        )
      }
      {
        role === 'agent' && (
          <>
            <FormTitle>
              Add building owner details
            </FormTitle>
            {
              added.owner
                ? <PersonDetailsBox
                  details={sectionValues.owner}
                  handleEdit={handleRemovePerson('owner', false)}
                  hideEdit={completed}
                />
                : (
                  <PersonDetailsForm
                    type='owner'
                    values={sectionValues.owner}
                    onChange={handleOnChange('owner')}
                    validate={validateOwnerField(sectionValues.owner)}
                    formValid={ownerValid}
                    addPerson={handleAdd('owner', true)}
                    setValues={fillUserByEmail}
                  />
                )
            }
          </>
        )
      }
      {
        completed && (
          <DescriptionWrapper>
            <InputDescription>
              You can manage users assignments in Access Control panel on Building Passport details view.
            </InputDescription>
          </DescriptionWrapper>
        )
      }
      <NextButton
        onClick={handleSectionSubmit}
        disabled={!valid || completed}
      >
        {completed ? 'Save' : t('createBp.labels.next')}
      </NextButton>
      <SkipButton onClick={setNextSection}>Skip relationship</SkipButton>
    </Panel>
  )
}

export default Relationship

const FormTitle = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .7;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[600]};
  margin-bottom: 25px;
`

const SkipButton = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  text-decoration: underline;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`
