import React, { useEffect, useMemo, useState } from 'react'
import { AddButton, MainContentPanel, SectionHeader, SectionHeaderWrapper } from 'features/bpDetails/BpDetails'
import styled, { css } from 'styled-components'
import GeneralNotes from 'features/bpDetails/Components/GeneralNotes'
import { useSelector } from 'react-redux'
import { getCurrentUser, getCurrentUserRole } from 'features/auth/ducks/selectors'
import Note from 'features/bpDetails/Components/Note'
import { isEmpty, propOr } from 'ramda'
import { getFloorplans } from 'utils/files'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { getFloorOptions } from 'utils/bpData'
import EditFileModal from 'features/bpDetails/Components/EditFileModal'
import { PDFViewer } from 'components/PdfViewer'
import { canEditBp } from 'utils/user'
import { getFileTypes, selectBpFilesList } from 'ducks/files/selectors'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'
import EmptyFileState from 'features/bpList/Components/EmptyFileState'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import { useParams } from 'react-router'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import AddNoteModal from 'features/bpDetails/Components/AddNoteModal'
import EmptyNotesState from 'features/bpList/Components/EmptyNotesState'
import { selectIsFetchingFiles } from 'ducks/loaderSelectors'
import { CircularProgress } from '@material-ui/core'

const Floorplans = () => {
  const { folder } = useParams()
  const isFrsFolder = folder === 'frs'
  const bp = useSelector(getSelectedBp)
  const user = useSelector(getCurrentUser)
  const files = useSelector(selectBpFilesList)
  const isFetchingFiles = useSelector(selectIsFetchingFiles)
  const [floorplans, setFloorplans] = useState([])
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [currentFile, setCurrentFile] = useState({})
  const userRole = useSelector(getCurrentUserRole)
  const [selectedFloor, setSelectedFloor] = useState('all')
  const isFrsUser = userRole === 'emergency_service'
  const shouldHideFrsElements = isFrsFolder && !isFrsUser
  const canEdit = canEditBp(user, bp, isFrsFolder)
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)

  const categoryId = fileCategories.find(cat => cat.name === 'Design & Construction')?.id
  const typeId = fileTypes.find(type => type.name === (isFrsFolder ? '' : 'Building plan'))?.id

  const floorNotes = useMemo(() => {
    return propOr([], 'notes', bp).filter(note => isNotNil(note.floorNumber))
  }, [bp])

  const handleEditModalOpen = file => () => {
    setCurrentFile(file)
    setEditModalOpen(true)
  }
  const handleEditModalClose = () => setEditModalOpen(false)

  useEffect(() => {
    setFloorplans(getFloorplans(files, typeId))
  }, [files, isFrsFolder, typeId])

  const floorOptions = useMemo(() => {
    return bp && getFloorOptions(bp, true)
  }, [bp])

  const selectedFloorFloorplans = useMemo(() => {
    if (floorplans && floorOptions) {
      return selectedFloor === 'all'
        ? floorplans
        : floorplans.filter(plan => {
          return plan.floorNumbers.includes(selectedFloor)
        })
    }
  }, [selectedFloor, floorplans, floorOptions])

  return (
    <FloorplansWrapper>
      <MainContentPanel>
        <SectionHeaderWrapper withBorder>
          <SectionHeader>
            Plans {isFrsFolder ? '(FRS)' : ''}
          </SectionHeader>
          {
            !shouldHideFrsElements && canEdit && (
              <AddMultipleFilesModal
                initialCategory={categoryId}
                initialType={typeId}
              >
                <AddButton>
                  + Add new files
                </AddButton>
              </AddMultipleFilesModal>
            )
          }
        </SectionHeaderWrapper>
        <FloorSelectWrapper>
          <FloorSelectButton
            onClick={() => setSelectedFloor('all')}
            active={selectedFloor === 'all'}
          >
            All floors
          </FloorSelectButton>
          {
            floorOptions.map(option => {
              return (
                <FloorSelectButton
                  key={option.label}
                  active={selectedFloor === option.value}
                  onClick={() => setSelectedFloor(option.value)}
                >
                  {option.label}
                </FloorSelectButton>
              )
            })
          }
        </FloorSelectWrapper>
        {
          isFetchingFiles
            ? (
              <FilesFetchingBox>
                <CircularProgress />
                Fetching files...
              </FilesFetchingBox>
            )
            : (
                <>
                  <FilesWrapper>
                    {
                      selectedFloorFloorplans.map(file => (
                        <PDFViewer file={file} key={file.id} openModal={handleEditModalOpen(file)} />
                      ))
                    }
                  </FilesWrapper>
                  {
                    !isNotNilOrEmpty(selectedFloorFloorplans) && (
                      <EmptyFileState
                        text={
                          shouldHideFrsElements
                            ? 'Only emergency services can add files to this folder'
                            : selectedFloor === 'all'
                              ? 'Add your first file to this Building Passport'
                              : 'There are no files assigned to this floor'
                        }
                        buttonText='+ Add new files'
                        initialCategory={categoryId}
                        initialType={typeId}
                        hideButton={shouldHideFrsElements || !canEdit}
                      />
                    )
                  }
                </>
            )
        }
        <SectionHeaderWrapper noMargin withBorder>
          <SectionHeader>Notes</SectionHeader>
          {canEdit && (
            <AddNoteModal>
              <AddButton>+ Add note</AddButton>
            </AddNoteModal>
          )}
        </SectionHeaderWrapper>
        {
          isEmpty(floorNotes)
            ? <EmptyNotesState
              text={
                canEdit
                  ? 'Add your first note to this Building Passport'
                  : 'No floor notes were added'
              }
              buttonText='+ Add note'
              padding='50px 0'
              hideButton={!canEdit}
            />
            : floorNotes.map(note => {
              return <Note note={note} key={note.id} user={user} />
            })
        }
      </MainContentPanel>
      <GeneralNotesPanel>
        <GeneralNotes bp={bp} user={user} />
      </GeneralNotesPanel>
      <EditFileModal
        open={editModalOpen}
        onClose={handleEditModalClose}
        floorOptions={getFloorOptions(bp)}
        file={currentFile}
      />
    </FloorplansWrapper>
  )
}

export default Floorplans

const FloorplansWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
`

const GeneralNotesPanel = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 25px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  border-radius: 4px;
  min-height: 100px;
  margin-top: 30px;
  width: 100%;
`

const FilesWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  @media (min-width: 1390px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const FloorSelectWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
`

const FloorSelectButton = styled.div`
  border-radius: 4px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.colors.haiti};
  background-color: #dfe4f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin: 3px 10px 3px 0;
  cursor: pointer;
  transition: all .3s;
  width: 20px;

  &:first-of-type {
    width: 70px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ active, theme }) => active && css`
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
  `}
`

const FilesFetchingBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`
