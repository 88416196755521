import { createRoutine } from 'redux-saga-routines'
import { put, call, fork, takeLatest } from '@redux-saga/core/effects'
import * as billingService from 'services/billingService'

export const getBillingListRoutine = createRoutine('GET_BILLING_LIST')
export const showInvoiceRoutine = createRoutine('SHOW_INVOICE')

function * getBillingList () {
  yield put(getBillingListRoutine.request())
  try {
    const result = yield call(billingService.getBillingList)
    yield put(getBillingListRoutine.success(result.data.data))
  } catch (err) {
    console.error(err)
    yield put(getBillingListRoutine.failure())
  }
}

function * showInvoice ({ payload }) {
  yield put(showInvoiceRoutine.request())
  try {
    yield call(billingService.showInvoice, payload.id)
    yield put(showInvoiceRoutine.success())
  } catch (err) {
    console.error(err)
    yield put(showInvoiceRoutine.failure())
  }
}

export function * getBillingListWatcher () {
  yield takeLatest(getBillingListRoutine.TRIGGER, getBillingList)
}

export function * showInvoiceWatcher () {
  yield takeLatest(showInvoiceRoutine.TRIGGER, showInvoice)
}

export const billingListSagas = [
  fork(getBillingListWatcher),
  fork(showInvoiceWatcher)
]
