import React, { useEffect } from 'react'
import styled from 'styled-components'
import Note from 'features/bpDetails/Components/Note'
import { isEmpty } from 'ramda'
import EmptyState from 'features/bpList/Components/EmptyState'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { MainContentPanel } from 'features/bpDetails/BpDetails'
import { fetchNoticeBoardRoutine } from 'ducks/units/actions'
import { selectNoticeBoard } from 'ducks/units/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const NoticeBoard = () => {
  const bp = useSelector(getSelectedBp)
  const user = useSelector(getCurrentUser)
  const notes = useSelector(selectNoticeBoard)
  const dispatch = useDispatch()

  useEffect(() => {
    bp && dispatch(fetchNoticeBoardRoutine({ bpId: bp.id }))
  }, [bp])

  return (
    <MainContentPanel>
      <NotesHeaderWrapper>
        <NotesHeader>Notice board</NotesHeader>
      </NotesHeaderWrapper>
      {
        isEmpty(notes)
          ? <EmptyState
              text='Notice board is empty'
              padding='50px 0'
              hideButton
            />
          : notes.map(note => <Note key={note.id} note={note} user={user} />)
      }
    </MainContentPanel>
  )
}

export default NoticeBoard

const NotesHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

const NotesHeader = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .3px;
`
