import { createRoutine } from 'redux-saga-routines'
import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'
import * as analyticsService from 'services/analyticsService'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'
import { dissoc } from 'ramda'

export const getAnalyticsTemplatesRoutine = createRoutine('GET_ANALYTICS_TEMPLATES')
export const getBpsWithAnalyticsRoutine = createRoutine('GET_BPS_ANALYTICS')
export const getAnalyticsSharingInvitationsRoutine = createRoutine('GET_ANALYTICS_SHARING_INVITATIONS')
export const createAnalyticsTemplateRoutine = createRoutine('CREATE_ANALYTICS_TEMPLATE')
export const updateAnalyticsTemplateRoutine = createRoutine('UPDATE_ANALYTICS_TEMPLATE')
export const duplicateAnalyticsTemplateRoutine = createRoutine('DUPLICATE_ANALYTICS_TEMPLATE')
export const removeAnalyticsTemplateRoutine = createRoutine('REMOVE_ANALYTICS_TEMPLATE')
export const attachAnalyticsTemplateRoutine = createRoutine('ATTACH_ANALYTICS_TEMPLATE')
export const attachAnalyticsTemplateToAllBpsRoutine = createRoutine('ATTACH_ANALYTICS_TEMPLATE_TO_ALL_BPS')
export const detachAnalyticsTemplateRoutine = createRoutine('DETACH_ANALYTICS_TEMPLATE')
export const shareAnalyticsTemplateRoutine = createRoutine('SHARE_ANALYTICS_TEMPLATE')

export const acceptAnalyticsSharingInvitationRoutine = createRoutine('ACCEPT_SHARING_INVITATION')
export const rejectAnalyticsSharingInvitationRoutine = createRoutine('REJECT_SHARING_INVITATION')

export const changeNewTemplateValueRoutine = createRoutine('CHANGE_NEW_TEMPLATE_VALUE')
export const changeNewTemplateFilesValueRoutine = createRoutine('CHANGE_NEW_TEMPLATE_FILES_VALUE')
export const removeNewTemplateFilesValueRoutine = createRoutine('REMOVE_NEW_TEMPLATE_FILES_VALUE')
export const clearNewTemplateValuesRoutine = createRoutine('CLEAR_NEW_TEMPLE_VALUES')

function * prepareAnalyticsTemplate ({ payload }) {
  yield put(changeNewTemplateValueRoutine.success(payload))
}

function * changeNewTemplateFilesValue ({ payload }) {
  yield put(changeNewTemplateFilesValueRoutine.success(payload))
}

function * removeNewTemplateFilesValue ({ payload }) {
  yield put(removeNewTemplateFilesValueRoutine.success(payload))
}

function * clearNewTemplateValues () {
  yield put(clearNewTemplateValuesRoutine.success())
}

function * getAnalyticsTemplates () {
  yield put(getAnalyticsTemplatesRoutine.request())
  try {
    const { data } = yield call(analyticsService.getAnalyticsTemplates)
    yield put(getAnalyticsTemplatesRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getAnalyticsTemplatesRoutine.failure(error))
  }
}

function * getBpsWithAnalytics ({ payload }) {
  yield put(getBpsWithAnalyticsRoutine.request())
  try {
    const { data } = yield call(analyticsService.getBpsWithAnalytics, payload)
    yield put(getBpsWithAnalyticsRoutine.success(data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getBpsWithAnalyticsRoutine.failure(error))
  }
}

function * getAnalyticsSharingInvitations () {
  yield put(getAnalyticsSharingInvitationsRoutine.request())
  try {
    const { data } = yield call(analyticsService.getAnalyticsSharingInvitations)
    yield put(getAnalyticsSharingInvitationsRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getAnalyticsSharingInvitationsRoutine.failure(error))
  }
}

function * acceptAnalyticsSharingInvitation ({ payload }) {
  yield put(acceptAnalyticsSharingInvitationRoutine.request())
  try {
    yield call(analyticsService.acceptAnalyticsSharingInvitation, payload)
    yield put(getAnalyticsSharingInvitationsRoutine())
    yield put(getAnalyticsTemplatesRoutine())
    yield put(acceptAnalyticsSharingInvitationRoutine.success())
    toast.success('Sharing invitation has been accepted')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(acceptAnalyticsSharingInvitationRoutine.failure(error))
  }
}

function * rejectAnalyticsSharingInvitation ({ payload }) {
  yield put(rejectAnalyticsSharingInvitationRoutine.request())
  try {
    yield call(analyticsService.rejectAnalyticsSharingInvitation, payload)
    yield put(getAnalyticsSharingInvitationsRoutine())
    yield put(getAnalyticsTemplatesRoutine())
    yield put(rejectAnalyticsSharingInvitationRoutine.success())
    toast.success('Sharing invitation has been rejected')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(rejectAnalyticsSharingInvitationRoutine.failure(error))
  }
}

function * createAnalyticsTemplate ({ payload }) {
  yield put(createAnalyticsTemplateRoutine.request())
  try {
    const { data } = yield call(analyticsService.createAnalyticsTemplate, payload)
    yield put(createAnalyticsTemplateRoutine.success(data.data))
    toast.success('Template has been created')
    redirect(PRIVATE_PATHS.analytics)
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(createAnalyticsTemplateRoutine.failure(error))
  }
}

function * attachAnalyticsTemplate ({ payload }) {
  yield put(attachAnalyticsTemplateRoutine.request())
  try {
    const { data } = yield call(analyticsService.attachAnalyticsTemplate, payload)
    yield put(attachAnalyticsTemplateRoutine.success(data.data))
    yield put(getBpsWithAnalyticsRoutine(payload.page))
    toast.success('Adding template...')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(attachAnalyticsTemplateRoutine.failure(error))
  }
}

function * attachAnalyticsTemplateToAllBps ({ payload }) {
  const { callback } = payload
  yield put(attachAnalyticsTemplateToAllBpsRoutine.request())
  try {
    const { data } = yield call(analyticsService.attachAnalyticsTemplateToAllBps, payload)
    yield put(attachAnalyticsTemplateToAllBpsRoutine.success(data.data))
    toast.success('Template has been assigned to all Building Passports')
    callback()
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(attachAnalyticsTemplateToAllBpsRoutine.failure(error))
    callback()
  }
}

function * detachAnalyticsTemplate ({ payload }) {
  yield put(detachAnalyticsTemplateRoutine.request())
  try {
    const { data } = yield call(analyticsService.detachAnalyticsTemplate, dissoc('shouldUpdateBp', payload))
    yield put(detachAnalyticsTemplateRoutine.success(data.data))
    if (payload.page) {
      yield put(getBpsWithAnalyticsRoutine(payload.page))
    }
    if (payload.shouldUpdateBp) {
      yield put(fetchBpRoutine({ id: payload.bpId }))
    }
    toast.success('Removing template...')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(detachAnalyticsTemplateRoutine.failure(error))
  }
}

function * shareAnalyticsTemplate ({ payload }) {
  yield put(shareAnalyticsTemplateRoutine.request())
  try {
    yield call(analyticsService.shareAnalyticsTemplate, payload)
    yield put(shareAnalyticsTemplateRoutine.success())
    toast.success('Template has been shared')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(shareAnalyticsTemplateRoutine.failure(error))
  }
}

function * updateAnalyticsTemplate ({ payload }) {
  yield put(updateAnalyticsTemplateRoutine.request())
  try {
    const { data } = yield call(analyticsService.updateAnalyticsTemplate, payload)
    yield put(updateAnalyticsTemplateRoutine.success(data.data))
    toast.success('Template has been updated')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(updateAnalyticsTemplateRoutine.failure(error))
  }
}

function * duplicateAnalyticsTemplate ({ payload }) {
  yield put(duplicateAnalyticsTemplateRoutine.request())
  try {
    const { data } = yield call(analyticsService.duplicateAnalyticsTemplate, payload)
    yield put(duplicateAnalyticsTemplateRoutine.success(data.data))
    yield put(getAnalyticsTemplatesRoutine())
    toast.success('Template has been duplicated')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(duplicateAnalyticsTemplateRoutine.failure(error))
  }
}

function * removeAnalyticsTemplate ({ payload }) {
  const { callback } = payload
  yield put(removeAnalyticsTemplateRoutine.request())
  try {
    const { data } = yield call(analyticsService.removeAnalyticsTemplate, payload)
    yield put(removeAnalyticsTemplateRoutine.success(data.data))
    yield put(getAnalyticsTemplatesRoutine())
    toast.success('Template has been removed')
    callback()
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(removeAnalyticsTemplateRoutine.failure(error))
    callback()
  }
}

export function * prepareAnalyticsTemplateWatcher () {
  yield takeLatest(changeNewTemplateValueRoutine.TRIGGER, prepareAnalyticsTemplate)
}

export function * changeNewTemplateFilesValueWatcher () {
  yield takeLatest(changeNewTemplateFilesValueRoutine.TRIGGER, changeNewTemplateFilesValue)
}

export function * removeNewTemplateFilesValueWatcher () {
  yield takeLatest(removeNewTemplateFilesValueRoutine.TRIGGER, removeNewTemplateFilesValue)
}

export function * clearNewTemplateValuesWatcher () {
  yield takeLatest(clearNewTemplateValuesRoutine.TRIGGER, clearNewTemplateValues)
}

export function * getAnalyticsTemplatesWatcher () {
  yield takeLatest(getAnalyticsTemplatesRoutine.TRIGGER, getAnalyticsTemplates)
}

export function * getBpsWithAnalyticsWatcher () {
  yield takeLatest(getBpsWithAnalyticsRoutine.TRIGGER, getBpsWithAnalytics)
}

export function * createAnalyticsTemplateWatcher () {
  yield takeLatest(createAnalyticsTemplateRoutine.TRIGGER, createAnalyticsTemplate)
}

export function * updateAnalyticsTemplateWatcher () {
  yield takeLatest(updateAnalyticsTemplateRoutine.TRIGGER, updateAnalyticsTemplate)
}

export function * duplicateAnalyticsTemplateWatcher () {
  yield takeLatest(duplicateAnalyticsTemplateRoutine.TRIGGER, duplicateAnalyticsTemplate)
}

export function * removeAnalyticsTemplateWatcher () {
  yield takeLatest(removeAnalyticsTemplateRoutine.TRIGGER, removeAnalyticsTemplate)
}

export function * attachAnalyticsTemplateWatcher () {
  yield takeLatest(attachAnalyticsTemplateRoutine.TRIGGER, attachAnalyticsTemplate)
}

export function * detachAnalyticsTemplateWatcher () {
  yield takeLatest(detachAnalyticsTemplateRoutine.TRIGGER, detachAnalyticsTemplate)
}

export function * shareAnalyticsTemplateWatcher () {
  yield takeLatest(shareAnalyticsTemplateRoutine.TRIGGER, shareAnalyticsTemplate)
}

export function * getAnalyticsSharingInvitationsWatcher () {
  yield takeLatest(getAnalyticsSharingInvitationsRoutine.TRIGGER, getAnalyticsSharingInvitations)
}

export function * acceptAnalyticsSharingInvitationWatcher () {
  yield takeLatest(acceptAnalyticsSharingInvitationRoutine.TRIGGER, acceptAnalyticsSharingInvitation)
}

export function * rejectAnalyticsSharingInvitationWatcher () {
  yield takeLatest(rejectAnalyticsSharingInvitationRoutine.TRIGGER, rejectAnalyticsSharingInvitation)
}

export function * attachAnalyticsTemplateToAllBpsWatcher () {
  yield takeLatest(attachAnalyticsTemplateToAllBpsRoutine.TRIGGER, attachAnalyticsTemplateToAllBps)
}

export const analyticsSagas = [
  fork(prepareAnalyticsTemplateWatcher),
  fork(changeNewTemplateFilesValueWatcher),
  fork(clearNewTemplateValuesWatcher),
  fork(removeNewTemplateFilesValueWatcher),
  fork(getAnalyticsTemplatesWatcher),
  fork(getBpsWithAnalyticsWatcher),
  fork(createAnalyticsTemplateWatcher),
  fork(updateAnalyticsTemplateWatcher),
  fork(duplicateAnalyticsTemplateWatcher),
  fork(removeAnalyticsTemplateWatcher),
  fork(attachAnalyticsTemplateWatcher),
  fork(detachAnalyticsTemplateWatcher),
  fork(shareAnalyticsTemplateWatcher),
  fork(getAnalyticsSharingInvitationsWatcher),
  fork(acceptAnalyticsSharingInvitationWatcher),
  fork(rejectAnalyticsSharingInvitationWatcher),
  fork(attachAnalyticsTemplateToAllBpsWatcher)
]
