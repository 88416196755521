const theme = {
  colors: {
    primary: '#D24724',
    secondary: '#33becc',
    white: '#ffffff',
    navigation: '#fafbfc',
    darkGrey: '#333333',
    lightGrey: '#f2f2f2',
    mediumGrey: '#e6e6e6',
    lighterGrey: '#fcfdfe',
    backgroundColor: '#f7f8fd',
    backgroundColorDark: '#ebedfa',
    error: '#d14925',
    success: '#00cc00',
    haiti: '#0C1229',
    linen: '#fbede9',
    grey: {
      400: '#fafafa',
      500: '#e6e6e6',
      600: '#cccccc',
      700: '#b3b3b3',
      800: '#999999'
    }
  },
  dimensions: {
    topBarHeight: '88px',
    navWidth: '106px'
  }
}

export default theme
