/* eslint-disable */
import React, { useMemo, useState } from 'react'
import { formatDate } from 'utils/date'
import styled from 'styled-components'
import NoteLabel from 'features/bpDetails/Components/NoteLabel'
import { isEmpty, isNil, pathOr } from 'ramda'
import { useDispatch } from 'react-redux'
import { editNoteRoutine, removeNoteRoutine } from 'features/createBp/ducks/actions'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'

const Note = ({ note, user }) => {
  const { id, author, message, updatedAt, floorNumber, buildingPassportId } = note
  const [isModalOpen, setModalOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [msg, setMsg] = useState(message)
  const avatarUrl = pathOr('', ['author', 'avatar'], note)
  const dispatch = useDispatch()

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const handleRemoveNote = () => {
    dispatch(removeNoteRoutine({ noteId: id, bpId: buildingPassportId }))
    handleModalClose()
  }

  const type = useMemo(() => {
    if (note.isNoticeForAllUnits) {
      return 'notice'
    } else {
      return isNil(note.floorNumber) ? 'general' : 'floor'
    }
  }, [note])

  const setEditMode = () => setEdit(true)
  const setMessageValue = (_, msg) => setMsg(msg)

  const handleEditNote = () => {
    setEdit(false)
    dispatch(editNoteRoutine({ noteId: id, bpId: buildingPassportId, message: msg }))
  }

  return (
    <NoteWrapper>
      <AvatarWrapper>
        <img src={avatarUrl} alt='user-avatar' />
      </AvatarWrapper>
      <ContentWrapper>
        <NoteHeader>
          <NoteDetails>
            <NoteUserName>
              {author.firstName}&nbsp;{author.lastName}
            </NoteUserName>
            <NoteCreationDate>
              {formatDate(updatedAt)}
            </NoteCreationDate>
          </NoteDetails>
          <NoteLabel type={type} floor={floorNumber} />
        </NoteHeader>
        <NoteContent>
          {
            edit
              ? (
                <Input
                  name='message'
                  value={msg}
                  multiline
                  onChange={setMessageValue}
                />
              )
              : msg
          }
        </NoteContent>
        {
          user.id === author.id &&
            <OptionsWrapper>
              {
                edit
                  ? <span onClick={isEmpty(msg) ? () => {} : handleEditNote}>Save</span>
                  : <span onClick={setEditMode}>Edit</span>

              }

              <span onClick={handleModalOpen}>Remove</span>
            </OptionsWrapper>
        }
      </ContentWrapper>
      <Modal
        open={isModalOpen}
        title='Remove note'
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onSubmit={handleRemoveNote}
        withSubmit
        withCancel
      >
        Do you really want to remove this note?
      </Modal>
    </NoteWrapper>
  )
}

export default Note

const NoteWrapper = styled.div`
  padding-top: 30px;
  display: flex;
`

const NoteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
`

const NoteUserName = styled.div`
  font-size: 13px;
  padding-right: 7px;
  color: ${({ theme }) => theme.colors.haiti};
  font-weight: bold;
`

const NoteCreationDate = styled.div`
  font-size: 12px;
  padding-left: 7px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .7;
  border-left: 1px solid ${({ theme }) => theme.colors.haiti};
`

const NoteContent = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 12px;
  opacity: .7
`

const AvatarWrapper = styled.div`
  margin-right: 15px;

  img {
    border-radius: 50%;
    width: 43px;
    height: 43px;
  }
`

const NoteDetails = styled.div`
  display: flex;
`

const ContentWrapper = styled.div`
  width: 100%;
`

const OptionsWrapper = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-top: 8px;

  span {
    transition: all .3s;
    display: inline-block;
    cursor: pointer;
    opacity: .7;

    &:hover {
      opacity: 1;
    }

    &:first-of-type {
      margin-right: 15px;
    }
  }
`
