import React, { useMemo } from 'react'
import styled from 'styled-components'
import theme from 'theme/theme'

const FullWidthButton = ({
  type,
  children,
  variant,
  disabled,
  onClick,
  hasMargin
}) => {
  const colorStyles = useMemo(() => {
    switch (true) {
      case disabled:
        return {
          backgroundColor: theme.colors.grey[500],
          color: theme.colors.grey[700],
          borderColor: theme.colors.grey[600],
          opacity: 0.7
        }
      case variant === 'white':
        return {
          backgroundColor: theme.colors.white,
          color: theme.colors.haiti
        }
      case variant === 'black':
      default:
        return {
          backgroundColor: theme.colors.haiti,
          color: theme.colors.white
        }
    }
  }, [disabled])

  return (
    <StyledButton
      type={type}
      style={colorStyles}
      disabled={disabled}
      onClick={onClick}
      hasMargin={hasMargin}
    >
      {children}
    </StyledButton>
  )
}

export default FullWidthButton

const StyledButton = styled.button`
  box-shadow: 0 0 10px rgba(93, 117, 208, 0.1);
  outline: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 45px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.colors.haiti};
  margin-right: ${({ hasMargin }) => (hasMargin ? '10px' : '0')};
  transition: all 0.3s;
  &:hover {
    opacity: 0.6;
  }
`
