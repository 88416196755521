import {
  fetchDashboardStatisticsRoutine,
  fetchLastViewedBpsRoutine,
  fetchLastSearchedBpsRoutine
} from 'features/dashboard/ducks/actions'
import { propOr } from 'ramda'
import emptyDashboardState from 'features/dashboard/ducks/state'

export default (state = emptyDashboardState, action) => {
  switch (action.type) {
    case fetchLastSearchedBpsRoutine.SUCCESS:
      return {
        ...state,
        lastSearched: propOr([], 'payload', action)
      }
    case fetchLastViewedBpsRoutine.SUCCESS:
      return {
        ...state,
        lastViewed: propOr([], 'payload', action)
      }
    case fetchDashboardStatisticsRoutine.SUCCESS:
      return {
        ...state,
        dashboard: propOr({}, 'payload', action)
      }
    default:
      return state
  }
}
