import React, { useMemo } from 'react'
import styled from 'styled-components'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { useSelector } from 'react-redux'
import { pathOr, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import InitialsAvatar from 'features/navigation/components/InitialsAvatar'
import { userRole } from 'utils/userRoles'
import DetailsSection from 'features/bpDetails/Components/BpInfo/DetailsSection'

const SummaryItem = ({ title, value, variant }) => {
  return (
    <div>
      <SummaryItemTitle>{title}</SummaryItemTitle>
      <SummaryItemValue variant={variant}>{value}</SummaryItemValue>
      <SummaryItemTotal>in total</SummaryItemTotal>
    </div>
  )
}

const ProfileInfo = () => {
  const user = useSelector(getCurrentUser)
  const userFirstName = propOr('', 'firstName', user)
  const userLastName = propOr('', 'lastName', user)
  const currentUserSource = pathOr('', ['source', 'value'], user)
  const isFromInfoExchange = currentUserSource === 'info_exchange'
  const avatarUrl = useMemo(() => pathOr(null, ['avatar'], user), [user])

  return (
    <div>
      <Title>
        Your profile
      </Title>
      <UserAvatar>
        {
          isNotNilOrEmpty(avatarUrl) && !isFromInfoExchange
            ? <img src={avatarUrl} alt='user avatar' />
            : <InitialsAvatar />
        }
      </UserAvatar>
      <UserName>
        {`${userFirstName} ${userLastName}`}
      </UserName>
      <UserEmail>
        {user.email}
      </UserEmail>
      <UserRole>
        {userRole(user)}
      </UserRole>
      <Details>
        {
          user.type !== 'emergency_service' && (
            <DetailsSection label='Summary'>
              <SummaryWrapper>
                <SummaryItem
                  title='Building passports'
                  value={propOr('', 'buildingPassportsCount', user)}
                />
                <SummaryItem
                  title='Amount spent'
                  value={pathOr('', ['amountSpent', 'formatted'], user)}
                  variant='primary'
                />
              </SummaryWrapper>
            </DetailsSection>
          )
        }
        {
          user.discount > 0 &&
            <DetailsSection label='Discount information'>
              <Discount>
                Your BP discount:
                <span>{user.discount}%</span>
              </Discount>
            </DetailsSection>
        }
      </Details>
    </div>
  )
}

export default ProfileInfo

const Title = styled.div`
  font-size: 14px;
  text-transform: uppercase;
`

const UserAvatar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 16px;

  img {
    border-radius: 50%;
    min-width: 84px;
    max-width: 84px;
    min-height: 84px;
    max-height: 84px;
  }
`

const UserName = styled.div`
  font-size: 23px;
  color: ${({ theme }) => theme.colors.haiti};
  text-align: center;
`

const UserEmail = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.grey[800]};
  text-align: center;
  margin: 3px 0;
`

const UserRole = styled.div`
  font-size: 17px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .7;
  text-align: center;
`

const Details = styled.div`
  margin-top: 20px;
`

const Discount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const SummaryWrapper = styled.div`
  display: flex;

  div {
    flex: 1;
  }
`

const SummaryItemTitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
`

const SummaryItemValue = styled.span`
  color: ${({ theme, variant }) => variant === 'primary' ? theme.colors.primary : theme.colors.secondary};
  font-size: 18px;
  margin-right: 5px;
`

const SummaryItemTotal = styled.span`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-size: 12px;
`
