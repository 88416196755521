import React, { useEffect } from 'react'
import styled from 'styled-components'
import { getAccessRequestsRoutine } from 'features/AccessRequest/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { selectAccessRequests } from 'features/AccessRequest/ducks/selectors'
import { isEmpty } from 'ramda'
import RequestBox from 'features/accessControl/components/RequestBox'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getUnitAccessRequestsRoutine } from 'ducks/units/actions'
import { selectUnitRequestsList } from 'ducks/units/selectors'

const RequestsList = ({ unit }) => {
  const dispatch = useDispatch()
  const bp = useSelector(getSelectedBp)
  const accessRequests = useSelector(selectAccessRequests)
  const unitAccessRequests = useSelector(selectUnitRequestsList)
  const isDraft = bp.status === 'draft'

  useEffect(() => {
    isNotNilOrEmpty(bp) && !isDraft && !isNotNilOrEmpty(unit) &&
      dispatch(getAccessRequestsRoutine({ bpId: bp.id }))
  }, [bp])

  useEffect(() => {
    isNotNilOrEmpty(bp) && isNotNilOrEmpty(unit) &&
      dispatch(getUnitAccessRequestsRoutine({ bpId: bp.id, unitId: unit.id }))
  }, [unit])

  const requests = isNotNilOrEmpty(unit) ? unitAccessRequests : accessRequests

  return (
    <>
      {
        !isEmpty(requests)
          ? requests.map(request => (
            <RequestBox
              key={`revoked-user-${request.id}`}
              request={request} bp={bp}
              unit={unit}
            />
          ))
          : <EmptyState>Access requests list is empty</EmptyState>
      }
    </>
  )
}

export default RequestsList

const EmptyState = styled.div`
  box-sizing: border-box;
  display: flex;
  min-height: 83px;
  align-items: center;
`
