import React from 'react'
import styled from 'styled-components'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import { Link } from 'react-router-dom'

const EmptyState = () => {
  return (
    <PanelWrapper>
      <Title>No transactions</Title>
      <Drafts>Add one of your <Link to={PRIVATE_PATHS.drafts}>drafts</Link> to cart</Drafts>
      <Or>or</Or>
      <CreateBpButton
        onClick={() => redirect(PRIVATE_PATHS.createBp)}
      >
        Create new Building Passport
      </CreateBpButton>
    </PanelWrapper>
  )
}

export default EmptyState

const PanelWrapper = styled.div`
  box-sizing: border-box;
  max-width: 400px;
  margin: 30px auto;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
`

const CreateBpButton = styled.button`
  outline: 0;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  border: 3px solid ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 0 20px rgba(41, 152, 163, 0.31);
  border-radius: 20px;
  min-width: 200px;
  height: 40px;
  transition: all .3s;
  cursor: pointer;
  font-size: 16px;
  padding: 2px 10px;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};  
  }
`

const Title = styled.div`
  font-size: 35px;
  ${({ theme }) => theme.colors.haiti};
`

const Or = styled.div`
  margin: 20px 0 25px;
`

const Drafts = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  margin-top: 15px;
  
  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`
