import React, { useState } from 'react'
import styled from 'styled-components'
import { USER_ROLES } from 'utils/userRoles'
import BoxButton from 'features/accessControl/components/BoxButton'
import Modal from 'components/atoms/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { cancelInvitationRoutine } from 'features/accessControl/ducks/actions'
import { cancelUnitInvitationRoutine } from 'ducks/units/actions'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { hideEmail, hidePhoneNumber } from 'utils/user'

const InvitationBox = ({ invitation, unit }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const currentBp = useSelector(getSelectedBp)

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const cancelInvitation = () => {
    if (unit) {
      dispatch(cancelUnitInvitationRoutine({
        bpId: currentBp.id,
        unitId: unit.id,
        invitationId: invitation.id
      }))
    } else {
      dispatch(cancelInvitationRoutine({
        bpId: invitation.buildingPassportId,
        invitationId: invitation.id
      }))
    }
    handleModalClose()
  }

  return (
    <BoxWrapper>
      <PersonDetailsWrapper>
        <div><span>{invitation.firstName} {invitation.lastName}</span></div>
        <div>Email: {hideEmail(invitation.email)}</div>
        {invitation.phoneNumber && <div>Phone: {hidePhoneNumber(invitation.phoneNumber)}</div>}
      </PersonDetailsWrapper>
      <Role>{USER_ROLES[invitation.relationType]}</Role>
      <DateAndButtonWrapper>
        <BoxButton red onClick={handleModalOpen}>Cancel</BoxButton>
      </DateAndButtonWrapper>
      <Modal
        title='Cancel invitation'
        open={modalOpen}
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onSubmit={cancelInvitation}
        withCancel
        withSubmit
      >
        Do you really want to cancel this invitation?
      </Modal>
    </BoxWrapper>
  )
}

export default InvitationBox

const BoxWrapper = styled.div`
  width: 100%;
  min-height: 68px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 4px;
`

const PersonDetailsWrapper = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.haiti};
  flex: 1;

  span {
    font-size: 14px;
  }
`

const Role = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  flex: 1;
  padding: 0 5px;
`

// const DateWrapper = styled.div`
//   font-size: 11px;
//   margin-right: 10px;
// `
//
// const NotSendYet = styled.div`
//   max-width: 140px;
//   color: ${({ theme }) => theme.colors.grey[800]};
// `

const DateAndButtonWrapper = styled.div`
  min-width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
