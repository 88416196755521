import React from 'react'
import styled from 'styled-components'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import Collapse from '@material-ui/core/Collapse'

const PanelFloor = ({ title, open, children, completed, step, setActiveStep }) => {

  const handleEditClick = () => setActiveStep(step)

  return (
    <PanelWrapper>
      <PanelTitle>
        <div>
          {title}
          <TitleLine />
        </div>
        {completed && !open && <EditIcon onClick={handleEditClick} />}
      </PanelTitle>
      <PanelContent in={open}>
        {children}
      </PanelContent>
    </PanelWrapper>
  )
}

export default PanelFloor

const PanelWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  overflow: hidden;
`

const PanelTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.colors.haiti};
  font-size: 18px;
  letter-spacing: .15px;
`

const TitleLine = styled.div`
  height: 4px;
  width: 50px;
  border-radius: 2px;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const EditIcon = styled(CreateOutlinedIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  cursor: pointer;
`

const PanelContent = styled(Collapse)`
  box-sizing: border-box;
  margin: ${(props) => props.in ? '0px auto 0' : '0 auto'};
  width: calc(100%-64px);
`
