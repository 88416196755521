import history from 'utils/history'
import { values } from 'ramda'

export const PATHS = {
  home: '/',
  login: '/login',
  signup: '/sign-up',
  signupConfirm: '/sign-up/confirm',
  emailVerification: '/email-verification',
  requestPasswordReset: '/request-password-reset',
  passwordReset: '/password-reset',
  notFound: '/404'
}

export const PRIVATE_PATHS = {
  dashboard: '/dashboard',
  bpList: '/bp-list',
  createBp: '/create-bp',
  editBp: '/edit-bp/:id',
  bpDetails: '/bp-list/:id/:folder?/:unitId?',
  draftDetails: '/drafts/:id/:folder?/:unitId?',
  savedPassports: '/favourites',
  savedDetails: '/favourites/:id/:folder?/:unitId?',
  profileSettings: '/profile',
  notificationsCenter: '/notifications-center',
  cart: '/cart',
  terms: '/terms',
  drafts: '/drafts',
  paymentSuccess: '/cart/success',
  paymentError: '/cart/failed',
  billingList: '/billing-list',
  accessRequests: '/access-requests',
  analytics: '/analytics',
  addAnalytics: '/analytics/add',
  editAnalytics: '/analytics/edit'
}

export const redirect = (path, state) => history.push(path, state)

export const isExcludedFromFetchRedirect = path => {
  return values(PATHS).includes(path)
}
