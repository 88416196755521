import React, { useState } from 'react'
import { AddButton } from 'features/bpDetails/BpDetails'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import styled from 'styled-components'
import { createFileTagRoutine } from 'ducks/files/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import PrimaryButton from 'components/atoms/PrimaryButton'

const AddNewFileTag = () => {
  const bp = useSelector(getSelectedBp)
  const [tagName, setTagName] = useState('')
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setTagName('')
  }

  const handleSubmit = () => {
    dispatch(createFileTagRoutine({
      bpId: bp.id,
      name: tagName,
      callback: handleClose
    }))
  }

  return (
    <div>
      <AddButton onClick={handleOpen}>+ Add new tag</AddButton>
      <Modal
        open={open}
        onClose={handleClose}
        title='Add new tag'
      >
        <Input
          name='name'
          label='Tag name'
          value={tagName}
          onChange={(_, v) => setTagName(v)}
        />
        <ButtonsWrapper>
          <Button
            variant='outlined'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <PrimaryButton
            onClick={handleSubmit}
          >
            Add tag
          </PrimaryButton>
        </ButtonsWrapper>
      </Modal>
    </div>
  )
}

export default AddNewFileTag

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;

  button {
    flex: 1;
  }
`
