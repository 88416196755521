import APIService from './APIService'

const api = APIService()

export const fetchAllNotifications = () => api.get('/me/notifications')

export const fetchUnreadNotiicationsCounter = () => api.get('/me/notifications/unread-counter')

export const fetchUnseenNotiicationsCounter = () => api.get('/me/notifications/unseen-counter')

export const markAllNotificationsAsSeen = () => api.patch('/me/notifications/mark-as-seen')

export const markAllNotificationsAsRead = () => api.patch('/me/notifications/mark-as-read')

export const markSingleNotificationsAsRead = id => api.patch(`/me/notifications/${id}/mark-as-read`)
