import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectFileTagsForBp } from 'ducks/files/selectors'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import Button from 'components/atoms/Button'
import PrimaryButton from 'components/atoms/PrimaryButton'
import { syncFileTagsInFileRoutine, syncFileTagsInFileVersionRoutine } from 'ducks/files/actions'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import Input from 'components/atoms/Input'

const getFileExtension = (file, isVersion) => {
  if (isVersion) {
    return file?.path?.split('.')?.pop() || ''
  } else {
    const versions = file?.versions || []
    if (versions[0]) {
      return versions[0].path?.split('.')?.pop() || ''
    } else {
      return ''
    }
  }
}

const AttachTagToFile = ({ file, children, isVersion }) => {
  const bp = useSelector(getSelectedBp)
  const [open, setOpen] = useState(false)
  const tags = useSelector(selectFileTagsForBp)
  const fileExtension = getFileExtension(file, isVersion)
  const fileName = isVersion ? file.name : file.displayName
  const [selectedTags, setSelectedTags] = useState([])
  const [newTags, setNewTags] = useState('')
  const dispatch = useDispatch()

  const taggings = file?.taggings || []

  useEffect(() => {
    if (open) {
      const currentTags = taggings?.map(tag => tag.tag.id) || []
      setSelectedTags(currentTags)
    }
  }, [open])

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setSelectedTags([])
    setNewTags('')
  }

  const toggleTagSelection = id => () => {
    setSelectedTags(prev => {
      if (prev.includes(id)) {
        return prev.filter(el => el !== id)
      } else {
        return [...prev, id]
      }
    })
  }

  const handleSubmit = () => {
    const parsedNewTags = newTags
      .replace(/,\s*/g, ',')
      .split(',')
      .filter(el => isNotNilOrEmpty(el))

    isVersion
      ? dispatch(syncFileTagsInFileVersionRoutine({
        bpId: bp.id,
        fileVersionId: file.id,
        tagIds: selectedTags,
        newTags: parsedNewTags,
        callback: handleClose
      }))
      : dispatch(syncFileTagsInFileRoutine({
        bpId: bp.id,
        fileId: file.id,
        tagIds: selectedTags,
        newTags: parsedNewTags,
        callback: handleClose
      }))
  }

  return (
    <>
      <TriggerWrapper onClick={handleOpen}>
        {children}
      </TriggerWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        title='Attach tag to a file'
      >
        <ModalContent>
          <FileWrapper>
            <IconWrapper>
              <FileIcon />
            </IconWrapper>
            <FileName>
              {`${fileName}.${fileExtension}${isVersion ? ` (v${file.version})` : ''}`}
            </FileName>
          </FileWrapper>
          <ModalDesc>
            Select tags to be assigned to this file:
          </ModalDesc>
          <TagsList>
            {
              isNotNilOrEmpty(tags) ? tags.map(tag => {
                return (
                  <Tag
                    key={tag.id}
                    active={selectedTags.includes(tag.id)}
                    onClick={toggleTagSelection(tag.id)}
                  >
                    {tag.name}
                  </Tag>
                )
              }) : (
                <div>No tags to display</div>
              )
            }
          </TagsList>
          <ModalDesc>
            or add new tags:
          </ModalDesc>
          <Input
            name='newTags'
            label='New tags (comma separated)'
            value={newTags}
            onChange={(_, value) => setNewTags(value)}
          />
          <ButtonsWrapper>
            <Button
              variant='outlined'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <PrimaryButton
              onClick={handleSubmit}
            >
              Save
            </PrimaryButton>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AttachTagToFile

const TriggerWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`

const ModalContent = styled.div`
  max-width: 500px;
`

const FileName = styled.div`
  font-size: 16px;
  word-break: break-all;
`

const ModalDesc = styled.div`
  margin-bottom: 20px;
`

const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  margin-bottom: 30px;
`

const Tag = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.darkGrey};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[500]};
  }

  ${({ active }) => active && css`
    background-color: #e6f2ff;
    border-color: #e6f2ff;

    &:hover {
      background-color: #e6f2ff;
      border-color: #e6f2ff;
    }
  `};

  ${({ disabled, theme }) => disabled && css`
    background-color: ${theme.colors.grey[400]};
    border-color: ${theme.colors.grey[400]};
    color: ${theme.colors.grey[700]};
    cursor: default;

    &:hover {
      background-color: ${theme.colors.grey[400]};
      border-color: ${theme.colors.grey[400]};
      color: ${theme.colors.grey[700]};
    }
  `};

`

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 20px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  margin-top: 20px;

  button {
    flex: 1;
  }
`
