/* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'
import { isEmpty, pathOr, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import Modal from 'components/atoms/Modal'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import UnitForm from 'features/createBp/components/atoms/UnitForm'
import { getFloorsIntervals } from 'utils/bpData'
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectBpUnits } from 'ducks/units/selectors'
import { selectCreatedBp } from 'features/createBp/ducks/selectors'

const UnitsTable = ({ units, handleUnitEdit, handleUnitRemove, isSoleUnit, isSoleResidential }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState(null)
  const dispatch = useDispatch()
  const data = useMemo(() => units, [units])
  const bpUnits = useSelector(selectBpUnits)
  const bp = useSelector(selectCreatedBp)

  const handleDeleteModalOpen = unit => e => {
    e.stopPropagation()
    setSelectedUnit(unit)
    setDeleteModalOpen(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false)
    setSelectedUnit(null)
  }

  const handleEditModalOpen = unit => e => {
    e.stopPropagation()
    e.preventDefault()
    setSelectedUnit(unit)
    setEditModalOpen(true)
  }

  const handleEditModalClose = () => {
    setSelectedUnit(null)
    setEditModalOpen(false)
  }

  const floorNumbers = floors => {
    const sorted = floors
      .map(num => num === 'lower_ground' ? -0.5 : Number(num))
      .sort((a, b) => b - a)

    return getFloorsIntervals(sorted)
  }

  const handleDelete = () => {
    handleUnitRemove({ unitId: selectedUnit.id })
    handleDeleteModalClose()
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit name',
        accessor: 'name',
        Cell: d => <PropertyName>{d.value}</PropertyName>
      },
      {
        Header: 'UPRN',
        accessor: 'uprn',
        Cell: d => {
          return isNotNilOrEmpty(d.value)
            ? d.value
            : 'Not yet allocated'
        }
      },
      {
        Header: 'Floor(s)',
        id: 'floorNumbers',
        accessor: 'floorNumbers',
        Cell: d => d.value ? floorNumbers(propOr([], 'value', d)).join(', ') : '---'
      },
      {
        Header: 'Area (sqm)',
        accessor: 'area',
        Cell: d => d.value || '---'
      },
      {
        Header: 'Owner',
        id: 'owners',
        accessor: 'owners',
        Cell: d => {
          return d.value[0]
            ? `${d.value[0].firstName} ${d.value[0].lastName}`
            : 'Unassigned'
        }
      },
      {
        Header: '',
        id: 'actions',
        Cell: d => {
          return (
            <IconsWrapper>
              {
                bpUnits.length > 1 && !isSoleResidential && (
                  <DeleteIconWrapper onClick={handleDeleteModalOpen(d.row.original)}>
                    <span>REMOVE</span>
                    <DeleteIcon />
                  </DeleteIconWrapper>
                )
              }
              {
                (!isSoleUnit || isSoleUnit && units.length > 1) && (
                  <EditIcon onClick={handleEditModalOpen(d.row.original)} />
                )
              }
            </IconsWrapper>
          )
        }
      }
    ],
    [data]
  )

  const tableInstance = useTable({
    columns,
    data
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance

  return (
    <TableWrapper>
      <table {...getTableProps()}>
        <thead>
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column => (
                  <th
                    style={{ cursor: 'pointer', paddingRight: '20px' }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))
              }
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          rows.map(row => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={e => {
                  if (!isSoleUnit || isSoleUnit && units.length > 1) {
                    handleEditModalOpen(row.original)(e)
                  } else {
                    return null
                  }
                }}
              >
                {
                  row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {
                          cell.render('Cell')
                        }
                      </td>
                    )
                  })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Modal
        open={deleteModalOpen}
        title='Remove unit'
        onClose={handleDeleteModalClose}
        onCancel={handleDeleteModalClose}
        onSubmit={handleDelete}
        withSubmit
        withCancel
      >
        Removing <ModalUnitName>{propOr('', 'name', selectedUnit)}</ModalUnitName> will delete this unit
        and remove any links that were made to files within the Building Passport.
        Any files specific to this unit will also be deleted. Are you sure you wish to continue?
      </Modal>
      <Modal
        open={editModalOpen}
        title='Edit unit'
        onClose={handleEditModalClose}
      >
        <UnitForm
          bp={bp}
          unit={selectedUnit}
          onSubmit={handleUnitEdit}
          closeEditModal={handleEditModalClose}
        />
      </Modal>
    </TableWrapper>
  )
}

export default UnitsTable

const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: auto;

  table {
    width: 100%;
  }

  th {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 11px;
    opacity: .5;
    text-transform: uppercase;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    position: relative;
  }

  td {
    box-sizing: border-box;
    padding: 0 10px;
    height: 56px;
    font-size: 14px;
  }

  tbody > tr {
    color: ${({ theme }) => theme.colors.grey[800]};
    cursor: pointer;
    transition: all .3s;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey[400]};
    }
  }
`

const PropertyName = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.haiti};
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-width: 60px;
`

const EditIcon = styled(EditOutlinedIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  transition: all .3s;
  margin: 0 5px;

  &:hover {
    opacity: .8;
  }
`

const DeleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.error};

  span {
    transition: all .3s;
    opacity: 0;
    margin-right: 3px;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`

const ModalUnitName = styled.span`
  font-weight: bold;
`
