import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/atoms/Input'
import { loginUserRoutine } from 'features/auth/ducks/actions'
import { validateLoginField, validateLoginValues } from 'features/auth/ducks/schema'
import { Redirect } from 'react-router-dom'
import { PATHS, PRIVATE_PATHS, redirect } from 'utils/paths'
import { useTranslation } from 'react-i18next'
import { getCurrentUser, isUserLoggedIn } from 'features/auth/ducks/selectors'
import FullWidthButton from 'features/auth/components/FullWidthButton'

const emptyValues = {
  email: '',
  password: ''
}

const Login = () => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setIsValid] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser)
  const isLoggedIn = useSelector(isUserLoggedIn)

  const { t } = useTranslation()

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    await dispatch(loginUserRoutine({
      password: values.password,
      email: values.email.toLowerCase()
    }))
  }

  useEffect(() => {
    validateLoginValues(values, setIsValid)
  }, [values])

  return isLoggedIn && user.type !== 'admin'
    ? <Redirect
        to={['emergency_service', 'responsible_person'].includes(user.type)
          ? PRIVATE_PATHS.bpList
          : PRIVATE_PATHS.dashboard}
      />
    : (
      <PageWrapper>
        <LoginWrapper>
          <HeaderText>
            {t('login.header')}
            <span>{t('login.company')}</span>
          </HeaderText>
          <LoginForm onSubmit={handleSubmit}>
            <Input
              required
              label={t('login.labels.email')}
              name='email'
              onChange={handleOnChange}
              validate={validateLoginField(values)}
            />
            <Input
              required
              label={t('login.labels.password')}
              type='password'
              name='password'
              onChange={handleOnChange}
              validate={validateLoginField(values)}
              additionalLabel={
                <ForgotPassword
                  onClick={() => redirect(PATHS.requestPasswordReset)}
                >
                  {t('login.forgotPassword')}
                </ForgotPassword>
              }
            />
            <ButtonWrapper>
              <FullWidthButton
                type='submit'
                disabled={!valid}
              >
                {t('login.labels.submitButton')}
              </FullWidthButton>
            </ButtonWrapper>
          </LoginForm>
          <NoAccount>
            <span>{t('login.noAccount')}</span>
          </NoAccount>
          <FullWidthButton
            variant='white'
            onClick={() => redirect(PATHS.signup)}
          >
            {t('login.labels.createAccount')}
          </FullWidthButton>
        </LoginWrapper>
      </PageWrapper>
    )
}

export default Login

const PageWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const LoginWrapper = styled.div`
  box-sizing: border-box;
  width: 645px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 50px 160px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(93, 117, 208, 0.1);
`

const HeaderText = styled.h1`
  font-size: 38px;
  margin-bottom: 30px;

  span {
    display: block;
    font-weight: bold;
  }
`

const LoginForm = styled.form`
  box-sizing: border-box;
  width: 100%;
`

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
`

const ForgotPassword = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`

const NoAccount = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .7;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 40px auto 25px;

  span {
    position: relative;
    z-index: 2;
    display: block;
    width: fit-content;
    margin: 0 auto;
    padding: 0 10px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.mediumGrey};
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
`
