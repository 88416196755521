import { emptyBpCreationState } from 'features/createBp/ducks/state'
import {
  clearCreatedBpRoutine,
  createBpRoutine,
  removeCreatedBpUnitRoutine, updateCreatedBpRoutine,
} from 'features/createBp/ducks/actions'
import { pathOr } from 'ramda'

export default (state = emptyBpCreationState, action) => {
  switch (action.type) {
    case createBpRoutine.SUCCESS:
      return {
        ...state,
        bp: action.payload
      }
    case clearCreatedBpRoutine.SUCCESS:
      return emptyBpCreationState
    case updateCreatedBpRoutine.SUCCESS:
      return {
        ...state,
        bp: action.payload
      }
    case removeCreatedBpUnitRoutine.SUCCESS:
      const filteredBpUnits = pathOr([], ['bp', 'units'], state).filter(unit => unit.id !== action.payload)
      return {
        ...state,
        bp: {
          ...state.bp,
          units: filteredBpUnits
        }
      }
    default:
      return state
  }
}
