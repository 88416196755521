import React, { useMemo } from 'react'
import styled from 'styled-components'
import Note from 'features/bpDetails/Components/Note'
import { isEmpty, isNil, propOr } from 'ramda'
import { canEditBp } from 'utils/user'
import AddNoteModal from 'features/bpDetails/Components/AddNoteModal'
import EmptyNotesState from 'features/bpList/Components/EmptyNotesState'

const GeneralNotes = ({ bp, user }) => {
  const notes = useMemo(() => {
    return propOr([], 'notes', bp).filter(note => isNil(note.floorNumber))
  }, [bp])

  const canEdit = canEditBp(user, bp, true)

  return (
    <div>
      <NotesHeaderWrapper>
        <NotesHeader>General Notes</NotesHeader>
        {canEdit && (
          <AddNoteModal>
            <AddNoteButton>+ Add note</AddNoteButton>
          </AddNoteModal>
        )}
      </NotesHeaderWrapper>
      {
        isEmpty(notes)
          ? <EmptyNotesState
            text={
              canEdit
                ? 'Add your first note to this Building Passport'
                : 'No floor notes were added'
            }
            buttonText='+ Add note'
            padding='50px 0'
            hideButton={!canEdit}
          />
          : notes.map(note => <Note key={note.id} note={note} user={user} />)
      }
    </div>
  )
}

export default GeneralNotes

const NotesHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

const NotesHeader = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .3px;
`

const AddNoteButton = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .3px;
`
