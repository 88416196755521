import React from 'react'
import styled from 'styled-components'

export const LargeScreenContent = ({ children }) =>
  <LargeWrapper>{children}</LargeWrapper>

export const SmallerScreenContent = ({ children }) =>
  <SmallWrapper>{children}</SmallWrapper>

export const smallScreenCss = css => `
  @media (max-width: 1279px) {
    ${css}
  }
`

const LargeWrapper = styled.div`
  display: none;
  
  @media (min-width: 1280px) {
    display: block;
  }
`

const SmallWrapper = styled.div`
  display: block;
  
  @media (min-width: 1280px) {
    display: none;
  }
`
