import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSelectedBpRoutine, fetchUserBpsRoutine } from 'features/bpList/ducks/actions'
import { getBpsCount, getPagesCount, getUserBps } from 'features/bpList/ducks/selectors'
import styled from 'styled-components'
import PageHeader from 'components/atoms/PageHeader'
import Table from 'features/bpList/Components/Table'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/atoms/Pagination'
import Filters from 'features/filters/Filters'
import { getFiltersQuery } from 'utils/filters'
import { isNotNilOrEmpty } from 'utils/ramda'
import { not, values, equals, isEmpty } from 'ramda'
import Drawer from '@material-ui/core/Drawer'
import FilterListIcon from '@material-ui/icons/FilterList'
import EmptyBpList from 'features/bpList/Components/EmptyBpsListState'
import { selectIsFetchingBps } from 'ducks/loaderSelectors'
import CircularProgress from '@material-ui/core/CircularProgress'
import { clearBpFilesRoutine } from 'ducks/files/actions'

const defaultFilters = {
  status: 'draft',
  owner: [],
  responsible_person: [],
  created_at: null,
  updated_at: null,
  building_types: []
}

const Drafts = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const bps = useSelector(getUserBps)
  const pagesCount = useSelector(getPagesCount)
  const totalFound = useSelector(getBpsCount)
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [filters, setFilters] = useState(defaultFilters)
  const [sortingOptions, setSortingOptions] = useState({
    column: 'name',
    desc: false,
    page: 1
  })
  const isLoading = useSelector(selectIsFetchingBps)

  const handleFetchBps = () => {
    const { page, desc, column } = sortingOptions
    dispatch(fetchUserBpsRoutine({
      sort: desc ? `-${column}` : column,
      filters: getFiltersQuery(filters),
      page
    }))
  }

  useEffect(() => {
    handleFetchBps()
  }, [sortingOptions, filters])

  useEffect(() => {
    setSortingOptions(prev => ({
      ...prev,
      page: prev.page > pagesCount ? pagesCount : prev.page
    }))
  }, [pagesCount])

  const handleChangeSorting = column => () => {
    setSortingOptions(prev => ({
      ...sortingOptions,
      column,
      desc: column === prev.column
        ? !prev.desc
        : false
    }))
  }

  const handlePageChange = page => {
    setSortingOptions({
      ...sortingOptions,
      page
    })
  }

  const openDrawer = () => setDrawerOpen(true)
  const closeDrawer = () => setDrawerOpen(false)

  const handleClearFilters = () => setFilters(defaultFilters)

  const shouldRenderClearButton = useMemo(() => {
    return values(filters).some(e => isNotNilOrEmpty(e)) && not(equals(filters, defaultFilters))
  }, [filters])

  useEffect(() => {
    dispatch(clearSelectedBpRoutine())
    dispatch(clearBpFilesRoutine())
  }, [])

  return (
    <PageWrapper>
      <ContentWrapper>
        <HeaderWrapper>
          <PageHeader>
            Drafts
          </PageHeader>
          <FiltersIcon onClick={openDrawer} />
        </HeaderWrapper>
        <ResultInfo>
          <BpsCount>
            {
              totalFound > 0 && !isLoading && (
                <>
                  <span>{totalFound}&nbsp;</span>
                  {t('bpList.countResults')}
                </>
              )
            }
          </BpsCount>
          {
            shouldRenderClearButton &&
            <ClearFiltersButton onClick={handleClearFilters}>
              {t('bpList.clearFilters')}
            </ClearFiltersButton>
          }
        </ResultInfo>
        {
          isLoading
            ? <Loading><CircularProgress /></Loading>
            : isEmpty(bps) && equals(defaultFilters, filters)
              ? <EmptyBpList />
              : (
                <Table
                  handleRefetch={handleFetchBps}
                  sortingOptions={sortingOptions}
                  sortingChange={handleChangeSorting}
                  bps={bps}
                  isDraftTable
                />
              )
        }
        <Pagination
          onChange={handlePageChange}
          totalPages={pagesCount}
          currentPage={sortingOptions.page}
        />
      </ContentWrapper>
        <Drawer
          anchor='right'
          variant='persistent'
          open={isDrawerOpen}
          onClose={closeDrawer}
        >
          <FiltersWrapper>
            <Filters
              closeDrawer={closeDrawer}
              parentFilters={filters}
              getFilters={setFilters}
              defaultFilters={defaultFilters}
              withCloseIcon
            />
          </FiltersWrapper>
        </Drawer>
    </PageWrapper>
  )
}

export default Drafts

const PageWrapper = styled.div`
  display: flex;
  padding-bottom: 50px;
`
const ContentWrapper = styled.div`
  width: 100%;
`
const FiltersWrapper = styled.div`
  width: 280px;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
  top: 0;
  position: relative;
`

const ResultInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const BpsCount = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 13px;

  span {
    font-weight: bold;
  }
`

const ClearFiltersButton = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FiltersIcon = styled(FilterListIcon)`
  transition: all .3s;
  cursor: pointer;
  font-size: 30px !important;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`
