import React, { useState } from 'react'
import styled from 'styled-components'
import { USER_ROLES } from 'utils/userRoles'
import { formatDate } from 'utils/date'
import BoxButton from 'features/accessControl/components/BoxButton'
import Modal from 'components/atoms/Modal'
import { useDispatch } from 'react-redux'
import { acceptAccessRequestRoutine, denyAccessRequestRoutine } from 'features/AccessRequest/ducks/actions'
import Select from 'components/atoms/Select'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import Input from 'components/atoms/Input'
import { isEmpty } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { acceptUnitAccessRequestRoutine, denyUnitAccessRequestRoutine } from 'ducks/units/actions'
import { hideEmail, hidePhoneNumber } from 'utils/user'

const RequestBox = ({ request, bp, unit }) => {
  const { user } = request
  const [restoreModalOpen, setRestoreModalOpen] = useState(false)
  const [selectedReason, setSelectedReason] = useState('Do not give reason')
  const [ownReason, setOwnReason] = useState('')
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const dispatch = useDispatch()

  const handleRestoreModalOpen = () => setRestoreModalOpen(true)
  const handleRestoreModalClose = () => setRestoreModalOpen(false)

  const handleCancelModalOpen = () => setCancelModalOpen(true)
  const handleCancelModalClose = () => setCancelModalOpen(false)

  const acceptAccess = () => {
    if (isNotNilOrEmpty(unit)) {
      dispatch(acceptUnitAccessRequestRoutine({
        bpId: bp.id,
        unitId: unit.id,
        requestId: request.id
      }))
    } else {
      dispatch(acceptAccessRequestRoutine({
        bpId: request.buildingPassportId,
        requestId: request.id
      }))
    }
    handleRestoreModalClose()
  }

  const denyAccess = () => {
    if (isNotNilOrEmpty(unit)) {
      dispatch(denyUnitAccessRequestRoutine({
        bpId: bp.id,
        unitId: unit.id,
        requestId: request.id,
        reason: selectedReason === 'other'
          ? ownReason
          : selectedReason
      }))
    } else {
      dispatch(denyAccessRequestRoutine({
        bpId: request.buildingPassportId,
        requestId: request.id,
        reason: selectedReason === 'other'
          ? ownReason
          : selectedReason
      }))
    }
    handleCancelModalClose()
  }

  return (
    <BoxWrapper>
      <PersonDetailsWrapper>
        <div><span>{user.firstName} {user.lastName}</span></div>
        <div>Email: {hideEmail(user.email)}</div>
        <div>Phone: {hidePhoneNumber(user.phoneNumber)}</div>
      </PersonDetailsWrapper>
      <Role>{USER_ROLES[request.relationType]}</Role>
      <DateAndButtonsWrapper>
        <DateWrapper>
          <div>Sent:</div>
          {formatDate(request.createdAt)}
        </DateWrapper>
        <ButtonsWrapper>
          <BoxButton onClick={handleRestoreModalOpen}>Accept</BoxButton>
          <BoxButton onClick={handleCancelModalOpen}>Deny</BoxButton>
        </ButtonsWrapper>
      </DateAndButtonsWrapper>
      <Modal
        title='Accept request'
        open={restoreModalOpen}
        onClose={handleRestoreModalClose}
        onCancel={handleRestoreModalClose}
        onSubmit={acceptAccess}
        withCancel
        withSubmit
      >
        Do you really want to accept this request?
      </Modal>
      <Modal
        title='Deny request'
        open={cancelModalOpen}
        onClose={handleCancelModalClose}
        onCancel={handleCancelModalClose}
        disabled={
          isEmpty(selectedReason)
            ? true
            : selectedReason === 'other'
              ? isEmpty(ownReason)
              : false
        }
        onSubmit={denyAccess}
        withCancel
        withSubmit
      >
        <ModalContent>
        <p>
          Do you really want to deny this request?
        </p>
        <InputDescription margin='20px 0'>
          You can select a reason for denying this user access to the Building Passport for <strong>{bp.name}</strong>.
          They will be sent an email outlining the reason you select below:
        </InputDescription>
        <Select
          name='selectedReason'
          placeholder='Select'
          options={[
            {
              label: 'Do not give reason',
              value: 'no_reason'
            },
            {
              label: 'I do not recognise this person',
              value: 'not_recognised'
            },
            {
              label: 'I do not wish to share building information with this person',
              value: 'dont_want_to_share'
            },
            {
              label: 'The information is sensitive and not able to be shared at present',
              value: 'information_is_sensitive'
            },
            {
              label: 'The person has applied under the wrong role (e.g. Owner rather than Occupant)',
              value: 'wrong_role'
            },
            {
              label: 'Other (enter the wording you wish the user to be sent)',
              value: 'other'
            }
          ]}
          value={selectedReason}
          label='Reason'
          onChange={(_, value) => setSelectedReason(value)}
        />
          {
            selectedReason === 'other' && (
              <Input
                name='otherReason'
                label='Your reason'
                multiline
                rows={4}
                value={ownReason}
                onChange={(_, value) => setOwnReason(value)}
              />
            )
          }
        </ModalContent>
      </Modal>
    </BoxWrapper>
  )
}

export default RequestBox

const BoxWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 4px;
`

const PersonDetailsWrapper = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.haiti};
  padding: 0 5px 0 0;
  width: 60%;

  span {
    font-size: 14px;
  }
`

const Role = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  padding: 0 5px;
  width: 40%;
`

const DateWrapper = styled.div`
  font-size: 11px;
  padding: 0 5px;
  margin-right: 10px;
`

const ButtonsWrapper = styled.div`
  min-width: 110px;
`

const ModalContent = styled.div`
  max-width: 320px;
  margin: 0 auto;
`

const DateAndButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
`
