import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isNotNil } from 'utils/ramda'

export const Checkbox = ({
  disabled,
  value,
  isChecked,
  label,
  name,
  onChange
 }) => {
  const [checked, setChecked] = useState(value || false)
  // const [touched, _setTouched] = useState(false)
  // const [{ valid, error }, _validate] = useState({
  //   valid: false,
  //   error: ''
  // })

  // useEffect(() => {
  //   if (touched) {
  //     validate(name, v => {
  //       _validate({ valid: v.valid, error: v.errors })
  //     })
  //   }
  // }, [checked, touched, isChecked])

  useEffect(() => {
    setChecked(value)
  }, [value])

  const handleChange = e => {
    // _setTouched(true)
    setChecked(e.target.checked)
    onChange(name, e.target.checked)
  }

  return (
    <>
      <CheckboxLabel disabled={disabled} valid>
        {label}
        <CheckboxInput
          disabled={disabled}
          type='checkbox'
          checked={isNotNil(isChecked) ? isChecked : checked}
          onChange={handleChange}
        />
        <CheckboxMark valid />
      </CheckboxLabel>
      {/*{!valid && !disabled && <CheckboxError>{error}</CheckboxError>}*/}
    </>
  )
}

Checkbox.defaultProps = {
  isChecked: null,
  onChange: () => {},
  validate: () => {},
  disabled: false,
  value: false,
  label: '',
  name: '',
}

export default Checkbox

export const CheckboxLabel = styled.label`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme, valid }) => valid ? 'inherit' : theme.colors.error};
  opacity: ${({ disabled }) => disabled ? '.5' : '1'};
`

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const CheckboxMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme, valid }) => valid
    ? theme.colors.mediumGrey
    : theme.colors.error};
  border-width: 1px;
  border-style: solid;
  transition: all .3s;
  
  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 3px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  ${CheckboxLabel} ${CheckboxInput}:checked ~ & {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }
  ${CheckboxLabel} ${CheckboxInput}:checked ~ &::after {
    display: block;
  }
`
//
// const CheckboxError = styled.div`
//   color: ${({ theme }) => theme.colors.error};
//   margin-left: 14px;
//   margin-right: 14px;
//   margin-top: 3px;
//   font-size: 0.75rem;
// `
