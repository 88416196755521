import React, { useEffect, useState } from 'react'
import { omit, propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import styled, { css } from 'styled-components'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import Select from 'components/atoms/Select'
import CountButton from 'components/atoms/CountButton'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { formatDate } from 'utils/date'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const EditResidents = ({ closeModal }) => {
  const dispatch = useDispatch()
  const bp = useSelector(getSelectedBp)
  const [valid, setValid] = useState(false)
  const [values, setValues] = useState({
    occupied: propOr(null, 'occupied', bp),
    expectedBuildingOccupantsAmount: propOr(0, 'expectedBuildingOccupantsAmount', bp),
    disabledOccupantsAmount: propOr(0, 'disabledOccupantsAmount', bp),
    elderlyOccupantsAmount: propOr(0, 'elderlyOccupantsAmount', bp),
    expectedOccupationDateInWeeks: propOr(2, 'expectedOccupationDateInWeeks', bp),
    expectedOccupationDate: propOr('', 'expectedOccupationDate', bp),
    id: propOr('', 'id', bp)
  })
  const [edit, setEdit] = useState(!isNotNilOrEmpty(propOr('', 'expectedOccupationDate', bp)))

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      expectedOccupationDateInWeeks: propOr(2, 'expectedOccupationDateInWeeks', bp),
      expectedOccupationDate: propOr('', 'expectedOccupationDate', bp),
    }))
  }, [bp])

  const handleUpdate = () => {
    const sectionValues = values.occupied
      ? omit([
        'expectedOccupationDateInWeeks',
        'expectedOccupationDate'
      ], values)
      : omit([
        'disabledOccupantsAmount',
        'elderlyOccupantsAmount'
      ], values)
    dispatch(updateBpRoutine({ ...sectionValues, isEdit: true }))
    closeModal()
  }

  useEffect(() => {
    setValid(isNotNil(values.occupied))
  }, [values])

  return (
    <FormWrapper>
      <CountButton
        value={values.expectedBuildingOccupantsAmount}
        label='Expected number building occupants'
        name='expectedBuildingOccupantsAmount'
        onChange={handleOnChange}
      />
      <Select
        placeholder='Select'
        value={values.occupied}
        label='Occupied'
        name='occupied'
        onChange={handleOnChange}
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
      {
        isNotNil(values.occupied) && (
          <>
            {
              values.occupied ? (
                <>
                  <AddDetails>Add details</AddDetails>
                  <SubFormWrapper>
                    <InputDescription>
                      Rough estimate
                    </InputDescription>
                    <MarginBox>
                      <CountButton
                        min={0}
                        value={values.disabledOccupantsAmount}
                        label='Disabilities'
                        name='disabledOccupantsAmount'
                        onChange={handleOnChange}
                      />
                    </MarginBox>
                    <CountButton
                      min={0}
                      label='Elderly'
                      value={values.elderlyOccupantsAmount}
                      name='elderlyOccupantsAmount'
                      onChange={handleOnChange}
                    />
                  </SubFormWrapper>
                </>
              ) : (
                <>
                  {
                    !edit
                    ? (
                        <>
                          <SubFormWrapper>
                            <InputDescription>
                              We will send you a reminder
                            </InputDescription>
                            <NotificationDateWrapper>
                              Notification date:
                              <Date>
                                {values.expectedOccupationDate && formatDate(values.expectedOccupationDate)}
                              </Date>
                            </NotificationDateWrapper>
                            <EditButtonWrapper>
                              <EditButton onClick={() => setEdit(true)}>
                                Edit date
                              </EditButton>
                            </EditButtonWrapper>
                          </SubFormWrapper>
                        </>
                      ) : (
                          <>
                            <Text>
                              When do you expect this building to be occupied?
                            </Text>
                            <SubFormWrapper>
                              <Select
                                value={values.expectedOccupationDateInWeeks}
                                label='Enter the approximate date'
                                name='expectedOccupationDateInWeeks'
                                onChange={handleOnChange}
                                options={[
                                  { label: '2 weeks', value: 2 },
                                  { label: '4 weeks', value: 4 }
                                ]}
                              />
                              <InputDescription>
                                We will send you a notification after this date.
                              </InputDescription>
                              <EditButtonWrapper>
                                <EditButton onClick={() => {
                                  dispatch(updateBpRoutine({
                                    id: bp.id,
                                    expectedOccupationDateInWeeks: values.expectedOccupationDateInWeeks
                                  }))
                                  setEdit(false)
                                }}>
                                  Save
                                </EditButton>
                              </EditButtonWrapper>
                            </SubFormWrapper>
                          </>
                        )
                  }

                </>
              )
            }
          </>
        )
      }
      <NextButton
        onClick={handleUpdate}
        disabled={!valid}
      >
        Save changes
      </NextButton>
    </FormWrapper>
  )
}

export default EditResidents

const FormWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`

const SubFormWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(247, 248, 253, 0.51);
  border: 1px solid #e9eff4;
  padding: 20px 15px;
  margin-bottom: 30px;
`

const MarginBox = styled.div`
  margin: 20px 0 25px;
`

const AddDetails = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .11px;
  font-weight: bold;
  margin-bottom: 15px;
`

const Text = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .11px;
  font-weight: bold;
  margin-bottom: 15px;
`

const Date = styled.div`
  display: inline-block;
  font-weight: bold;
  font-size: 13px;
  padding-top: 3px;
`

const NotificationDateWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const EditButton = styled.button`
  width: 195px;
  height: 36px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 2px;
  transition: all .3s;
  outline: none;
  box-shadow: none;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.grey[500]};
    border-color: ${({ theme }) => theme.colors.grey[500]};

    &:hover {
      color: ${({ theme }) => theme.colors.grey[500]};
      background-color: ${({ theme }) => theme.colors.white};
    }
  `}
`

const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0 0;
`
