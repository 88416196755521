import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { verifyEmailRoutine } from 'features/auth/ducks/actions'
import { isNotEmpty } from 'utils/ramda'
import ConfirmationPage from 'features/auth/components/ConfirmationPage'

const EmailConfirmation = () => {
  const [status, setStatus] = useState('')
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const queryParams = window.location.search
  const dispatch = useDispatch()

  useEffect(() => {
    if (isMobile) {
      window.location = `erpviewer://AuthStackScreen/ConfirmEmail${queryParams}`
    }

    if (queryParams) {
      setTimeout(() => {
        dispatch(verifyEmailRoutine({ queryParams, setStatus }))
      }, 1000)
    }
  }, [queryParams])

  return isNotEmpty(status)
    ? <ConfirmationPage status={status} />
    : null
}

export default EmailConfirmation
