import { applySpec, pathOr, propOr, pipe, values, join, head, map } from 'ramda'
import { createSelector } from 'reselect'
import { isNotEmpty } from 'utils/ramda'

const getAuthState = state => state.auth

export const getCurrentUser = createSelector(
  getAuthState,
  propOr({}, 'user')
)

export const selectCurrentInvitation = createSelector(
  getAuthState,
  propOr({}, 'currentInvitation')
)

export const getCurrentUserName = createSelector(
  getAuthState,
  state => pipe(
    applySpec({
      firstName: pathOr('', ['user', 'firstName']),
      lastName: pathOr('', ['user', 'lastName'])
    }),
    values,
    join(' ')
  )(state)
)

export const getCurrentUserInitials = createSelector(
  getAuthState,
  state => pipe(
    applySpec({
      firstName: pathOr('', ['user', 'firstName']),
      lastName: pathOr('', ['user', 'lastName'])
    }),
    values,
    map(head),
    join('')
  )(state)
)

export const getUserFirstName = createSelector(
  getAuthState,
  pathOr('', ['user', 'firstName'])
)

export const getUserPhotoUrl = createSelector(
  getAuthState,
  pathOr(null, ['user', 'avatar'])
)

export const getCurrentUserRole = createSelector(
  getAuthState,
  pathOr('', ['user', 'type'])
)

export const selectAccreditationTypes = createSelector(
  getAuthState,
  propOr([], 'accreditations')
)

export const selectPublicApiToken = createSelector(
  getAuthState,
  propOr(null, 'publicApiToken')
)

export const isUserLoggedIn = createSelector(
  getCurrentUser,
  isNotEmpty
)
