import React from 'react'
import styled from 'styled-components'
import ClearIcon from '@material-ui/icons/Clear'
import { propOr } from 'ramda'
import { isNotNil } from 'utils/ramda'
import { hidePhoneNumber } from 'utils/user'

const PersonDetailsBox = ({ details, handleEdit, hideEdit }) => {
  return (
    <Wrapper>
      <Name>{propOr('', 'firstName', details)}&nbsp;{propOr('', 'lastName', details)}</Name>
      <div>{hidePhoneNumber(propOr('', 'phoneNumber', details))}</div>
      <div>{propOr('', 'email', details)}</div>
      {isNotNil(details) && isNotNil(details.company) &&
        <Company>
          <CompanyName>{propOr('', 'company', details)}</CompanyName>
        </Company>
      }
      {!hideEdit && (
        <EditWrapper>
          <span>CLEAR</span>
          <EditIcon onClick={handleEdit} />
        </EditWrapper>
      )}
    </Wrapper>
  )
}

export default PersonDetailsBox

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 6px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[800]};
  margin-bottom: 25px;
  position: relative;
`

const Name = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 4px;
  font-weight: bold;
`

const Company = styled.div`
  margin-top: 10px;
`

const CompanyName = styled.div`
  font-weight: bold;
`

const EditIcon = styled(ClearIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  cursor: pointer;
`

const EditWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;

  span {
    transition: all .3s;
    opacity: 0;
    margin-right: 3px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`
