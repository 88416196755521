import {
fetchBpRoutine,
clearSelectedBpRoutine,
getUserRelationToBpRoutine,
getBpConnectedUsersRoutine,
getBpConnectedUsersByEmailRoutine,
getUsersByEmailRoutine,
clearConnectedUsersRoutine,
clearUsersFoundByEmailRoutine
} from 'features/bpDetails/ducks/actions'
import { pathOr } from 'ramda'
import { createBpRoutine, removeNoteRoutine } from 'features/createBp/ducks/actions'
import { logoutUserRoutine } from 'features/auth/ducks/actions'
import emptyBpDetailsState from 'features/bpDetails/ducks/state'

export default (state = emptyBpDetailsState, action) => {
  switch (action.type) {
    case fetchBpRoutine.SUCCESS:
      return {
        ...state,
        selectedBp: action.payload,
        currentBpFiles: pathOr([], ['payload', 'files'], action)
      }
    case removeNoteRoutine.SUCCESS:
      return {
        ...state,
        selectedBp: {
          ...state.selectedBp,
          notes: state.selectedBp.notes.filter(e => e.id !== action.payload)
        }
      }
    case getUsersByEmailRoutine.SUCCESS:
      return {
        ...state,
        relationship: {
          ...state.relationship,
          [action.payload.type]: action.payload.data
        }
      }
    case clearUsersFoundByEmailRoutine.SUCCESS:
      return {
        ...state,
        relationship: emptyBpDetailsState.relationship
      }
    case getBpConnectedUsersRoutine.SUCCESS:
    case getBpConnectedUsersByEmailRoutine.SUCCESS:
      return {
        ...state,
        connectedUsers: action.payload
      }
    case getBpConnectedUsersByEmailRoutine.FAILURE:
    case clearConnectedUsersRoutine.SUCCESS:
      return {
        ...state,
        connectedUsers: []
      }
    case createBpRoutine.SUCCESS:
      return {
        ...state,
        selectedBp: action.payload
      }
    case logoutUserRoutine.SUCCESS:
      return emptyBpDetailsState
    case clearSelectedBpRoutine.SUCCESS:
      return {
        ...state,
        selectedBp: {}
      }
    case getUserRelationToBpRoutine.SUCCESS:
      return {
        ...state,
        userRelation: action.payload
      }
    default:
      return state
  }
}
