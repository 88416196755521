import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as bpService from 'services/BpService'
import * as authService from 'services/AuthService'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'

export const requestAccessToBpRoutine = createRoutine('REQUEST_ACCESS_TO_BP')
export const searchInAllBpsRoutine = createRoutine('SEARCH_IN_ALL_BPS')
export const acceptAccessRequestRoutine = createRoutine('ACCEPT_ACCESS_REQUEST')
export const denyAccessRequestRoutine = createRoutine('DENY_ACCESS_REQUEST')
export const getAccessRequestsRoutine = createRoutine('GET_ACCESS_REQUESTS')
export const clearSearchedBpsListRoutine = createRoutine('CLEAR_ACCESS_SEARCHED_BPS')
export const fetchAuthUserAccessRequestsRoutine = createRoutine('FETCH_PENDING_ACCESS_REQUESTS')

function * requestAccessToBp ({ payload }) {
  yield put(requestAccessToBpRoutine.request())
  try {
    yield call(bpService.requestAccessToBp, payload)
    yield put(requestAccessToBpRoutine.success())
    toast.success('Your access request has been sent')
    yield put(fetchAuthUserAccessRequestsRoutine())
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(requestAccessToBpRoutine.failure(error))
  }
}

function * searchInAllBps ({ payload }) {
  yield put(searchInAllBpsRoutine.request())
  try {
    const { data } = yield call(bpService.searchInAllBps, payload)
    yield put(searchInAllBpsRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(searchInAllBpsRoutine.failure(error))
  }
}

function * fetchAuthUserAccessRequests () {
  yield put(fetchAuthUserAccessRequestsRoutine.request())
  try {
    const { data } = yield call(authService.fetchAuthUserAccessRequests)
    yield put(fetchAuthUserAccessRequestsRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(fetchAuthUserAccessRequestsRoutine.failure(error))
  }
}

function * clearSearchedBpsList () {
  yield put(clearSearchedBpsListRoutine.success())
}

function * acceptAccessRequest ({ payload }) {
  yield put(acceptAccessRequestRoutine.request())
  try {
    yield call(bpService.acceptAccessRequest, payload)
    yield put(acceptAccessRequestRoutine.success())
    yield put(fetchBpRoutine({ id: payload.bpId }))
    yield put(getAccessRequestsRoutine({ bpId: payload.bpId }))
    toast.success('Access request has been accepted')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(acceptAccessRequestRoutine.failure(error))
  }
}

function * denyAccessRequest ({ payload }) {
  yield put(denyAccessRequestRoutine.request())
  try {
    yield call(bpService.denyAccessRequest, payload)
    yield put(denyAccessRequestRoutine.success())
    yield put(fetchBpRoutine({ id: payload.bpId }))
    yield put(getAccessRequestsRoutine({ bpId: payload.bpId }))
    toast.success('Access request has been denied')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(denyAccessRequestRoutine.failure(error))
  }
}

function * getAccessRequests ({ payload }) {
  yield put(getAccessRequestsRoutine.request())
  try {
    const { data } = yield call(bpService.getAccessRequests, payload)
    yield put(getAccessRequestsRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getAccessRequestsRoutine.failure(error))
  }
}

// WATCHERS
export function * requestAccessToBpWatcher () {
  yield takeLatest(requestAccessToBpRoutine.TRIGGER, requestAccessToBp)
}

export function * searchInAllBpsWatcher () {
  yield takeLatest(searchInAllBpsRoutine.TRIGGER, searchInAllBps)
}

export function * acceptAccessRequestWatcher () {
  yield takeLatest(acceptAccessRequestRoutine.TRIGGER, acceptAccessRequest)
}

export function * denyAccessRequestWatcher () {
  yield takeLatest(denyAccessRequestRoutine.TRIGGER, denyAccessRequest)
}

export function * getAccessRequestsWatcher () {
  yield takeLatest(getAccessRequestsRoutine.TRIGGER, getAccessRequests)
}

export function * clearSearchedBpsListWatcher () {
  yield takeLatest(clearSearchedBpsListRoutine.TRIGGER, clearSearchedBpsList)
}

export function * fetchAuthUserAccessRequestsWatcher () {
  yield takeLatest(fetchAuthUserAccessRequestsRoutine.TRIGGER, fetchAuthUserAccessRequests)
}

// SAGAS
export const requestAccessSagas = [
  fork(requestAccessToBpWatcher),
  fork(searchInAllBpsWatcher),
  fork(acceptAccessRequestWatcher),
  fork(denyAccessRequestWatcher),
  fork(clearSearchedBpsListWatcher),
  fork(fetchAuthUserAccessRequestsWatcher),
  fork(getAccessRequestsWatcher)
]
