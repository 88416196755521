import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import { useDispatch } from 'react-redux'
import {
  validateUpdateField,
  validateUpdateValues
} from 'features/auth/ducks/schema'
import FullWidthButton from 'features/auth/components/FullWidthButton'
import { dissoc, isEmpty, pick } from 'ramda'
import PhoneField from 'components/atoms/PhoneInput'
import {
  logoutUserRoutine,
  updateUserRoutine
} from 'features/auth/ducks/actions'

const emptyValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  postalCode: '',
  street: '',
  city: ''
}

const UpdateInfoExchangeAccount = ({ userInfo }) => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setIsValid] = useState(false)
  const [phoneValid, setPhoneValid] = useState(false)
  const dispatch = useDispatch()

  const logoutUser = () => dispatch(logoutUserRoutine())

  useEffect(() => {
    !isEmpty(userInfo) &&
      setValues(prev => ({
        ...prev,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phoneNumber: userInfo.phoneNumber || '',
        postalCode: userInfo.postalCode,
        street: userInfo.street,
        city: userInfo.city
      }))
  }, [userInfo])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      updateUserRoutine(
        pick(
          [
            'firstName',
            'lastName',
            'phoneNumber',
            'postalCode',
            'street',
            'city'
          ],
          values
        )
      )
    )
  }

  useEffect(() => {
    validateUpdateValues(dissoc('phoneNumber', values), setIsValid)
  }, [values])

  return (
    <PageWrapper>
      <SignupWrapper>
        <FormDescription>
          Fill the missing data in order to get access to the platform
        </FormDescription>
        <SignupForm>
          <SectionName>personal details</SectionName>
          <Input
            required
            value={values.firstName}
            label='First name'
            name='firstName'
            onChange={handleOnChange}
            validate={validateUpdateField(values)}
          />
          <Input
            required
            value={values.lastName}
            label='Last name'
            name='lastName'
            onChange={handleOnChange}
            validate={validateUpdateField(values)}
          />
          <PhoneField
            name='phoneNumber'
            label='Phone number'
            value={values.phoneNumber}
            onChange={handleOnChange}
            validate={valid => setPhoneValid(valid)}
          />
          <SectionName>contact details</SectionName>
          <Input
            required
            value={values.postalCode}
            label='Postal code'
            name='postalCode'
            onChange={handleOnChange}
            validate={validateUpdateField(values)}
          />
          <Input
            required
            label='Address'
            value={values.street}
            name='street'
            onChange={handleOnChange}
            validate={validateUpdateField(values)}
          />
          <Input
            required
            label='City'
            name='city'
            value={values.city}
            onChange={handleOnChange}
            validate={validateUpdateField(values)}
          />
        </SignupForm>
        <ButtonsWrapper>
          <FullWidthButton variant='white' onClick={logoutUser} hasMargin>
            Logout
          </FullWidthButton>
          <FullWidthButton disabled={!valid || !phoneValid} onClick={handleSubmit}>
            Update account
          </FullWidthButton>
        </ButtonsWrapper>
      </SignupWrapper>
    </PageWrapper>
  )
}

export default UpdateInfoExchangeAccount

const PageWrapper = styled.div`
  width: 645px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  height: 90vh;
  overflow-y: auto;
  overflow-x: none;
  scrollbar-color: #0c1229 #fff;
  scrollbar-width: thin;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.white};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #0c1229;
  }
`
const SignupWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 50px 160px;
  border-radius: 4px;
`

const FormDescription = styled.div`
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: 0.7;
  margin: 25px 0 30px;
`

const SignupForm = styled.form`
  height: auto;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const SectionName = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.1px;
  margin: 15px 0;

  span {
    color: ${({ theme }) => theme.colors.grey[700]};
    text-transform: lowercase;
  }
`
