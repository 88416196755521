import React, { useCallback, useEffect, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import styled from 'styled-components'
import { redirect } from 'utils/paths'
import { ReactComponent as NotificationIcon } from 'assets/images/notification.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllNotificationsRoutine,
  fetchUnreadNotificationsCounterRoutine,
  fetchUnseenNotificationsCounterRoutine,
  markAllNotificationsAsReadRoutine,
  markAllNotificationsAsSeenRoutine, markNotificationAsReadRoutine
} from 'features/notifications/ducks/actions'
import {
  getAllNotifications,
  getUnreadNotificationsCounter,
  getUnseenNotificationsCounter
} from 'features/notifications/ducks/selectors'
import NotificationItem from 'features/notifications/components/NotificationItem'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router'

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const notifications = useSelector(getAllNotifications)
  const unreadCounter = useSelector(getUnreadNotificationsCounter)
  const unseenCounter = useSelector(getUnseenNotificationsCounter)
  const { location } = useHistory()
  const { pathname } = location

  useEffect(() => {
    dispatch(fetchAllNotificationsRoutine())
    dispatch(fetchUnreadNotificationsCounterRoutine())
    dispatch(fetchUnseenNotificationsCounterRoutine())
  }, [])

  useEffect(() => {
    open && dispatch(fetchAllNotificationsRoutine())
  }, [open, unreadCounter])

  useEffect(() => {
    dispatch(fetchUnreadNotificationsCounterRoutine())
  }, [unreadCounter])

  const markAllAsRead = useCallback(() => {
    dispatch(markAllNotificationsAsReadRoutine())
  }, [dispatch])

  const markAsRead = useCallback(async id => {
    await dispatch(markNotificationAsReadRoutine(id))
  }, [dispatch])

  const openMenu = event => {
    setAnchorEl(event.currentTarget)
    dispatch(markAllNotificationsAsSeenRoutine())
  }
  const handleClose = () => setAnchorEl(null)

  const handleItemClick = (id, bpId, redirectPath) => () => {
    handleClose()
    markAsRead(id)
    const pathToRedirect = redirectPath ? redirectPath : `/bp-list/${bpId}`

    if (['bp-list', 'favourites', 'drafts'].some(el => pathname.includes(el))) {
      const fullDomain = `${window.location.protocol}//${window.location.hostname}${window.location.port
        ? ':' + window.location.port : ''}`
      window.location.href = `${fullDomain}${pathToRedirect}`
    } else {
      redirect(redirectPath ? redirectPath : `/bp-list/${bpId}`)
    }
  }

  return (
    <div>
      <Menu
        keepMounted
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        style={{
          marginTop: '10px'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        onClose={handleClose}
      >
        <NotificationsWrapper>
          <Title>Notifications</Title>
          {
            unreadCounter > 0 && (
              <UnreadWrapper>
                <UnreadCount>{unreadCounter} unread</UnreadCount>
                <MarkAsReadButton onClick={markAllAsRead}>mark all as read</MarkAsReadButton>
              </UnreadWrapper>

            )
          }
          <EntriesWrapper>
            {
              isNotNilOrEmpty(notifications)
                ? notifications.map(notification => (
                  <NotificationItem
                    key={notification.id}
                    handleClick={handleItemClick}
                    notification={notification}
                  />
                ))
                : <EmptyList>
                  Notifications list is empty
                </EmptyList>
            }
          </EntriesWrapper>
        </NotificationsWrapper>
      </Menu>
      <IconWrapper open={open} onClick={openMenu}>
        <NotificationIcon />
        {unseenCounter > 0 && <Badge>&bull;</Badge>}
        {open && <IconBackground />}
      </IconWrapper>

    </div>
  )
}

export default Notifications

const NotificationsWrapper = styled.div`
  box-sizing: border-box;
  width: 500px;
  padding: 15px;

  &:focus {
    outline: 0 !important;
  }
`

const Title = styled.div`
  box-sizing: border-box;
  width: 100%;
  //text-overflow: ellipsis;
  //overflow: hidden;
  //white-space: nowrap;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.darkGrey};
  margin-bottom: 10px;
`

const UnreadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

const UnreadCount = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};

  span {
    font-weight: bold;
  }
`

const MarkAsReadButton = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  cursor: pointer;
`

const IconWrapper = styled.div`
  position: relative;

  svg {
    position: relative;
    z-index: ${({ open }) => open ? 1 : 0};
  }
`

const IconBackground = styled.div`
  position: absolute;
  border-radius: 50%;
  opacity: .6;
  background-color: #dfe4f6;
  width: 40px;
  top: -7px;
  left: -9px;
  height: 40px;
`

const Badge = styled.div`
  position: absolute;
  cursor: pointer;
  box-sizing: border-box;
  bottom: 0;
  right: 27px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  font-weight: bold;
`

const EntriesWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100%;
`

const EmptyList = styled.div`
  font-size: 14px;
  padding: 20px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey[700]};
`
