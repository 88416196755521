import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFloorPlansForUnitRoutine } from 'ducks/units/actions'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { selectUnitFloorPlans } from 'ducks/units/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { PDFViewer } from 'components/PdfViewer'
import EmptyState from 'features/bpList/Components/EmptyState'
import { SectionHeader, SectionHeaderWrapper } from 'features/bpDetails/BpDetails'
import styled from 'styled-components'
import { isEmpty } from 'ramda'

const UnitFloorplans = ({ unit }) => {
  const bp = useSelector(getSelectedBp)
  const plans = useSelector(selectUnitFloorPlans)
  const dispatch = useDispatch()

  useEffect(() => {
    if (unit && !isEmpty(bp)) {
      dispatch(getFloorPlansForUnitRoutine({ bpId: bp.id, unitId: unit.id }))
    }
  }, [unit, bp])

  return (
    <FloorplansWrapper>
      <SectionHeaderWrapper>
        <SectionHeader>
          Plans
        </SectionHeader>
      </SectionHeaderWrapper>
      {
        isNotNilOrEmpty(plans)
          ? (
            <FilesWrapper>
              {
                plans.map(file => (
                  <PDFViewer
                    simple
                    file={file}
                    key={file.id}
                    openModal={() => {}}
                  />
                ))
              }
            </FilesWrapper>
          ) : (
            <EmptyState
              text={'There are no plans assigned to this floor'}
              hideButton
            />
          )
      }
    </FloorplansWrapper>
  )
}

export default UnitFloorplans

const FloorplansWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
  margin-top: 30px;
  height: fit-content;
`

const FilesWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  @media (min-width: 1390px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
