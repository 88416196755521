import React from 'react'
import styled, { css } from 'styled-components'

const BoxButton = props => {
  return <StyledButton {...props}>
    {props.children}
  </StyledButton>
}

export default BoxButton

const StyledButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.haiti};
  height: 24px;
  padding: 0 10px;
  font-size: 11px;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transition: all .3s;
  border-radius: 4px;
  margin-left: 5px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.haiti};
    background-color: ${({ theme }) => theme.colors.white};
  }
  
  ${({ red }) => red && css`
    border-color: ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.error};
    background-color: ${({ theme }) => theme.colors.white};
    
    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.error};
    }  
  `}
  
  ${({ outline }) => outline && css`
    border-color: ${({ theme }) => theme.colors.haiti};
    color: ${({ theme }) => theme.colors.haiti};
    background-color: ${({ theme }) => theme.colors.white};
    
    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.haiti};
    }
  `}
`
