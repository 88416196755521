import emptyUnitsState from 'ducks/units/state'
import {
  setSelectedUnitRoutine,
  clearSelectedUnitRoutine,
  fetchBpUnitsRoutine,
  fetchUnitRoutine,
  fetchUnitInvitationsListRoutine,
  fetchUserUnitsRoutine,
  updateSelectedUnitRoutine,
  fetchNoticeBoardRoutine,
  getFloorPlansForUnitRoutine,
  getUnitAccessRequestsRoutine,
  getUserRelationToUnitRoutine,
  fetchBpUnitsForAccessRequestRoutine
} from 'ducks/units/actions'
import { pathOr } from 'ramda'
import { clearSharedUnitsListForFileRoutine, getSharedUnitsListForFileRoutine } from 'ducks/files/actions'

export default (state = emptyUnitsState, action) => {
  switch (action.type) {
    case setSelectedUnitRoutine.SUCCESS:
      return {
        ...state,
        editUnit: action.payload
      }
    case updateSelectedUnitRoutine.SUCCESS:
      return {
        ...state,
        selectedUnit: action.payload
      }
    case clearSelectedUnitRoutine.SUCCESS:
      return {
        ...state,
        selectedUnit: null
      }
    case fetchBpUnitsRoutine.SUCCESS:
    case fetchBpUnitsForAccessRequestRoutine.SUCCESS:
      return {
        ...state,
        units: action.payload
      }
    case fetchUnitRoutine.SUCCESS:
      return {
        ...state,
        selectedUnit: action.payload
      }
    case fetchNoticeBoardRoutine.SUCCESS:
      return {
        ...state,
        notices: action.payload
      }
    case fetchUnitInvitationsListRoutine.SUCCESS:
      return {
        ...state,
        invitationsList: action.payload
      }
    case getSharedUnitsListForFileRoutine.SUCCESS:
      return {
        ...state,
        sharedUnitsList: action.payload
      }
    case clearSharedUnitsListForFileRoutine.SUCCESS:
      return {
        ...state,
        sharedUnitsList: []
      }
    case fetchUserUnitsRoutine.SUCCESS:
      return {
        ...state,
        userUnits: pathOr([], ['payload', 'data'], action),
        totalPages: pathOr(1, ['payload', 'meta', 'lastPage'], action),
        totalFound: pathOr(1, ['payload', 'meta', 'total'], action)
      }
    case getFloorPlansForUnitRoutine.SUCCESS:
      return {
        ...state,
        floorplans: action.payload
      }
    case getUnitAccessRequestsRoutine.SUCCESS:
      return {
        ...state,
        accessRequests: action.payload
      }
    case getUserRelationToUnitRoutine.SUCCESS:
      return {
        ...state,
        userRelation: action.payload
      }
    default:
      return state
  }
}
