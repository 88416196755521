import React from 'react'
import styled, { css } from 'styled-components'
import InfoIcon from '@material-ui/icons/Info'

const InputDescription = ({ children, fontSize = 13, margin }) => {
  return (
    <Wrapper margin={margin}>
      <IconWrapper>
        <StyledInfoIcon />
        <Text fontSize={fontSize}>
          {children}
        </Text>
      </IconWrapper>
    </Wrapper>
  )
}

export default InputDescription

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  
  ${({ margin }) => margin && css`
    margin: ${margin};
  `}
  
  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.secondary};
  margin-right: 7px;
  position: relative;
  top: 1px;
`

const Text = styled.div`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ theme }) => theme.colors.haiti};
`
