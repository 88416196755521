import React, { useEffect, useState } from 'react'
import { propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { validateAddressField, validateAddressValues } from 'features/bpList/ducks/schema'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const EditAddress = ({ closeModal, isAnalyticsView, currentPage }) => {
  const bp = useSelector(getSelectedBp)
  const dispatch = useDispatch()
  const [valid, setValid] = useState(false)
  const [values, setValues] = useState({
    postalCode: propOr('', 'postalCode', bp),
    street: propOr('', 'street', bp),
    city: propOr('', 'city', bp),
    id: propOr('', 'id', bp),
  })

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleUpdate = () => {
    dispatch(updateBpRoutine({
      ...values,
      isEdit: true,
      isAnalyticsView: isAnalyticsView,
      currentPage: currentPage
    }))
    closeModal()
  }

  useEffect(() => {
    validateAddressValues(values, setValid)
  }, [values])

  return (
    <FormWrapper>
      <Form>
        <Input
          required
          label='Postal code'
          value={values.postalCode}
          name='postalCode'
          onChange={handleOnChange}
          validate={validateAddressField(values)}
        />
        <Input
          required
          label='Address'
          value={values.street}
          name='street'
          onChange={handleOnChange}
          validate={validateAddressField(values)}
        />
        <Input
          required
          label='City'
          value={values.city}
          name='city'
          onChange={handleOnChange}
          validate={validateAddressField(values)}
        />
        <NextButton
          onClick={handleUpdate}
          disabled={!valid}
        >
          Save changes
        </NextButton>
      </Form>
    </FormWrapper>
  )
}

export default EditAddress

const FormWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`

const Form = styled.form`
  box-sizing: border-box;
  width: 100%;
`
