import React, { useState } from 'react'
import NextButton from 'features/createBp/components/atoms/NextButton'
import CountButton from 'components/atoms/CountButton'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { addBpUnitsRoutine } from 'ducks/units/actions'

const emptyValues = {
  buildingType: '',
  amount: 1
}

const AddUnitForm = ({ closeModal, bp, buildingType, isWizard }) => {
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleOnChange = (name, value) =>
    setValues(prev => ({ ...prev, [name]: value }))

  const handleSubmit = () => {
    dispatch(addBpUnitsRoutine({
      ...values,
      bpId: bp.id,
      buildingType,
      isWizard
    }))
    closeModal()
  }

  return (
    <FormWrapper>
      <CountButton
        required
        label='Number of units'
        name='amount'
        value={values.amount}
        onChange={handleOnChange}
      />
      <InputDescription>
        Enter the number of units you want to add to this type.
      </InputDescription>
      <ButtonWrapper>
        <NextButton onClick={handleSubmit}>
          Add units
        </NextButton>
      </ButtonWrapper>
    </FormWrapper>
  )
}

export default AddUnitForm

const FormWrapper = styled.div`
  width: 400px;
`

const ButtonWrapper = styled.div`
  margin-top: 30px;
`
