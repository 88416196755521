import { emptyNotificationsState } from 'features/notifications/ducks/state'
import {
  fetchUnreadNotificationsCounterRoutine,
  fetchUnseenNotificationsCounterRoutine,
  fetchAllNotificationsRoutine,
  markAllNotificationsAsReadRoutine,
  markAllNotificationsAsSeenRoutine, markNotificationAsReadRoutine
} from 'features/notifications/ducks/actions'

export default (state = emptyNotificationsState, action) => {
  switch (action.type) {
    case fetchAllNotificationsRoutine.SUCCESS:
      return {
        ...state,
        all: action.payload
      }
    case fetchUnreadNotificationsCounterRoutine.SUCCESS:
      return {
        ...state,
        unreadCounter: action.payload
      }
    case fetchUnseenNotificationsCounterRoutine.SUCCESS:
      return {
        ...state,
        unseenCounter: action.payload
      }
    case markAllNotificationsAsSeenRoutine.SUCCESS:
      return {
        ...state,
        unseenCounter: 0
      }
    case markAllNotificationsAsReadRoutine.SUCCESS:
      return {
        ...state,
        unreadCounter: 0
      }
    case markNotificationAsReadRoutine.SUCCESS:
      return {
        ...state,
        unreadCounter: state.unreadCounter - 1
      }
    default:
      return state
  }
}
