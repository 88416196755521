import React, { useCallback, useEffect, useState } from 'react'
import { head, isEmpty, pathOr, pick } from 'ramda'
import Input from 'components/atoms/Input'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isNotEmpty, isNotNilOrEmpty } from 'utils/ramda'
import {
  validateAssignmentField,
  validateAssignmentValues,
  validateEmailValue
} from 'features/createBp/ducks/schema'
import PhoneField from 'components/atoms/PhoneInput'
import Select from 'components/atoms/Select'
import NextButton from 'features/createBp/components/atoms/NextButton'
import {
  assignUserToBpRoutine,
  editUserRelationToBpRoutine,
  inviteUserToBpRoutine
} from 'features/accessControl/ducks/actions'
import PersonDetailsBox from 'features/createBp/components/atoms/PersonDetailsBox'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { ROLE_DESCRIPTION } from 'utils/constants'
import moment from 'moment'
import { toSnakeCase } from 'js-convert-case/lib'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { getOwners, getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { clearUsersFoundByEmailRoutine, getUsersByEmailRoutine } from 'features/bpDetails/ducks/actions'

const emptyValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  relationType: '',
  expiresAt: ''
}

const AddAssignmentModal = ({ initialUserType, closeModal, userToEdit, isAnalyticsView, currentPage }) => {
  const bp = useSelector(getSelectedBp)
  const { t } = useTranslation()
  const [formVisible, setFormVisible] = useState(false)
  const [autoFilled, setAutoFilled] = useState(false)
  const [email, setEmail] = useState('')
  const [hasValidEmail, setHasValidEmail] = useState(false)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const foundOwners = useSelector(getOwners)
  const [phoneValid, setPhoneValid] = useState(false)
  const [values, setValues] = useState(
    userToEdit
      ? userToEdit
      : emptyValues
  )

  const currentUser = useSelector(getCurrentUser)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleModalClose = () => {
    closeModal()
    setAutoFilled(false)
    dispatch(clearUsersFoundByEmailRoutine())
    setValues(emptyValues)
    setFormVisible(false)
  }

  const handleSubmit = () => {
    if (isNotNilOrEmpty(userToEdit)) {
      const relationId = pathOr(null, ['pivot', 'id'], userToEdit) || pathOr(null, ['pivot', 0, 'id'], userToEdit)
      dispatch(editUserRelationToBpRoutine({
        bpId: bp.id,
        relationId: relationId,
        body: {
          ...pathOr('', ['relationType'], userToEdit) === values.relationType
            ? {}
            : {
              relationType: values.relationType
            },
          expiresAt: values.expiresAt
        }
      }))
    } else {
      autoFilled
        ? dispatch(assignUserToBpRoutine({
          bpId: bp.id,
          body: {
            userId: values.id,
            relationType: values.relationType,
            expiresAt: values.expiresAt
          },
          isEdit: true,
          isAnalyticsView: isAnalyticsView,
          currentPage: currentPage
        }))
        : dispatch(inviteUserToBpRoutine({
          bpId: bp.id,
          body: {
            email: values.email.toLowerCase(),
            relationType: values.relationType,
            ...pick(['firstName', 'lastName', 'phoneNumber', 'expiresAt'], values)
          },
          isEdit: true,
          isDraft: bp.status === 'draft'
        }))
    }
    handleModalClose()
  }

  const searchUser = useCallback(() => {
    setFormVisible(true)
    dispatch(getUsersByEmailRoutine({ email, type: 'owner' }))
  }, [dispatch, email])

  useEffect(() => {
    validateEmailValue({ email }, setHasValidEmail)
    validateAssignmentValues(values, setValid)
  }, [values])

  const handleChangeEmail = (name, value) => {
    handleValueChange(name, value)
    setEmail(value)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(userToEdit)) {
      setValues({
        ...userToEdit,
        expiresAt: pathOr('', ['pivot', 'expiresAt'], userToEdit)
      })
      setEmail(userToEdit.email)
    } else {
      setValues({
        ...emptyValues,
        relationType: initialUserType ? initialUserType : ''
      })
    }
  }, [userToEdit, initialUserType])

  useEffect(() => {
    if (isNotNilOrEmpty(foundOwners)) {
      setValues(prev => ({
        ...prev,
        ...head(foundOwners),
        email
      }))
      setAutoFilled(true)
    } else {
      setValues(prev => ({
        ...prev,
        relationType: prev.relationType,
        expiresAt: prev.expiresAt,
        email
      }))
      setAutoFilled(false)
    }
  }, [foundOwners, email])

  useEffect(() => {
    isNotEmpty(email) && setFormVisible(true)
  }, [])

  return (
    <ContentWrapper>
      <Select
        label='Role'
        noMargin={isNotNilOrEmpty(values.relationType)}
        onChange={handleValueChange}
        validate={validateAssignmentField(values)}
        placeholder='Select'
        value={toSnakeCase(values.relationType)}
        name='relationType'
        options={[
          { label: 'Agent', value: 'agent' },
          { label: 'Owner', value: 'owner' },
          { label: 'Responsible person', value: 'responsible_person' },
          { label: 'Prospective purchaser', value: 'prospective_purchaser' },
          { label: 'Principal Accountable Person', value: 'principal_accountable_person' },
          { label: 'Principal Designer', value: 'principal_designer' }
        ]}
      />
      {
        values.relationType && (
          <DescriptionWrapper>
            <InputDescription>
              {ROLE_DESCRIPTION[values.relationType]
                .replace('[User name]', `${currentUser.firstName} ${currentUser.lastName}`)}
            </InputDescription>
          </DescriptionWrapper>
        )
      }
      <Input
        required
        label='Expires at'
        inputProps={{
          min: moment().format('YYYY-MM-DD')
        }}
        type='date'
        value={moment(values.expiresAt).format('YYYY-MM-DD')}
        optional
        name='expiresAt'
        onChange={handleValueChange}
        additionalLabel={
          <ClearInputButton
            disabled={isEmpty(values.expiresAt)}
            onClick={() => handleValueChange('expiresAt', '')}
          >
            clear
          </ClearInputButton>
        }
      />
      {
        isNotNilOrEmpty(userToEdit)
          ? (
            <PersonDetailsBox
              details={values}
              hideEdit
            />
          )
          : (
            <FormWrapper>
              <Input
                type='email'
                required
                label={t('signUp.labels.email')}
                value={pathOr('', ['email'], values)}
                name='email'
                onChange={handleChangeEmail}
                validate={validateAssignmentField(values)}
              />
              <CheckButtonWrapper>
                <CheckEmailButton disabled={!hasValidEmail} type='button' onClick={searchUser}>check
                  email</CheckEmailButton>
              </CheckButtonWrapper>
              {
                formVisible && (
                  <>
                    <SectionName>personal details</SectionName>
                    <Input
                      required
                      label={t('signUp.labels.firstName')}
                      value={pathOr('', ['firstName'], values)}
                      name='firstName'
                      onChange={handleValueChange}
                      validate={validateAssignmentField(values)}
                      disabled={autoFilled}
                    />
                    <Input
                      required
                      label={t('signUp.labels.lastName')}
                      value={pathOr('', ['lastName'], values)}
                      name='lastName'
                      onChange={handleValueChange}
                      validate={validateAssignmentField(values)}
                      disabled={autoFilled}
                    />
                    <PhoneField
                      name='phoneNumber'
                      label='Phone number'
                      value={pathOr('', ['phoneNumber'], values)}
                      onChange={handleValueChange}
                      validate={valid => setPhoneValid(valid)}
                      disabled={autoFilled}
                      noValidation={autoFilled}
                      optional
                    />
                  </>
                )
              }
            </FormWrapper>
          )
      }
      <NextButton
        onClick={handleSubmit}
        disabled={!valid || (!phoneValid && !isNotNilOrEmpty(userToEdit))}
      >
        Save
      </NextButton>
    </ContentWrapper>
  )
}

export default AddAssignmentModal

const FormWrapper = styled.form`
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 25px;
`

const SectionName = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: .1px;
  margin: 15px 0;
`

const CheckEmailButton = styled.button`
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  transition: all .3s;
  border-radius: 4px;
  padding: 5px 15px;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.haiti};
    border-color: ${({ theme }) => theme.colors.haiti};
  }

  ${({ disabled, theme }) => disabled && css`
    background-color: ${theme.colors.grey[500]};
    color: ${theme.colors.darkGrey};
    border-color: ${theme.colors.grey[900]};
    cursor: default;

    &:hover {
      background-color: ${theme.colors.grey[500]};
      color: ${theme.colors.grey[900]};
      border-color: transparent;
    }
  `}
`

const CheckButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  max-width: 320px;
  margin: 0 auto;
`

const DescriptionWrapper = styled.div`
  margin: 5px 0 15px;
`

const ClearInputButton = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  cursor: pointer;

  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.grey[600]};
    cursor: default;
  `}
`
