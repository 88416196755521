import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import useDebounce from 'utils/hooks/useDebounce'
import { isEmpty, pathOr, propOr } from 'ramda'
import { useSelector } from 'react-redux'
import { selectBpFilesList } from 'ducks/files/selectors'

export const FilesSearchInput = ({
  disabled,
  setIsSearching,
  placeholder,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 500)
  const files = useSelector(selectBpFilesList)

  const handleClearValue = () => setValue('')
  const handleInputChange = e => setValue(e.target.value)
  const checkIncludes = value => {
    const val = value ? value.replace(' ', '').toLowerCase() : ''
    const query = debouncedValue ? debouncedValue.replace(' ', '').toLowerCase() : ''
    return val.includes(query)
  }

  useEffect(() => {
    if (files && debouncedValue.length >= 3) {
      onChange(files.filter(file =>
        (checkIncludes(propOr('', 'displayName', file)) ||
        checkIncludes(pathOr('', ['fileType', 'fileCategory', 'name'], file)) ||
        checkIncludes(pathOr('', ['fileType', 'name'], file)))
      ))
      setIsSearching(true)
    } else {
      onChange([])
      setIsSearching(false)
    }
  }, [debouncedValue])

  return (
    <SearchInputContainer disabled={disabled}>
      <InputWrapper>
        <SearchInputIcon>
          <StyledSearchIcon />
        </SearchInputIcon>
        <StyledInput
          {...props}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={handleInputChange}
        />
        {!isEmpty(value) && <StyledClearIcon onClick={handleClearValue} />}
      </InputWrapper>
    </SearchInputContainer>
  )
}

FilesSearchInput.defaultProps = {
  disabled: false,
  placeholder: 'Search... (at least 3 characters)'
}

export default FilesSearchInput

const StyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  line-height: 16px;
  font-size: 16px;
  flex-grow: 2;
  padding-right: 20px;
  height: 100%;
`

const SearchInputContainer = styled.div`
  border-radius: 4px;
  height: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.disabled ? '.5' : '1')};
  width: 100%;
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  input {
    padding: 0;
  }
`

const SearchInputIcon = styled.div`
  position: relative;
  top: 2px;
  margin-right: 15px;
  color: rgba(152, 152, 152, 0.61);
`

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 16px;
  color: rgba(152, 152, 152, 0.61);
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 25px !important;
`
