import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as unitsService from 'services/unitsService'
import * as bpService from 'services/BpService'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'
import { createBpRoutine, updateCreatedBpRoutine } from 'features/createBp/ducks/actions'
import { fetchAuthUserAccessRequestsRoutine } from 'features/AccessRequest/ducks/actions'

export const fetchUserUnitsRoutine = createRoutine('FETCH_USER_UNITS')
export const fetchBpUnitsRoutine = createRoutine('FETCH_BP_UNITS')
export const fetchBpUnitsForAccessRequestRoutine = createRoutine('FETCH_BP_UNITS_FOR_ACCESS_REQUEST')
export const fetchUnitRoutine = createRoutine('FETCH_UNIT')
export const addBpUnitsRoutine = createRoutine('ADD_BP_UNITS')
export const updateBpUnitRoutine = createRoutine('UPDATE_BP_UNIT')
export const removeBpUnitRoutine = createRoutine('REMOVE_BP_UNIT')
export const setSelectedUnitRoutine = createRoutine('SET_SELECTED_UNIT')
export const updateSelectedUnitRoutine = createRoutine('UPDATE_SELECTED_UNIT')
export const clearSelectedUnitRoutine = createRoutine('CLEAR_SELECTED_UNIT')
export const editUserRelationToUnitRoutine = createRoutine('EDIT_USER_RELATION_TO_UNIT')
export const assignUserToUnitRoutine = createRoutine('ASSIGN_USER_TO_UNIT')
export const inviteUserToUnitRoutine = createRoutine('INVITE_USER_TO_UNIT')
export const fetchUnitInvitationsListRoutine = createRoutine('GET_UNIT_INVITATIONS_LIST')
export const revokeAccessToUnitRoutine = createRoutine('REVOKE_ACCESS_TO_UNIT')
export const cancelUnitInvitationRoutine = createRoutine('CANCEL_UNIT_INVITATION')
export const reactivateUnitUserRoutine = createRoutine('REACTIVATE_UNIT_USER')
export const detachUnitUserRoutine = createRoutine('DETACH_UNIT_USER')
export const fetchNoticeBoardRoutine = createRoutine('FETCH_NOTICE_BOARD')
export const getFloorPlansForUnitRoutine = createRoutine('GET_FLOORPLANS_FOR_UNIT')
export const updateUnitsInCreatedBpRoutine = createRoutine('UPDATE_UNITS_IN_CREATED_BP')
export const getUnitInvitationRoutine = createRoutine('GET_UNIT_INVITATION')

export const requestAccessToUnitRoutine = createRoutine('REQUEST_ACCESS_TO_UNIT')
export const getUnitAccessRequestsRoutine = createRoutine('GET_UNIT_ACCESS_REQUESTS')
export const acceptUnitAccessRequestRoutine = createRoutine('ACCEPT_UNIT_ACCESS_REQUEST')
export const denyUnitAccessRequestRoutine = createRoutine('DENY_UNIT_ACCESS_REQUEST')

export const updateUnitFileRoutine = createRoutine('UPDATE_UNIT_FILE')
export const removeUnitFileRoutine = createRoutine('REMOVE_UNIT_FILE')

export const getUserRelationToUnitRoutine = createRoutine('GET_USER_RELATION_TO_UNIT')

function * fetchUserUnits ({ payload }) {
  yield put(fetchUserUnitsRoutine.request())
  try {
    const { data } = yield call(unitsService.fetchUserUnits, payload)
    yield put(fetchUserUnitsRoutine.success(data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(fetchUserUnitsRoutine.failure(error))
  }
}

function * fetchNoticeBoard ({ payload }) {
  yield put(fetchNoticeBoardRoutine.request())
  try {
    const { data } = yield call(unitsService.fetchNoticeBoard, payload)
    yield put(fetchNoticeBoardRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(fetchNoticeBoardRoutine.failure(error))
  }
}

function * fetchBpUnits ({ payload }) {
  yield put(fetchBpUnitsRoutine.request())
  try {
    const { data } = yield call(unitsService.fetchBpUnits, payload)
    yield put(fetchBpUnitsRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(fetchBpUnitsRoutine.failure(error))
  }
}

function * fetchBpUnitsForAccessRequest ({ payload }) {
  yield put(fetchBpUnitsForAccessRequestRoutine.request())
  try {
    const { data } = yield call(unitsService.fetchBpUnitsForAccessRequest, payload)
    yield put(fetchBpUnitsForAccessRequestRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(fetchBpUnitsForAccessRequestRoutine.failure(error))
  }
}

function * updateUnitsInCreatedBp ({ payload }) {
  yield put(updateUnitsInCreatedBpRoutine.success(payload))
}

function * getFloorPlansForUnit ({ payload }) {
  yield put(getFloorPlansForUnitRoutine.request())
  try {
    const { data } = yield call(unitsService.getFloorPlansForUnit, payload)
    yield put(getFloorPlansForUnitRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getFloorPlansForUnitRoutine.failure(error))
  }
}

function * fetchUnit ({ payload }) {
  const { editForm } = payload
  yield put(fetchUnitRoutine.request())
  try {
    const { data } = yield call(unitsService.fetchUnit, payload)
    if (editForm) {
      yield put(setSelectedUnitRoutine(data.data))
    } else {
      yield put(fetchUnitRoutine.success(data.data))
    }
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(fetchUnitRoutine.failure(error))
  }
}

function * addBpUnits ({ payload }) {
  const { isWizard } = payload
  yield put(addBpUnitsRoutine.request())
  try {
    const { data } = yield call(unitsService.addBpUnits, payload)
    yield put(addBpUnitsRoutine.success(data.data))
    yield put(fetchBpUnitsRoutine({ bpId: payload.bpId }))
    const bpResult = yield call(bpService.fetchBp, payload.bpId)
    if (isWizard) {
      yield put(updateCreatedBpRoutine.success(bpResult.data.data))
    } else {
      yield put(fetchBpRoutine.success(bpResult.data.data))
    }
    toast.success('Unit has been added')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(addBpUnitsRoutine.failure(error))
  }
}

function * updateBpUnit ({ payload }) {
  const { updateSelected, isCreationFlow, callback } = payload
  yield put(updateBpUnitRoutine.request())
  try {
    const { data } = yield call(unitsService.editBpUnit, payload)
    yield put(updateBpUnitRoutine.success(data.data))
    const bpData = yield call(bpService.fetchBp, payload.bpId)
    if (updateSelected) {
      yield put(updateSelectedUnitRoutine(payload))
    }
    if (isCreationFlow) {
      yield put(createBpRoutine.success(bpData.data.data))
    }
    yield put(fetchBpUnitsRoutine({ bpId: payload.bpId }))
    yield put(fetchBpRoutine({ id: payload.bpId }))
    typeof callback === 'function' && callback()
    toast.success('Unit has been updated')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(updateBpUnitRoutine.failure(error))
  }
}

function * removeBpUnit ({ payload }) {
  const { isWizard } = payload
  yield put(removeBpUnitRoutine.request())
  try {
    yield call(unitsService.removeBpUnit, payload)
    yield put(removeBpUnitRoutine.success())
    const { data } = yield call(bpService.fetchBp, payload.bpId)
    yield put(fetchBpUnitsRoutine({ bpId: payload.bpId }))
    yield put(fetchBpRoutine.success(data.data))
    if (isWizard) {
      yield put(updateCreatedBpRoutine.success(data.data))
    }
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(removeBpUnitRoutine.failure(error))
  }
}

function * setSelectedUnit ({ payload }) {
  yield put(setSelectedUnitRoutine.request())
  try {
    yield put(setSelectedUnitRoutine.success(payload))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(setSelectedUnitRoutine.failure(error))
  }
}

function * updateSelectedUnit ({ payload }) {
  yield put(updateSelectedUnitRoutine.request())
  try {
    yield put(updateSelectedUnitRoutine.success(payload))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(updateSelectedUnitRoutine.failure(error))
  }
}

function * editUserRelationToUnit ({ payload }) {
  yield put(editUserRelationToUnitRoutine.request())
  try {
    yield call(unitsService.editUserRelationToUnit, payload)
    yield put(editUserRelationToUnitRoutine.success())
    toast.success('Assignment has been updated')
    yield put(fetchUnitRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(editUserRelationToUnitRoutine.failure(error))
  }
}

function * assignUserToUnit ({ payload }) {
  yield put(assignUserToUnitRoutine.request())
  try {
    yield call(unitsService.assignUserToUnit, payload)
    yield put(assignUserToUnitRoutine.success())
    toast.success('User has been assigned')
    yield put(fetchUnitRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(assignUserToUnitRoutine.failure(error))
  }
}

function * inviteUserToUnit ({ payload }) {
  yield put(inviteUserToUnitRoutine.request())
  try {
    yield call(unitsService.inviteUserToUnit, payload)
    yield put(inviteUserToUnitRoutine.success())
    toast.success('Invitation has been sent')
    yield put(fetchUnitInvitationsListRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(inviteUserToUnitRoutine.failure(error))
  }
}

function * fetchUnitInvitationsList ({ payload }) {
  yield put(fetchUnitInvitationsListRoutine.request())
  try {
    const { data } = yield call(unitsService.fetchUnitInvitationsList, payload)
    yield put(fetchUnitInvitationsListRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(fetchUnitInvitationsListRoutine.failure(error))
  }
}

function * revokeAccessToUnit ({ payload }) {
  yield put(revokeAccessToUnitRoutine.request())
  try {
    yield call(unitsService.revokeAccessToUnit, payload)
    yield put(revokeAccessToUnitRoutine.success())
    yield put(fetchUnitRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
    toast.success('Assignment has been revoked')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(revokeAccessToUnitRoutine.failure(error))
  }
}

function * cancelUnitInvitation ({ payload }) {
  yield put(cancelUnitInvitationRoutine.request())
  try {
    yield call(unitsService.cancelUnitInvitation, payload)
    yield put(cancelUnitInvitationRoutine.success())
    yield put(fetchUnitInvitationsListRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
    toast.success('Invitation has been deleted')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(cancelUnitInvitationRoutine.failure(error))
  }
}

function * reactivateUnitUser ({ payload }) {
  yield put(reactivateUnitUserRoutine.request())
  try {
    yield call(unitsService.reactivateUnitUser, payload)
    yield put(reactivateUnitUserRoutine.success())
    yield put(fetchUnitRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
    toast.success('User access has been reactivated')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(reactivateUnitUserRoutine.failure(error))
  }
}

function * detachUnitUser ({ payload }) {
  yield put(detachUnitUserRoutine.request())
  try {
    yield call(unitsService.detachUnitUser, payload)
    yield put(detachUnitUserRoutine.success())
    yield put(fetchUnitRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
    toast.success('User has been detached')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(detachUnitUserRoutine.failure(error))
  }
}

function * requestAccessToUnit ({ payload }) {
  yield put(requestAccessToUnitRoutine.request())
  try {
    yield call(unitsService.requestAccessToUnit, payload)
    yield put(requestAccessToUnitRoutine.success())
    yield put(fetchAuthUserAccessRequestsRoutine())
    toast.success('Request has been sent')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(requestAccessToUnitRoutine.failure(error))
  }
}

function * getUnitAccessRequests ({ payload }) {
  yield put(getUnitAccessRequestsRoutine.request())
  try {
    const { data } = yield call(unitsService.getUnitAccessRequests, payload)
    yield put(getUnitAccessRequestsRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getUnitAccessRequestsRoutine.failure(error))
  }
}

function * acceptUnitAccessRequest ({ payload }) {
  yield put(acceptUnitAccessRequestRoutine.request())
  try {
    yield call(unitsService.acceptUnitAccessRequest, payload)
    yield put(getUnitAccessRequestsRoutine(payload))
    yield put(fetchUnitRoutine(payload))
    yield put(acceptUnitAccessRequestRoutine.success())
    toast.success('Request has been accepted')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(acceptUnitAccessRequestRoutine.failure(error))
  }
}

function * denyUnitAccessRequest ({ payload }) {
  yield put(denyUnitAccessRequestRoutine.request())
  try {
    yield call(unitsService.denyUnitAccessRequest, payload)
    yield put(getUnitAccessRequestsRoutine(payload))
    yield put(denyUnitAccessRequestRoutine.success())
    toast.success('Request has been denied')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(denyUnitAccessRequestRoutine.failure(error))
  }
}

function * updateUnitFile ({ payload }) {
  yield put(updateUnitFileRoutine.request())
  try {
    yield call(unitsService.updateUnitFile, payload)
    yield put(updateUnitFileRoutine.success())
    yield put(fetchUnitRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
    toast.success('File has been updated')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(updateUnitFileRoutine.failure(error))
  }
}

function * removeUnitFile ({ payload }) {
  yield put(removeUnitFileRoutine.request())
  try {
    yield call(unitsService.removeUnitFile, payload)
    yield put(removeUnitFileRoutine.success())
    yield put(fetchUnitRoutine({ bpId: payload.bpId, unitId: payload.unitId }))
    toast.success('File has been removed')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(removeUnitFileRoutine.failure(error))
  }
}

function * getUserRelationToUnit ({ payload }) {
  yield put(getUserRelationToUnitRoutine.request())
  try {
    const { data } = yield call(unitsService.getUserRelationToUnit, payload)
    yield put(getUserRelationToUnitRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getUserRelationToUnitRoutine.failure(error))
  }
}

function * getUnitInvitation ({ payload }) {
  yield put(getUnitInvitationRoutine.request())
  try {
    const { data } = yield call(unitsService.getUnitInvitation, payload)
    yield put(getUnitInvitationRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getUnitInvitationRoutine.failure(error))
  }
}

function * clearSelectedUnit () {
  yield put(clearSelectedUnitRoutine.success())
}

export function * fetchUserUnitsWatcher () {
  yield takeLatest(fetchUserUnitsRoutine.TRIGGER, fetchUserUnits)
}

export function * fetchBpUnitsWatcher () {
  yield takeLatest(fetchBpUnitsRoutine.TRIGGER, fetchBpUnits)
}

export function * fetchUnitWatcher () {
  yield takeLatest(fetchUnitRoutine.TRIGGER, fetchUnit)
}

export function * addBpUnitsWatcher () {
  yield takeLatest(addBpUnitsRoutine.TRIGGER, addBpUnits)
}

export function * updateBpUnitWatcher () {
  yield takeLatest(updateBpUnitRoutine.TRIGGER, updateBpUnit)
}

export function * removeBpUnitWatcher () {
  yield takeLatest(removeBpUnitRoutine.TRIGGER, removeBpUnit)
}

export function * setSelectedUnitWatcher () {
  yield takeLatest(setSelectedUnitRoutine.TRIGGER, setSelectedUnit)
}

export function * clearSelectedUnitWatcher () {
  yield takeLatest(clearSelectedUnitRoutine.TRIGGER, clearSelectedUnit)
}

export function * editUserRelationToUnitWatcher () {
  yield takeLatest(editUserRelationToUnitRoutine.TRIGGER, editUserRelationToUnit)
}

export function * assignUserToUnitWatcher () {
  yield takeLatest(assignUserToUnitRoutine.TRIGGER, assignUserToUnit)
}

export function * inviteUserToUnitWatcher () {
  yield takeLatest(inviteUserToUnitRoutine.TRIGGER, inviteUserToUnit)
}

export function * fetchUnitInvitationsListWatcher () {
  yield takeLatest(fetchUnitInvitationsListRoutine.TRIGGER, fetchUnitInvitationsList)
}

export function * revokeAccessToUnitWatcher () {
  yield takeLatest(revokeAccessToUnitRoutine.TRIGGER, revokeAccessToUnit)
}

export function * cancelUnitInvitationWatcher () {
  yield takeLatest(cancelUnitInvitationRoutine.TRIGGER, cancelUnitInvitation)
}

export function * reactivateUnitUserWatcher () {
  yield takeLatest(reactivateUnitUserRoutine.TRIGGER, reactivateUnitUser)
}

export function * detachUnitUserWatcher () {
  yield takeLatest(detachUnitUserRoutine.TRIGGER, detachUnitUser)
}

export function * updateSelectedUnitWatcher () {
  yield takeLatest(updateSelectedUnitRoutine.TRIGGER, updateSelectedUnit)
}

export function * fetchNoticeBoardWatcher () {
  yield takeLatest(fetchNoticeBoardRoutine.TRIGGER, fetchNoticeBoard)
}

export function * getFloorPlansForUnitWatcher () {
  yield takeLatest(getFloorPlansForUnitRoutine.TRIGGER, getFloorPlansForUnit)
}

export function * updateUnitsInCreatedBpWatcher () {
  yield takeLatest(updateUnitsInCreatedBpRoutine.TRIGGER, updateUnitsInCreatedBp)
}

export function * requestAccessToUnitWatcher () {
  yield takeLatest(requestAccessToUnitRoutine.TRIGGER, requestAccessToUnit)
}

export function * getUnitAccessRequestsWatcher () {
  yield takeLatest(getUnitAccessRequestsRoutine.TRIGGER, getUnitAccessRequests)
}

export function * acceptUnitAccessRequestWatcher () {
  yield takeLatest(acceptUnitAccessRequestRoutine.TRIGGER, acceptUnitAccessRequest)
}

export function * denyUnitAccessRequestWatcher () {
  yield takeLatest(denyUnitAccessRequestRoutine.TRIGGER, denyUnitAccessRequest)
}

export function * updateUnitFileWatcher () {
  yield takeLatest(updateUnitFileRoutine.TRIGGER, updateUnitFile)
}

export function * removeUnitFileWatcher () {
  yield takeLatest(removeUnitFileRoutine.TRIGGER, removeUnitFile)
}

export function * getUserRelationToUnitWatcher () {
  yield takeLatest(getUserRelationToUnitRoutine.TRIGGER, getUserRelationToUnit)
}

export function * getUnitInvitationWatcher () {
  yield takeLatest(getUnitInvitationRoutine.TRIGGER, getUnitInvitation)
}

export function * fetchBpUnitsForAccessRequestWatcher () {
  yield takeLatest(fetchBpUnitsForAccessRequestRoutine.TRIGGER, fetchBpUnitsForAccessRequest)
}

// SAGAS
export const unitSagas = [
  fork(fetchUserUnitsWatcher),
  fork(fetchBpUnitsWatcher),
  fork(fetchUnitWatcher),
  fork(updateBpUnitWatcher),
  fork(removeBpUnitWatcher),
  fork(addBpUnitsWatcher),
  fork(setSelectedUnitWatcher),
  fork(clearSelectedUnitWatcher),
  fork(editUserRelationToUnitWatcher),
  fork(assignUserToUnitWatcher),
  fork(inviteUserToUnitWatcher),
  fork(fetchUnitInvitationsListWatcher),
  fork(revokeAccessToUnitWatcher),
  fork(cancelUnitInvitationWatcher),
  fork(reactivateUnitUserWatcher),
  fork(detachUnitUserWatcher),
  fork(updateSelectedUnitWatcher),
  fork(fetchNoticeBoardWatcher),
  fork(getFloorPlansForUnitWatcher),
  fork(updateUnitsInCreatedBpWatcher),
  fork(requestAccessToUnitWatcher),
  fork(getUnitAccessRequestsWatcher),
  fork(acceptUnitAccessRequestWatcher),
  fork(denyUnitAccessRequestWatcher),
  fork(updateUnitFileWatcher),
  fork(removeUnitFileWatcher),
  fork(getUserRelationToUnitWatcher),
  fork(getUnitInvitationWatcher),
  fork(fetchBpUnitsForAccessRequestWatcher)
]
