/* eslint-disable */

export const logActions = [
  {
    key: 'building_passport.update',
    value: 'updated Building Passport'
  },
  {
    key: 'building_passport.files.add',
    value: 'added a new file'
  },
  {
    key: 'building_passport.files.delete',
    value: 'deleted a file'
  },
  {
    key: 'building_passport.notes.add',
    value: 'added a new note'
  },
  {
    key: 'building_passport.notes.edit',
    value: 'edited a note'
  },
  {
    key: 'building_passport.notes.delete',
    value: 'deleted a note'
  },
  {
    key: 'building_passport.user.assign',
    value: 'assigned a new user'
  },
  {
    key: 'building_passport.user.update',
    value: 'updated user assignment'
  },
  {
    key: 'building_passport.user.revoke',
    value: 'revoked user access'
  },
  {
    key: 'building_passport.user.detach',
    value: 'detached user access'
  },
  {
    key: 'building_passport.user.reactivate',
    value: 'reactivated user access'
  },
  {
    key: 'building_passport.units.add',
    value: 'added a new unit(s)'
  },
  {
    key: 'building_passport.units.delete',
    value: 'deleted a unit'
  }
]


export const ROLE_DESCRIPTION = {
  agent: '"Agents" include any person working on behalf of, or representing, the owner/landlord or another agent of the building. This includes architects, developers, surveyors, contractors, engineers etc.',
  landlord: '"Landlord" refers to the freeholder or long leaseholder of the property. If this is under corporate ownership, a contact person must be identified.',
  owner: 'Owner refers to the freeholder or long leaseholder of the property. If this is under corporate ownership, a contact person must be identified.',
  responsiblePerson: '"Responsible Person" - select this if you are a "Building Safety Manager" or "Accountable Person" - i.e. if is your explicit responsibility for an element of building safety. There may be more than one for any building.',
  responsible_person: '"Responsible Person" - select this if you are a "Building Safety Manager" or "Accountable Person" - i.e. if is your explicit responsibility for an element of building safety. There may be more than one for any building.',
  emergencyService: 'FRS refers to any person working for the Fire and Rescue Service.',
  occupant: 'An Occupant is a person who resides in or regularly uses a property.',
  user: 'Common user role refers to the freeholder, long leaseholder of the property or any person working on behalf of, or representing, the owner/landlord or another agent of the building. Also includes person who is officially responsible for reducing the risk from fire and making sure that people can safely escape if the case of a fire (as set out in The Regulatory Reform (Fire Safety) Order 2005).',
  prospectivePurchaser: 'A prospective purchaser will have access to a read-only version of the Building Passport. If you invite a prospective purchaser, only you will see their details (i.e. email address and name). Other users with access to this Building Passport will see this user as “Anonymous user - invited by [User name]"',
  prospective_purchaser: 'A prospective purchaser will have access to a read-only version of the Building Passport. If you invite a prospective purchaser, only you will see their details (i.e. email address and name). Other users with access to this Building Passport will see this user as “Anonymous user - invited by [User name]"',
  principalAccountablePerson: '',
  principal_accountable_person: '',
  principalDesigner: '',
  principal_designer: ''
}


export const TEMPLATE_ITEMS = [
  {
    label: 'UPRN',
    value: 'hasUprn'
  },
  {
    label: 'Address',
    value: 'hasAddress'
  },
  {
    label: 'Building type',
    value: 'hasBuildingTypes'
  },
  {
    label: 'Agent details',
    value: 'hasAgent'
  },
  {
    label: 'Owner details',
    value: 'hasOwner'
  }
]

export const TEMPLATE_ITEMS_NAMES = {
  hasUprn: 'UPRN',
  hasAddress: 'Address',
  hasBuildingTypes: 'Building type',
  hasAgent: 'Agent details',
  hasOwner: 'Owner details'
}
