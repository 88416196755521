import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ConfirmationPage = ({ status }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Content>
        {
          status === 'success'
            ? (
              <>
                <Header>
                  {t('signUp.verification.verified')}
                </Header>
                <Description>
                  {t('signUp.verification.verifiedDescription')}
                </Description>
              </>
            )
            : (
              <>
                <Header>
                  {t('signUp.verification.notVerified')}
                </Header>
                <Description>
                  Please try clicking the link in the email we sent you once more. If that doesn&apos;t work you can
                  contact the support team on <a href='mailto:app@buildingpassport.com'>app@buildingpassport.com</a>
                </Description>
              </>
            )
        }
      </Content>
    </Wrapper>
  )
}

export default ConfirmationPage

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  max-width: 550px;
  font-size: 30px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.dimensions.topBarHeight};
`

const Header = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
`

const Description = styled.p`
  font-size: 20px;
`
