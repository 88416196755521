import React, { useEffect, useState } from 'react'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import styled from 'styled-components'
import { ReactComponent as BasketIcon } from 'assets/images/basket.svg'
import { getCart } from 'utils/localStorage'
import { cartEmitter } from 'utils/emitters'
import { uniq } from 'ramda'

const CartIcon = () => {
  const [itemsInCart, setItemsInCart] = useState(0)

  const countCartItems = () => {
    const cart = getCart()
    setItemsInCart(uniq(cart).length)
  }

  useEffect(() => {
    countCartItems()
    cartEmitter.on('cart-change', countCartItems)
    cartEmitter.on('count-cart-items', countCartItems)
    return () => {
      cartEmitter.off('cart-change', countCartItems)
      cartEmitter.off('count-cart-items', countCartItems)
    }
  }, [])

  return (
    <IconWrapper>
      <BasketIcon onClick={() => redirect(PRIVATE_PATHS.cart)} />
      {
        itemsInCart > 0 && (
          <Badge onClick={() => redirect(PRIVATE_PATHS.cart)}>{itemsInCart}</Badge>
        )
      }
    </IconWrapper>

  )
}

export default CartIcon

const IconWrapper = styled.div`
  position: relative;

  &:hover {
    svg {
      g {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

`

const Badge = styled.div`
  position: absolute;
  cursor: pointer;
  box-sizing: border-box;
  bottom: 0;
  right: 27px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  font-weight: bold;
`
