import React, { useEffect, useMemo, useState } from 'react'
import { logActions } from 'utils/constants'
import styled from 'styled-components'
import { calendarDate, formatDate, formatDateWithTime } from 'utils/date'
import { pathOr, prop, propOr, sortBy, reverse, keys, isEmpty, values } from 'ramda'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import { toCamelCase, toTextCase } from 'js-convert-case/lib'
import { handleBooleanValue } from 'utils/hooks/values'
import { useSelector } from 'react-redux'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { getBuildingTypeValueType, getFloorName } from 'utils/bpData'
import { isNotNilOrEmpty } from 'utils/ramda'
import ActivityExpandablePanel from 'features/bpList/Components/BpInfo/ActivityExpandablePanel'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getBpActivityLog } from 'services/BpService'
import { CircularProgress } from '@material-ui/core'

const Activity = () => {
  const bp = useSelector(getSelectedBp)
  const [auditLog, setAuditLog] = useState([])
  const [loading, setLoading] = useState(false)
  const buildingTypes = useSelector(getBuildingTypes)

  const filteredAuditLog = useMemo(() => {
    return auditLog.filter(logItem =>
      (!isEmpty(pathOr([], ['data', 'changes', 'attributes'], logItem)) &&
        !isEmpty(pathOr([], ['data', 'changes', 'old'], logItem))) || logItem.action.includes('file')
    )
  }, [auditLog])

  useEffect(() => {
    setLoading(true)
    getBpActivityLog({ id: bp.id })
      .then(resp => {
        setAuditLog(resp.data.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [bp])

  const renderChangedValue = (changes, key) => {
    const valueType = typeof changes[key]

    switch (true) {
      case valueType === 'boolean':
        return handleBooleanValue(changes[key])
      case key === 'buildingTypes':
        const typesObject = changes[key]
        return typesObject.map(el => {
          const type = propOr(
            { label: '', unit: '' },
            toCamelCase(el.type),
            buildingTypes)
          return (
            <BuildingTypes key={el.type}>
              <div>{type.label}</div>
              <BuildingTypeWrapper>
                <span>{getBuildingTypeValueType(el.type).replace(' (sqm)', '')}</span>
                <div>{type.unit === 'unit' ? el.units : el.area}&nbsp;{type.unit}</div>
              </BuildingTypeWrapper>
            </BuildingTypes>
          )
        })
      default:
        return changes[key]
    }
  }

  const getBpChanges = logElement => {
    const changes = pathOr({}, ['data', 'changes', 'attributes'], logElement)
    const objectKeys = keys(changes)
    return objectKeys && objectKeys.map(key => {
      return (
        <ListItem key={`bp-change-item-${key}-${Math.random()}`}>
          {toTextCase(key === 'hoardings' ? 'hoarding' : key)}:
          <div>{renderChangedValue(changes, key)}</div>
        </ListItem>
      )
    })
  }

  const getContent = (log, logElement) => {
    switch (true) {
      case log && log.key.includes('user'):
        return (
          <UpdatedBpWrapper>
            <ListItem>
              User name:
              <div>{pathOr('', ['data', 'user', 'firstName'], logElement)} {pathOr('', ['data', 'user', 'lastName'], logElement)}</div>
            </ListItem>
          </UpdatedBpWrapper>
        )
      case log && log.key.includes('update'):
        return (
          <UpdatedBpWrapper>
            {getBpChanges(logElement)}
          </UpdatedBpWrapper>
        )
      case log && log.key.includes('notes'):
        const floorNumber = pathOr('', ['data', 'floorNumber'], logElement)

        return (
          <NoteContentWrapper>
            {!isNotNilOrEmpty(floorNumber) ? 'General note' : getFloorName(String(floorNumber))}
            <div>{`"${pathOr('', ['data', 'note'], logElement)}"`}</div>
          </NoteContentWrapper>
        )
      case log && log.key.includes('files'):
        return (
          <FileContentWrapper>
            <FileIcon />
            <p>
              <a href={pathOr('', ['data', 'files', 0, 'url'], logElement)} target='_blank' rel='noreferrer'>
                {pathOr('', ['data', 'files', 0, 'name'], logElement)}
              </a>
            </p>
          </FileContentWrapper>
        )
      case log && log.key.includes('units.add'):
        return (
          <UnitsContentWrapper>
            <BuildingTypeWrapper>
              <span>Type:</span>
              {pathOr(
                '',
                [toCamelCase(pathOr('', ['data', 'units', 0, 'buildingType'], logElement)), 'label'],
                buildingTypes
              )}
            </BuildingTypeWrapper>
            <BuildingTypeWrapper>
              <span>Number of units:</span>
              {pathOr([], ['data', 'units'], logElement).length}
            </BuildingTypeWrapper>
          </UnitsContentWrapper>
        )
      case log && log.key.includes('units.delete'):
        return (
          <UnitsContentWrapper>
            <BuildingTypeWrapper>
              {values(pathOr({}, ['data'], logElement))[0]}
            </BuildingTypeWrapper>
          </UnitsContentWrapper>
        )
      default:
        return <div />
    }
  }

  const renderActivity = logElement => {
    const { user, action, createdAt } = logElement
    const log = logActions.find(e => e.key === action)

    return (
      <ActivityWrapper>
        <ActivityHeaderWrapper>
          <Bullet>&bull;</Bullet>
          {`${propOr('', 'firstName', user)} ${propOr('', 'lastName', user)} ${propOr('', 'value', log)}`}
        </ActivityHeaderWrapper>
        <CreationDate>
          {formatDateWithTime(createdAt)}
        </CreationDate>
        <ActivityContent>
          {getContent(log, logElement)}
        </ActivityContent>
      </ActivityWrapper>
    )
  }
  const [panelStatuses, setPanelStatuses] = useState({})
  const toggleSinglePanel = key => () => {
    const newState = { ...panelStatuses }
    newState[key] = !newState[key]
    setPanelStatuses(newState)
    }
  const expandAll = () => {
    const newState = {}
    for (const key in panelStatuses) {
      newState[key] = true
    }
    setPanelStatuses(newState)
  }
  const collapseAll = () => {
    const newState = {}
    for (const key in panelStatuses) {
      newState[key] = false
    }
    setPanelStatuses(newState)
  }

  useEffect(() => {
    let panelStates = {}
    filteredAuditLog.forEach(log => {
      const date = calendarDate(log.createdAt)
      panelStates[date] = false
    })
    setPanelStatuses(panelStates)
  }, [filteredAuditLog])

  const logsByDates = useMemo(() => {
    let obj = {}
    filteredAuditLog.forEach(log => {
      const date = calendarDate(log.createdAt)
      obj[date] = [...pathOr([], [date], obj), log]
    })
    const objKeys = keys(obj) ? keys(obj).sort((a, b) => {
      return new Date(b) - new Date(a)
    }) : []
    return objKeys.map(key => {
      const title = formatDate(key)
      const sorted = obj[key] ? reverse(sortBy(prop('createdAt'))(obj[key])) : []
      return (
        <>
          <ActivityExpandablePanel
            key={key}
            title={title}
            expanded={panelStatuses[key]}
            onToggle={toggleSinglePanel(key)}
          >
            {sorted.map(logElement => {
              return renderActivity(logElement)
            })}
          </ActivityExpandablePanel>
        </>)
    })
  }, [filteredAuditLog, panelStatuses])

  return (
    <div>
      {
        loading
          ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          )
          : (
            <>
              <ButtonWrapper>
                {logsByDates.length > 1
                  ? <ExpandCollapseButton onClick={expandAll}>Expand all</ExpandCollapseButton>
                  : null}
                {logsByDates.length > 1
                  ? <ExpandCollapseButton onClick={collapseAll}>Collapse all</ExpandCollapseButton>
                  : null}
              </ButtonWrapper>
              {logsByDates}
            </>
          )
      }
    </div>
  )
}

export default Activity

const ExpandCollapseButton = styled.button`
  border-radius: 4px;
  font-size: 12px;
  box-shadow: none;
  background-color: #dfe4f6;
  border: none;
  margin-bottom: 7px;
  padding: 5px 10px;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-bottom: 5px;
`

const ActivityWrapper = styled.div`
  box-sizing: border-box;
  margin-bottom: 25px;
  max-width: 100%;
`

const ActivityHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  ${({ theme }) => theme.colors.haiti};
`

const ActivityContent = styled.div``

const Bullet = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 25px;
  margin-right: 10px;
`

const CreationDate = styled.div`
  padding-left: 35px;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .4;
`

const NoteContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 10px 0 0 35px;
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};

  & > div {
    opacity: .7;
  }
`

const FileContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 10px 0 0 35px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};
  width: 95%;

  p {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    min-width: 19px;
    min-height: 17px;
    margin-right: 10px;
  }
`

const UnitsContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 10px 0 0 35px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};
  width: 95%;

  p {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    min-width: 19px;
    min-height: 17px;
    margin-right: 10px;
  }
`

const ListItem = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 12px;
  margin-bottom: 7px;

  &:first-letter {
    text-transform: uppercase;
  }

  & > div {
    opacity: .7;
  }
`

const UpdatedBpWrapper = styled.div`
  padding: 10px 0 0 35px;
`

const BuildingTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;

  div, span {
    opacity: 1;
  }
`

const BuildingTypes = styled.div`
  opacity: 1;
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
