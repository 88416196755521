const emptyBpDetailsState = {
  selectedBp: {},
  relationship: {
    owner: [],
    responsiblePersons: [],
    agent: [],
    occupant: []
  },
  connectedUsers: [],
  userRelation: []
}

export default emptyBpDetailsState
