import { emptyBillingListState } from 'features/billingList/ducks/state'
import { getBillingListRoutine, showInvoiceRoutine } from 'features/billingList/ducks/actions'

export default (state = emptyBillingListState, action) => {
  switch (action.type) {
    case getBillingListRoutine.SUCCESS:
      return {
        ...state,
        entries: action.payload
      }
    case showInvoiceRoutine.REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case showInvoiceRoutine.SUCCESS:
    case showInvoiceRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
