export default {
  templates: [],
  newTemplate: {
    hasUprn: false,
    hasAddress: false,
    hasBuildingTypes: false,
    hasOwner: false,
    hasAgent: false
  },
  newTemplateFiles: [],
  bps: [],
  invitations: [],
  totalPages: 1
}
